import { action, makeObservable, observable } from 'mobx';
import { DateTime } from 'luxon';

export default class DefaultValues {
  myActivities?: boolean;

  subctivities?: boolean = false;

  reportRangefilter: DateRange = { start: DateTime.local().startOf('month'), end: DateTime.local().endOf('month') };

  rangefilter: DateRange = { start: undefined, end: undefined };

  constructor() {
    makeObservable(this, {
      myActivities: observable,
      subctivities: observable,
      reportRangefilter: observable,
      rangefilter: observable,
      setMyActivities: action,
      setSubactivities: action,
      setReportRangeFilter: action,
      setRangeFilter: action,
    });
  }

  setMyActivities(myActivities: boolean) {
    this.myActivities = myActivities;
  }

  setSubactivities(subctivities: boolean) {
    this.subctivities = subctivities;
  }

  setReportRangeFilter(range: DateRange) {
    this.reportRangefilter = range;
  }

  setRangeFilter(range: DateRange) {
    this.rangefilter = range;
  }
}

interface DateRange {
  start?: DateTime;
  end?: DateTime;
}
