import React from 'react';
import { observer } from 'mobx-react-lite';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { Container, Hidden, Visible } from 'react-grid-system';
import { RouteNode } from 'route-node';
import { Layout, Affix } from 'antd';

import HomePage from '@pages/HomePage';
import Info from '@pages/Info';
import Mailerlite from '@pages/Mailerlite';
import Links from '@pages/Links';
import UsersPage from '@pages/UsersPage';
import User from '@pages/UsersPage/User';
import CellsPage from '@pages/CellsPage';
import Cell from '@pages/CellsPage/Cell';
import Checking from '@pages/CheckingPage';
import AccountsPage from '@pages/AccountsPage';
import Account from '@pages/AccountsPage/Account';
import Search from '@pages/Search';
import ProfilePage from '@pages/Profile';
import Activities from '@pages/Activities';
import TemplateReports from '@pages/TemplatesReports';
import TimeReports from '@pages/TimeReports';
import TimersPage from '@pages/Timers';
import Templates from '@pages/Templates';
import RealmPage from '@pages/RealmPage';
import NotificationsPage from '@pages/NotificationsPage';
import DrawersManager from '@comp/DrawersManager';
import ErrorAndLoading from '@comp/ErrorAndLoading';
import AppBar from '@comp/AppBar';
import SideMenu from '@comp/SideMenu';
import AccountFacebookReports from '@pages/AccountFacebookReports';
import AccountGoogleReports from '@pages/AccountGoogleReports';
import TutorialMenu from './TutorialMenu';

import useConfig from '@logic/config';

import styles from './router.module.less';
import Leads from '@pages/Leads';

const { Header, Content, Sider } = Layout;

interface RoutesProps {
  name: string;
  path: string;
  childRoutes?: RoutesProps[];
}

interface RoutesComponentsProps {
  path: string;
  component: (() => JSX.Element) | React.FC<{}>;
  childRoutes?: RoutesComponentsProps[];
}

interface RoutesArrayProps {
  name: string;
  path: string;
  component: (() => JSX.Element) | React.FC<{}>;
  childRoutes?: RoutesArrayProps[];
}

const routes: RoutesArrayProps[] = [
  { name: '', path: '/', component: () => <HomePage /> },
  { name: 'profile', path: '/profile', component: () => <ProfilePage /> },
  { name: 'timers', path: '/timers', component: () => <TimersPage /> },
  { name: 'templates', path: '/templates', component: () => <Templates accountTemplates={false} /> },
  {
    name: 'realm',
    path: '/realm',
    component: () => <ErrorAndLoading error={{ message: 'Página vazia' }} />,
    childRoutes: [{ name: 'activities', path: '/activities', component: () => <Activities page="realm_activities" /> }],
  },
  { name: 'notifications', path: '/notifications', component: () => <NotificationsPage /> },
  {
    name: 'search',
    path: '/search',
    component: () => <ErrorAndLoading error={{ message: 'Página vazia' }} />,
    childRoutes: [{ name: 'subject', path: '/:search', component: () => <Search /> }],
  },
  {
    name: 'admin',
    path: '/admin',
    component: () => <ErrorAndLoading error={{ message: 'Página vazia' }} />,
    childRoutes: [
      { name: 'home', path: '/', component: () => <ErrorAndLoading error={{ message: 'Página vazia' }} /> },
      {
        name: 'users',
        path: '/users',
        component: () => <UsersPage />,
        childRoutes: [{ name: 'edit', path: '/:user', component: () => <User /> }],
      },
      { name: 'realm', path: '/realm', component: () => <RealmPage /> },
      {
        name: 'cells',
        path: '/cells',
        component: () => <CellsPage />,
        childRoutes: [{ name: 'edit', path: '/:cell', component: () => <Cell /> }],
      },
      {
        name: 'accounts',
        path: '/accounts',
        component: () => <AccountsPage />,
        childRoutes: [{ name: 'edit', path: '/:account', component: () => <Account /> }],
      },
    ],
  },
  { name: 'activities', path: '/activities', component: () => <Activities page="my_activities" /> },
  {
    name: 'reports',
    path: '/reports',
    component: () => <ErrorAndLoading error={{ message: 'Página vazia' }} />,
    childRoutes: [
      { name: 'general', path: '/general', component: () => <Checking /> },
      { name: 'templates', path: '/templates', component: () => <TemplateReports /> },
      { name: 'time', path: '/time', component: () => <TimeReports /> },
    ],
  },
  {
    name: 'id',
    path: '/id',
    component: () => <ErrorAndLoading error={{ message: 'Página vazia' }} />,
    childRoutes: [
      {
        name: 'home',
        path: '/:id',
        component: () => <ErrorAndLoading error={{ message: 'Página vazia' }} />,
        childRoutes: [
          { name: 'tasks', path: '/tasks', component: () => <Activities page="account_activities" /> },
          { name: 'templates', path: '/templates', component: () => <Templates accountTemplates={true} /> },
          { name: 'leads', path: '/leads', component: () => <Leads /> },
          { name: 'infos', path: '/infos', component: () => <Info /> },
          { name: 'mailerlite', path: '/mailerlite', component: () => <Mailerlite /> },
          { name: 'facebook', path: '/facebook', component: () => <AccountFacebookReports /> },
          { name: 'google', path: '/google', component: () => <AccountGoogleReports /> },
          { name: 'links', path: '/links', component: () => <Links /> },
        ],
      },
    ],
  },
];

//returns the path to access the route
export function route(name: string, params?: Record<string, any> | undefined): string {
  return rootNode.buildPath(name, params);
}

//recursive function that creates the tree of routes
function createPaths(routes: RoutesProps[]): RouteNode[] {
  return routes.map(({ name, path, childRoutes }) => {
    const child = childRoutes ? createPaths(childRoutes) : undefined;
    return new RouteNode(name, path, child);
  });
}

//creates the routes
const rootNode = new RouteNode('', '', createPaths(routes));

//recursive function that returns the route and the path only
function flattenRoutes(routes: RoutesComponentsProps[], parentRoute?: string): RoutesComponentsProps[] {
  const routesArray = routes.map(({ path, component, childRoutes }) => {
    const route = { path: parentRoute ? parentRoute + path : path, component };
    const child = childRoutes ? flattenRoutes(childRoutes, route.path) : undefined;

    if (childRoutes && !!child) {
      return [route, ...child];
    }

    return route;
  });
  return routesArray.flat();
}

const Router: React.FC = () => {
  const config = useConfig();

  return (
    <BrowserRouter>
      <Visible xs sm>
        <Container>
          <div>Não é possível utilizar o sistema nestas dimenções de tela</div>
        </Container>
      </Visible>

      <Hidden xs sm>
        <Switch>
          {flattenRoutes(routes).map(
            (route: { path: string; component: (() => JSX.Element) | React.FC<{}> }, idx: number) => (
              <Route exact path={route.path} key={idx}>
                <Layout style={{ minHeight: '100vh' }} className={styles.root}>
                  <Affix offsetTop={0}>
                    <Header style={{ padding: 0 }}>
                      <AppBar />
                    </Header>
                  </Affix>

                  <Layout>
                    <Sider
                      width={240}
                      collapsedWidth={62}
                      theme="light"
                      collapsed={!!config.collapsed_menu}
                      className={styles.side_menu}>
                      <SideMenu />
                    </Sider>

                    <Layout className={styles.content}>
                      <Content style={{ padding: '24px 48px 0 48px', display: 'flex', flexDirection: 'column' }}>
                        <div style={{ flex: 1 }}>{route.component({})}</div>
                        <div style={{ textAlign: 'center', height: 60, padding: '16px' }}>ID Action Lab ©2021</div>
                        <DrawersManager />
                      </Content>
                    </Layout>
                  </Layout>
                </Layout>
                <TutorialMenu />
              </Route>
            ),
          )}

          <Route>
            <ErrorAndLoading error={{ message: 'Página não encontrada' }} />
          </Route>
        </Switch>
      </Hidden>
    </BrowserRouter>
  );
};

export default observer(Router);
