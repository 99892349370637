import React from 'react';
import { DateTime } from 'luxon';

import styles from './styles.module.less';

interface Props {
  name: string;
}

const Welcome: React.FC<Props> = ({ name }) => {
  const now = DateTime.local();

  let welcomeStr = 'Bom dia';
  if (now.hour >= 12) welcomeStr = 'Boa tarde';
  if (now.hour >= 18) welcomeStr = 'Boa noite';

  return (
    <div className={styles.root}>
      <div className={styles.introduction}>
        {welcomeStr}, {name}!
      </div>

      <div className={styles.performance_text}>Veja sua performance neste mês até agora:</div>
    </div>
  );
};

export default Welcome;
