import React from 'react';
import { Typography, List } from 'antd';
import { GrFormNext } from "react-icons/gr";

import styles from './styles.module.less';
import { classifications, sources } from '@pages/Leads/constants';

interface Props {
  leadId: string;
  leadName: string;
  leadClassification: string;
  leadSource: 'none' | 'whatsapp' | 'facebook' | 'website' | 'linkedin';
  setSelected: (id: string) => void;
}

const LeadItem: React.FC<Props> = ({ leadId, setSelected, leadName, leadClassification, leadSource }) => {
  const source = sources[leadSource];
  const classification = classifications[leadClassification];

  return (
    <List.Item
      id="open_lead"
      onClick={() => setSelected(leadId)}
      extra={<GrFormNext className="nexticon" />}
      className={styles.item}
      style={listItemStyle}>
      <Typography.Text className={styles.icon}>
        {React.cloneElement(classification.icon, {
          color: classification.color,
          size: 22,
        })}
      </Typography.Text>

      <div className={styles.text}>
        <Typography.Text ellipsis>
          {leadName}
        </Typography.Text>
        {source !== 'none' && (
          <Typography.Text ellipsis className={styles.icontext}>
            {React.cloneElement(source.icon, { color: '#ffffff0', size: 12 })} <span>via {source.label}</span>
          </Typography.Text>
        )}
      </div>
      <div style={{ display: 'flex', flex: 1, cursor: 'pointer' }} />
    </List.Item>
  );
};

export default LeadItem;

const listItemStyle: React.CSSProperties = {
  padding: '8px 0',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  backgroundColor: '#fafafa',
  cursor: 'pointer',
};
