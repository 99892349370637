import React, { useState } from 'react';
import { Typography } from 'antd';

import { RightOutlined } from '@ant-design/icons';

import ItemRender from './ItemReport';
import AvatarPicture from '@comp/AvatarPicture';

import styles from './styles.module.less';

interface TemplateRenderProps {
  id: string; // Unused
  account?: any;
  title: string;
  items: any[];
}

const TemplateRender: React.FC<TemplateRenderProps> = ({ id, title, account, items }) => {
  const [collapsed, setCollapsed] = useState(true);

  return (
    <div className={styles.template} onClick={() => setCollapsed((c: boolean) => !c)}>
      <div className={styles.title}>
        {items.length > 0 && <RightOutlined rotate={collapsed ? 0 : 90} style={{ marginRight: 6, fontSize: 12 }} />}
        {account && (
          <span style={{ marginRight: 6 }}>
            <AvatarPicture pictureLink={account.logoUrl} target="Account" size={20} name={account.name} />
          </span>
        )}
        <Typography.Text style={{ flex: 1, fontSize: 16 }}>{title}</Typography.Text>
      </div>

      {!collapsed && items.length > 0 && (
        <table>
          <tr>
            <th>
              <Typography.Text style={{ fontWeight: 600 }}>Atividade</Typography.Text>
            </th>
            <th>
              <Typography.Text style={{ fontWeight: 600 }}>Mínimo</Typography.Text>
            </th>
            <th>
              <Typography.Text style={{ fontWeight: 600 }}>Máximo</Typography.Text>
            </th>
            <th>
              <Typography.Text style={{ fontWeight: 600 }}>Médio</Typography.Text>
            </th>
            <th>
              <Typography.Text style={{ fontWeight: 600 }}>Planejado</Typography.Text>
            </th>
            <th>
              <Typography.Text style={{ fontWeight: 600 }}>Atividades</Typography.Text>
            </th>
          </tr>
          {items.map((item: string) => (
            <ItemRender key={`item_${item}`} id={item} level={1} />
          ))}
        </table>
      )}
    </div>
  );
};

export default TemplateRender;
