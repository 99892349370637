import React, { useCallback } from 'react';
import { Steps, Tooltip } from 'antd';

import { stepsIconsArray, stepsKeys } from '@pages/Activities';

import styles from './styles.module.less';

interface Props {
  stepsIcons: JSX.Element[];
  stepKeys: string[];
  stepKeysTitle: { title: string }[];
  onChange?: (e: any) => void;
  id?: string;
  value?: string;
  type: 'assignment' | 'default';
}

// Etapa da atividade

const StepSelector: React.FC<Props> = ({ stepsIcons, stepKeys, stepKeysTitle, onChange, id, value, type }) => {
  const curr = stepKeys.indexOf(value || 'execution');

  const handleChange = useCallback(
    (current) => (onChange ? onChange({ target: { value: stepKeys[current], id } }) : {}),
    [onChange, id, stepKeys],
  );

  const color = !((type === 'assignment' && curr === 1) || (type === 'default' && curr === 4))
    ? stepsKeys[curr]?.color
    : '#b2dd9d';

  return (
    <Steps current={curr} onChange={handleChange} className={styles.steps}>
      {stepKeys.map((key, idx) => {
        if (type === 'assignment' && ['execution', 'done'].indexOf(key) === -1) return null;
        const index = type === 'assignment' && idx === 1 ? 4 : idx;

        return (
          <Steps.Step
            icon={
              <Tooltip destroyTooltipOnHide={{ keepParent: false }} title={stepsKeys[index].title}>
                {stepsIconsArray(curr >= idx ? { color } : {})[index]}
              </Tooltip>
            }
            style={{ color }}
            key={key}
            status={curr > idx ? 'finish' : curr === idx ? 'process' : 'wait'}
          />
        );
      })}
    </Steps>
  );
};

export default StepSelector;
