import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useMutation } from '@apollo/client';
import { Card, Button, List, Popover, notification, Tag } from 'antd';

import PaginatedList from '@comp/PaginatedList';
import AvatarPicture from '@comp/AvatarPicture';
import TutorialButton from '@comp/TutorialButton';
import TutorialUserList from './TutorialUserList';

import { route } from '@router';
import { delete_user } from '@logic/mutations';
import { users_list } from '@logic/queries';
import errorMessage from '@logic/functions/errorHandeling';

import styles from './styles.module.less';

const UsersPage: React.FC = () => {
  const [confirm, setConfirm] = useState<number | undefined>();
  const [reload, setReload] = useState(false);
  const [users, setUsers] = useState(false);
  const history = useHistory();

  const [DeleteUser] = useMutation(delete_user, { variables: { id: 0 } });

  async function Delete(id: number) {
    try {
      let res = await DeleteUser({ variables: { id } });
      notification.open({
        type: 'success',
        message: 'Usuário deletado',
        description: `usuário ${res.data?.deleteUser.name} deletado`,
      });
      setReload(true);
    } catch (err) {
      notification.open({ type: 'error', ...errorMessage('graph_err', err) });
    }
  }

  return (
    <Card className={styles.root}>
      <Button id="add_new_user" type="primary" onClick={() => history.push(route('admin.users.edit', { user: 'new' }))}>
        Adicionar Usuário
      </Button>

      <PaginatedList
        query={users_list}
        reloadParam={reload}
        showPath={(data) => {
          setUsers(data.me?.realm?.users?.total > 0);
          return { total: data.me?.realm?.users?.total, data: data.me?.realm?.users?.data };
        }}
        limit={10}
        renderItem={({ name, roles, status, email, id, avatarUrl }, idx: number) => (
          <List.Item
            id="open_user"
            key={id}
            className={styles.item}
            onClick={() => history.push(route('admin.users.edit', { user: id }))}
            actions={[
              <Popover
                destroyTooltipOnHide={{ keepParent: false }}
                content={
                  <>
                    <Button
                      onClick={(e) => {
                        e.stopPropagation();
                        setConfirm(undefined);
                      }}>
                      Não
                    </Button>
                    <Button
                      style={{ marginLeft: 6 }}
                      onClick={(e) => {
                        e.stopPropagation();
                        setConfirm(undefined);
                        Delete(id);
                      }}>
                      Sim
                    </Button>
                  </>
                }
                title="Excluir este usuário?"
                trigger="click"
                visible={confirm === idx}
                onVisibleChange={() => setConfirm(idx)}>
                <Button id="delete_user" onClick={(e) => e.stopPropagation()}>
                  Excluir
                </Button>
              </Popover>,
              <Button
                id="open_user_button"
                onClick={(e) => {
                  e.stopPropagation();
                  return history.push(route('admin.users.edit', { user: id }));
                }}>
                Visualizar
              </Button>,
            ]}>
            <List.Item.Meta
              avatar={
                <div style={{ cursor: 'pointer' }}>
                  <AvatarPicture pictureLink={avatarUrl} target="User" size={40} name={name} />
                </div>
              }
              title={
                <>
                  {name}

                  {roles.map((role: string) => (
                    <Tag
                      key={role}
                      color="blue"
                      style={{
                        position: 'relative',
                        top: -2,
                        margin: '0 0 0 8px',
                        borderRadius: 4,
                        fontSize: 7,
                        fontWeight: 700,
                        padding: '4px 6px',
                        lineHeight: '7px',
                        cursor: 'pointer',
                      }}>
                      {role === 'admin' && 'ADMINISTRADOR'}
                      {role === 'customer_success' && 'SUCESSO DE CLIENTE'}
                      {role === 'media_tester' && 'MEDIA TESTER'}
                      {role === 'creator' && 'CRIAÇÃO'}
                      {role === 'customer' && 'CLIENTE'}
                    </Tag>
                  ))}
                  {
                    <Tag
                      key={status}
                      color="red"
                      visible={status === 'inactive' ? true : false}
                      style={{
                        position: 'relative',
                        top: -2,
                        margin: '0 0 0 8px',
                        borderRadius: 4,
                        fontSize: 7,
                        fontWeight: 700,
                        padding: '4px 6px',
                        lineHeight: '7px',
                        cursor: 'pointer',
                      }}>
                      {status === 'inactive' && 'INATIVO'}
                    </Tag>
                  }
                </>
              }
              description={email}
            />
          </List.Item>
        )}
      />

      <TutorialButton
        tourId="020_UserListManagement"
        top={80}
        right={20}
        placement="left"
        title="Tutorial da lista de usuários"
      />

      <TutorialUserList hasUser={users} />
    </Card>
  );
};

export default UsersPage;
