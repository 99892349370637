import { DateTime } from 'luxon';

export function parseDate(date: string, format: string = 'yyyy-LL-dd') {
  return DateTime.fromFormat(date, format, {
    zone: 'utc',
    locale: 'pt-br',
  }).toLocal();
}

export function parseDateTime(date: string) {
  return parseDate(date, 'yyyy-LL-dd HH:mm:ss');
}

export function weekDays() {
  const now = DateTime.local().startOf('day');
  const end = DateTime.local().endOf('month');

  const prev = [];
  const next = [];

  let curr = DateTime.local().startOf('month');

  while (true) {
    // Para o loop
    if (!curr.hasSame(end, 'month')) break;

    // Ignora fim de semana
    if (curr.weekday < 6) {
      if (curr.diff(now, 'days').days < 0) {
        prev.push(curr.toFormat('yyyyLLdd'));
      } else {
        next.push(curr.toFormat('yyyyLLdd'));
      }
    }

    curr = curr.plus({ day: 1 });
  }

  return { prev, next };
}
