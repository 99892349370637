import React from 'react';
import { useLocation } from 'react-router-dom';

import ReportsAppBar from './ReportsAppBar';
import AdminAppBar from './AdminAppBar';
import AccountsAppBar from './AccountsAppBar';

import styles from './styles.module.less';

const AppBar: React.FC = () => {
  const location = useLocation();

  const admin = location.pathname.startsWith('/admin');
  const reports = location.pathname.startsWith('/reports');

  return (
    <div className={styles.root}>
      {!!admin && <AdminAppBar />}

      {!!reports && <ReportsAppBar />}

      {!reports && !admin && <AccountsAppBar />}
    </div>
  );
};

export default AppBar;
