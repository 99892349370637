import React, { useEffect, useCallback } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { Drawer, Tabs, notification, Popconfirm, Button } from 'antd';
import { useLazyQuery, useMutation } from '@apollo/client';

import { DeleteOutlined, SyncOutlined, CloseCircleOutlined } from '@ant-design/icons';

import { route } from '@router';
import { account_info } from '@logic/queries';
import errorMessage from '@logic/functions/errorHandeling';
import useCloseFn from '@logic/functions/closeDrawer';
import { account_info_delete } from '@logic/mutations';

import ErrorAndLoading from '@comp/ErrorAndLoading';
import TutorialButton from '@comp/TutorialButton';
import EditInfo from './EditInfo';
import NewInfo from './NewInfo';
import TutorialInfoDrawer from './TutorialInfoDrawer';

interface Props {
  infoId: string;
}

const InfoDrawer: React.FC<Props> = ({ infoId }) => {
  const { id: paramAccountId } = useParams<any>();
  const history = useHistory();

  const accountId = paramAccountId;

  const newInfo = infoId === 'new';

  //----------------- Read info------------------------------

  const [fetchInfo, { data, loading, error }] = useLazyQuery(account_info, { fetchPolicy: 'cache-and-network' });

  //----------------- Delete info---------------------------

  const [DeleteAccountInfo] = useMutation(account_info_delete, { variables: { id: infoId } });

  async function Delete() {
    try {
      await DeleteAccountInfo();

      notification.open({
        placement: 'bottomRight',
        duration: 2,
        type: 'success',
        message: 'Informação do cliente excluído',
      });

      history.push(route('id.home.infos', { id: accountId }));
    } catch (err) {
      notification.open({ type: 'error', ...errorMessage('graph_err', err) });
    }
  }

  const doCloseDrawer = useCallback(() => history.push(route('id.home.infos', { id: accountId })), [
    accountId,
    history,
  ]);

  useEffect(() => {
    if (newInfo) return;

    fetchInfo({ variables: { id: infoId } });
  }, [infoId, newInfo, fetchInfo]);

  useCloseFn(doCloseDrawer, 'infoListItem');

  //------------------------------------------------------------------------------

  return (
    <Drawer
      visible
      forceRender
      destroyOnClose
      mask={false}
      width={'800px'}
      onClose={doCloseDrawer}
      closeIcon={loading ? <SyncOutlined spin style={{ color: '#ff5333' }} /> : <CloseCircleOutlined />}
      title={data?.account_info?.account?.name}
      headerStyle={{ paddingBottom: 8, paddingTop: 8, borderBottom: 'none' }}
      bodyStyle={{ height: '100vh', paddingBottom: 0, paddingTop: 0 }}>
      {error && <ErrorAndLoading error={error} />}
      <Tabs
        destroyInactiveTabPane={true}
        tabPosition="top"
        style={{ flexDirection: 'column', width: '100%', height: '100%' }}>
        <Tabs.TabPane tab="Infos" key="info_tab">
          {newInfo && <NewInfo />}

          {!newInfo && data && <EditInfo infoId={infoId} accountId={accountId} />}
        </Tabs.TabPane>

        {!newInfo && (
          <Tabs.TabPane
            active={false}
            disabled
            tab={
              <Popconfirm
                overlayStyle={{ width: 300 }}
                title="Tem certeza que deseja excluír a informação deste cliente?"
                onConfirm={() => Delete()}
                okText="Sim"
                cancelText="Não">
                <Button style={{ marginLeft: 8, padding: 0 }} type="text" icon={<DeleteOutlined id="delete_info" />} />
              </Popconfirm>
            }
          />
        )}
      </Tabs>

      <TutorialButton
        tourId="013_InfoDrawer"
        circleSize="small"
        iconSize={14}
        bottom={20}
        right={40}
        placement="left"
        title="Tutorial para editar informações úteis"
      />

      <TutorialInfoDrawer isNew={newInfo} />
    </Drawer>
  );
};

export default InfoDrawer;
