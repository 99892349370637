import { Badge, Dropdown, Menu, Modal, notification, Tabs } from 'antd';
import React, { useCallback } from 'react';
import { useHistory } from 'react-router-dom';

import { DeleteOutlined, EllipsisOutlined, SwapOutlined } from '@ant-design/icons';
import { useMutation } from '@apollo/client';
import errorMessage from '@logic/functions/errorHandeling';
import { convert_task, delete_task } from '@logic/mutations';

import ClockTab from '../ClockTab';
import HelpTab from '../HelpTab';
import NotesTab from '../NotesTab';
import TaskTimer from '../TaskTimer';
import NewTask from './NewTask';
import styles from './styles.module.less';
import TaskTab from './TaskTab';

interface Props {
  tab?: string;
  task?: any;
  taskId: string;
  accountId: string;
  setSelected: (id: string) => void;
  parent?: string;
  refetch: () => void;
}
const DefaultTask: React.FC<Props> = ({ task, tab, accountId, taskId, setSelected, parent, refetch }) => {
  const history = useHistory();

  const newTask = taskId === 'new';

  const [DeleteTask] = useMutation(delete_task, {
    variables: { id: taskId },
  });

  const [ConvertTask] = useMutation(convert_task, {
    variables: { id: taskId, type: 'assignment' },
  });

  const handleDelete = useCallback(() => {
    Modal.confirm({
      title: 'Excluir tarefa',
      icon: <DeleteOutlined />,
      content: (
        <div>
          <p>Tem certeza que deseja excluír esta tarefa?</p>
          <p>Ao excluír uma tarefa todas as subtarefas também serão excluídas.</p>
        </div>
      ),
      onOk: async () => {
        try {
          await DeleteTask();

          notification.open({
            placement: 'bottomRight',
            duration: 2,
            type: 'success',
            message: 'Tarefa excluída',
          });

          !!parent ? setSelected(parent) : history.push(history.location.pathname);
        } catch (err) {
          notification.open({
            type: 'error',
            ...errorMessage('graph_err', err),
          });
        }
      },
      okType: 'danger',
    });
  }, [DeleteTask, parent, setSelected, history]);

  const handleConvert = useCallback(() => {
    Modal.confirm({
      title: 'Converter tarefa',
      icon: <SwapOutlined />,
      content: (
        <div>
          <p>Tem certeza que deseja converter esta tarefa?</p>
          <p>Ao converter a tarefa para tarefa pontual, a data de execução e os feedbacks serão perdidos.</p>
        </div>
      ),
      onOk: async () => {
        try {
          await ConvertTask();

          notification.open({
            placement: 'topRight',
            duration: 2,
            type: 'success',
            message: 'Tarefa convertida',
          });

          refetch();
        } catch (err) {
          notification.open({
            type: 'error',
            ...errorMessage('graph_err', err),
          });
        }
      },
      okType: 'danger',
    });
  }, [ConvertTask, refetch]);

  const convertMenu = (
    <Menu style={{ width: 260 }} mode="vertical" theme="light">
      <Menu.Item key="convert_assignment" icon={<SwapOutlined style={{ marginRight: 8 }} />} onClick={handleConvert}>
        Converter tarefa pontual
      </Menu.Item>

      <Menu.Item icon={<DeleteOutlined style={{ marginRight: 8 }} />} onClick={handleDelete}>
        Excluir
      </Menu.Item>
    </Menu>
  );

  return (
    <div className={styles.root}>
      <Tabs
        activeKey={history.location.hash.split('/')[2]}
        destroyInactiveTabPane={true}
        tabPosition="top"
        tabBarStyle={{ height: '30px', margin: 0 }}
        style={{ height: '100%' }}
        onTabClick={(activeKey) => history.push('#task/' + taskId + '/' + activeKey)}
        tabBarExtraContent={!newTask && <TaskTimer taskId={taskId} />}>
        <Tabs.TabPane tab="Atividade" key="task_tab">
          {newTask && <NewTask />}

          {!!task && !newTask && (
            <TaskTab refetch={refetch} accountId={accountId} taskData={task} setSelected={setSelected} />
          )}
        </Tabs.TabPane>

        {!newTask && (
          <Tabs.TabPane
            tab={
              <Badge count={task?.notes?.total} size="small" offset={[10, 8]}>
                Notas
              </Badge>
            }
            key="notes_tab">
            <NotesTab taskId={taskId} />
          </Tabs.TabPane>
        )}

        {!newTask && (
          <Tabs.TabPane tab="Tempo" key="clock_tab">
            <ClockTab taskId={taskId} />
          </Tabs.TabPane>
        )}

        {!!task?.from_template_item && !newTask && (
          <Tabs.TabPane tab="Ajuda" key="help_tab">
            <HelpTab taskId={taskId} />
          </Tabs.TabPane>
        )}

        {!newTask && (
          <Tabs.TabPane
            active={false}
            disabled
            tab={
              <Dropdown trigger={['click']} overlay={convertMenu}>
                <EllipsisOutlined style={{ color: 'black', fontSize: 20 }} />
              </Dropdown>
            }
            key="options_tab"
          />
        )}
      </Tabs>
    </div>
  );
};

export default DefaultTask;
