import { useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

export default function useHashInfo(): any {
  const history = useHistory();
  const location = useLocation();

  const [hashString, setHashString] = useState(location.hash);

  history.listen(({ hash }) => {
    setHashString(hash);
  });

  const hash = hashString.split('/');
  const hashObject = hash.reduce((acc, curr, idx) => ({ ...acc, [idx]: curr }), {});

  return hashObject;
}
