import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useMutation, useQuery, useLazyQuery } from '@apollo/client';
import { List, Result } from 'antd';

import Notification from '../Notification';
import Navigate from '@comp/Navigate';

import { notifications_query, total_notifications_query } from '@logic/queries';
import { delete_notification, mark_all_as_viewed, mark_as_viewed } from '@logic/mutations';
import { route } from '@router';

import fireworks from '../../../img/fireworks.svg';

import styles from './styles.module.less';

interface Props {
  unreadNotifications: number;
}

const NotificationsList: React.FC<Props> = ({ unreadNotifications }) => {
  const [fetchTotal] = useLazyQuery(total_notifications_query, { fetchPolicy: 'network-only' });

  return (
    <List style={{ backgroundColor: '#fff', width: 460 }}>
      <List.Item key="header" style={{ padding: '6px 10px', fontSize: 14, fontWeight: 600 }}>
        Últimas notificações
      </List.Item>

      <UnreadNotifications refetchTotalUnread={() => fetchTotal()} />

      <ReadNotifications limit={unreadNotifications} />

      <Navigate to={route('notifications')}>
        <List.Item key="all" className={styles.see_all}>
          Ver todas
        </List.Item>
      </Navigate>
    </List>
  );
};

export default NotificationsList;

interface UnreadNotificationsProps {
  refetchTotalUnread: () => void;
}

const UnreadNotifications: React.FC<UnreadNotificationsProps> = ({ refetchTotalUnread }) => {
  const [notifications, setNotifications] = useState<any[]>([]);

  const history = useHistory();

  const { data, refetch } = useQuery(notifications_query, {
    variables: { limit: 9, viewed: false },
    fetchPolicy: 'network-only',
  });

  const [MarkAsViewed] = useMutation(mark_as_viewed);
  const handleMarkAsSeen = (id: string) =>
    MarkAsViewed({ variables: { id } }).then(() => {
      refetchTotalUnread();
      refetch();
    });

  const [MarkAllAsViewed] = useMutation(mark_all_as_viewed);
  const handleMarkAllAsSeen = () =>
    MarkAllAsViewed().then(() => {
      refetchTotalUnread();
      refetch();
    });

  const [Delete] = useMutation(delete_notification);
  const handleDelete = (id: string) =>
    Delete({ variables: { id } }).then(() => {
      refetchTotalUnread();
      refetch();
    });

  useEffect(() => {
    if (!data) return;
    setNotifications(data?.notifications?.data);
  }, [data]);

  if (notifications.length === 0) return null;

  return (
    <>
      {notifications.map(
        ({ id, type, initiator: { name, avatarUrl }, step, viewed, notified_at, task: { title, id: taskId } }: any) => (
          <List.Item style={{ padding: 0 }} key={`notification_${id}`}>
            <Notification
              id={id}
              initiatorAvatar={avatarUrl}
              initiatorName={name}
              step={step}
              taskId={taskId}
              taskTitle={title}
              type={type}
              notified_at={notified_at}
              viewed={viewed}
              handleMarkAsSeen={handleMarkAsSeen}
              handleDelete={handleDelete}
              handleOpenTask={(id, tab) => history.push(history?.location?.pathname + '#task/' + id + tab)}
            />
          </List.Item>
        ),
      )}

      {notifications.length > 0 && (
        <List.Item key="mark_all_as_read" className={styles.see_all} onClick={handleMarkAllAsSeen}>
          marcar todas como lidas
        </List.Item>
      )}
    </>
  );
};

interface ReadNotificationsProps {
  limit: number;
}

const ReadNotifications: React.FC<ReadNotificationsProps> = ({ limit }) => {
  const history = useHistory();
  const readLimit = limit >= 9 ? 0 : 9 - limit;

  const { data, refetch } = useQuery(notifications_query, {
    variables: { limit: readLimit, viewed: true },
    fetchPolicy: 'network-only',
  });

  const [Delete] = useMutation(delete_notification);
  const handleDelete = (id: string) => Delete({ variables: { id } }).then(() => refetch());

  if (limit >= 9) return null;

  if (data?.notifications?.data?.length === 0 && limit === 0)
    return (
      <Result
        style={{ height: 160, paddingTop: 30 }}
        icon={<img src={fireworks} alt="Parabéns!" className={styles.fireworks_image} style={{ height: 64 }} />}
        subTitle="Nenhuma notificação, você está em dia!"
      />
    );

  return (
    <>
      {data?.notifications?.data?.length > 0 && (
        <List.Item key="read" className={styles.read}>
          notificações lidas
        </List.Item>
      )}

      {data?.notifications?.data?.map(
        ({ id, type, initiator: { name, avatarUrl }, step, viewed, notified_at, task: { title, id: taskId } }: any) => (
          <List.Item style={{ padding: 0 }} key={`notification_${id}`}>
            <Notification
              id={id}
              initiatorAvatar={avatarUrl}
              initiatorName={name}
              step={step}
              taskId={taskId}
              taskTitle={title}
              type={type}
              notified_at={notified_at}
              viewed={viewed}
              handleDelete={handleDelete}
              handleOpenTask={(id, tab) => history.push(history?.location?.pathname + '#task/' + id + tab)}
            />
          </List.Item>
        ),
      )}
    </>
  );
};
