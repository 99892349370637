import axios from 'axios';

export async function getCurrentBuildInfo(): Promise<string> {
  if (process.env.NODE_ENV === 'development') return 'dev';

  try {
    const { data } = await axios.get(`https://lab.idaction.com.br/build-time.txt`, {
      params: {
        q: Date.now(),
      },
    });

    return data;
  } catch (err) {
    return '';
  }
}
