import React, { useMemo } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { Menu } from 'antd';
import { useTranslation } from 'react-i18next';

import { FaAddressBook, FaChess, FaRegCalendarAlt /*, FaThermometerHalf, FaTrafficLight*/ } from 'react-icons/fa';
import { RiInformationLine } from 'react-icons/ri';
import { GoTasklist, GoMail } from 'react-icons/go';
import { CgToolbox } from 'react-icons/cg';
import { MdHttp } from 'react-icons/md';
//import { AiOutlineSchedule } from 'react-icons/ai';
//import { ImPencil2 } from 'react-icons/im';
import { MenuOutlined } from '@ant-design/icons';

import HomeButton from '../HomeButton';
import RightMenu from '../RigthMenu';
import Navigate from '@comp/Navigate';
import NewVersionButton from '@comp/NewVersionButton';

import { route } from '@router';
import useConfig from '@logic/config';

import styles from './styles.module.less';

const { Item, SubMenu } = Menu;

// Menu superior para as telas da conta

const AccountsAppBar: React.FC = () => {
  const location = useLocation();
  const { id: paramId } = useParams<any>();

  const { t } = useTranslation('accountsAppBar');

  const config = useConfig();
  const collapsed = config.collapsed_menu;

  const checkRoute = useMemo(
    () => (key: string, routeName: string, params?: object) =>
      location.pathname.indexOf(route(routeName, params)) !== -1 ? key : '',
    [location],
  );

  const id = paramId || 0;

  // Itens do menu que estão selecionados
  const selectedKeys = [
    checkRoute('activities', 'id.home.tasks', { id }),
    checkRoute('url-redirect', 'id.home.links', { id }),
  ].filter((e) => !!e);

  return (
    <Menu style={{ width: '100%' }} mode="horizontal" theme="light" selectedKeys={selectedKeys}>
      <Item key="home" className={styles.item_logo}>
        <NewVersionButton elseRender={<HomeButton />} />
      </Item>

      <Item
        id="button_collapsed_menu"
        key="side_menu"
        title={!collapsed ? t('tooltip_collapsed_menu') : t('tooltip_expanded_menu')}
        icon={<MenuOutlined />}
        style={{ padding: 0 }}
        onClick={() => {
          config.setConfig('collapsed_menu', !collapsed);
        }}
      />

      {!!id && (
        <Item key="activities">
          <Navigate to={route('id.home.tasks', { id })}>
            <span className={styles.menuItem}>
              <GoTasklist style={{ fontSize: '20px', marginRight: '6px' }} />
              <span>{t('activities')}</span>
            </span>
          </Navigate>
        </Item>
      )}

      {!!id && (
        <Item key="leads">
          <Navigate to={route('id.home.leads', { id })}>
            <span className={styles.menuItem}>
              <FaAddressBook style={{ fontSize: '20px', marginRight: '6px' }} />
              <span>{t('leads')}</span>
            </span>
          </Navigate>
        </Item>
      )}

      {!!id && (
        <SubMenu
          key="strategic"
          title={t('strategic')}
          icon={<FaChess style={{ fontSize: '20px', marginRight: '6px', marginBottom: -5 }} />}>
          <Item key="account-info">
            <Navigate to={route('id.home.infos', { id })}>
              <span className={styles.menuItem}>
                <RiInformationLine style={{ fontSize: '18px', marginRight: '6px', marginBottom: -4 }} />
                <span>{t('info')}</span>
              </span>
            </Navigate>
          </Item>

          <Item key="mailerlite-info">
            <Navigate to={route('id.home.mailerlite', { id })}>
              <span className={styles.menuItem}>
                <GoMail style={{ fontSize: '18px', marginRight: '6px', marginBottom: -4 }} />
                <span>MailerLite</span>
              </span>
            </Navigate>
          </Item>

          {/*
          <SubMenu
            key="account-report"
            title={t('reports')}
            icon={<LineChartOutlined style={{ fontSize: '18px', marginRight: '6px', marginBottom: -4 }} />}>
            <Item key="facebook-report">
              <Navigate to={route('id.home.facebook', { id })}>
                <span className={styles.menuItem}>
                  <FaFacebook style={{ fontSize: '18px', marginRight: '6px', marginBottom: 0 }} />
                  <span>{t('facebook_report')}</span>
                </span>
              </Navigate>
            </Item>

            <Item key="google-report">
              <Navigate to={route('id.home.google', { id })}>
                <span className={styles.menuItem}>
                  <GoogleOutlined style={{ fontSize: '18px', marginRight: '6px', marginBottom: 0 }} />
                  <span>{t('google_report')}</span>
                </span>
              </Navigate>
            </Item>
          </SubMenu>
          */}
        </SubMenu>
      )}

      {/* {!!id && (
        <Item key="meetings" disabled>
          <span className={styles.menuItem}>
            <FaRegCalendarAlt style={{ fontSize: '20px', marginRight: '6px' }} />
            <span>{t('meetings')}</span>
          </span>
        </Item>
      )} */}

      {!!id && (
        <SubMenu
          key="tools"
          title={t('tools')}
          icon={<CgToolbox style={{ fontSize: '20px', marginRight: '6px', marginBottom: -5 }} />}>
          <Item key="url-redirect">
            <Navigate to={route('id.home.links', { id })}>
              <span className={styles.menuItem}>
                <MdHttp style={{ fontSize: '18px', marginRight: '6px', marginBottom: -4 }} />
                <span>{t('links')}</span>
              </span>
            </Navigate>
          </Item>

          {/*<SubMenu
            key="trafic-tools"
            title={t('traffic_tools')}
            icon={<FaTrafficLight style={{ fontSize: '20px', marginRight: '6px', marginBottom: -5 }} />}>
            <Item key="thermometer" disabled>
              <span className={styles.menuItem}>
                <FaThermometerHalf style={{ fontSize: '18px', marginRight: '6px', marginBottom: -4 }} />
                <span>{t('thermometer')}</span>
              </span>
            </Item>

            <Item key="ads-agenda" disabled>
              <span className={styles.menuItem}>
                <AiOutlineSchedule style={{ fontSize: '18px', marginRight: '6px', marginBottom: -4 }} />
                <span>{t('ad_schedule')}</span>
              </span>
            </Item>

            <Item key="url-redirect">
              <Navigate to={route('id.home.links', { id })}>
                <span className={styles.menuItem}>
                  <MdHttp style={{ fontSize: '18px', marginRight: '6px', marginBottom: -4 }} />
                  <span>{t('links')}</span>
                </span>
              </Navigate>
            </Item>
          </SubMenu>

          <SubMenu
            key="creation-tools"
            title={t('creation_tools')}
            disabled
            icon={<ImPencil2 style={{ fontSize: '20px', marginRight: '6px', marginBottom: -5 }} />}>
            <Item key="landing-pages" disabled>
              <span className={styles.menuItem}>
                <span>{t('landing_page')}</span>
              </span>
            </Item>

            <Item key="chatbot" disabled>
              <span className={styles.menuItem}>
                <span>{t('chat_bot')}</span>
              </span>
            </Item>

            <Item key="email-marketing" disabled>
              <span className={styles.menuItem}>
                <span>{t('email_marketing')}</span>
              </span>
            </Item>

            <Item key="copy-creator" disabled>
              <span className={styles.menuItem}>
                <span>{t('copy')}</span>
              </span>
            </Item>
          </SubMenu>*/}
        </SubMenu>
      )}

      <RightMenu />
    </Menu>
  );
};

export default AccountsAppBar;
