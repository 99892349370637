import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useMutation } from '@apollo/client';
import { Card, Button, List, notification, Popover } from 'antd';

import PaginatedList from '@comp/PaginatedList';
import AvatarPicture from '@comp/AvatarPicture';
import TutorialButton from '@comp/TutorialButton';
import TutorialCellList from './TutorialCellList';

import { route } from '@router';
import { cells_list } from '@logic/queries';
import { delete_cell } from '@logic/mutations';
import errorMessage from '@logic/functions/errorHandeling';

import styles from './styles.module.less';

const CellsPage: React.FC = () => {
  const [confirm, setConfirm] = useState<number | undefined>();
  const [reload, setReload] = useState(false);
  const [cells, setCells] = useState(false);
  const history = useHistory();

  const [DeleteCell] = useMutation(delete_cell, { variables: { id: 0 } });

  async function Delete(id: number) {
    try {
      let res = await DeleteCell({ variables: { id } });
      notification.open({
        type: 'success',
        message: 'Célula excluída',
        description: `Célula ${res.data?.deleteCell.name} excluída`,
      });
      setReload(true);
    } catch (err) {
      notification.open({ type: 'error', ...errorMessage('graph_err', err) });
    }
  }

  return (
    <Card className={styles.root}>
      <Button id="add_new_cell" type="primary" onClick={() => history.push(route('admin.cells.edit', { cell: 'new' }))}>
        Adicionar Célula
      </Button>

      <PaginatedList
        query={cells_list}
        params={{ fetchPolicy: 'network-only' }}
        reloadParam={reload}
        showPath={(data) => {
          setCells(data.me?.realm?.cells?.total > 0);

          return {
            total: data.me?.realm?.cells?.total,
            data: data.me?.realm?.cells?.data,
          };
        }}
        limit={10}
        renderItem={({ name, id, accounts, logoUrl }, idx: number) => (
          <List.Item
            id="open_cell"
            key={id}
            className={styles.item}
            onClick={() => history.push(route('admin.cells.edit', { cell: id }))}
            actions={[
              // se trocar por um popconfirm fica melhor
              <Popover
                destroyTooltipOnHide={{ keepParent: false }}
                content={
                  <>
                    <Button
                      onClick={(e) => {
                        e.stopPropagation();
                        setConfirm(undefined);
                      }}>
                      Não
                    </Button>
                    <Button
                      style={{ marginLeft: 6 }}
                      onClick={(e) => {
                        e.stopPropagation();
                        setConfirm(undefined);
                        Delete(id);
                      }}>
                      Sim
                    </Button>
                  </>
                }
                title="Excluir esta célula?"
                trigger="click"
                visible={confirm === idx}
                onVisibleChange={() => setConfirm(idx)}>
                <Button id="delete_cell" onClick={(e) => e.stopPropagation()}>
                  Excluir
                </Button>
              </Popover>,
              <Button
                id="open_cell_button"
                onClick={(e) => {
                  e.stopPropagation();
                  return history.push(route('admin.cells.edit', { cell: id }));
                }}>
                Visualizar
              </Button>,
            ]}>
            <List.Item.Meta
              avatar={<AvatarPicture pictureLink={logoUrl} target="Cell" size={40} name={name} />}
              title={name}
              description={`${accounts.total} clientes`}
            />
          </List.Item>
        )}
      />
      <TutorialButton
        tourId="017_CellListManagement"
        top={80}
        right={20}
        placement="left"
        title="Tutorial da lista de células"
      />

      <TutorialCellList hasCell={cells} />
    </Card>
  );
};

export default CellsPage;
