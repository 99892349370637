import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Card } from 'antd';
import { useHistory } from 'react-router-dom';
import { DocumentNode } from '@apollo/client';

import { PlusOutlined } from '@ant-design/icons';
import { BiBook } from 'react-icons/bi';

import ActivitiesFilters from '../ActivitiesFilters';
import ActivitiesListByTemplates from './ActivitiesListByTemplates';
import ActivitiesListBySteps from './ActivitiesListBySteps';
import ActivitiesListAll from './ActivitiesListAll';
import TutorialFilters from '../ActivitiesFilters/TutorialFilters';

import { route } from '@router';

import styles from './styles.module.less';

interface Props {
  accountId: string;
  setSelected: (taskId: string) => void;
  page: 'my_activities' | 'account_activities' | 'realm_activities';
  query: DocumentNode;
  variables: any;
  getData: (data: any) => any;
  view: string;
}

// Tela de atividades

const ActivitiesLists: React.FC<Props> = ({ accountId, view, setSelected, page, variables, query, getData }) => {
  const history = useHistory();

  const { t } = useTranslation('activitiesLists');

  const filters = (
    <ActivitiesFilters
      accountId={accountId}
      view={view}
      page={page}
      params={{ ...variables }}
      newTask={() => setSelected('new')}
    />
  );

  const stepsTasksView = (
    <ActivitiesListBySteps
      accountId={accountId}
      page={page}
      setSelected={setSelected}
      query={query}
      params={{ ...variables }}
      getData={getData}
    />
  );

  const allTasksView = (
    <ActivitiesListAll
      accountId={accountId}
      page={page}
      setSelected={setSelected}
      query={query}
      params={{ ...variables, steps: ['execution', 'check', 'approval', 'finish'] }}
      getData={getData}
    />
  );

  const tempaltesTasksView = (
    <ActivitiesListByTemplates
      accountId={accountId}
      page={page}
      setSelected={setSelected}
      query={query}
      params={{ ...variables, begin: null, end: null }}
      getData={getData}
    />
  );

  return (
    <div>
      <Card className={styles.root} style={{ display: 'flex', width: '100%' }} bodyStyle={{ width: '100%' }}>
        {filters}

        {view === 'show_by_steps' && stepsTasksView}
        {view === 'show_by_state' && allTasksView}
        {view === 'show_by_templates' && tempaltesTasksView}
      </Card>

      <div style={{ position: 'fixed', bottom: 10, right: 60, display: 'inline-flex' }}>
        <div className={styles.buttons_view} id="drawerException">
          <Button
            id="create_task_bottom"
            type="primary"
            shape="circle"
            size="large"
            title={t('title_create_task')}
            icon={<PlusOutlined style={{ fontSize: 20 }} />}
            onClick={() => setSelected('new')}
          />

          {page === 'account_activities' && (
            <Button
              id="show_account_templates_bottom"
              type="primary"
              shape="circle"
              size="large"
              title={t('title_templates')}
              icon={<BiBook style={{ fontSize: 24, paddingTop: 4 }} />}
              className={styles.blue_button}
              onClick={() => history.push(route('id.home.templates', { id: accountId }))}
            />
          )}
        </div>
      </div>

      <TutorialFilters page={page} view={view} />
    </div>
  );
};

export default ActivitiesLists;
