import React, { useCallback, useEffect, useLayoutEffect, useMemo, useState } from 'react';
import { Avatar, Badge, Button, DatePicker, Popover, Radio, Select, Tag, Tooltip, Typography } from 'antd';
import { gql, useLazyQuery, useQuery } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react-lite';
import { DateTime } from 'luxon';
import moment from 'moment';

import { BiBook } from 'react-icons/bi';
import { MdEdit } from 'react-icons/md';
import {
  ApartmentOutlined,
  AppstoreOutlined,
  CalendarOutlined,
  CloseCircleOutlined,
  EyeInvisibleOutlined,
  EyeOutlined,
  GlobalOutlined,
  MenuOutlined,
  ReloadOutlined,
  SoundOutlined,
  TeamOutlined,
  RightOutlined,
  LeftOutlined,
} from '@ant-design/icons';

import AvatarPicture from '@comp/AvatarPicture';
import { stepsIconsArray, stepsKeys } from '@pages/Activities';
import { realm_cells_and_users } from '@pages/Activities/tasksQueries';
import WeekendActivities from '@pages/Activities/WeekendActivities';
import EditMultiple from './EditMultiple';

import useConfig from '@logic/config';
import { useStore } from '@logic/context';

import styles from './styles.module.less';

// - Visões das atividades - 47
// - Filtro para EXIBIR ou OCULTAR finais de semana - 109
// - Filtro para EXIBIR por finish_date ou por execution_date - 148
// - Filtro para EXIBIR ou OCULTAR subtarefas - 186
// - Filtro para exibir APENAS AS ATIVIDADES DO USUÁRIO - 225
// - Filtro para exibir APENAS AS ATIVIDADES DO USUÁRIO OU DA CÉLULA SELECIONADOS - 284
// - Filtro de atividades por ETAPA - 448
// - Filtro por PERÍODO - 501
// - Editar multiplas atividades - 561

//----------------------- Visões das atividades --------------------------

export const ChangeViews: React.FC = observer(() => {
  const config = useConfig();

  const { t } = useTranslation('filters');

  const view = config.tasks_view;
  useEffect(() => {
    if (!view) config.setConfig('tasks_view', 'show_as_calendar');
  }, [view, config]);

  let icon = <></>;

  switch (view) {
    case 'show_by_state':
      icon = <GlobalOutlined />;
      break;
    case 'show_by_steps':
      icon = <AppstoreOutlined />;
      break;
    case 'show_by_templates':
      icon = <BiBook style={{ marginBottom: -3 }} size={16} />;
      break;
    case 'show_as_calendar':
      icon = <CalendarOutlined />;
      break;
    default:
      icon = <CalendarOutlined />;
  }

  const options = (
    <Radio.Group
      style={{ margin: -6 }}
      value={view}
      onChange={({ target }) => config.setConfig('tasks_view', target.value)}>
      <Tooltip destroyTooltipOnHide={{ keepParent: false }} title={t('show_by_state')} mouseEnterDelay={0.6}>
        <Radio.Button value="show_by_state">
          <GlobalOutlined />
        </Radio.Button>
      </Tooltip>

      <Tooltip destroyTooltipOnHide={{ keepParent: false }} title={t('show_by_steps')} mouseEnterDelay={0.6}>
        <Radio.Button value="show_by_steps">
          <AppstoreOutlined />
        </Radio.Button>
      </Tooltip>

      <Tooltip destroyTooltipOnHide={{ keepParent: false }} title={t('show_by_templates')} mouseEnterDelay={0.6}>
        <Radio.Button value="show_by_templates">
          <BiBook style={{ marginBottom: -3 }} size={16} />
        </Radio.Button>
      </Tooltip>

      <Tooltip destroyTooltipOnHide={{ keepParent: false }} title={t('show_as_calendar')} mouseEnterDelay={0.6}>
        <Radio.Button value="show_as_calendar">
          <CalendarOutlined />
        </Radio.Button>
      </Tooltip>
    </Radio.Group>
  );

  return (
    <Popover
      destroyTooltipOnHide={{ keepParent: false }}
      content={options}
      mouseEnterDelay={0.2}
      trigger={['click', 'contextMenu']}>
      <Button id="activities_views" style={{ marginLeft: 6 }}>
        {icon}
      </Button>
    </Popover>
  );
});

//------------------- Filtro para EXIBIR ou OCULTAR finais de semana ------------------------

interface HideWeekendProps {
  totalWeekendActivities: number;
}
export const HideWeekend: React.FC<HideWeekendProps> = observer(({ totalWeekendActivities }) => {
  const config = useConfig();
  const hideWeekend = !!config.hide_calendar_weekend;

  const { t } = useTranslation('filters');

  const options = (
    <Radio.Group
      style={{ margin: -6 }}
      value={hideWeekend}
      onChange={({ target }) => config.setConfig('hide_calendar_weekend', target.value)}>
      <Tooltip destroyTooltipOnHide={{ keepParent: false }} title="Esconder final de semana" mouseEnterDelay={0.6}>
        <Radio.Button value={true}>
          <EyeInvisibleOutlined />
        </Radio.Button>
      </Tooltip>

      <Tooltip destroyTooltipOnHide={{ keepParent: false }} title="Exibir final de semana" mouseEnterDelay={0.6}>
        <Radio.Button value={false}>
          <EyeOutlined />
        </Radio.Button>
      </Tooltip>
    </Radio.Group>
  );

  return (
    <WeekendActivities total={totalWeekendActivities} hideWeekend={hideWeekend} title="Atividades nos finais de semana">
      <Popover destroyTooltipOnHide={{ keepParent: false }} content={options} trigger="contextMenu">
        <Button
          id="show_hide_weekend"
          style={{ marginLeft: 6 }}
          onClick={() => config.setConfig('hide_calendar_weekend', !hideWeekend)}
          title={hideWeekend ? t('show_weekend') : t('hide_weekend')}>
          {hideWeekend ? <EyeInvisibleOutlined /> : <EyeOutlined />}
        </Button>
      </Popover>
    </WeekendActivities>
  );
});

//-------------------- Filtro para EXIBIR por finish_date ou por execution_date ----------------------

export const DateFilter: React.FC = observer(() => {
  const config = useConfig();
  const date = config.tasks_filter_date || 'finish_date';

  const { t } = useTranslation('filters');

  const options = (
    <Radio.Group
      style={{ margin: -6 }}
      value={date}
      onChange={({ target }) => config.setConfig('tasks_filter_date', target.value)}>
      <Tooltip destroyTooltipOnHide={{ keepParent: false }} title="Exibir por data de execução" mouseEnterDelay={0.6}>
        <Radio.Button value="execution_date">
          <ReloadOutlined />
        </Radio.Button>
      </Tooltip>

      <Tooltip destroyTooltipOnHide={{ keepParent: false }} title="Exibir por data de publicação" mouseEnterDelay={0.6}>
        <Radio.Button value="finish_date">
          <SoundOutlined />
        </Radio.Button>
      </Tooltip>
    </Radio.Group>
  );

  return (
    <Popover destroyTooltipOnHide={{ keepParent: false }} content={options} trigger="contextMenu">
      <Button
        id="filter_date_execution_finish"
        style={{ marginLeft: 6 }}
        onClick={() =>
          config.setConfig('tasks_filter_date', date === 'execution_date' ? 'finish_date' : 'execution_date')
        }
        title={date !== 'execution_date' ? t('execution_date') : t('finish_date')}>
        {date === 'execution_date' ? <ReloadOutlined /> : <SoundOutlined />}
      </Button>
    </Popover>
  );
});

//-------------------- Filtro para EXIBIR ou OCULTAR subtarefas ----------------------
interface SubtasksFilterProps {
  params: any;
}

export const SubtasksFilter: React.FC<SubtasksFilterProps> = observer(({ params }) => {
  const config = useConfig();

  const { excludeSubtasks } = params;

  const { t } = useTranslation('filters');

  const options = (
    <Radio.Group
      style={{ margin: -6 }}
      value={excludeSubtasks}
      buttonStyle="solid"
      onChange={({ target }) => config.setConfig('tasks_filter_subtasks', target.value)}>
      <Tooltip destroyTooltipOnHide={{ keepParent: false }} title="Esconder subtarefas" mouseEnterDelay={0.6}>
        <Radio.Button value={true}>
          <MenuOutlined />
        </Radio.Button>
      </Tooltip>

      <Tooltip destroyTooltipOnHide={{ keepParent: false }} title="Exibir subtarefas" mouseEnterDelay={0.6}>
        <Radio.Button value={false}>
          <ApartmentOutlined />
        </Radio.Button>
      </Tooltip>
    </Radio.Group>
  );

  return (
    <Popover destroyTooltipOnHide={{ keepParent: false }} content={options} trigger="contextMenu">
      <Button
        id="show_hide_subtask"
        style={{ marginLeft: 6 }}
        type="primary"
        ghost
        onClick={() => config.setConfig('tasks_filter_subtasks', !excludeSubtasks)}
        title={!!excludeSubtasks ? t('show_subtasks') : t('hide_subtasks')}>
        {!!excludeSubtasks ? <MenuOutlined /> : <ApartmentOutlined />}
      </Button>
    </Popover>
  );
});

//-------------------- Filtro para exibir APENAS AS ATIVIDADES DO USUÁRIO ----------------------

interface FilterMineProps {
  params: any;
  page: 'my_activities' | 'account_activities' | 'realm_activities' | 'home_dashboard';
}

export const FilterMine: React.FC<FilterMineProps> = observer(({ params, page }) => {
  const store = useStore();
  const config = useConfig();
  const { t } = useTranslation('filters');

  const { users } = params;

  const myUserId = useMemo(() => (store.profile ? [store.profile.id] : undefined), [store.profile]);
  const myActivities = !!myUserId && users?.includes(myUserId[0]);

  useLayoutEffect(() => {
    if (page === 'realm_activities') return;

    if (users) {
      if (myActivities && users.length === 1) return;
      if (myActivities && users.length > 1) config.setConfig('tasks_filter_users', myUserId);
      else config.setConfig('tasks_filter_users', undefined);
    }
  }, [config, myActivities, myUserId, page, users]);

  const options = (
    <Radio.Group
      style={{ margin: -6 }}
      value={!!myActivities}
      buttonStyle="solid"
      onChange={({ target }) => config.setConfig('tasks_filter_users', target.value ? myUserId : undefined)}>
      <Tooltip destroyTooltipOnHide={{ keepParent: false }} title="Minhas atividades" mouseEnterDelay={0.6}>
        <Radio.Button value={true} style={{ marginBottom: 4, paddingTop: -4 }}>
          <Avatar src={store.profile?.avatarUrl} shape="circle" size={22} style={{ marginTop: -4 }} />
        </Radio.Button>
      </Tooltip>

      <Tooltip destroyTooltipOnHide={{ keepParent: false }} title="Todas as atividades" mouseEnterDelay={0.6}>
        <Radio.Button value={false}>
          <TeamOutlined />
        </Radio.Button>
      </Tooltip>
    </Radio.Group>
  );

  return (
    <Popover destroyTooltipOnHide={{ keepParent: false }} content={options} trigger="contextMenu">
      <Button
        id="my_tasks_all_tasks"
        style={{ marginLeft: 6 }}
        type="primary"
        ghost
        onClick={() => config.setConfig('tasks_filter_users', !!myActivities ? undefined : myUserId)}
        title={!!myActivities ? t('all_tasks') : t('my_tasks')}>
        {!!myActivities ? (
          <Avatar src={store.profile?.avatarUrl} shape="circle" size={22} style={{ marginTop: -4 }} />
        ) : (
          <TeamOutlined />
        )}
      </Button>
    </Popover>
  );
});

//--------- Filtro para exibir APENAS AS ATIVIDADES DO USUÁRIO OU DA CÉLULA SELECIONADOS -------------

interface FilterUserAndCellProps {
  params: any;
  page: 'my_activities' | 'account_activities' | 'realm_activities';
}

export const FilterUserAndCell: React.FC<FilterUserAndCellProps> = observer(({ params, page }) => {
  const config = useConfig();

  const [fetchCellsAndUsers, { data: cellsAndUsers, loading }] = useLazyQuery(realm_cells_and_users, {
    fetchPolicy: 'cache-and-network',
  });

  const { users, cells } = params;

  const cellsSelect: any[] = cellsAndUsers?.me?.realm?.cells?.data;
  const usersSelect: any[] = cellsAndUsers?.me?.realm?.users?.data;

  useLayoutEffect(() => {
    if (page === 'realm_activities') fetchCellsAndUsers();
  }, [fetchCellsAndUsers, page]);

  const onDeSelect = useCallback(
    (value) => {
      if (users && users.findIndex((user: any) => user === value) !== -1) {
        const newUsers = [...(users || [])];
        const valueIndex = newUsers.indexOf(value.toString());
        newUsers.splice(valueIndex, 1);
        config.setConfig('tasks_filter_users', newUsers);
      } else if (cells && cells.findIndex((cell: any) => cell === value) !== -1) {
        const newCells = [...(cells || [])];
        const valueIndex = newCells.indexOf(value.toString());
        newCells.splice(valueIndex, 1);
        config.setConfig('tasks_filter_cells', newCells);
      }
    },
    [config, cells, users],
  );

  const onSelect = useCallback(
    (value) => {
      if (usersSelect && usersSelect.findIndex((user) => user.id === value) !== -1) {
        const newUsers = [...(users || [])];
        newUsers.push(value.toString());
        config.setConfig('tasks_filter_users', newUsers);
      } else if (cellsSelect && cellsSelect.findIndex((cell) => cell.id === value) !== -1) {
        const newCells = [...(cells || [])];
        newCells.push(value.toString());
        config.setConfig('tasks_filter_cells', newCells);
      }
    },
    [config, cells, users, usersSelect, cellsSelect],
  );

  return (
    <SelectComponent
      value={[...(users || []), ...(cells || [])]}
      loading={loading}
      usersSelect={usersSelect}
      cellsSelect={cellsSelect}
      onClear={() => {
        config.setConfig('tasks_filter_users', undefined);
        config.setConfig('tasks_filter_cells', undefined);
      }}
      onSelect={onSelect}
      onDeSelect={onDeSelect}
    />
  );
});

interface SelectComponentProps {
  loading: boolean;
  onDeSelect: (value: any) => void;
  onSelect: (value: any) => void;
  onClear: () => void;
  usersSelect: any[];
  cellsSelect: any[];
  value: any[];
}
const SelectComponent: React.FC<SelectComponentProps> = ({
  loading,
  onDeSelect,
  onSelect,
  onClear,
  usersSelect,
  cellsSelect,
  value,
}) => {
  const [searchText, setSearchText] = useState<string>();

  const handleSearch = useCallback((value?: any) => setSearchText(value), []);

  const { t } = useTranslation('filters');

  const usersSelectItems = useMemo(() => {
    let users = usersSelect;

    if (searchText) {
      users = (users || []).filter((user) => user.name.toLowerCase().indexOf(searchText.toLowerCase()) !== -1);
    }

    users = (users || []).filter((user: any) => user.status !== 'inactive');

    if (users && users.length > 0) {
      return (
        <Select.OptGroup label="Usuários">
          {(users || []).map((user: any) => (
            <Select.Option key={user?.id} value={user?.id}>
              <div className={styles.users_list_item}>
                <div style={{ width: 30 }}>
                  <AvatarPicture pictureLink={user?.avatarUrl} target="User" size={20} name={user?.name} />
                </div>
                <Typography.Text className={styles.user_name} ellipsis>
                  {user?.name}
                </Typography.Text>
              </div>
            </Select.Option>
          ))}
        </Select.OptGroup>
      );
    }

    return null;
  }, [usersSelect, searchText]);

  const cellsSelectItems = useMemo(() => {
    let cells = cellsSelect;

    if (searchText) {
      cells = cells.filter((cell) => cell.name.toLowerCase().indexOf(searchText.toLowerCase()) !== -1);
    }

    if (cells && cells.length > 0) {
      return (
        <Select.OptGroup label="Células">
          {cells.map((cell: any) => (
            <Select.Option key={cell?.id} value={cell?.id}>
              <div className={styles.users_list_item}>
                <div style={{ width: 30 }}>
                  <AvatarPicture pictureLink={cell?.logoUrl} target="Cell" size={20} name={cell?.name} />
                </div>

                <Typography.Text className={styles.user_name} ellipsis>
                  {cell?.name}
                </Typography.Text>
              </div>
            </Select.Option>
          ))}
        </Select.OptGroup>
      );
    }

    return null;
  }, [cellsSelect, searchText]);

  return (
    <div id="see_users_team">
      <Select
        mode="multiple"
        style={{ minWidth: 150 }}
        allowClear
        loading={loading}
        placeholder={t('filter_user_cell')}
        onClear={onClear}
        value={value}
        onSearch={handleSearch}
        showSearch
        filterOption={false}
        dropdownStyle={{ width: 240 }}
        dropdownMatchSelectWidth={false}
        onDeselect={onDeSelect}
        onSelect={onSelect}>
        {cellsSelectItems}

        {usersSelectItems}
      </Select>
    </div>
  );
};

//------------------------ Filtro de atividades por ETAPA ------------------------------

interface StepsFilterProps {
  params: any;
}

export const StepsFilter: React.FC<StepsFilterProps> = observer(({ params }) => {
  const config = useConfig();

  const { steps } = params;

  const { t } = useTranslation('filters');

  return (
    <div id="filter_steps">
      <Select
        mode="multiple"
        style={{ minWidth: 150 }}
        allowClear
        placeholder="Etapas"
        onClear={() => config.setConfig('tasks_filter_steps', [])}
        value={steps}
        dropdownMatchSelectWidth={false}
        tagRender={({ label, value, closable, onClose }) => {
          const index = stepsKeys.findIndex((s) => s.key === value);

          function stepTitleName(index: number): string {
            let title = '';
            switch (index) {
              case 0:
                title = t('step_execution');
                break;
              case 1:
                title = t('step_check');
                break;
              case 2:
                title = t('step_approval');
                break;
              case 3:
                title = t('step_finish');
                break;
              case 4:
                title = t('step_done');
                break;
            }

            return title;
          }

          const stepTitle = stepTitleName(index);

          return (
            <Tag
              closable={closable}
              onClose={onClose}
              title={stepTitle} //{stepsKeys[index].title}
              closeIcon={
                <CloseCircleOutlined style={{ fontSize: 12, padding: 0, color: 'rgba(255, 255, 255, 0.87)' }} />
              }
              color={stepsKeys[index]?.color}
              style={{ display: 'flex', alignItems: 'center', marginRight: 3, marginTop: 1, padding: '2px 6px' }}>
              <div style={{ marginRight: 4, fontSize: 16 }}>{stepsIconsArray()[index]}</div>
              {/* {label} */}
            </Tag>
          );
        }}
        onDeselect={(value) => {
          const newSteps = [...(steps || [])];
          const valueIndex = newSteps.indexOf(value.toString());
          newSteps.splice(valueIndex, 1);
          config.setConfig('tasks_filter_steps', newSteps);
        }}
        onSelect={(value) => {
          const newSteps = [...(steps || [])];
          newSteps.push(value.toString());
          config.setConfig('tasks_filter_steps', newSteps);
        }}>
        {stepsKeys.map(({ key, tagLabel }, index) => (
          <Select.Option value={key} key={`calendar${key}`}>
            {stepsIconsArray()[index]}
            {'   '}
            {tagLabel}
          </Select.Option>
        ))}
      </Select>
    </div>
  );
});

//-------------------------------- Filtro por PERÍODO ----------------------------------

interface PeriodFilterProps {
  params: any;
  value?: moment.Moment;
  onChange?: (date: moment.Moment) => void;
}

//  TODO  change language according to location
export const PeriodFilter: React.FC<PeriodFilterProps> = observer(({ params, value, onChange }) => {
  const config = useConfig();

  // O onChange muda a data no calendário, o config muda a data no filtro apenas

  const view = config.tasks_view;
  const calendarView = view === 'show_as_calendar';

  const begin = config.tasks_filter_begin as any;

  const setConfig = useCallback((date: string) => {
    config.setConfig('tasks_filter_begin', formatMonthToIsoPeriod(date).begin || undefined);
    config.setConfig('tasks_filter_end', formatMonthToIsoPeriod(date).end || undefined);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (calendarView) setConfig(moment(value).format('MMMM YYYY'));
  }, [value, calendarView, setConfig]);

  const fowardPeriodFilter = () => {
    const newValue = DateTime.fromFormat(begin, 'yyyy-LL-dd').plus({ month: 1 });
    setConfig(newValue.toFormat('LLLL yyyy', { locale: 'pt-BR' }));
    if (onChange) onChange(moment(begin).add({ month: 1 }));
  };

  const backwardsPeriodFilter = () => {
    const newValue = DateTime.fromFormat(begin, 'yyyy-LL-dd').minus({ month: 1 });
    setConfig(newValue.toFormat('LLLL yyyy', { locale: 'pt-BR' }));
    if (onChange) onChange(moment(begin).subtract({ month: 1 }));
  };

  return (
    <>
      {begin && <Button icon={<LeftOutlined />} onClick={backwardsPeriodFilter} />}

      <div style={{ marginBottom: 12, marginLeft: 6 }} id="filter_year_month">
        <DatePicker.MonthPicker
          format="MMMM YYYY"
          placeholder="Selecionar o mês"
          style={{ width: 160 }}
          allowClear={!calendarView}
          value={!!begin ? moment(begin).endOf('week') : null}
          defaultValue={undefined}
          onChange={(date, dateString) => {
            if (value && onChange) {
              const now = value.clone().year(Number(date?.year()));
              onChange(now);

              const newValue = value.clone().month(Number(date?.month())).year(Number(date?.year()));
              onChange(newValue);
            }

            setConfig(dateString);
          }}
        />
      </div>

      {begin && <Button style={{ marginLeft: 6 }} icon={<RightOutlined />} onClick={fowardPeriodFilter} />}
    </>
  );
});

function formatMonthToIsoPeriod(time?: string) {
  return !time
    ? { begin: undefined, end: undefined }
    : {
        begin: DateTime.fromFormat(time, 'LLLL yyyy', { locale: 'pt-BR' }).startOf('month').toFormat('yyyy-LL-dd'),
        end: DateTime.fromFormat(time, 'LLLL yyyy', { locale: 'pt-BR' }).endOf('month').toFormat('yyyy-LL-dd'),
      };
}

//---------------------------------- Editar multiplas atividades ------------------------------------

export const EditMultipleButton: React.FC = observer(() => {
  const [modal, setModal] = useState(false);
  const store = useStore();

  const { t } = useTranslation('filters');

  const count = store.editMultipleList.length;

  const handleEditMultiple = () => {
    store.cleanEditMultipleList();
    store.setEditMultiple(true);
  };

  const handleCancel = () => {
    store.setEditMultiple(false);
    store.cleanEditMultipleList();
    setModal(false);
  };

  if (!store.editMultiple) {
    return (
      <Button
        id="edit_task"
        title={t('edit_multiple')}
        style={{ marginRight: 6 }}
        type="default"
        onClick={handleEditMultiple}
        icon={<MdEdit />}
      />
    );
  }

  return (
    <>
      {modal && <EditMultiple cancelEdit={handleCancel} closeModal={() => setModal(false)} />}
      <Badge
        count={count > 0 ? count : <CloseCircleOutlined onClick={handleCancel} style={{ color: '#f5222d' }} />}
        offset={[-12, 0]}
        size="small">
        <Button
          title={t('open_edit')}
          style={{ marginRight: count || count === 0 ? 12 : 6 }}
          onClick={() => setModal(true)}
          icon={<MdEdit />}
        />
      </Badge>
    </>
  );
});

//---------------------------------- Editar multiplas atividades ------------------------------------

interface AccountNameProps {
  accountId: string;
}

const account_name = gql`
  query Account($id: ID!) {
    account(id: $id) {
      id
      name
      logoUrl
    }
  }
`;

export const AccountName: React.FC<AccountNameProps> = ({ accountId }) => {
  const { data } = useQuery(account_name, { variables: { id: accountId } });

  return (
    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'flex-start' }}>
      <AvatarPicture target="Account" pictureLink={data?.account?.logoUrl} size={28} name={data?.account?.name} />
      <Typography.Title style={{ fontSize: 20, marginLeft: 6 }}>{data?.account?.name}</Typography.Title>
    </div>
  );
};
