import { Card, DatePicker, Row, Col } from 'antd';
import React, { useMemo, useState } from 'react';

import Header from './Header';
import Performance from './Performance/index';
import TopAds from './TopAds/index';

import { ResponsiveBar } from '@nivo/bar';
import { useParams } from 'react-router-dom';
import moment from 'moment';

import { account_insights_data_query } from '@logic/queries';
import { DateTime } from 'luxon';
import { useQuery } from '@apollo/client';
import ErrorAndLoading from '@comp/ErrorAndLoading';

const Chart: React.FC<{
  insights: any;
  keys: string[];
  title: string;
  money?: boolean;
  accOperation?: 'sum' | 'average';
}> = ({ insights, keys, title, money, accOperation }) => {
  const fmt = new Intl.NumberFormat('pt-BR', { style: money ? 'currency' : 'decimal', currency: 'BRL' });

  const sum = useMemo(() => insights.reduce((acc: number, e: any) => acc + e[keys[0]], 0), [keys, insights]);

  return (
    <Card style={{ marginBottom: 20, boxShadow: '0 1px 4px #00000040' }}>
      <div style={{ display: 'flex', marginBottom: 24 }}>
        <h3 style={{ flex: 1, textAlign: 'center' }}>{title}</h3>
        {accOperation && (
          <div
            style={{
              backgroundColor: '#ff5333',
              color: '#FFF',
              padding: '6px 12px',
              borderRadius: 4,
              fontSize: 16,
              fontWeight: 500,
            }}>
            {fmt.format(accOperation === 'average' ? sum / (insights.length || 1) : sum)}
          </div>
        )}
      </div>

      <div style={{ height: 350 }}>
        <ResponsiveBar
          data={insights}
          keys={keys}
          padding={0.3}
          indexBy={'date'}
          valueScale={{ type: 'linear' }}
          tooltipFormat={(label) => fmt.format(label)}
          enableLabel={false}
          labelSkipWidth={12}
          labelSkipHeight={12}
          axisBottom={{
            format: (value) => DateTime.fromFormat(value, 'yyyy-LL-dd').toFormat('dd/LL/yy'),
            tickRotation: -45,
          }}
          axisLeft={{
            format: (label) => fmt.format(label),
            //tickValues,
          }}
          colors={['#ff5333']}
          margin={{ top: 20, right: 20, bottom: 60, left: 60 }}
        />
      </div>
    </Card>
  );
};

const AccountFacebookReports: React.FC = () => {
  const { id } = useParams<any>();

  const [period, setPeriod] = useState({
    begin: DateTime.now().minus({ days: 30 }).toFormat('yyyy-LL-dd'),
    end: DateTime.now().toFormat('yyyy-LL-dd'),
  });

  const beginDate = DateTime.fromFormat(period.begin, 'yyyy-LL-dd');
  const endDate = DateTime.fromFormat(period.end, 'yyyy-LL-dd');
  const timeDiff = endDate.diff(beginDate, 'days');

  const { data, loading, error } = useQuery(account_insights_data_query, {
    variables: {
      accountId: id,
      type: 'facebook',
      currentPeriod: period,
      previousPeriod: {
        begin: beginDate.minus({ days: timeDiff.days + 1 }).toFormat('yyyy-LL-dd'),
        end: beginDate.minus({ day: 1 }).toFormat('yyyy-LL-dd'),
      },
    },
    fetchPolicy: 'network-only',
  });

  if (loading || error) return <ErrorAndLoading loading={loading} error={error} />;

  return (
    <div>
      <DatePicker.RangePicker
        style={{ marginBottom: 16 }}
        format="DD/MM/yyyy"
        allowClear={false}
        value={[moment(period.begin, 'yyyy-MM-DD'), moment(period.end, 'yyyy-MM-DD')]}
        onChange={(_, format) =>
          setPeriod({
            begin: DateTime.fromFormat(format[0], 'dd/LL/yyyy').toFormat('yyyy-LL-dd'),
            end: DateTime.fromFormat(format[1], 'dd/LL/yyyy').toFormat('yyyy-LL-dd'),
          })
        }
      />

      {data.current.length === 0 && <Card>Não há dados desta conta para o periodo informado.</Card>}

      {data.current.length > 0 && (
        <>
          <Row gutter={[0, 24]}>
            <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
              <Header insights={data.current} previous={data.previous} />
            </Col>
          </Row>

          <Row gutter={[16, 24]} style={{ marginTop: 20, marginBottom: 20 }}>
            <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={12}>
              <Performance insights={data.current} previous={data.previous} />
            </Col>

            <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={12}>
              <TopAds />
            </Col>
          </Row>

          <Chart insights={data.current} keys={['clicks']} title="Cliques" accOperation="sum" />
          <Chart insights={data.current} keys={['frequency']} title="Frequência" accOperation="average" />
          <Chart insights={data.current} keys={['reach']} title="Alcance" accOperation="sum" />
          <Chart insights={data.current} keys={['conversions']} title="Conversões" accOperation="sum" />
          <Chart insights={data.current} keys={['spend']} title="Valor gasto" money accOperation="sum" />
          <Chart insights={data.current} keys={['cpc']} title="CPC" money accOperation="average" />
          <Chart insights={data.current} keys={['cpm']} title="CPM" money accOperation="average" />
          <Chart insights={data.current} keys={['cpp']} title="CPP" money accOperation="average" />
          <Chart insights={data.current} keys={['ctr']} title="CTR" accOperation="average" />
        </>
      )}
    </div>
  );
};

export default AccountFacebookReports;
