import React, { useState, useLayoutEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Tooltip } from 'antd';

import { getCurrentBuildInfo } from '@logic/version';

interface Props {
  elseRender: any;
}

const NewVersionButton: React.FC<Props> = ({ elseRender }) => {
  const [appVersion, setAppVersion] = useState('');
  const [updateAvailable, setUpdateAvailable] = useState(false);

  const { t } = useTranslation('newVersionButton');

  // Primeira verificação de versionamento, feito após 15 segundos para evitar render desnecessário em telas rápidas
  useLayoutEffect(() => {
    if (appVersion) return;

    const tm = setTimeout(() => {
      getCurrentBuildInfo()
        .then((version) => {
          setAppVersion(version);
        })
        .catch((err) => console.warn(err));
    }, 15000);

    return () => clearTimeout(tm);
  }, [appVersion]);

  // Interval para checagem do versionamento periodicamente
  useLayoutEffect(() => {
    if (!appVersion) return;

    const i = setInterval(async () => {
      try {
        const version = await getCurrentBuildInfo();
        if (version !== appVersion) setUpdateAvailable(true);
      } catch (err) {}
    }, 60000);

    return () => clearInterval(i);
  }, [appVersion]);

  if (updateAvailable && !!appVersion) {
    return (
      <Tooltip destroyTooltipOnHide={{ keepParent: false }} title={t('tooltip')}>
        <Button onClick={() => window.location.reload()} type="primary">
          {t('button')}
        </Button>
      </Tooltip>
    );
  }

  return elseRender;
};

export default NewVersionButton;
