import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { useMutation } from '@apollo/client';

import { Card, Button, Form, Input, Row, Col, notification, Select } from 'antd';

import { accounts_create } from '@logic/mutations';
import errorMessage from '@logic/functions/errorHandeling';
import { route } from '@router';

import styles from './styles.module.less';

const NewAccount: React.FC = () => {
  const [form] = Form.useForm();
  const history = useHistory();

  const { t } = useTranslation('newAccount');

  //-------------------------Mutations------------------------------

  const [CreateAccount] = useMutation(accounts_create, {
    variables: { name: '', status: '', journey: 'implantation' },
  });

  //-----------------------------------------------------------------

  async function doSave(name: string, status: string, journey: string) {
    try {
      await CreateAccount({ variables: { name, status, journey } }).then((res) => {
        window.location.replace(route('admin.accounts.edit', { account: res.data?.createAccount?.id }));
      });
      //history.push();
    } catch (err) {
      notification.open({
        type: 'error',
        ...errorMessage('graph_err', err),
      });
    }
  }

  const onFinish = (values: any) => {
    form
      .validateFields()
      .then((values) => {
        doSave(values.name, values.status, values.journey);

        form.resetFields();
      })
      .catch((info) => {
        notification.open({
          type: 'error',
          ...errorMessage('form_validation', info),
        });
      });
  };

  const onFinishFailed = (errorInfo: any) => {
    notification.open({
      type: 'error',
      ...errorMessage('', errorInfo),
    });
  };

  return (
    <Card className={styles.root}>
      <Form
        form={form}
        labelCol={{
          span: 2,
          style: {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-start',
          },
        }}
        wrapperCol={{ style: { display: 'flex', flex: 1 } }}
        layout="horizontal"
        name="account_cadaster"
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}>
        <Form.Item name="name" label={t('label_name')}>
          <Input type="text" />
        </Form.Item>

        <Form.Item name="status" label={t('label_status')}>
          <Select
            value={form.getFieldValue('status')}
            options={[
              { value: 'active', label: t('status_active') },
              { value: 'inactive', label: t('status_inactive') },
              { value: 'suspended', label: t('status_suspended') },
            ]}
          />
        </Form.Item>

        <Form.Item name="journey" label="Jornada">
          <Select
            value={form.getFieldValue('journey')}
            options={[
              { value: 'implantation', label: 'Implantação' },
              { value: 'channel_proof', label: 'Prova de canais' },
              { value: 'validation', label: 'Validação' },
              { value: 'otimization', label: 'Otimização' },
              { value: 'scale', label: 'Escalada' },
              { value: 'new_journey', label: 'Nova jornada' },
            ]}
          />
        </Form.Item>

        <Form.Item>
          <Row gutter={24}>
            <Col span={6}>
              <Button type="primary" htmlType="reset" block onClick={() => history.goBack()}>
                {t('go_back')}
              </Button>
            </Col>

            <Col span={6}>
              <Button type="primary" htmlType="submit" block>
                {t('save')}
              </Button>
            </Col>
          </Row>
        </Form.Item>
      </Form>
    </Card>
  );
};

export default NewAccount;
