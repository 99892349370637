import React from 'react';
import { Typography } from 'antd';
import { DateTime, Duration } from 'luxon';

import { parseDateTime } from '@logic/functions/date';

//------------------------------- Notified at ... -----------------------------

interface NotificationTimeProps {
  time: string;
}

export const NotificationTime: React.FC<NotificationTimeProps> = ({ time }) => {
  const diff = -parseDateTime(time).diffNow().as('milliseconds');
  const formatDiff = diffUnit(Duration.fromMillis(diff));

  return (
    <Typography.Text
      title={DateTime.fromFormat(time, 'yyyy-LL-dd HH:mm:ss').toFormat('HH:mm:ss - dd/LL/yyyy')}
      style={{ padding: 0, fontSize: 12, color: 'rgba(0,0,0,0.64)' }}>
      {formatDiff}
    </Typography.Text>
  );
};

//------------------------- Notified at (x) segundos/horas/... ---------------------

function diffUnit(diff: Duration) {
  if (Number(diff.as('seconds').toFixed(0)) < 1) return `agora`;

  if (Number(diff.as('seconds').toFixed(0)) === 1) return `há ${diff.as('seconds').toFixed(0)} segundo`;
  if (Number(diff.as('seconds').toFixed(0)) < 60) return `há ${diff.as('seconds').toFixed(0)} segundos`;

  if (Number(diff.as('minutes').toFixed(0)) === 1) return `há ${diff.as('minutes').toFixed(0)} minuto`;
  if (Number(diff.as('minutes').toFixed(0)) < 60) return `há ${diff.as('minutes').toFixed(0)} minutos`;

  if (Number(diff.as('hours').toFixed(0)) === 1) return `há ${diff.as('hours').toFixed(0)} hora`;
  if (Number(diff.as('hours').toFixed(0)) < 24) return `há ${diff.as('hours').toFixed(0)} horas`;

  if (Number(diff.as('days').toFixed(0)) === 1) return `há ${diff.as('days').toFixed(0)} dia`;
  if (Number(diff.as('days').toFixed(0)) < 7) return `há ${diff.as('days').toFixed(0)} dias`;

  if (Number(diff.as('weeks').toFixed(0)) === 1) return `há ${diff.as('weeks').toFixed(0)} semana`;
  return `há ${Number(diff.as('weeks').toFixed(0))} semanas`;
}

//---------------------------- O usuário FEZ TAL COISA + extras -----------------------------

export function notificationAction(type: string, extra: any) {
  const step = extra.step ? (
    <p style={{ display: 'inline', fontWeight: 600, color: status(extra.step).color }}>{status(extra.step).text}</p>
  ) : (
    <></>
  );
  const task = <p style={{ display: 'inline' }}>{extra.taskTitle}</p>;

  if (type === 'task_step_changed') {
    return (
      <span style={notificationSpanStyle}>
        alterou a <b style={highlight}>etapa</b> para {step} em {task}
      </span>
    );
  }
  if (type === 'task_feedback_created') {
    return (
      <span style={notificationSpanStyle}>
        adicionou um <b style={highlight}>feedback</b> em {task}
      </span>
    );
  }
  if (type === 'task_note_created') {
    return (
      <span style={notificationSpanStyle}>
        criou uma <b style={highlight}>nota</b> em {task}
      </span>
    );
  }
  return 'ação...';
}

const highlight = { color: '#ff5333', fontWeight: 600 };

const notificationSpanStyle = { display: 'inline', fontWeight: 500, color: 'rgba(0,0,0,0.87)' };

export function status(step: string) {
  let text = '';
  let color = '';

  switch (step) {
    case 'execution':
      text = 'execução';
      color = '#ff8c00';
      break;
    case 'check':
      text = 'revisão';
      color = '#3385ff';
      break;
    case 'approval':
      text = 'aprovação';
      color = '#ff5333';
      break;
    case 'finish':
      text = 'publicação';
      color = '#B03591';
      break;
    case 'done':
      text = 'finalizado';
      color = '#55a630';
      break;
  }

  return { text, color };
}
