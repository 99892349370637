import React, { useState } from 'react';
import { DatePicker, Card } from 'antd';

import { DateTime } from 'luxon';

//import Header from './Header/index';
//import Performance from './Performance/index';
//import Campaigns from './Campaigns/index';
//import KeyWords from './KeyWords';
import moment from 'moment';

//Em progresso ainda

const AccountGoogleReports: React.FC = () => {
  const [period, setPeriod] = useState({
    begin: DateTime.now().minus({ days: 30 }).toFormat('yyyy-LL-dd'),
    end: DateTime.now().toFormat('yyyy-LL-dd'),
  });

  return (
    <div>
      <DatePicker.RangePicker
        style={{ marginBottom: 16 }}
        format="DD/MM/yyyy"
        allowClear={false}
        value={[moment(period.begin, 'yyyy-MM-DD'), moment(period.end, 'yyyy-MM-DD')]}
        onChange={(_, format) =>
          setPeriod({
            begin: DateTime.fromFormat(format[0], 'dd/LL/yyyy').toFormat('yyyy-LL-dd'),
            end: DateTime.fromFormat(format[1], 'dd/LL/yyyy').toFormat('yyyy-LL-dd'),
          })
        }
      />

      <Card>Não há dados desta conta para o periodo informado.</Card>

      {/*(
        <>
          <Row gutter={[0, 12]}>
            <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
              <Header />
            </Col>
          </Row>

          <Row gutter={[16, 24]} style={{ marginTop: 20 }}>
            <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={12}>
              <Performance />
            </Col>

            <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={12}>
              <Campaigns />
            </Col>
          </Row>

          <Row gutter={[16, 24]} style={{ marginTop: 20 }}>
            <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={12}>
              <KeyWords />
            </Col>
          </Row>
        </>
      )*/}
    </div>
  );
};

export default AccountGoogleReports;
