import React, { useMemo } from 'react';
import { Card, Collapse, DatePicker, Typography } from 'antd';
import { DateTime } from 'luxon';
import moment from 'moment';
import { gql, useQuery } from '@apollo/client';
import { observer } from 'mobx-react-lite';

import ErrorAndLoading from '@comp/ErrorAndLoading';
import Checking from './Checking';

import useConfig from '@logic/config';

import styles from './styles.module.less';
import { useStore } from './../../logic/context';

function formatTimeFromIso(time: any) {
  return DateTime.fromISO(time).toFormat('yyyy-LL-dd');
}

const accounts_cells = gql`
  query UserAccountsCells {
    me {
      id
      name
      realm {
        id
        name
      }
      cells(pagination: { limit: -1, page: 1 }) {
        data {
          id
          name
        }
      }
      accounts(pagination: { limit: -1, page: 1 }) {
        data {
          id
          name
        }
      }
    }
  }
`;

const members_accounts_cells = gql`
  query UserAccountsCells($begin: Date, $end: Date) {
    me {
      id
      name
      realm {
        id
        name
        members(pagination: { limit: -1, page: 1 }) {
          data {
            id
            name
            summary(period: { begin: $begin, end: $end }) {
              tasks_total
              tasks_done
              tasks_delayed
              planned_seconds
              planned_seconds_used
              total_seconds_used
            }
          }
        }
      }
      cells(pagination: { limit: -1, page: 1 }) {
        data {
          id
          name
        }
      }
      accounts(pagination: { limit: -1, page: 1 }) {
        data {
          id
          name
        }
      }
    }
  }
`;

const pannel: React.CSSProperties = { padding: 0 };

const titleStyle: React.CSSProperties = { fontSize: '16px', margin: 0 };

const CheckingPage: React.FC = () => {
  const { profile } = useStore();
  const config = useConfig();

  const period: any = (config.checking_period_filter as any) || {
    start: DateTime.local().startOf('month'),
    end: DateTime.local().endOf('month'),
  };
  const admin = profile?.roles.includes('admin');

  const { data, loading, error } = useQuery(!admin ? accounts_cells : members_accounts_cells, {
    variables: {
      begin: period && period.start ? formatTimeFromIso(period.start) : undefined,
      end: period && period.end ? formatTimeFromIso(period.end) : undefined,
    },
  });

  const { user, realm, members, cells, accounts } = useMemo(
    () => ({
      user: data?.me,
      realm: data?.me?.realm,
      members: data?.me?.realm?.members?.data,
      cells: data?.me?.cells?.data,
      accounts: data?.me?.accounts?.data,
    }),
    [data],
  );

  if (loading || error) return <ErrorAndLoading error={error} loading={loading} />;

  return (
    <>
      <Card className={styles.root}>
        <div className={styles.filters}>
          <DatePicker.RangePicker
            format="DD/MM/YYYY"
            allowClear
            ranges={{
              Hoje: [moment(), moment()],
              'Esta semana': [moment().startOf('week'), moment().endOf('week')],
              'Este mês': [moment().startOf('month'), moment().endOf('month')],
            }}
            allowEmpty={[true, true]}
            value={[period.start ? moment(period.start) : null, period.end ? moment(period.end) : null]}
            onCalendarChange={(range, rangeString) => {
              config.setConfig('checking_period_filter', {
                start: !!rangeString[0] ? DateTime.fromFormat(rangeString[0], 'dd/LL/yyyy').toISO() : undefined,
                end: !!rangeString[1] ? DateTime.fromFormat(rangeString[1], 'dd/LL/yyyy').toISO() : undefined,
              });
            }}
          />
        </div>

        <div style={{ borderTop: '1px solid #d9d9d9' }}>
          <Collapse
            accordion
            bordered={false}
            destroyInactivePanel={true}
            expandIconPosition="right"
            defaultActiveKey="user">
            {user && (
              <Collapse.Panel
                key="user"
                style={pannel}
                header={<Typography.Title style={titleStyle}>{user.name}</Typography.Title>}>
                <Checking
                  user={user.id}
                  period={{
                    begin: period.start ? formatTimeFromIso(period.start) : undefined,
                    end: period.end ? formatTimeFromIso(period.end) : undefined,
                  }}
                />
              </Collapse.Panel>
            )}

            {members && (
              <Collapse.Panel
                key="members"
                style={pannel}
                header={<Typography.Title style={titleStyle}>Usuários</Typography.Title>}>
                <div style={{ marginBottom: -16 }} />
                {members.map(({ id, name, summary }: any) => {
                  return (
                    <Checking
                      key={id}
                      user={id}
                      title={name}
                      period={{
                        begin: period.start ? formatTimeFromIso(period.start) : undefined,
                        end: period.end ? formatTimeFromIso(period.end) : undefined,
                      }}
                      summary={summary}
                    />
                  );
                })}
              </Collapse.Panel>
            )}

            {cells && (
              <Collapse.Panel
                key="cells"
                style={pannel}
                header={<Typography.Title style={titleStyle}>Células</Typography.Title>}>
                <div style={{ marginBottom: -16 }} />
                {cells.map(({ id, name }: any) => {
                  return (
                    <Checking
                      key={id}
                      cell={id}
                      title={name}
                      period={{
                        begin: period.start ? formatTimeFromIso(period.start) : undefined,
                        end: period.end ? formatTimeFromIso(period.end) : undefined,
                      }}
                    />
                  );
                })}
              </Collapse.Panel>
            )}

            {accounts && (
              <Collapse.Panel
                key="accounts"
                style={pannel}
                header={<Typography.Title style={titleStyle}>Clientes</Typography.Title>}>
                <div style={{ marginBottom: -16 }} />
                {accounts.map(({ id, name }: any) => {
                  return (
                    <Checking
                      key={id}
                      account={id}
                      title={name}
                      period={{
                        begin: period.start ? formatTimeFromIso(period.start) : undefined,
                        end: period.end ? formatTimeFromIso(period.end) : undefined,
                      }}
                    />
                  );
                })}
              </Collapse.Panel>
            )}

            {realm && (
              <Collapse.Panel
                key="realm"
                style={pannel}
                header={<Typography.Title style={titleStyle}>{realm.name}</Typography.Title>}>
                <Checking
                  period={{
                    begin: period.start ? formatTimeFromIso(period.start) : undefined,
                    end: period.end ? formatTimeFromIso(period.end) : undefined,
                  }}
                />
              </Collapse.Panel>
            )}
          </Collapse>
        </div>
      </Card>
    </>
  );
};

export default observer(CheckingPage);
