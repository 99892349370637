import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useMutation } from '@apollo/client';
import { notification, Typography, DatePicker, Col, Row } from 'antd';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

import EmojiLinkInput from '@comp/EmojiLinkInput';
import AttatchUser from '@comp/AttatchUser';
import StepSelector from '@comp/StepSelector';
import OnPasteDiv from '@comp/OnPasteDiv';
import CellSelector from '@comp/CellSelector';
import Upload from '@comp/Upload';
import Help from '@comp/Help';
import TutorialButton from '@comp/TutorialButton';
import SubTasks from '../../SubTasks';

import { update_task } from '@logic/mutations';
import errorMessage from '@logic/functions/errorHandeling';
import { useUploadManager } from '@logic/context';
import { UploadItem } from '@logic/stores/uploadManager';

import { stepsKeys, stepsIconsArray } from '../../../';

import styles from './styles.module.less';

interface Props {
  assignmentData: any;
  accountId: string;
  setSelected: (id: string) => void;
  refetch: () => void;
}

// Atividade pontual

const AssignmentTab: React.FC<Props> = ({ assignmentData, accountId, setSelected, refetch }) => {
  const [assignment, setAssignment] = useState(assignmentData);

  const uploadManager = useUploadManager();

  const { t } = useTranslation('assignmentTab');

  const { cell, assignmentId, assignmentAccountId } = useMemo(
    () => ({
      cell: assignmentData.cell_id,
      assignmentId: assignmentData.id,
      assignmentAccountId: assignmentData.account?.id,
    }),
    [assignmentData],
  );

  useEffect(() => {
    if (setAssignment) setAssignment(assignmentData);
  }, [assignmentData, setAssignment]); // para verificar se o setAssignments existe ele precisa estar nas dependências

  //--------------------------- mutations -------------------------

  const [UpdateAssignment] = useMutation(update_task);

  //------------------------- Save function -----------------------

  const update = useCallback(
    async (key: string, value: any) => {
      try {
        await UpdateAssignment({ variables: { id: assignmentId, [key]: value } });

        refetch();
      } catch (err) {
        notification.open({ type: 'error', ...errorMessage('graph_err', err) });
      }
    },
    [UpdateAssignment, refetch, assignmentId],
  );

  //------------------------ Form functions ---------------------------

  const handleChange = useCallback(
    ({ target }) => setAssignment((t: any) => ({ ...t, [target.name]: target.value })),
    [],
  );

  const handleBlur = ({ target }: any) => {
    if (assignmentData[target.name] !== target.value) update(target.name, target.value);
  };

  //------------------------------------------------------------------------------

  return (
    <OnPasteDiv
      style={{ paddingBottom: '16px' }}
      onPaste={(data) => {
        for (const file of data) {
          const uploadItem: UploadItem = {
            targetId: assignmentId,
            targetType: 'Task',
            file,
          };

          uploadManager.uploadFile(uploadItem);
        }
      }}>
      <div id="title_task">
        <EmojiLinkInput
          name="title"
          placeholder={t('title_placeholder')}
          value={assignment.title}
          className={styles.title}
          onChange={handleChange}
          onBlur={handleBlur}
          emojiPicker="focus"
        />
      </div>

      <Row id="cell_task" className={styles.form_item}>
        <Col span={6} className={styles.column}>
          <Typography.Text className={styles.label}>{t('cell_label')}</Typography.Text>
          <Help
            title={t('help_cell_title')}
            description={t('help_cell_description')}
            placement="right"
            iconStyle={{ color: '#5c5c5c', fontSize: 12 }}
          />
        </Col>
        <Col span={18}>
          <CellSelector
            cellId={cell}
            accountId={assignmentAccountId}
            cellChange={(id) => handleBlur({ target: { name: 'cell', value: id } })}
          />
        </Col>
      </Row>

      <Row id="responsibles_task" className={styles.form_item}>
        <Col span={6} className={styles.column}>
          <Typography.Text className={styles.label}>{t('responsible_label')}</Typography.Text>
          <Help
            title={t('help_responsible_title')}
            description={t('help_responsible_description')}
            placement="right"
            iconStyle={{ color: '#5c5c5c', fontSize: 12 }}
          />
        </Col>
        <Col span={18}>
          {!!cell && <AttatchUser target={assignmentId} cellId={cell} attachType="Task" selectMode="multiple" />}
        </Col>
      </Row>

      <Row id="finish_date_task" className={styles.form_item}>
        <Col span={6} className={styles.column}>
          <Typography.Text className={styles.label}>{t('finish_date_label')}</Typography.Text>
          <Help
            title={t('help_finish_date_title')}
            description={t('help_finish_date_description')}
            placement="right"
            iconStyle={{ color: '#5c5c5c', fontSize: 12 }}
          />
        </Col>
        <Col span={18}>
          <DatePicker
            name="finish_date"
            format="DD/MM/YYYY"
            value={assignment?.finish_date ? moment(assignment?.finish_date) : null}
            onChange={(value) => handleBlur({ target: { name: 'finish_date', value: momentToStr(value) } })}
          />
        </Col>
      </Row>

      <div id="step_task" className={styles.form_item}>
        <StepSelector
          type="assignment"
          value={assignment.step}
          onChange={({ target }) => handleBlur({ target: { name: 'step', value: target.value } })}
          stepKeys={['execution', 'done']}
          stepKeysTitle={stepsKeys.filter((e, idx) => idx === 0 || idx === 4)}
          stepsIcons={stepsIconsArray().filter((e, idx) => idx === 0 || idx === 4)}
        />
      </div>

      <div id="description_task">
        <div className={styles.column}>
          <Typography.Text className={styles.label}>{t('description_label')}</Typography.Text>
          <Help
            title={t('help_description_title')}
            description={t('help_description')}
            iconStyle={{ color: '#5c5c5c', fontSize: 12 }}
            placement="right"
          />
        </div>
        <div className={styles.form_item}>
          <EmojiLinkInput
            name="body"
            emojiPicker="focus"
            placeholder={t('description_placeholder')}
            border
            multiline
            className={styles.body}
            value={assignment.body}
            onChange={handleChange}
            onBlur={handleBlur}
          />
        </div>
      </div>

      <SubTasks taskId={assignmentId} accountId={accountId} type="assignment" setSelected={setSelected} />

      <div id="files_task">
        <Upload targetId={assignmentId} parentId={assignmentId} targetType="Task" />
      </div>

      <TutorialButton
        tourId="006_TaskTab"
        circleSize="small"
        iconSize={14}
        bottom={20}
        right={40}
        placement="left"
        title={t('assignment_tutorial')}
      />
    </OnPasteDiv>
  );
};

export default AssignmentTab;

function momentToStr(time?: moment.Moment | null) {
  if (!time) return null;
  return moment(time).format('YYYY-MM-DD');
}
