import React, { useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import { Menu } from 'antd';
import { useTranslation } from 'react-i18next';

import { IoMdPeople, IoMdPerson } from 'react-icons/io';
import { FaRegHandshake } from 'react-icons/fa';
import { RiBuildingLine } from 'react-icons/ri';
import { MenuOutlined } from '@ant-design/icons';

import HomeButton from '../HomeButton';
import RightMenu from '../RigthMenu';
import Navigate from '../../Navigate';
import NewVersionButton from '@comp/NewVersionButton';

import { route } from '@router';
import useConfig from '@logic/config';

import styles from './styles.module.less';

// Menu superior para as telas de admin

const AdminAppBar: React.FC = () => {
  const location = useLocation();

  const { t } = useTranslation('adminAppBar');

  const config = useConfig();
  const collapsed = config.collapsed_menu;

  const checkRoute = useMemo(
    () => (key: string, routeName: string, params?: object) =>
      location.pathname.indexOf(route(routeName, params)) !== -1 ? key : '',
    [location],
  );

  // Itens do menu que estão selecionados
  const selectedKeys = [
    checkRoute('realm', 'admin.realm'),
    checkRoute('users', 'admin.users'),
    checkRoute('cells', 'admin.cells'),
    checkRoute('accounts', 'admin.accounts'),
  ].filter((e) => !!e);

  return (
    <Menu style={{ width: '100%' }} mode="horizontal" theme="light" selectedKeys={selectedKeys}>
      <Menu.Item key="admin_home" className={styles.item_logo}>
        <NewVersionButton elseRender={<HomeButton />} />
      </Menu.Item>

      <Menu.Item
        id="button_collapsed_menu"
        key="side_menu"
        title={!collapsed ? t('tooltip_collapsed_menu') : t('tooltip_expanded_menu')}
        icon={<MenuOutlined />}
        style={{ padding: 0 }}
        onClick={() => {
          config.setConfig('collapsed_menu', !collapsed);
        }}
      />

      <Menu.Item key="realm">
        <Navigate to={route('admin.realm')}>
          <span className={styles.menuItem}>
            <RiBuildingLine style={{ fontSize: '24px', marginRight: '6px' }} />
            <span>{t('realm')}</span>
          </span>
        </Navigate>
      </Menu.Item>

      <Menu.Item key="accounts">
        <Navigate to={route('admin.accounts')}>
          <span className={styles.menuItem}>
            <FaRegHandshake style={{ fontSize: '24px', marginRight: '6px' }} />
            <span>{t('accounts')}</span>
          </span>
        </Navigate>
      </Menu.Item>

      <Menu.Item key="cells">
        <Navigate to={route('admin.cells')}>
          <span className={styles.menuItem}>
            <IoMdPeople style={{ fontSize: '24px', marginRight: '6px' }} />
            <span>{t('cells')}</span>
          </span>
        </Navigate>
      </Menu.Item>

      <Menu.Item key="users">
        <Navigate to={route('admin.users')}>
          <span className={styles.menuItem}>
            <IoMdPerson style={{ fontSize: '24px', marginRight: '6px' }} />
            <span>{t('users')}</span>
          </span>
        </Navigate>
      </Menu.Item>

      <RightMenu />
    </Menu>
  );
};

export default AdminAppBar;
