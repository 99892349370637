import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { useMutation } from '@apollo/client';
import { Card, Button, List, Popover, notification } from 'antd';

import PaginatedList from '@comp/PaginatedList';
import AvatarPicture from '@comp/AvatarPicture';
import TutorialButton from '@comp/TutorialButton';
import TutorialAccountList from './TutorialAccountList';

import { route } from '@router';
import { accounts_list } from '@logic/queries';
import { delete_account } from '@logic/mutations';
import errorMessage from '@logic/functions/errorHandeling';

import styles from './styles.module.less';

// Lista de clientes cadastrados na idlab

const AccountsPage: React.FC = () => {
  const [confirm, setConfirm] = useState<number | undefined>();
  const [reload, setReload] = useState(false);
  const [accounts, setAccounts] = useState(false);
  const history = useHistory();

  const { t } = useTranslation('accountsPage');

  //--------------------------Mutations-----------------------------------------

  const [DeleteAccount] = useMutation(delete_account, { variables: { id: 0 } });

  //-----------------------------------------------------------------------------

  useEffect(() => {
    setReload(false);
  }, [reload]);

  async function Delete(id: number) {
    try {
      let res = await DeleteAccount({ variables: { id } });
      notification.open({
        type: 'success',
        message: t('message_deleted_account'),
        description: t('description_customer') + ` ${res.data?.deleteAccount.name} ` + t('description_deleted'),
      });
      setReload(true);
    } catch (err) {
      notification.open({
        type: 'error',
        ...errorMessage('graph_err', err),
      });
    }
  }

  return (
    <Card className={styles.root}>
      <Button
        id="add_new_account"
        type="primary"
        onClick={() => history.push(route('admin.accounts.edit', { account: 'new' }))}>
        {t('button_add')}
      </Button>

      <PaginatedList
        query={accounts_list}
        params={{ fetchPolicy: 'network-only' }}
        reloadParam={reload}
        showPath={(data) => {
          setAccounts(data.me?.accounts?.total > 0);

          return {
            total: data.me?.accounts?.total,
            data: data.me?.accounts?.data,
          };
        }}
        limit={10}
        renderItem={({ name, id, status, logoUrl }, idx: number) => (
          <List.Item
            id="open_account"
            key={id}
            onClick={() => history.push(route('admin.accounts.edit', { account: id }))}
            className={styles.item}
            actions={[
              <Popover
                destroyTooltipOnHide={{ keepParent: false }}
                content={
                  <>
                    <Button
                      onClick={(e) => {
                        e.stopPropagation();
                        setConfirm(undefined);
                      }}>
                      {t('cancel_text')}
                    </Button>
                    <Button
                      style={{ marginLeft: 6 }}
                      onClick={(e) => {
                        e.stopPropagation();
                        setConfirm(undefined);
                        Delete(id);
                      }}>
                      {t('ok_text')}
                    </Button>
                  </>
                }
                title={t('delete_question')}
                trigger="click"
                visible={confirm === idx}
                onVisibleChange={() => setConfirm(idx)}>
                <Button id="delete_account" onClick={(e) => e.stopPropagation()}>
                  {t('button_delete')}
                </Button>
              </Popover>,
              <Button
                id="open_account_button"
                onClick={(e) => {
                  e.stopPropagation();
                  return history.push(route('admin.accounts.edit', { account: id }));
                }}>
                {t('button_view')}
              </Button>,
            ]}>
            <List.Item.Meta
              avatar={<AvatarPicture pictureLink={logoUrl} target="Account" size={40} name={name} />}
              title={name}
              description={
                status === 'inactive'
                  ? t('status_inactive')
                  : status === 'active'
                  ? t('status_active')
                  : status === 'suspended'
                  ? t('status_suspendend')
                  : ''
              }
            />
          </List.Item>
        )}
      />
      <TutorialButton
        tourId="023_AccountListManagement"
        top={80}
        right={20}
        placement="left"
        title="Tutorial da lista de clientes"
      />

      <TutorialAccountList hasAccount={accounts} />
    </Card>
  );
};

export default AccountsPage;
