import React from 'react';
import { observer } from 'mobx-react-lite';
import { useMutation } from '@apollo/client';
import { Card, Button, Form, notification, Row, Col } from 'antd';

import Upload from '@comp/Upload';
import EmojiLinkInput from '@comp/EmojiLinkInput';
import OnPasteDiv from '@comp/OnPasteDiv';

import { create_note } from '@logic/mutations';
import errorMessage from '@logic/functions/errorHandeling';
import { UploadItem } from '@logic/stores/uploadManager';
import { useStore } from '@logic/context';

import styles from './styles.module.less';

interface Props {
  taskId: string;
  refresh: () => void;
}

const NewNote: React.FC<Props> = ({ taskId, refresh }) => {
  const [form] = Form.useForm();
  const { uploadManager } = useStore();

  //--------------------- Mutation --------------------------------

  const [CreateNote, { loading }] = useMutation(create_note);

  //--------------------- SAVE FUNCTION ---------------------------
  async function doSave(body: string) {
    try {
      await CreateNote({
        variables: {
          task: taskId,
          body: body,
          files: uploadManager.temporaryFiles
            .filter((file) => file.parentId === taskId)
            .map((file) => ({ key: file.fileKey, name: file.file.name })),
        },
      }).then(() => {
        notification.open({
          placement: 'bottomRight',
          duration: 2,
          type: 'success',
          message: 'Nota criada.',
        });

        uploadManager.removeTemporaryFiles(taskId);
        refresh();
      });
    } catch (err) {
      notification.open({ type: 'error', ...errorMessage('graph_err', err) });
    }
  }

  //------------------ Form functions ------------------------------

  const onFinish = () => {
    form
      .validateFields()
      .then((values) => {
        doSave(values.body);
        form.resetFields();
      })
      .catch((info) => {
        notification.open({ type: 'error', ...errorMessage('form_validation', info) });
      });
  };

  const onFinishFailed = (errorInfo: any) => {
    notification.open({ type: 'error', ...errorMessage('', errorInfo) });
  };

  //------------------------------------------------------------------------------

  return (
    <OnPasteDiv
      onPaste={(data) => {
        for (const file of data) {
          const uploadItem: UploadItem = { parentId: taskId, targetType: 'Note', file };

          uploadManager.uploadFile(uploadItem);
        }
      }}>
      <Card id="create_note" className={styles.card} style={{ marginBottom: '16px' }} bodyStyle={{ paddingBottom: 0 }}>
        <Form form={form} layout="vertical" name="new_note" onFinish={onFinish} onFinishFailed={onFinishFailed}>
          <Form.Item name="body">
            <EmojiLinkInput multiline border emojiPicker="on" placeholder="Digite aqui..." className={styles.body} />
          </Form.Item>

          <Form.Item>
            <Row gutter={24}>
              <Col span={6}>
                <Button
                  title={'Crie anotações e anexar arquivos  referente a atividade'}
                  type="primary"
                  htmlType="submit"
                  block
                  size="small"
                  loading={loading}>
                  Criar nota
                </Button>
              </Col>
            </Row>
          </Form.Item>
        </Form>

        <Upload parentId={taskId} targetType="Note" loading={loading} />
      </Card>
    </OnPasteDiv>
  );
};

export default observer(NewNote);
