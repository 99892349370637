import React, { useMemo } from 'react';
import { DateTime, Duration } from 'luxon';

import { Col, Row } from 'antd';
import { ResponsiveBar } from '@nivo/bar';
import { ResponsivePie } from '@nivo/pie';

import useWindowSize from '@logic/functions/windowSize';
import useConfig from '@logic/config';
import { parseDateTime } from '@logic/functions/date';

interface Props {
  data: any[];
  view: string;
  total: number; // Unused
  period: { begin: any; end: any };
}

const TimersGraphs: React.FC<Props> = ({ data, view, period, total }) => {
  const config = useConfig();
  const { width } = useWindowSize();
  const collapsedMenu = !!config.collapsed_menu;

  const { bars, barsKeys } = useMemo(() => formatBarsData(data, period), [data, period]);
  const { pie } = useMemo(() => formatPieData(data, view), [data, view]);

  // const tickValues = [];

  // for (let i = 0; i < total / 3600000; i++) {
  //   tickValues.push(i * 3600000);
  // }

  const days = DateTime.fromFormat(period.end, 'yyyy-LL-dd').diff(
    DateTime.fromFormat(period.begin, 'yyyy-LL-dd'),
    'days',
  ).days;

  const breakGraphs = (collapsedMenu ? width < 1400 : width < 1600) || days > 40;

  return (
    <div style={{ display: 'flex' /*height: days < 40 ? 300 : 600*/ }}>
      <Row style={{ width: '100%', marginBottom: 16 }}>
        <Col id="bar_chart" span={!breakGraphs ? (pie.length < 6 ? 14 : 18) : 24} style={{ height: 300 }}>
          <ResponsiveBar
            data={bars}
            keys={barsKeys}
            padding={0.3}
            indexBy={'date'}
            valueScale={{ type: 'linear' }}
            tooltipFormat={(label) => Duration.fromMillis(Number(label)).toFormat('hh:mm:ss')}
            labelFormat={(label) => Duration.fromMillis(Number(label)).toFormat('hh:mm:ss')}
            enableLabel={false}
            labelSkipWidth={12}
            labelSkipHeight={12}
            axisBottom={{ tickRotation: -45 }}
            axisLeft={{
              format: (label) => Duration.fromMillis(Number(label)).toFormat('hh:mm'),
              //tickValues,
            }}
            colors={['#ff5333']}
            margin={{ top: 20, right: 40, bottom: 60, left: 60 }}
          />
        </Col>

        <Col id="pizza_chart" span={!breakGraphs ? (pie.length < 6 ? 10 : 6) : 24} style={{ height: 300 }}>
          <ResponsivePie
            data={pie}
            margin={{ top: 20, right: 10, bottom: 20, left: 10 }}
            colors={colors.sort((a, b) => Math.round(Math.random() * 2) - 1)}
            enableArcLabels={false}
            enableArcLinkLabels={pie.length < 6}
            //colors={['#E07A5F', '#2A6F97', '#69306D', '#52796F', '#8f8f8f', '#B9375E']}
            //colors={['#E76F00', '#1982C4', '#6A4C93', '#2A9D67', '#8f8f8f', '#E05780']}
            //colors={['#f4a259', '#219ebc', '#a5668b', '#8cb369', '#8f8f8f', '#f07167']}
            //colors={['#ff5333', '#3385FF', '#B03591', '#55A630', '#FF2362']}
            valueFormat={(label) => Duration.fromMillis(Number(label)).toFormat('hh:mm:ss')}
          />
        </Col>
      </Row>
    </div>
  );
};

export default TimersGraphs;

function formatPieData(data: any[], view: string) {
  let total = reduceTotals(data, view);

  if (view === 'date') {
    total = sortDateTotals(total);
  }

  const pie = total.map(({ id, label, tempo }: any) => ({ id: label, label, value: tempo }));

  return { pie };
}

function formatBarsData(data: any[], period: any) {
  const barsTotals = sortDateTotals(reduceTotals(data, 'date', period));

  const bars = barsTotals.map(({ id, label, tempo }: any) => ({ date: label, tempo }));

  return { bars, barsKeys: ['tempo'] };
}

function sortDateTotals(data: any[]) {
  return data.sort((a: any, b: any) => {
    const dateA = DateTime.fromFormat(a.id, 'yyyy_LL_dd');
    const dateB = DateTime.fromFormat(b.id, 'yyyy_LL_dd');
    if (dateA > dateB) return 1;
    if (dateA < dateB) return -1;
    return 0;
  });
}

function reduceTotals(data: any[], reduceBy: any, period?: any) {
  let periodDaysArray: any[] = [];

  if (period) {
    const periodBegin = DateTime.fromFormat(period.begin, 'yyyy-LL-dd');
    const periodEnd = DateTime.fromFormat(period.end, 'yyyy-LL-dd');

    for (let day = periodBegin; day <= periodEnd; day = day.plus({ days: 1 })) {
      periodDaysArray.push({ id: day.toFormat('yyyy_LL_dd'), label: day.toFormat('dd/LL/yyyy'), tempo: 0 });
    }
  }

  return data.reduce(
    (acc, curr) => {
      const start = parseDateTime(curr.start);
      const end = parseDateTime(curr.end);

      let key = '';
      let label = '';

      switch (reduceBy) {
        case 'date':
          key = start.toFormat('yyyy_LL_dd');
          label = start.toFormat('dd/LL/yyyy');
          break;
        case 'account':
          key = curr.clientId;
          label = curr.clientName;
          break;
        case 'cell':
          key = curr.cellId;
          label = curr.cellName;
          break;
        case 'user':
          key = curr.userId;
          label = curr.userName;
          break;
        case 'user_type':
          key = curr.userType;
          label = userType(curr.userType);
          break;
      }
      const diff = end.diff(start, 'milliseconds');

      const index = acc.findIndex((e: any) => e.id === key);

      if (index === -1) {
        acc.push({ id: key, label, tempo: diff.milliseconds });
      } else {
        acc[index] = { ...acc[index], tempo: acc[index].tempo + diff.milliseconds };
      }

      return acc;
    },
    reduceBy === 'date' ? periodDaysArray : [],
  );
}

function userType(type: any) {
  switch (type) {
    case 'admin':
      return 'administrador';
    case 'customer_success':
      return 'Sucesso de cliente';
    case 'creator':
      return 'Criação';
    case 'media_tester':
      return 'Media tester';
    case 'customer':
      return 'Cliente';
  }
  return '';
}

const colors = [
  '#ff5333',
  /* '#3385FF', '#B03591', '#55A630', '#FF2362',*/
  '#ffc7bf',
  '#e68173',
  '#bf6b60',
  '#804840',
  '#ffe9e6',
  '#e6d1cf',
  '#ff8f80',
  '#bf4130',
  '#bfffd4',
  '#55aa70',
  '#60bf7f',
  '#408054',
  '#e6ffee',
  '#cfe6d6',
  '#80ffa9',
  '#30bf5e',
  '#bfd7ff',
  '#5777ae',
  '#6083bf',
  '#405780',
  '#e6efff',
  '#cfd7e6',
  '#80aeff',
  '#3064bf',
  '#ffe3bf',
  '#e6b373',
  '#bf9560',
  '#806440',
  '#fff4e6',
  '#e6dbcf',
  '#ffc780',
  '#bf8030',
];
/*
function getColors() {
  let colors: string[] = [];
  //http://c0bra.github.io/color-scheme-js/
  var scheme = new ColorScheme();
  const colorshex = scheme.from_hue(9).scheme('tetrade').variation('pastel').colors();
  colors = colorshex.reduce((acc: string[], curr: string) => [...acc, '#' + curr], []);

  while (colors.length < 30) {
    const color = Math.floor(Math.random() * 1000000 + 1).toString();
    if (colors.indexOf(color) === -1) colors.push('#' + color.toString().padStart(6, '3'));
  }
  return colors;
}
*/
/**"#ffc7bf","#e68173", "#bf6b60","#804840",
 "#ffe9e6","#e6d1cf", "#ff8f80","#bf4130",
 "#bfffd4","#55aa70", "#60bf7f","#408054",
 "#e6ffee","#cfe6d6", "#80ffa9","#30bf5e",
 "#bfd7ff","#5777ae", "#6083bf","#405780",
 "#e6efff","#cfd7e6", "#80aeff","#3064bf",
 "#ffe3bf","#e6b373", "#bf9560","#806440",
 "#fff4e6","#e6dbcf", "#ffc780","#bf8030" */
//
