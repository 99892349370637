import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useMutation } from '@apollo/client';
import { notification, Typography, DatePicker, Row, Col } from 'antd';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

import Upload from '@comp/Upload';
import EmojiLinkInput from '@comp/EmojiLinkInput';
import AttatchUser from '@comp/AttatchUser';
import StepSelector from '@comp/StepSelector';
import OnPasteDiv from '@comp/OnPasteDiv';
import CellSelector from '@comp/CellSelector';
import Help from '@comp/Help';
import TutorialButton from '@comp/TutorialButton';
import FeedbacksTab from '../../FeedbacksTab';
import SubTasks from '../../SubTasks';
import TaskFeedback from '../../NewFeedback';

import { update_task } from '@logic/mutations';
import errorMessage from '@logic/functions/errorHandeling';
import { useUploadManager } from '@logic/context';
import { UploadItem } from '@logic/stores/uploadManager';

import { stepsKeysArray, stepsKeys, stepsIconsArray } from '../../..';

import styles from './styles.module.less';

interface Props {
  taskData: any;
  accountId: string;
  setSelected: (id: string) => void;
  refetch: () => void;
}

const TaskTab: React.FC<Props> = ({ taskData, accountId, setSelected, refetch }) => {
  const [task, setTask] = useState(taskData);

  const uploadManager = useUploadManager();

  const { t } = useTranslation('tutorialTooltip');

  const { cell, taskId, taskAccountId } = useMemo(
    () => ({
      cell: taskData.cell_id,
      taskId: taskData.id,
      taskAccountId: taskData.account?.id,
    }),
    [taskData],
  );

  useEffect(() => {
    if (setTask) setTask(taskData);
  }, [taskData, setTask]); // para verificar se o setTask existe ele precisa estar nas dependências

  //------------------------ mutation -----------------------------

  const [UpdateTask] = useMutation(update_task);

  //------------------------- Save function -----------------------

  const update = useCallback(
    async (key: string, value: any) => {
      try {
        await UpdateTask({ variables: { id: taskId, [key]: value } });

        refetch();
      } catch (err) {
        notification.open({ type: 'error', ...errorMessage('graph_err', err) });
      }
    },
    [UpdateTask, refetch, taskId],
  );

  //------------------------ Form functions ---------------------------

  const handleChange = useCallback(({ target }) => setTask((t: any) => ({ ...t, [target.name]: target.value })), []);

  const handleBlur = ({ target }: any) => {
    if (taskData[target.name] !== target.value) update(target.name, target.value);
  };

  //---------------------------------------------------------------------

  return (
    <OnPasteDiv
      style={{ paddingBottom: '16px' }}
      onPaste={(data) => {
        for (const file of data) {
          const uploadItem: UploadItem = { targetId: taskId, targetType: 'Task', file };

          uploadManager.uploadFile(uploadItem);
        }
      }}>
      <div id="title_task">
        <EmojiLinkInput
          name="title"
          placeholder="Título da atividade"
          value={task.title}
          className={styles.title}
          onChange={handleChange}
          onBlur={handleBlur}
          emojiPicker="focus"
        />
      </div>

      <Row id="cell_task" data-tut="cell_task" className={styles.form_item}>
        <Col span={6} className={styles.column}>
          <Typography.Text className={styles.label}>Célula:</Typography.Text>

          <Help
            title="Célula"
            description="É uma equipe que contém membros de sucesso de cliente, criação e media tester"
            iconStyle={{ color: '#5c5c5c', fontSize: 12 }}
            placement="right"
          />
        </Col>
        <Col span={18}>
          <CellSelector
            cellId={cell}
            accountId={taskAccountId}
            cellChange={(id) => handleBlur({ target: { name: 'cell', value: id } })}
          />
        </Col>
      </Row>

      <Row id="responsibles_task" className={styles.form_item}>
        <Col span={6} className={styles.column}>
          <Typography.Text className={styles.label}>Responsáveis:</Typography.Text>
          <Help
            title="Responsáveis"
            description="Membros escolhidos entre aqueles que compõem a célula escolhida, para que sejam responsáveis pela atividade"
            iconStyle={{ color: '#5c5c5c', fontSize: 12 }}
            placement="right"
          />
        </Col>
        <Col span={18}>
          {!!cell && <AttatchUser target={taskId} cellId={cell} attachType="Task" selectMode="multiple" />}
        </Col>
      </Row>

      <Row id="execution_date_task" className={styles.form_item}>
        <Col span={6} className={styles.column}>
          <Typography.Text className={styles.label}>Data de execução: </Typography.Text>
          <Help
            title="Data de execução"
            description="Data em que a atividade será iniciada"
            iconStyle={{ color: '#5c5c5c', fontSize: 12 }}
            placement="right"
          />
        </Col>
        <Col span={18}>
          <DatePicker
            name="execution_date"
            format="DD/MM/YYYY"
            value={task?.execution_date ? moment(task?.execution_date) : null}
            onChange={(value) => handleBlur({ target: { name: 'execution_date', value: momentToStr(value) } })}
          />
        </Col>
      </Row>

      <Row id="finish_date_task" className={styles.form_item}>
        <Col span={6} className={styles.column}>
          <Typography.Text className={styles.label}>Data de publicação: </Typography.Text>
          <Help
            title="Data de publicação"
            description="Data em que a atividade será finalizada e a arte será publicada"
            iconStyle={{ color: '#5c5c5c', fontSize: 12 }}
            placement="right"
          />
        </Col>
        <Col span={18}>
          <DatePicker
            name="finish_date"
            format="DD/MM/YYYY"
            value={task?.finish_date ? moment(task?.finish_date) : null}
            onChange={(value) => handleBlur({ target: { name: 'finish_date', value: momentToStr(value) } })}
          />
        </Col>
      </Row>

      <div id="step_task" className={styles.form_item}>
        <StepSelector
          type="default"
          value={task.step}
          onChange={({ target }) => handleBlur({ target: { name: 'step', value: target.value } })}
          stepKeys={stepsKeysArray}
          stepKeysTitle={stepsKeys}
          stepsIcons={stepsIconsArray()}
        />
      </div>

      <div id="description_task">
        <div className={styles.column}>
          <Typography.Text className={styles.label}>Descrição: </Typography.Text>
          <Help
            title="Descrição"
            description="Campo contendo informação do que se trata a atividade ou do que será publicado"
            iconStyle={{ color: '#5c5c5c', fontSize: 12 }}
            placement="right"
          />
        </div>
        <div className={styles.form_item}>
          <EmojiLinkInput
            name="body"
            emojiPicker="focus"
            placeholder="Descrição da atividade"
            border
            multiline
            className={styles.body}
            value={task.body}
            onChange={handleChange}
            onBlur={handleBlur}
          />
        </div>
      </div>

      {task.step === 'check' && <TaskFeedback taskId={taskId} refetch={refetch} />}

      <SubTasks taskId={taskId} accountId={accountId} type="default" setSelected={setSelected} />

      <div id="files_task">
        <Upload targetId={taskId} parentId={taskId} targetType="Task" />
      </div>

      <FeedbacksTab taskId={taskId} />

      <TutorialButton
        tourId="006_TaskTab"
        circleSize="small"
        iconSize={14}
        bottom={20}
        right={40}
        placement="left"
        title={t('task_tab')}
      />
    </OnPasteDiv>
  );
};

export default TaskTab;

function momentToStr(time?: moment.Moment | null) {
  if (!time) return null;
  return moment(time).format('YYYY-MM-DD');
}
