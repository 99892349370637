import React, { useState, useEffect } from 'react';
import { DragDropContext, Draggable, Droppable, DropResult } from 'react-beautiful-dnd';
import { gql, useMutation, useQuery } from '@apollo/client';
import { List, Input, Button, notification } from 'antd';

import ErrorAndLoading from '@comp/ErrorAndLoading';
import SubTemplateListItem from './SubTemplateListItem';

import { create_template_item, move_before, move_after } from '@logic/mutations';
import errorMessage from '@logic/functions/errorHandeling';

import styles from './styles.module.less';

interface Props {
  templateItemId: string;
  setSelected: (id: string) => void;
  displayOnly?: boolean;
}

const subtemplates = gql`
  query SubTemplatesItems($id: ID!) {
    templateItem(id: $id) {
      id
      type
      template {
        id
      }
      subitems(pagination: { limit: -1, page: 1 }) {
        data {
          id
          title
        }
      }
    }
  }
`;

const SubTemplatesList: React.FC<Props> = ({ templateItemId, setSelected, displayOnly }) => {
  const [templates, setTemplates] = useState<any[]>();
  const [newTemplate, setNewTemplate] = useState('');

  const { data, loading, error, refetch } = useQuery(subtemplates, {
    fetchPolicy: 'network-only',
    variables: { id: templateItemId },
  });

  const [MoveAfter] = useMutation(move_after);
  const [MoveBefore] = useMutation(move_before);

  const [CreateTemplateItem] = useMutation(create_template_item);

  useEffect(() => {
    refetch({ id: templateItemId });
  }, [templateItemId, refetch]);

  useEffect(() => {
    if (!data) return;

    setTemplates(data.templateItem?.subitems?.data);
  }, [data]);

  const onDragEnd = (result: DropResult) => {
    if (!result.destination || !templates) return;

    const from = result.source.index;
    const to = result.destination.index;

    if (from === to) return;

    const reference = from < to ? 'after' : 'before';
    const templateOrdId = templates[from].id;
    const templateRefId = templates[to].id;

    Order(reference, templateRefId, templateOrdId);

    const newTemplates = [...templates];

    const originItem = newTemplates.find((t: any) => t.id === templateOrdId);

    newTemplates.splice(from, 1);

    newTemplates.splice(to, 0, originItem);

    setTemplates(newTemplates);
  };

  async function Order(reference: 'before' | 'after', templateRefId: string, templateOrdId: string) {
    try {
      if (reference === 'after')
        await MoveAfter({ variables: { type: 'TemplateItem', id: templateOrdId, target: templateRefId } });
      if (reference === 'before')
        await MoveBefore({ variables: { type: 'TemplateItem', id: templateOrdId, target: templateRefId } });
    } catch (err) {
      console.error(err);
    } finally {
      refetch({ id: templateItemId });
    }
  }

  async function doCreate() {
    try {
      await CreateTemplateItem({
        variables: {
          template: data?.templateItem?.template?.id,
          title: newTemplate,
          parent: templateItemId,
          type: 'default',
        },
      });

      setNewTemplate('');

      refetch({ id: templateItemId });
    } catch (err) {
      notification.open({ type: 'error', ...errorMessage('graph_err', err) });
    }
  }

  function handleChange(event: React.ChangeEvent<HTMLInputElement>) {
    setNewTemplate(event?.target?.value);
  }

  if (error) return <ErrorAndLoading error={error} />;

  return (
    <List size="small" style={{ margin: '0 0 16px' }} loading={loading && !data}>
      {data && templates && (
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId={templateItemId}>
            {(provided) => (
              <div ref={provided.innerRef} {...provided.droppableProps}>
                {templates.map(({ id, title }: any, idx: number) => (
                  <Draggable draggableId={id} key={`${id + idx}`} index={idx}>
                    {(provided) => (
                      <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                        <SubTemplateListItem
                          key={`${id}_${idx}`}
                          accountId={''}
                          templateItemId={id}
                          templateTitle={title}
                          openItem={(id: string) => setSelected(id)}
                          displayOnly={displayOnly}
                        />
                      </div>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      )}

      {!displayOnly && (
        <List.Item className={styles.item} style={{ padding: 0, display: 'flex', flexDirection: 'row' }}>
          <Input
            style={{ border: 'none', backgroundColor: 'transparent' }}
            placeholder="Criar nova subtarefa"
            value={newTemplate}
            onChange={handleChange}
            onKeyPress={({ key }) => {
              if (key === 'Enter') {
                doCreate();
              }
            }}
          />
          <Button onClick={() => doCreate()} type="primary">
            Salvar
          </Button>
        </List.Item>
      )}
    </List>
  );
};

export default SubTemplatesList;
