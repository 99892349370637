import React from 'react';
import { useHistory } from 'react-router-dom';
import { useMutation } from '@apollo/client';

import { Card, Button, Form, Input, Row, Col, notification } from 'antd';

import { cells_create } from '@logic/mutations';
import errorMessage from '@logic/functions/errorHandeling';
import { route } from '@router';

import TutorialButton from '@comp/TutorialButton';
import TutorialNewCell from './TutorialNewCell';

import styles from './styles.module.less';

const NewCell: React.FC = () => {
  const [form] = Form.useForm();
  const history = useHistory();

  const [CreateCell] = useMutation(cells_create, { variables: { name: '' } });

  async function doSave(name: string) {
    try {
      const res = await CreateCell({ variables: { name } });

      notification.open({ type: 'success', message: 'Célula criada', description: res.data?.createCell?.name });
      history.push(route('admin.cells.edit', { cell: res.data?.createCell?.id }));
    } catch (err) {
      notification.open({ type: 'error', ...errorMessage('graph_err', err) });
    }
  }

  const onFinish = (values: any) => {
    form
      .validateFields()
      .then((values) => {
        doSave(values.name);

        form.resetFields();
      })
      .catch((info) => {
        notification.open({ type: 'error', ...errorMessage('form_validation', info) });
      });
  };

  const onFinishFailed = (errorInfo: any) => {
    notification.open({ type: 'error', ...errorMessage('', errorInfo) });
  };

  return (
    <Card className={styles.root}>
      <Form
        form={form}
        labelCol={{
          span: 2,
          style: { display: 'flex', flexDirection: 'row', justifyContent: 'flex-start' },
        }}
        wrapperCol={{ style: { display: 'flex', flex: 1 } }}
        layout="horizontal"
        name="cell_cadaster"
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}>
        <div id="write_cell_name">
          <Form.Item name="name" label="Nome">
            <Input type="text" />
          </Form.Item>
        </div>

        <Form.Item>
          <Row gutter={24}>
            <Col span={6}>
              <Button type="primary" htmlType="reset" block onClick={() => history.goBack()}>
                Voltar
              </Button>
            </Col>

            <Col span={6}>
              <Button id="save_cell" type="primary" htmlType="submit" block>
                Salvar
              </Button>
            </Col>
          </Row>
        </Form.Item>
      </Form>

      <TutorialButton
        tourId="019_NewCellManagement"
        top={80}
        right={20}
        placement="left"
        title="Tutorial para criar uma nova célula"
      />

      <TutorialNewCell />
    </Card>
  );
};

export default NewCell;
