import React, { useState } from 'react';
import { Upload as UploadAntd } from 'antd';
import { useTranslation } from 'react-i18next';

import { CloudUploadOutlined } from '@ant-design/icons';

import FilesList from './FilesList';
import UploadProgressList from './UploadProgressList';

import { useUploadManager } from '@logic/context';
import { UploadItem } from '@logic/stores/uploadManager';

interface Props {
  parentId: string;
  targetId?: string;
  targetType: 'Task' | 'Note' | 'AccountInfo' | 'Feedback';
  loading?: boolean;
  viewOnly?: boolean;
  previewSize?: number;
}

const Upload: React.FC<Props> = ({ parentId, targetType, targetId, loading, viewOnly, previewSize }) => {
  const [error, setError] = useState(false);
  const uploadManager = useUploadManager();

  const { t } = useTranslation('upload');

  function processRequest({ file, onSuccess }: any) {
    if (loading) return;
    const uploadItem: UploadItem = {
      parentId,
      targetId,
      targetType,
      file,
    };

    uploadManager.uploadFile(uploadItem);
    onSuccess({}, file);
  }

  return (
    <div>
      {!viewOnly && (
        <div style={{ marginTop: 16, marginBottom: 16 }}>
          <UploadAntd.Dragger
            multiple
            disabled={loading}
            customRequest={processRequest}
            beforeUpload={(file) => {
              if (
                file.type.startsWith('image/') &&
                !['image/png', 'image/jpeg', 'image/bmp', 'image/webp'].includes(file.type)
              ) {
                setError(true);
                return false;
              }
              setError(false);
              return true;
            }}
            action={undefined}
            method={undefined}
            showUploadList={false}>
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
              <CloudUploadOutlined style={{ fontSize: 24, marginRight: 6 }} />
              {t('upload_text')}
            </div>
          </UploadAntd.Dragger>
          {error && (
            <p style={{ textAlign: 'center', color: '#ff4d4f', fontWeight: 'bold', fontSize: 12, margin: 0 }}>
              {t('wrong_format')}
            </p>
          )}
        </div>
      )}

      {!viewOnly && (
        <div style={{ display: 'flex', flexDirection: 'row', flex: 1 }}>
          <UploadProgressList parentId={parentId} targetId={targetId} />
        </div>
      )}

      <FilesList
        parentId={parentId}
        targetId={targetId}
        targetType={targetType}
        viewOnly={viewOnly}
        previewSize={previewSize}
      />
    </div>
  );
};

export default Upload;
