import React from 'react';
import { gql, useQuery } from '@apollo/client';
import { List, Typography } from 'antd';
import { DateTime, Duration } from 'luxon';

import ErrorAndLoading from '@comp/ErrorAndLoading';
import AvatarPicture from '@comp/AvatarPicture';

import useConfig from '@logic/config';

const AccountTimeReports: React.FC = () => {
  const config = useConfig();

  const { end, begin } = {
    begin: config.reports_timers_filter_begin || DateTime.local().startOf('month').toFormat('yyyy-LL-dd'),
    end: config.reports_timers_filter_end || DateTime.local().endOf('month').toFormat('yyyy-LL-dd'),
  };

  const { data, loading, error } = useQuery(accounts_reports_query, { variables: { begin, end } });

  if (error) return <ErrorAndLoading error={error} borderless />;

  return (
    <List loading={loading}>
      <List.Item key="header">
        <Typography.Text style={{ flex: 2 }}>Cliente</Typography.Text>
        <Typography.Text style={{ flex: 1, textAlign: 'center' }}>Atividades</Typography.Text>
        <Typography.Text style={{ flex: 1, textAlign: 'center' }}>Tempo gasto</Typography.Text>
        <Typography.Text style={{ flex: 1, textAlign: 'center' }}>Tempo planejado</Typography.Text>
        <Typography.Text style={{ flex: 1, textAlign: 'center' }}>Tempo contratado</Typography.Text>
      </List.Item>

      {data &&
        (data?.me?.accounts?.data || []).map(({ name, id, logoUrl, timers_report }: any) => {
          if (!timers_report) return null;

          const { tasks, total_seconds, planned_seconds, monthly_planned_seconds } = timers_report;

          return (
            <List.Item key={`account_time_reports_${id}`}>
              <div style={{ flex: 2, display: 'flex', alignItems: 'center' }}>
                <AvatarPicture pictureLink={logoUrl} target="Account" size={28} name={name} />
                <Typography.Text style={{ marginLeft: 6 }}>{name}</Typography.Text>
              </div>
              <Typography.Text style={{ flex: 1, textAlign: 'center' }}>{tasks}</Typography.Text>
              <Typography.Text
                style={{
                  flex: 1,
                  textAlign: 'center',
                  fontWeight: total_seconds > monthly_planned_seconds ? 600 : undefined,
                  color:
                    total_seconds > monthly_planned_seconds
                      ? '#ff0000'
                      : total_seconds > planned_seconds
                      ? '#fd6347'
                      : undefined,
                }}>
                {Duration.fromMillis(total_seconds * 1000).toFormat('hh:mm:ss')}
              </Typography.Text>
              <Typography.Text
                style={{
                  flex: 1,
                  textAlign: 'center',
                  fontWeight: planned_seconds > monthly_planned_seconds ? 600 : undefined,
                  color: planned_seconds > monthly_planned_seconds ? '#ff0000' : undefined,
                }}>
                {Duration.fromMillis(planned_seconds * 1000).toFormat('hh:mm:ss')}
              </Typography.Text>
              <Typography.Text style={{ flex: 1, textAlign: 'center' }}>
                {Duration.fromMillis(monthly_planned_seconds * 1000).toFormat('hh:mm:ss')}
              </Typography.Text>
            </List.Item>
          );
        })}
    </List>
  );
};

export default AccountTimeReports;

const accounts_reports_query = gql`
  query AccountsReports($begin: Date, $end: Date) {
    me {
      accounts(pagination: { limit: -1 }) {
        data {
          id
          name
          logoUrl
          timers_report(period: { begin: $begin, end: $end }) {
            tasks
            total_seconds
            planned_seconds
            monthly_planned_seconds
          }
        }
      }
    }
  }
`;
