import React, { useState, useEffect, useRef, useCallback, useMemo } from 'react';
import { Draggable, DragDropContext, Droppable, DropResult } from 'react-beautiful-dnd';
import { DocumentNode, useMutation, useLazyQuery } from '@apollo/client';
import { List, Empty, Result } from 'antd';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import fireworks from '../../../../img/fireworks.svg';

import ErrorAndLoading from '@comp/ErrorAndLoading';
import TutorialButton from '@comp/TutorialButton';
import ActivitytListItem from './ActivityListItem';
import NewActivity from './NewActivity';
import TutorialActivitiesList from '../TutorialActivitiesList';

import { move_before, move_after } from '@logic/mutations';

import styles from './styles.module.less';

interface Props {
  accountId?: string; // Para criar novas atividades pela lista
  setSelected: (taskId: string) => void; // Abrir atividade
  hideProblemStyle?: boolean; // Não diferenciar atividades com problema
  canReorderList?: boolean; // DragAndDrop
  canCreateNewTask?: boolean; // Mostrar ou ocultar o input no final da lista
  showAccountLogo: boolean; // Mostrar a logo da conta associada à atividade
  showSelectEditMultiple: boolean; // Checkbox para edição múltipla (excluir múltiplos)
  query: DocumentNode; // Query da tela onde a lista será usada
  variables: any; // Filtros
  getData: (data: any) => any; // Extrair as atividades para mapear na lista
  page?: string;
}

const ActivitytList: React.FC<Props> = ({
  accountId,
  setSelected,
  hideProblemStyle,
  canReorderList,
  canCreateNewTask,
  showAccountLogo,
  showSelectEditMultiple,
  query,
  variables,
  getData,
  page,
}) => {
  const history = useHistory();

  const [tasks, setTasks] = useState<any[]>();

  const { t } = useTranslation('activitiesList');

  const total = useRef(0);
  const limit = useRef(30);

  //--------------------------Queries----------------------------------

  const [fetch, { data, loading, error }] = useLazyQuery(query, { fetchPolicy: 'cache-and-network' });

  //--------------------------Mutations---------------------------------

  const [MoveAfter] = useMutation(move_after);
  const [MoveBefore] = useMutation(move_before);

  //--------------------------------------------------------------------

  const refetchTasks = useCallback(() => {
    fetch({ variables: { ...variables, page: 1, limit: limit.current } });
  }, [variables, fetch]);

  const fetchMoreTasks = useCallback(() => {
    limit.current += 30;
    refetchTasks();
  }, [refetchTasks]);

  useEffect(() => {
    refetchTasks();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [variables]);

  useEffect(() => {
    if (!data) return;

    const newTasks = getData(data);
    total.current = newTasks.total;
    setTasks(newTasks.data);
  }, [data, getData]);

  // Remove a atividade do index antigo e adiciona ela no novo index
  const onDragEnd = (result: DropResult) => {
    if (!result.destination || !tasks) return;

    const from = result.source.index;
    const to = result.destination.index;
    //const lastIndex = tasks.length - 1;
    if (from === to) return;

    const reference = from < to ? 'after' : 'before';
    const taskOrdId = tasks[from].id;
    const taskRefId = tasks[to].id;
    Order(reference, taskRefId, taskOrdId);

    const newTasks = [...tasks];
    const originItem = newTasks.find((t: any) => t.id === taskOrdId);

    newTasks.splice(from, 1);
    newTasks.splice(to, 0, originItem);
    setTasks(newTasks);
  };

  async function Order(reference: 'before' | 'after', taskRefId: string, taskOrdId: string) {
    try {
      if (reference === 'after') await MoveAfter({ variables: { type: 'Task', id: taskOrdId, target: taskRefId } });
      if (reference === 'before') await MoveBefore({ variables: { type: 'Task', id: taskOrdId, target: taskRefId } });
    } catch (err) {
      console.error(err);
    } finally {
      refetchTasks();
    }
  }

  const hasAssignment = useMemo(() => {
    if (!!tasks && tasks.find((item) => item.type === 'assignment') !== undefined) return true;

    return false;
  }, [tasks]);

  const hasDefault = useMemo(() => {
    if (!!tasks && tasks.find((item) => item.type === 'default') !== undefined) return true;

    return false;
  }, [tasks]);

  const tourId = useMemo(() => {
    if (!tasks || tasks.length === 0) {
      return ['003_ActivitiesFilters'];
    }

    if (!!tasks && tasks.length > 0) {
      return ['003_ActivitiesFilters', '004_ActivitiesList'];
    }
  }, [tasks]);

  const title = useMemo(() => {
    if (!tasks || tasks.length === 0) {
      return [t('activities_filter')];
    }

    if (!!tasks && tasks.length > 0) {
      return [t('activities_filter'), t('activities_list')];
    }
  }, [tasks, t]);

  if (error && !data && !loading) return <ErrorAndLoading error={error} />;

  return (
    <List
      size="small"
      style={{ margin: 0, border: !tasks || tasks.length === 0 ? '1px solid transparent' : undefined }}
      loading={!tasks && loading}>
      {data && !loading && (!tasks || tasks.length === 0) && page !== 'home_dashboard' && (
        <Empty
          image={Empty.PRESENTED_IMAGE_SIMPLE}
          style={{ margin: 0, paddingTop: 8, paddingBottom: 8 }}
          description={t('empty_description')}
        />
      )}

      {data && !loading && (!tasks || tasks.length === 0) && page === 'home_dashboard' && (
        <Result
          style={{ paddingTop: 150 }}
          icon={<img src={fireworks} alt={t('result_alt')} className={styles.fireworks_image} style={{ height: 64 }} />}
          subTitle={t('result_description')}
        />
      )}

      {tasks && (
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId={variables.steps ? variables.steps[0] : 'execution'}>
            {(provided) => (
              <div ref={provided.innerRef} {...provided.droppableProps}>
                {tasks.map(
                  (
                    {
                      id,
                      title,
                      execution_date,
                      finish_date,
                      step,
                      type,
                      account_id,
                      cell_id,
                      has_problem,
                      users,
                    }: any,
                    idx: number,
                  ) => (
                    <Draggable isDragDisabled={canReorderList} draggableId={id} key={`${id}_${idx}`} index={idx}>
                      {(provided) => (
                        <div
                          //{idx === 0 ? 'activity_item_open' : undefined}
                          id="drawerException"
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}>
                          <ActivitytListItem
                            index={idx}
                            accountId={account_id}
                            showAccountLogo={showAccountLogo}
                            cellId={cell_id}
                            problem={!hideProblemStyle && has_problem}
                            taskId={id}
                            taskTitle={title}
                            taskStep={step}
                            taskExecutionDate={execution_date}
                            taskFinishDate={finish_date}
                            taskUsers={users}
                            taskType={type}
                            showSelectEditMultiple={showSelectEditMultiple}
                            openTask={() => setSelected(id)}
                            refresh={refetchTasks}
                          />
                        </div>
                      )}
                    </Draggable>
                  ),
                )}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      )}

      {tasks && tasks.length < total.current && (
        <List.Item
          key="more"
          className={styles.item}
          onClick={fetchMoreTasks}
          style={{
            cursor: 'pointer',
            display: 'flex',
            justifyContent: 'center',
          }}>
          {!loading && (
            <>
              {t('showing')} {tasks.length} {t('of')} {total.current}. {t('see_more')}
            </>
          )}
          {loading && t('loading')}
        </List.Item>
      )}

      {accountId && canCreateNewTask && (
        <List.Item
          className={styles.item}
          style={{
            padding: 0,
            display: 'flex',
            flexDirection: 'row',
          }}>
          <NewActivity
            accountId={accountId}
            step={variables.steps ? variables.steps[0] : 'execution'}
            type={variables.types ? variables.types[0] : 'default'}
            refetch={refetchTasks}
          />
        </List.Item>
      )}

      {history.location.pathname !== '/' && <TutorialButton tourId={tourId} top={80} right={20} title={title} />}

      <TutorialActivitiesList hasAssignment={hasAssignment} hasDefault={hasDefault} />
    </List>
  );
};

export default ActivitytList;
