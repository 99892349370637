import React, { useState, useEffect, Fragment } from 'react';
import { useLazyQuery } from '@apollo/client';
import { List, Empty, Typography, Row, Col, Input, Select, Button, DatePicker, notification, Pagination } from 'antd';
import moment from 'moment';

import ErrorAndLoading from '@comp/ErrorAndLoading';
import LeadItem from './LeadItem';

import { selected_account_leads_list } from '@logic/queries';
import styles from './styles.module.less';
import { masks } from '@logic/functions/masks';
import extractNumbers from '@logic/functions/extractNumbers';
import { classifications, sources } from '../constants';
import LeadClassificationFilter from '../LeadDrawer/LeadClassificationFilter';
import Progress from '../Progress';

interface Props {
  accountId: string;
  setSelected: (id: string) => void;
  leadId: string;
}

const LeadsList: React.FC<Props> = ({ accountId, setSelected, leadId }) => {  
  const [filters, setFilters] = useState<string[]>([]);
  const [initialDate, setInitialDate] = useState<string>();
  const [finalDate, setFinalDate] = useState<string>();

  const [name, setName] = useState<string>('');
  const [email, setEmail] = useState<string>('');
  const [phone, setPhone] = useState<string>('');
  const [source, setSource] = useState<string>('');
  const [searchClicked, setSearchClicked] = useState(true);

  const [page, setPage] = useState(1);

  const [fetchLeads, { data, loading, error, refetch }] = useLazyQuery(selected_account_leads_list, {
    fetchPolicy: 'network-only',
    variables: { 
      account: accountId,
      page
    },
  });

  const [fetchAllLeads, { data: dataAll, loading: loadingAll, error: errorAll, refetch: refetchAll }] = useLazyQuery(selected_account_leads_list, {
    fetchPolicy: 'network-only',
    variables: { 
      account: accountId,
      page: -1,
      limit: -1
    },
  });

  useEffect(() => {
    if (!accountId) {
      setSearchClicked(false);
      return;
    }

    if (searchClicked) {
      leadsFilter();
      setSearchClicked(false);
    }
  }, [accountId, searchClicked]);

  useEffect(() => {
    refetch({ account: accountId });
    refetchAll({ account: accountId })
  }, [accountId, refetch, refetchAll, leadId]);

  useEffect(() => {
    leadsFilter();
  }, [page, filters]);

  const leadsFilter = () => {
    let variables: any = {
      account: accountId,
      name,
      email,
      page
    };

    if (filters?.length > 0) variables = { ...variables, classifications: filters} ;
    if (initialDate) variables = { ...variables, begin: moment(initialDate).format('YYYY-MM-DD') };
    if (finalDate) variables = { ...variables, end: moment(finalDate).format('YYYY-MM-DD') };
    if (phone) variables = { ...variables, phone: extractNumbers(phone) };
    if (source) variables = { ...variables, sources: [source] };

    fetchLeads({ variables });
    fetchAllLeads({ ...variables, limit: -1 });
  };
  
  const processedLeads = [...(data?.account.leads.data ?? [])];

  const stats = [...(dataAll?.account.leads.data ?? [])].reduce(
    (prev, curr) => {
      return { ...prev, [curr.classification]: (prev[curr.classification] ?? 0) + 1 };
    },
    {
      none: 0,
      no_contact: 0,
      has_profile: 0,
      no_profile: 0,
      closed: 0,
    }
  );

  const handleInput = (e: React.ChangeEvent<HTMLInputElement>, type: string) => {
    const { value } = e.target;

    if (type === 'name') {
      setName(value);
    }
    
    if (type === 'phone') {
      setPhone(masks.phone(value));
    }

    if (type === 'email') {
      setEmail(value);
    }
  }

  const handleChange = (value: string) => {
    setSource(value);
  };

  const handleSearch = () => {
    const firstDate = moment(initialDate, 'YYYY-MM-DD', true);
    const lastDate = moment(finalDate, 'YYYY-MM-DD', true);

    if (initialDate && firstDate.isValid() !== true) {
      notification.open({ type: 'error', message: 'A data inicial não é válida!' });
      return;
    }

    if (finalDate && lastDate.isValid() !== true) {
      notification.open({ type: 'error', message: 'A data final não é válida!' });
      return;
    }

    if (initialDate && finalDate && firstDate.isAfter(lastDate)) {
      notification.open({ type: 'error', message: 'A data inicial é maior que a data final!' });
      return;
    }

    setSearchClicked(true);
  };

  const handleChangeDate = (value: any, type: 'initial' | 'final') => {
    let val = '';

    if (value) {
      val = moment(value).format('YYYY-MM-DD');
    }

    if (type === 'initial') {
      setInitialDate(val);
    }

    if (type === 'final') {
      setFinalDate(val);
    }
  }

  //------------------------------------------------------------------------------

  if (error || errorAll) return <ErrorAndLoading error={error || errorAll} />;

  return (
    <List size="small" style={{ margin: 0, borderBottom: 'none' }} loading={loading || loadingAll}>
      <div className={styles.filtercontainer}>
        <Row gutter={[8, 8]}>
          <Col span={12}>
            <DatePicker
              name="initial_date"
              format="DD/MM/YYYY"
              placeholder="De"
              onChange={(value) => handleChangeDate(value, 'initial')}
              style={{ width: '100%' }}
            />
          </Col>
          <Col span={12}>
            <DatePicker
              name="final_date"
              placeholder="Até"
              format="DD/MM/YYYY"
              onChange={(value) => handleChangeDate(value, 'final')}
              style={{ width: '100%' }}
            />
          </Col>
          <Col span={12}>
            <Input 
              type="text"
              placeholder="Nome"
              value={name}
              defaultValue={name}
              onChange={(e) => handleInput(e, 'name')}
            />
          </Col>
          <Col span={12}>
            <Input 
              type="tel"
              placeholder="Telefone"
              value={phone}
              defaultValue={phone}
              onChange={(e) => handleInput(e, 'phone')}
            />
          </Col>
          <Col span={12}>
            <Input 
              type="email"
              placeholder="E-mail"
              value={email}
              defaultValue={email}
              onChange={(e) => handleInput(e, 'email')}
            />
          </Col>
          <Col span={12}>
            <Select
              showSearch
              placeholder="Origem"
              onChange={handleChange}
              style={{ width: '100%' }}
            >
              {Object.entries(sources).map(([key, src]: [string, any]) => (
                <Select.Option value={src.name} key={key}>
                  <div style={{display: 'flex', alignItems: 'center'}}>
                    {src.icon}<span style={{ marginLeft: 8 }}>{src.label}</span>
                  </div>
                </Select.Option>
              ))}
            </Select>
          </Col>
          <Col span={24}>
            <div className={styles.search}>
              <Button type="primary" onClick={() => handleSearch()}>Pesquisar</Button>
            </div>
          </Col>
          <Col span={24}>
            <Progress
              checked={filters}
              values={[
                { key: 'none', value: stats.none, color: classifications.none.color },
                { key: 'no_contact', value: stats.no_contact, color: classifications.no_contact.color },
                { key: 'has_profile', value: stats.has_profile, color: classifications.has_profile.color },
                { key: 'no_profile', value: stats.no_profile, color: classifications.no_profile.color },
                { key: 'closed', value: stats.closed, color: classifications.closed.color },
              ]}
            />
            <LeadClassificationFilter 
              quantity={{
                none: stats.none,
                no_contact: stats.no_contact,
                has_profile: stats.has_profile,
                no_profile: stats.no_profile,
                closed: stats.closed,
              }} 
              onChange={(options) => setFilters(options)} 
            />
          </Col>
        </Row>
      </div>

      {data && !loading && !loadingAll && (!processedLeads || processedLeads.length === 0) && (
        <Empty
          image={Empty.PRESENTED_IMAGE_SIMPLE}
          style={{ margin: 0, paddingTop: 8, paddingBottom: 8, marginTop: 10 }}
          description="Não existem leads nessa conta"
        />
      )}

      {!!processedLeads &&
        processedLeads.map(function ({ id, classification, name, source, subscribed_at }: any, idx: number) {
          const subscribedAt = moment.utc(subscribed_at, 'YYYY-MM-DD HH:mm:ss').local();

          const hasDateComponent =
            idx === 0 ||
            !subscribedAt.isSame(
              moment.utc(processedLeads[idx - 1].subscribed_at, 'YYYY-MM-DD HH:mm:ss').local(),
              'day'
            );
            
          return (
            <Fragment key={`${idx}`}>
              {hasDateComponent && (
                <Typography.Paragraph className={styles.textdate} key={`${subscribedAt}_${idx}`}>
                  {subscribedAt.format('dddd[,] DD [de] MMMM')}
                </Typography.Paragraph>
              )}
              <div id="leadListItem" key={`${id}_${idx}`}>
                <LeadItem
                  leadId={id}
                  leadName={name}
                  leadClassification={classification}
                  leadSource={source}
                  setSelected={(id) => setSelected(id)}
                />
              </div>
            </Fragment>
          );
        })
      }
      <>

        {!!data && data.account.leads.total / 10 > 1 && (
          <div className={styles.pageIndicator}>
            <Pagination
              defaultCurrent={1}
              pageSize={10}
              total={data.account.leads.total}
              current={page}
              onChange={(p) => {
                setPage(p);
              }}
              hideOnSinglePage
            />
          </div>
        )}
      </>
    </List>
  );
};

export default LeadsList;
