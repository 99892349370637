import React, { useEffect, useMemo, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { gql, useQuery } from '@apollo/client';
import { Card, Col, message, Radio, Row, Tooltip, Typography } from 'antd';
import { DateTime, Duration } from 'luxon';

import { CalendarOutlined } from '@ant-design/icons';
import { IoMdPeople, IoMdPerson } from 'react-icons/io';
import { FaRegHandshake } from 'react-icons/fa';
import { BiIdCard } from 'react-icons/bi';

import ErrorAndLoading from '@comp/ErrorAndLoading';
import TutorialButton from '@comp/TutorialButton';
import ExportTimers from './ExportTimers';
import TimersListMap from './TimersList';
import TimersGraphs from './TimersGraphs';
import FilterPeriod from './FilterPeriod';
import FilterUserAccountCell from './FilterUserAccountCell';
import TutorialTimers from './TutorialTimers';

import { useStore } from '@logic/context';
import useConfig from '@logic/config';
import { parseDateTime } from '@logic/functions/date';

import styles from './styles.module.less';

const TimersPage: React.FC = () => {
  const [view, setView] = useState<'date' | 'user' | 'user_type' | 'account' | 'cell'>('date');
  const { profile } = useStore();
  const config = useConfig();

  const admin = profile?.roles.includes('admin');

  const { begin, end, users, accounts, cells } = {
    begin: config.timers_filter_begin || DateTime.local().toFormat('yyyy-LL-dd'),
    end: config.timers_filter_end || DateTime.local().toFormat('yyyy-LL-dd'),
    users: ((config.timers_filter_users as any) || []).length > 0 ? config.timers_filter_users : undefined,
    accounts: ((config.timers_filter_accounts as any) || []).length > 0 ? config.timers_filter_accounts : undefined,
    cells: ((config.timers_filter_cells as any) || []).length > 0 ? config.timers_filter_cells : undefined,
  };

  //-------------------------- QUERY -------------------------------------

  const { data, loading, error, refetch } = useQuery(timers_query, {
    fetchPolicy: 'network-only',
    variables: { begin, end, users: !!admin ? users : [profile?.id], accounts, cells },
  });

  useEffect(() => {
    if (loading) message.loading('Carregando tempos');
    if (!loading) message.destroy();
  }, [loading]);

  const timers: any[] = useMemo(() => {
    if (!data) return [];

    const timersList = data?.me?.realm?.timers?.data.map(({ id, task, start_date, finish_date, user }: any) => ({
      id,
      taskId: task?.id,
      activity: task?.title || ' ',
      step: task?.step || ' ',
      start: start_date,
      end: finish_date,
      diff: fmtMomentDiff(finish_date, start_date),
      cellId: task?.cell?.id,
      cellName: task?.cell?.name,
      cellLogo: task?.cell?.logoUrl,
      userId: user.id,
      userName: user.name,
      userType: user.roles?.filter((e: string) => e !== 'admin')[0],
      userAvatar: user.avatarUrl,
      clientId: task?.account?.id,
      clientName: task?.account?.name || ' ',
      clientLogo: task?.account?.logoUrl,
    }));

    let sortBy: string = '';

    switch (view) {
      case 'account':
        sortBy = 'clientName';
        break;
      case 'cell':
        sortBy = 'cellName';
        break;
      case 'user':
        sortBy = 'userName';
        break;
      case 'user_type':
        sortBy = 'userType';
        break;
    }

    const sortedTimersList = !!sortBy
      ? timersList.sort((a: any, b: any) => {
          if (a[sortBy] > b[sortBy]) return 1;
          if (a[sortBy] < b[sortBy]) return -1;
          return 0;
        })
      : timersList;

    return sortedTimersList;
  }, [data, view]);

  const totals = useMemo(() => {
    return {
      date: reduceTotals(timers, 'date'),
      account: reduceTotals(timers, 'account'),
      cell: reduceTotals(timers, 'cell'),
      user: reduceTotals(timers, 'user'),
      user_type: reduceTotals(timers, 'user_type'),
    };
  }, [timers]);

  const total = Object.keys(totals[view])
    .map((key) => totals[view][key])
    .reduce((acc: number, curr: number) => acc + curr, 0);

  return (
    <Card style={{ display: 'flex', width: '100%' }} bodyStyle={{ width: '100%' }}>
      <div className={styles.filters}>
        <Row style={{ display: 'flex' }}>
          <Col id="filter_period" style={{ marginBottom: 16 }}>
            <FilterPeriod params={{ begin, end }} />
          </Col>

          <Col id="filter_user_cell_account" style={{ marginBottom: 16 }}>
            <FilterUserAccountCell params={{ cells, accounts, users }} />
          </Col>

          <Col id="timer_views" style={{ marginBottom: 16 }}>
            <Radio.Group style={{ marginLeft: 6 }} value={view} onChange={({ target }) => setView(target.value)}>
              <Tooltip destroyTooltipOnHide={{ keepParent: false }} title="Exibir por data" mouseEnterDelay={0.6}>
                <Radio.Button value="date">
                  <CalendarOutlined />
                </Radio.Button>
              </Tooltip>
              {admin && (
                <Tooltip destroyTooltipOnHide={{ keepParent: false }} title="Exibir por usuários" mouseEnterDelay={0.6}>
                  <Radio.Button value="user">
                    <IoMdPerson className={styles.filter_icons} />
                  </Radio.Button>
                </Tooltip>
              )}
              {admin && (
                <Tooltip
                  destroyTooltipOnHide={{ keepParent: false }}
                  title="Exibir por tipos de usuários"
                  mouseEnterDelay={0.6}>
                  <Radio.Button value="user_type">
                    <BiIdCard className={styles.filter_icons} />
                  </Radio.Button>
                </Tooltip>
              )}
              <Tooltip destroyTooltipOnHide={{ keepParent: false }} title="Exibir por célula" mouseEnterDelay={0.6}>
                <Radio.Button value="cell">
                  <IoMdPeople className={styles.filter_icons} />
                </Radio.Button>
              </Tooltip>
              <Tooltip destroyTooltipOnHide={{ keepParent: false }} title="Exibir por cliente" mouseEnterDelay={0.6}>
                <Radio.Button value="account">
                  <FaRegHandshake className={styles.filter_icons} />
                </Radio.Button>
              </Tooltip>
            </Radio.Group>
          </Col>
        </Row>

        <div style={{ display: 'flex', flexDirection: 'row' }}>
          <Typography.Link style={{ fontWeight: 'bold', fontSize: 18, whiteSpace: 'nowrap', marginRight: 8 }}>
            Total: {Duration.fromMillis(total).toFormat('hh:mm:ss')}
          </Typography.Link>

          <ExportTimers
            author={profile?.name || 'ID Lab'}
            data={timers}
            view={view}
            period={{ begin: begin as any, end: end as any }}
          />
        </div>
      </div>

      <>
        {loading && <ErrorAndLoading loading borderless />}
        {error && <ErrorAndLoading error={error} borderless />}

        {!error && !loading && timers.length > 0 && (
          <TimersGraphs data={timers} total={total} period={{ begin, end }} view={view} />
        )}

        {timers && !error && !loading && (
          <TimersListMap data={timers} totals={totals} view={view} refetch={refetch} showUsersAvatar={!!admin} />
        )}
      </>

      <TutorialButton
        tourId="010_TimersPage"
        top={80}
        right={20}
        placement="left"
        title="Tutorial do relatório de tempos"
      />

      <TutorialTimers hasTimers={!!timers && timers.length > 0 ? true : false} />
    </Card>
  );
};

export default observer(TimersPage);

function reduceTotals(data: any[], reduceBy: 'date' | 'user' | 'user_type' | 'account' | 'cell') {
  return data.reduce((acc, curr) => {
    const start = parseDateTime(curr.start);
    const end = parseDateTime(curr.end);

    let key = '';

    switch (reduceBy) {
      case 'date':
        key = start.toFormat('yyyy_LL_dd');
        break;
      case 'account':
        key = curr.clientId;
        break;
      case 'cell':
        key = curr.cellId;
        break;
      case 'user':
        key = curr.userId;
        break;
      case 'user_type':
        key = curr.userType;
        break;
    }
    const diff = end.diff(start, 'milliseconds');

    if (!acc[key]) {
      acc[key] = 0;
    }
    acc[key] = acc[key] + diff.milliseconds;

    return acc;
  }, {});
}

function fmtMomentDiff(from: string, to: string) {
  const start = parseDateTime(to);
  const end = parseDateTime(from);

  const diff = end.diff(start, 'milliseconds').toFormat('hh:mm:ss');

  return diff;
}

const timers_query = gql`
  query TimersList($begin: Date, $end: Date, $users: [ID!], $cells: [ID!], $accounts: [ID!]) {
    me {
      id
      realm {
        id
        timers(
          pagination: { page: 1, limit: -1 }
          filter: { period: { begin: $begin, end: $end }, users: $users, cells: $cells, accounts: $accounts }
        ) {
          total
          data {
            id
            start_date
            finish_date
            total_seconds
            user {
              id
              name
              roles
              avatarUrl
            }
            task {
              id
              title
              step
              cell {
                id
                name
                logoUrl
              }
              account {
                id
                name
                logoUrl
              }
            }
          }
        }
      }
    }
  }
`;
