import React, { useEffect, useLayoutEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { gql, useMutation, useQuery } from '@apollo/client';
import { Card, Typography, notification, Input, Form, Button } from 'antd';

import { update_profile } from '@logic/mutations';
import errorMessage from '@logic/functions/errorHandeling';

import ErrorAndLoading from '@comp/ErrorAndLoading';
import UploadAvatar from '@comp/UploadAvatar';
import TutorialButton from '@comp/TutorialButton';
import TutorialProfile from './TutorialProfile';

import styles from './styles.module.less';

const user_info = gql`
  query UserInfo {
    me {
      id
      name
      email
      avatarUrl
    }
  }
`;

const ProfilePage: React.FC = () => {
  const [form] = Form.useForm();

  const [user, setUser] = useState({ id: '', name: '', email: '' });

  //--------------------- Queries  --------------------------------

  const { data, loading, error } = useQuery(user_info, { fetchPolicy: 'network-only' });

  useEffect(() => {
    if (!data) return;

    setUser({ id: data.me.id, name: data.me.name, email: data.me.email });
  }, [data]);

  //---------------------- mutations ------------------------------

  const [UpdateUserProfile] = useMutation(update_profile);

  //----------------- Resets the form fields -----------------------

  useLayoutEffect(() => {
    if (!user) return;

    form.setFieldsValue({ name: user.name, email: user.email });
  }, [user, form]);

  //--------------Save and Delete functions -----------------------

  async function doSave(name: string, email: string, password: string) {
    try {
      UpdateUserProfile({ variables: { name, email, password } });

      notification.open({ type: 'success', message: 'Dados alterados sucesso' });
    } catch (err) {
      notification.open({ type: 'error', ...errorMessage('graphql', err) });
    }
  }

  //------------- Form functions ------------------------------------------------

  const onFinish = () => {
    form
      .validateFields()
      .then((values: any) => {
        doSave(values.name, values.email, values.password);
      })
      .catch((info) => {
        notification.open({ type: 'error', ...errorMessage('form_validation', info) });
      });
  };

  const onFinishFailed = (errorInfo: any) => {
    notification.open({ type: 'error', ...errorMessage('', errorInfo) });
  };

  //------------------------------------------------------------------------------

  if (loading || error) return <ErrorAndLoading loading={loading} error={error} />;

  return (
    <Card>
      <div className={styles.div_avatar}>
        <div id="avatar_profile" className={styles.avatar}>
          <UploadAvatar
            id={user.id}
            query={user_info}
            extractData={(data) => data?.me?.avatarUrl}
            radius="50%"
            target="User"
          />
        </div>

        <div className={styles.form}>
          <Typography className={styles.title}>Dados do usuário</Typography>

          <Form
            form={form}
            labelCol={{
              style: {
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'flex-start',
                minWidth: 160,
              },
            }}
            wrapperCol={{ style: { display: 'flex', flex: 1 } }}
            layout="horizontal"
            name="Password"
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            initialValues={{ name: user.name, email: user.email }}>
            <div id="name_profile">
              <Form.Item name="name" label={<Typography.Text className={styles.divisor}>Name</Typography.Text>}>
                <Input type="text" />
              </Form.Item>
            </div>

            <div id="email_profile">
              <Form.Item name="email" label={<Typography.Text className={styles.divisor}>Email</Typography.Text>}>
                <Input type="text" />
              </Form.Item>
            </div>

            <div id="password_profile">
              <Form.Item
                name="password"
                hasFeedback
                rules={[
                  {
                    message: 'Informe a nova senha',
                  },
                  {
                    min: 8,
                    message: 'A senha precisa conter ao menos 8 caracteres',
                  },
                ]}
                label={<Typography.Text className={styles.divisor}>Altere a senha</Typography.Text>}>
                <Input.Password type="text" title="Altere a senha. Sua senha deve ter no mínimo 8 caracteres" />
              </Form.Item>
            </div>

            <div id="confirm_password_profile">
              <Form.Item
                name="confirm_password"
                hasFeedback
                dependencies={['password']}
                rules={[
                  {
                    message: 'Confirme a nova senha',
                  },
                  ({ getFieldValue }) => ({
                    validator(rule, value) {
                      if (!value || getFieldValue('password') === value) {
                        return Promise.resolve();
                      }
                      return Promise.reject('Senhas não coincidem');
                    },
                  }),
                ]}
                label={<Typography.Text className={styles.divisor}>Confirme a senha</Typography.Text>}>
                <Input.Password type="text" />
              </Form.Item>
            </div>

            <Form.Item noStyle>
              <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                <Button id="save_button_profile" type="primary" htmlType="submit">
                  Salvar
                </Button>
              </div>
            </Form.Item>
          </Form>
        </div>
      </div>

      <TutorialButton tourId="016_Profile" top={80} right={20} placement="left" title="Tutorial do perfil do usuário" />

      <TutorialProfile />
    </Card>
  );
};

export default observer(ProfilePage);
