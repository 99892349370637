import React, { useEffect, useMemo } from 'react';
import { useHistory, useParams, useLocation } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { Menu, Typography, Card } from 'antd';
import { useTranslation } from 'react-i18next';

import { BiBook, BiBookOpen, BiUserCheck, BiLineChart, BiTimer } from 'react-icons/bi';
import { FiLayers } from 'react-icons/fi';

import AvatarPicture from '@comp/AvatarPicture';
import TutorialButton from '@comp/TutorialButton';
import NewFunctionalities from '@comp/NewFunctionalities';

import { route } from '@router';
import { useStore } from '@logic/context';
import useConfig from '@logic/config';
import useHashInfo from '@logic/functions/currentTask';

import { version } from '../../../package.json';

import styles from './styles.module.less';

function checkRoute(location: any, key: string, routeName: string, params?: object) {
  return location.pathname.indexOf(route(routeName, params)) !== -1 ? key : '';
}

const SideMenu: React.FC = () => {
  const location = useLocation();
  const history = useHistory();

  const { id } = useParams<any>();
  const store = useStore();
  const config = useConfig();
  const collapsed = !!config.collapsed_menu;

  const { t } = useTranslation('sideMenu');

  const isAdmin = store.profile && store.profile.roles.includes('admin');
  // Conta/cliente que está selecionado
  const selAccount = id || undefined;

  const drawer = useHashInfo();
  const drawerIsOpen = ['#task', '#acc_info', '#template', '#url_link'].includes(drawer[0]);

  // Item do menu que está selecionado
  const selectedKeys = useMemo(
    () =>
      [
        checkRoute(location, 'realm_activities', 'realm.activities'),
        checkRoute(location, 'my_activities', 'activities'),
        checkRoute(location, 'templates', 'templates'),
        checkRoute(location, 'timers', 'timers'),
        checkRoute(location, 'reports', 'reports'),
        checkRoute(location, 'admin', 'admin'),
      ].filter((e) => !!e),
    [location],
  );

  function navigate(url: string) {
    history.push(url);
  }

  useEffect(() => {
    if (!!selAccount) document.getElementById(selAccount)?.scrollIntoView();
  }, [selAccount]);

  return (
    <>
      <Menu mode="inline" selectedKeys={!selAccount ? [selectedKeys[0]] : undefined}>
        {isAdmin && (
          <Menu.Item
            id="general_view_menu"
            className={styles.menu_item}
            icon={<FiLayers className={styles.icon} size={16} />}
            key="realm_activities"
            onClick={() => history.push(route('realm.activities'))}>
            <span style={collapsed ? { color: '#ffffff' } : undefined}>{t('general_view_menu')}</span>
          </Menu.Item>
        )}

        <Menu.Item
          id="my_tasks_menu"
          className={styles.menu_item}
          icon={<BiUserCheck className={styles.icon} size={18} />}
          key="my_activities"
          onClick={() => history.push(route('activities'))}>
          <span style={collapsed ? { color: '#ffffff' } : undefined}>{t('my_tasks_menu')}</span>
        </Menu.Item>

        {isAdmin && (
          <Menu.Item
            id="templates_menu"
            className={styles.menu_item}
            icon={
              selectedKeys[0] === 'templates' ? (
                <BiBookOpen className={styles.icon} size={18} />
              ) : (
                <BiBook className={styles.icon} size={18} />
              )
            }
            key="templates"
            onClick={() => history.push(route('templates'))}>
            <span style={collapsed ? { color: '#ffffff' } : undefined}>{t('templates_menu')}</span>
          </Menu.Item>
        )}

        <Menu.Item
          id="timers_menu"
          className={styles.menu_item}
          icon={<BiTimer className={styles.icon} size={18} />}
          key="timers"
          onClick={() => history.push(route('timers'))}>
          <span style={collapsed ? { color: '#ffffff' } : undefined}>{t('timers_menu')}</span>
        </Menu.Item>

        <Menu.Item
          id="reports_menu"
          className={styles.menu_item}
          icon={<BiLineChart className={styles.icon} size={18} />}
          key="reports"
          onClick={() => history.push(route('reports.general'))}>
          <span style={collapsed ? { color: '#ffffff' } : undefined}>{t('reports_menu')}</span>
        </Menu.Item>
      </Menu>

      <div style={{ height: 1, backgroundColor: '#e5e5e5' }} />

      <Menu
        id="accounts_menu"
        className={styles.side_menu}
        style={{ height: `calc(100vh - 66px - ${isAdmin ? 240 : 144}px - 40px - 1px)` }}
        mode="inline"
        selectedKeys={[selAccount]}>
        {(store?.profile?.accounts || []).map((account: any, idx: number) => (
          <Menu.Item
            key={account.id}
            id={account.id}
            className={styles.account_item}
            style={collapsed ? { color: 'transparent' } : undefined}
            icon={
              <div className={styles.account_icon}>
                <AvatarPicture pictureLink={account.logoUrl} target="Account" size={30} name={account.name} />
              </div>
            }
            onClick={() => navigate(route('id.home.tasks', { id: account.id }))}>
            {account.name}
          </Menu.Item>
        ))}
      </Menu>

      <Card
        style={{ borderRadius: 0 }}
        bodyStyle={{
          height: 40,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          padding: 0,
        }}>
        {!collapsed && (
          <Typography.Text style={{ color: 'rgba(0, 0, 0, 0.37)', fontSize: 12 }}>
            {t('version')}
            {version}
          </Typography.Text>
        )}
      </Card>

      {!drawerIsOpen && (
        <NewFunctionalities
          title={t('new_functionalities_title')}
          description={t('new_functionalities_description')}
          configId="2021-02-04-teste.010"
          fixedTooltip={true}
          ribbon={false}
          placement="topLeft"
          offset={[-6, -2]}
          primaryColor={true}>
          <TutorialButton
            title={t('menu')}
            placement="left"
            tourId="002_MenuTutorial"
            bottom={10}
            left={18}
            circleSize="small"
            iconSize={14}
          />
        </NewFunctionalities>
      )}
    </>
  );
};

export default observer(SideMenu);
