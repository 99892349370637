import React from 'react';
import { Divider, Typography } from 'antd';

import SubActivitytList from './SubActivityList';
import Help from '@comp/Help';

interface Props {
  accountId: string;
  taskId: string;
  type: string;
  setSelected: (id: string) => void;
}

const SubTasks: React.FC<Props> = ({ taskId, accountId, type, setSelected }) => {
  return (
    <div id="subtarefas_task">
      <Divider />
      <div style={{ display: 'flex', alignItems: 'baseline' }}>
        <Typography.Text style={{ marginRight: 4, marginBottom: '16px', fontSize: '16px', fontWeight: 600 }}>
          Subtarefas
        </Typography.Text>

        <Help
          title="Subtarefas"
          description="São atividades menores que compõem ou que pertencem uma atividade"
          iconStyle={{ color: '#5c5c5c', fontSize: 12 }}
          placement="right"
        />
      </div>

      <div style={{ height: 16 }} />

      <SubActivitytList taskId={taskId} accountId={accountId} type={type} setSelected={setSelected} />
    </div>
  );
};

export default SubTasks;
