import React, { useEffect, useState } from 'react';
import { Input, Select, Button } from 'antd';
import { gql, useMutation, useQuery } from '@apollo/client';
import { Duration } from 'luxon';

import { ArrowRightOutlined } from '@ant-design/icons';

interface Props {
  templateId: string;
}

const TemplateTimer: React.FC<Props> = ({ templateId }) => {
  const [planned, setPlanned] = useState(0);
  const { data, loading } = useQuery(template_timer_seconds, {
    variables: { id: templateId },
    fetchPolicy: 'network-only',
  });

  useEffect(() => {
    if (!data) return;
    setPlanned(data?.templateItem?.planned_seconds);
  }, [data]);

  if (loading) return null;

  return <SelectPlannedSeconds templateId={templateId} planned={planned} setPlanned={(value) => setPlanned(value)} />;
};

export default TemplateTimer;

interface SelectPlannedSecondsProps {
  templateId: string;
  planned: number;
  setPlanned: (value: number) => void;
}

const SelectPlannedSeconds: React.FC<SelectPlannedSecondsProps> = ({ templateId, planned, setPlanned }) => {
  const [addOption, setAddOption] = useState<any>();
  let options = ['00:30', '01:00', '02:00', Duration.fromMillis(planned * 1000).toFormat('hh:mm')];
  options = options.filter((elem, index, self) => index === self.indexOf(elem));
  options = options.sort();

  const [UpdatePlannedSeconds] = useMutation(update_template_planned_seconds);

  const handleChange = (value: any) => {
    setPlanned(formataddOption(value));
    UpdatePlannedSeconds({ variables: { id: templateId, planned_seconds: formataddOption(value) } });
  };

  const handleKeyPress = ({ code }: any) => {
    if (code === 'Enter') handleChange(addOption);
  };

  return (
    <Select
      style={{ width: 100 }}
      value={Duration.fromMillis(planned * 1000).toFormat('hh:mm')}
      onChange={handleChange}
      dropdownRender={(menu) => (
        <div>
          <Input
            type="time"
            value={addOption}
            style={{ paddingRight: 0, paddingLeft: 2 }}
            onChange={({ target: { value } }) => setAddOption(value)}
            onKeyPress={handleKeyPress}
            suffix={<Button icon={<ArrowRightOutlined />} size="small" onClick={() => handleChange(addOption)} />}
          />
          {menu}
        </div>
      )}>
      {options.map((option, idx) => (
        <Select.Option key={`option_${idx}_${option}`} value={option}>
          {option}
        </Select.Option>
      ))}
    </Select>
  );
};

function formataddOption(time: string) {
  const [hours, minutes] = time.split(':');
  return Number(hours) * 60 * 60 + Number(minutes) * 60;
}

const template_timer_seconds = gql`
  query TemplateTimersListOnlySeconds($id: ID!) {
    templateItem(id: $id) {
      id
      planned_seconds
    }
  }
`;

const update_template_planned_seconds = gql`
  mutation UpdateTemplatePlannedSeconds($id: ID!, $planned_seconds: Int) {
    updateTemplateItem(id: $id, planned_seconds: $planned_seconds) {
      id
      planned_seconds
    }
  }
`;
