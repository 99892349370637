import React from 'react';
import { Result } from 'antd';
import { useMutation } from '@apollo/client';
import { useHistory } from 'react-router-dom';

import Notification from '@comp/Notifications/Notification';

import fireworks from '../../../../img/fireworks.svg';

import { mark_as_viewed } from '@logic/mutations';

import styles from './styles.module.less';

interface Props {
  recentTaskUpdate: any[];
}

const MapUpdatedTasks: React.FC<Props> = ({ recentTaskUpdate }) => {
  const history = useHistory();

  const [MarAsViewed] = useMutation(mark_as_viewed);
  const handleMarkAsSeen = (id: string) => MarAsViewed({ variables: { id } });

  if (!recentTaskUpdate || recentTaskUpdate.length === 0)
    return (
      <Result
        style={{ height: 554, paddingTop: 210 }}
        icon={<img src={fireworks} alt="Parabéns!" className={styles.fireworks_image} style={{ height: 64 }} />}
        subTitle="Nenhuma atualização recente, você está em dia!"
      />
    );

  return (
    <>
      {recentTaskUpdate.map(
        (
          { id, type, initiatorName, initiatorAvatar, step, viewed, notified_at, taskId, taskTitle }: any,
          idx: number,
        ) => {
          return (
            <div key={`recent_updates_${id}`}>
              <Notification
                id={id}
                initiatorAvatar={initiatorAvatar}
                initiatorName={initiatorName}
                type={type}
                step={step}
                notified_at={notified_at}
                taskId={taskId}
                taskTitle={taskTitle}
                viewed={viewed}
                handleMarkAsSeen={handleMarkAsSeen}
                handleOpenTask={(id, tab) => history.push(history?.location?.pathname + '#task/' + id + tab)}
              />
            </div>
          );
        },
      )}
    </>
  );
};

export default MapUpdatedTasks;
