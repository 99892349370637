import React from 'react';
import { observer } from 'mobx-react-lite';
import { Checkbox } from 'antd';
import { useStore } from '@logic/context';

interface Props {
  taskId: string;
  style?: any;
}

// Checkbox utiliza o store para verificar se está selecionado ou não

const ActivityCheckbox: React.FC<Props> = ({ taskId, style }) => {
  const store = useStore();
  const handleChange = ({ target: { checked } }: any) => {
    if (checked) {
      store.addTaskToMultipleEdit(taskId);
    } else {
      store.removeTaskFromMultipleEdit(taskId);
    }
  };
  const stopPropagation = (e: any) => e.stopPropagation();

  if (!store.editMultiple) return null;

  return (
    <Checkbox
      onClick={stopPropagation}
      checked={store.editMultipleList.includes(taskId)}
      onChange={handleChange}
      style={{ ...style }}
    />
  );
};

export default observer(ActivityCheckbox);
