import React from 'react';
import { Typography, List } from 'antd';

import { LinkOutlined, WhatsAppOutlined } from '@ant-design/icons';

import LinkActions from './LinkActions';

import styles from './styles.module.less';

interface Props {
  linkId: string;
  url: string;
  linkType: 'default' | 'whatsapp';
  setSelected: (id: string) => void;
  refresh: () => void;
}

const LinkItem: React.FC<Props> = (props) => {
  const { linkId, setSelected, linkType, url, refresh } = props;

  return (
    <List.Item
      id="open_link"
      onClick={() => setSelected(linkId)}
      extra={<LinkActions linkId={linkId} type={linkType} refresh={refresh} />}
      className={styles.item}
      style={listItemStyle}>
      <Typography.Text className={styles.icon}>
        {linkType === 'whatsapp' ? (
          <WhatsAppOutlined className={styles.whatsapp} />
        ) : (
          <LinkOutlined className={styles.padrao} />
        )}
      </Typography.Text>

      <Typography.Text ellipsis className={styles.url}>
        {url}
      </Typography.Text>
      <div style={{ display: 'flex', flex: 1, cursor: 'pointer' }} />
    </List.Item>
  );
};

export default LinkItem;

const listItemStyle: React.CSSProperties = {
  padding: 0,
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  backgroundColor: '#fafafa',
  cursor: 'pointer',
};
