import React, { useState, useMemo, useRef, useLayoutEffect } from 'react';
import { useMutation } from '@apollo/client';
import { List, notification, Input, Typography } from 'antd';
import moment from 'moment';

import Clickable from '@comp/Clickable';

import { update_template_item } from '@logic/mutations';
import errorMessage from '@logic/functions/errorHandeling';

import styles from './styles.module.less';

interface Props {
  templateItemId: string;
  accountId: string; // Unused
  openItem: (id: string) => void;
  templateTitle: string;
  displayOnly?: boolean;
}

const SubTemplateListItem: React.FC<Props> = (props) => {
  const { templateItemId, openItem, templateTitle, displayOnly } = props;

  const [title, setTitle] = useState(templateTitle);
  const [focused, setFocused] = useState(false);

  const ref = useRef<any>();

  const id = templateItemId;

  //---------------------- mutations ------------------------------

  const [UpdateTemplateItem] = useMutation(update_template_item);

  //--------------Save and Delete functions -----------------------

  const save = useMemo(
    () => async (title: string) => {
      try {
        await UpdateTemplateItem({ variables: { id, title } });
      } catch (err) {
        notification.open({ type: 'error', ...errorMessage('graph_err', err) });
      }
    },
    [id, UpdateTemplateItem],
  );

  useLayoutEffect(() => {
    if (!ref.current) return;

    ref.current.input.focus();
  }, [ref, focused]);

  function handleChange(key: string, value: moment.Moment | string) {
    setTitle(value.toString());
  }

  return (
    <List.Item
      className={styles.item}
      onClick={() => openItem(id)}
      style={{ display: 'flex', paddingTop: 0, paddingBottom: 0 }}>
      <Clickable
        onClick={() => openItem(id)}
        onDbClick={() => setFocused(true)}
        onBlur={() => setFocused(false)}
        style={{ display: 'flex', flex: 1, cursor: 'pointer', height: '30px', alignItems: 'center' }}>
        {!focused || displayOnly ? (
          <Typography.Text ellipsis style={{ maxWidth: 620 }}>
            {title}
          </Typography.Text>
        ) : (
          <Input
            ref={ref}
            value={title}
            onClick={(e) => e.stopPropagation()}
            style={{ border: 'none', width: '100%' }}
            onChange={({ target }) => handleChange('title', target.value)}
            onBlur={() => save(title)}
          />
        )}
      </Clickable>
    </List.Item>
  );
};

export default SubTemplateListItem;
