import React, { useState } from 'react';
import { useMutation } from '@apollo/client';
import { useParams, useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { notification, Typography, Row, Col, DatePicker } from 'antd';
import moment from 'moment';

import EmojiLinkInput from '@comp/EmojiLinkInput';
import StepSelector from '@comp/StepSelector';
import CellSelector from '@comp/CellSelector';
import AccountSelector from '@comp/AccountSelector';
import TutorialButton from '@comp/TutorialButton';
import Help from '@comp/Help';

import { stepsKeys, stepsKeysArray, stepsIconsArray } from '../../..';
import { create_task } from '@logic/mutations';
import errorMessage from '@logic/functions/errorHandeling';

import styles from './styles.module.less';

const NewTask: React.FC = () => {
  const { id: paramId } = useParams<any>();
  const history = useHistory();
  const date = history.location.hash.replace('#', '').split('/')[3];

  const { t } = useTranslation('newTask');

  const defaultValues: any = {
    account: paramId,
    title: '',
    step: 'execution',
    body: '',
    cell: 'selectFirst',
    execution_date: date || undefined,
    finish_date: date || undefined,
  };

  const [task, setTask] = useState<any>(defaultValues);

  //------------------------- Mutation ----------------------------

  const [CreateTask] = useMutation(create_task);

  //----------------------- Save function --------------------------

  async function create(key: string, value: any) {
    try {
      const { data } = await CreateTask({
        variables: {
          type: 'default',
          ...{
            ...task,
            ...(key === 'execution_date' || key === 'finish_date'
              ? { finish_date: value, execution_date: value }
              : { [key]: value }),
          },
        },
      });

      history.push(history.location.pathname + '#task/' + data?.createTask?.id + '/task_tab');
    } catch (err) {
      notification.open({ type: 'error', ...errorMessage('graph_err', err) });
    }
  }

  //------------------------ Form functions ---------------------------

  const handleChange = ({ target: { name, value } }: any) => {
    if (((name === 'cell' && !!task.account) || (name === 'account' && task.cell !== 'selectFirst')) && !paramId) {
      create(name, value);
    }
    setTask((t: any) => ({ ...t, [name]: value }));
  };

  const handleBlur = ({ target: { name, value } }: any) => {
    if (!task.account || task.cell === 'selectFirst') return;
    if (defaultValues[name] !== value) {
      create(name, value);
    }
  };

  const executionDateValue =
    task && (task.execution_date || task.finish_date) ? moment(task.execution_date || task.finish_date) : null;
  const finishDateValue =
    task && (task.finish_date || task.execution_date) ? moment(task.finish_date || task.execution_date) : null;

  //----------------------------------------------------------------------

  return (
    <div id="any_field_task" className={styles.root} style={{ height: '100%' }}>
      <Row className={styles.form_item}>
        <Col span={6} className={styles.column}>
          <Typography.Text className={styles.label}>{t('title_label')}</Typography.Text>
          <Help
            title={t('help_title')}
            description={t('help_title_description')}
            placement="right"
            iconStyle={{ color: '#5c5c5c', fontSize: 12 }}
          />
        </Col>
        <Col span={18}>
          <EmojiLinkInput
            name="title"
            placeholder={t('title_placeholder')}
            border
            value={task.title}
            onChange={handleChange}
            onBlur={handleBlur}
            emojiPicker="focus"
          />
        </Col>
      </Row>

      <Row id="account_task" className={styles.form_item}>
        <Col span={6} className={styles.column}>
          <Typography.Text className={styles.label}>{t('customer_label')}</Typography.Text>
          <Help
            title={t('help_customer_title')}
            description={t('help_customer_description')}
            placement="right"
            iconStyle={{ color: '#5c5c5c', fontSize: 12 }}
          />
        </Col>
        <Col span={18}>
          <AccountSelector
            accountId={task.account}
            accountChange={(id) => handleChange({ target: { name: 'account', value: id } })}
          />
        </Col>
      </Row>

      {!!task.account && (
        <Row className={styles.form_item}>
          <Col span={6} className={styles.column}>
            <Typography.Text className={styles.label}>{t('cell_label')}</Typography.Text>
            <Help
              title={t('help_cell_title')}
              description={t('help_cell_description')}
              placement="right"
              iconStyle={{ color: '#5c5c5c', fontSize: 12 }}
            />
          </Col>
          <Col span={18}>
            <CellSelector
              cellId={task.cell}
              accountId={task.account}
              cellChange={(id) => handleChange({ target: { name: 'cell', value: id } })}
            />
          </Col>
        </Row>
      )}

      <Row className={styles.form_item}>
        <Col span={6} className={styles.column}>
          <Typography.Text className={styles.label}>{t('execution_date_label')}</Typography.Text>
          <Help
            title={t('help_execution_date_title')}
            description={t('help_execution_date_description')}
            iconStyle={{ color: '#5c5c5c', fontSize: 12 }}
            placement="right"
          />
        </Col>
        <Col span={18}>
          <DatePicker
            name="execution_date"
            format="DD/MM/YYYY"
            value={executionDateValue}
            onChange={(value) => handleChange({ target: { name: 'execution_date', value: momentToStr(value) } })}
            onBlur={({ target }) => handleBlur({ target: { name: 'execution_date', value: strToStr(target.value) } })}
          />
        </Col>
      </Row>

      <Row className={styles.form_item}>
        <Col span={6} className={styles.column}>
          <Typography.Text className={styles.label}>{t('finish_date_label')}</Typography.Text>
          <Help
            title={t('help_finish_date_title')}
            description={t('help_finish_date_description')}
            iconStyle={{ color: '#5c5c5c', fontSize: 12 }}
            placement="right"
          />
        </Col>
        <Col span={18}>
          <DatePicker
            name="finish_date"
            format="DD/MM/YYYY"
            value={finishDateValue}
            onChange={(value) => handleChange({ target: { name: 'finish_date', value: momentToStr(value) } })}
            onBlur={({ target }) => handleBlur({ target: { name: 'finish_date', value: strToStr(target.value) } })}
          />
        </Col>
      </Row>

      <div className={styles.form_item}>
        <StepSelector
          type="default"
          onChange={({ target }) => handleChange({ target: { name: 'step', value: target.value } })}
          stepKeys={stepsKeysArray}
          stepKeysTitle={stepsKeys}
          stepsIcons={stepsIconsArray()}
        />
      </div>

      <div className={styles.column}>
        <Typography.Text className={styles.label}>{t('description_label')}</Typography.Text>
        <Help
          title={t('help_description_title')}
          description={t('help_description')}
          iconStyle={{ color: '#5c5c5c', fontSize: 12 }}
          placement="right"
        />
      </div>
      <div className={styles.form_item}>
        <EmojiLinkInput
          name="body"
          emojiPicker="focus"
          placeholder={t('description_placeholder')}
          border
          multiline
          className={styles.body}
          value={task.body}
          onChange={handleChange}
          onBlur={handleBlur}
        />
      </div>

      <TutorialButton
        tourId="009_NewTask"
        circleSize="small"
        iconSize={14}
        top={18}
        right={490}
        placement="right"
        title={t('new_task')}
      />
    </div>
  );
};

export default NewTask;

function momentToStr(time?: moment.Moment | null) {
  if (!time) return null;
  return moment(time).format('YYYY-MM-DD');
}
function strToStr(date?: string | null) {
  if (!date) return undefined;
  return moment(date, 'DD/MM/YYYY').format('YYYY-MM-DD');
}
