import React from 'react';
import { useTranslation } from 'react-i18next';
import { Card, Row, Typography } from 'antd';
import { DocumentNode } from '@apollo/client';

import { BiUserCheck } from 'react-icons/bi';

import Help from '@comp/Help';

import ActivitytList from '../ActivitiesLists/ActivitiesList';

import styles from './styles.module.less';

interface Props {
  taskId: string;
  accountId: string;
  page: 'my_activities' | 'account_activities' | 'realm_activities' | 'home_dashboard';
  setSelected: (taskId: string) => void;
  query: DocumentNode;
  params: any;
  getData: (data: any) => any;
}

// Bloco de próximas atividades

const ActivitiesDashboard: React.FC<Props> = ({ taskId, accountId, page, setSelected, params, getData, query }) => {
  const { t } = useTranslation('activitiesDashboard');

  return (
    <Card bodyStyle={cardBodyStyle} style={cardStyle} id="next_activities">
      <Row className={styles.title}>
        <Typography.Title className={styles.title_text}>
          <BiUserCheck className={styles.title_icon} size={18} />
          {t('title')}
        </Typography.Title>

        <div className={styles.help}>
          <Help title={t('title')} description={t('description')} iconStyle={{ color: '#5c5c5c' }} />
        </div>
      </Row>

      <div className={styles.activities_list}>
        <ActivitytList
          accountId={accountId}
          setSelected={setSelected}
          getData={getData}
          query={query}
          variables={{
            ...params,
            steps: ['execution', 'check', 'approval', 'finish'],
            excludeSubtasks: false,
            excludeAllExceptTasksWithPendingFeedbacks: false,
          }}
          canReorderList={!params.excludeSubtasks}
          canCreateNewTask={page === 'account_activities'}
          showAccountLogo={page !== 'account_activities'}
          showSelectEditMultiple={page !== 'home_dashboard'}
          page={page}
        />
      </div>
    </Card>
  );
};

export default ActivitiesDashboard;

const cardStyle = { borderRadius: 4, overflow: 'hidden', boxShadow: '0 1px 4px #00000040' };
const cardBodyStyle = { padding: 0 };
