import React, { useCallback, useMemo, useState } from 'react';
import { Collapse, Empty, Typography } from 'antd';
import { DocumentNode, gql, useQuery } from '@apollo/client';
import { useTranslation } from 'react-i18next';

import ActivitytList from '../ActivitiesList';
import ErrorAndLoading from '@comp/ErrorAndLoading';
import TutorialButton from '@comp/TutorialButton';

import styles from './styles.module.less';

interface Props {
  accountId: string;
  page: 'my_activities' | 'account_activities' | 'realm_activities';
  setSelected: (taskId: string) => void;
  query: DocumentNode;
  params: any;
  getData: (data: any) => any;
}

// Verifica quais templates possuem atividades criadas
// Exibe as atividades criadas por template

const ActivitiesListByTemplates: React.FC<Props> = ({ accountId, page, setSelected, params, getData, query }) => {
  const [openPannel, setOpenPannel] = useState(false);

  const { t } = useTranslation('activitiesListByTemplate');

  function templatesQuery() {
    switch (page) {
      case 'account_activities':
        return account_templates_list;
      case 'my_activities':
        return my_templates_list;
      case 'realm_activities':
        return my_templates_list;
    }
  }
  const { data, loading, error } = useQuery(templatesQuery(), {
    fetchPolicy: 'no-cache',
    variables: { ...params, steps: ['execution', 'check', 'approval', 'finish'] },
  });

  const templates: any[] = useMemo(() => {
    let templatesList;
    switch (page) {
      case 'account_activities':
        templatesList = data?.account?.stats?.tasks?.templates;
        break;
      case 'my_activities':
        templatesList = data?.me?.stats?.tasks?.templates;
        break;
      case 'realm_activities':
        templatesList = data?.me?.stats?.tasks?.templates;
        break;
    }
    return templatesList;
  }, [data, page]);

  const checkPannel = useCallback(
    (key: any | any[]) => {
      if (key.length === 0) setOpenPannel(false);
      if (key.length > 0) setOpenPannel(true);
    },
    [setOpenPannel],
  );

  if (loading || error) return <ErrorAndLoading loading={loading} error={error} />;

  return (
    <>
      {(templates.length === 0 || !templates) && (
        <div>
          <Empty
            image={Empty.PRESENTED_IMAGE_SIMPLE}
            style={{ margin: 0, paddingTop: 8, paddingBottom: 8 }}
            description={t('description')}
          />
        </div>
      )}

      {templates && templates.length > 0 && (
        <Collapse
          bordered={false}
          destroyInactivePanel={true}
          expandIconPosition="right"
          className={styles.root}
          onChange={checkPannel}>
          {templates
            .sort((a, b) => {
              if (a.template.pos < b.template.pos) return -1;
              if (a.template.pos > b.template.pos) return 1;
              return 0;
            })
            .map(({ count, template }: any) => {
              if (count > 0)
                return (
                  <Collapse.Panel
                    key={template.id}
                    style={pannel}
                    header={<Typography.Title style={titleStyle}>{template.title}</Typography.Title>}>
                    <ActivitytList
                      accountId={accountId}
                      setSelected={setSelected}
                      getData={getData}
                      query={query}
                      variables={{
                        ...params,
                        steps: ['execution', 'check', 'approval', 'finish'],
                        templates: [template.id],
                      }}
                      canReorderList={!params.excludeSubtasks}
                      canCreateNewTask={false}
                      showAccountLogo={page !== 'account_activities'}
                      showSelectEditMultiple={true}
                    />
                  </Collapse.Panel>
                );

              return null;
            })}
        </Collapse>
      )}
      {!openPannel && (
        <TutorialButton
          title={[t('activities_filter')]}
          placement="left"
          tourId={['003_ActivitiesFilters']}
          top={80}
          right={20}
        />
      )}
    </>
  );
};

export default ActivitiesListByTemplates;

const pannel: React.CSSProperties = {
  padding: 0,
  marginBottom: '8px',
};

const titleStyle: React.CSSProperties = {
  fontSize: '16px',
  margin: 0,
};

const my_templates_list = gql`
  query Templates($begin: Date, $end: Date, $types: [TaskType!], $steps: [TaskStep!], $excludeSubtasks: Boolean) {
    me {
      stats {
        tasks(
          filter: {
            period: { begin: $begin, end: $end }
            types: $types
            steps: $steps
            excludeSubtasks: $excludeSubtasks
          }
        ) {
          templates {
            template {
              id
              title
              pos
            }
            count
          }
        }
      }
    }
  }
`;

const account_templates_list = gql`
  query Templates(
    $id: ID!
    $begin: Date
    $end: Date
    $types: [TaskType!]
    $steps: [TaskStep!]
    $users: [ID!]
    $excludeSubtasks: Boolean
  ) {
    account(id: $id) {
      stats {
        tasks(
          filter: {
            period: { begin: $begin, end: $end }
            types: $types
            steps: $steps
            users: $users
            excludeSubtasks: $excludeSubtasks
          }
        ) {
          templates {
            template {
              id
              title
              pos
            }
            count
          }
        }
      }
    }
  }
`;
