import { gql } from '@apollo/client';

export const task_info = gql`
  query Task($id: ID!, $account_timers_total_seconds_begin: Date, $account_timers_total_seconds_end: Date) {
    task(id: $id) {
      id
      title
      body
      step
      type
      planned_seconds
      timers_total_seconds
      parent {
        id
        title
        parent {
          id
          title
        }
      }
      from_template_item {
        id
      }
      notes {
        total
      }
      finish_date
      ... on DefaultTask {
        execution_date
        has_problem
        feedbacks(pagination: { limit: -1, page: 1 }) {
          count
          data {
            id
            feedback
          }
        }
      }
      cell_id
      users {
        id
      }
      account {
        id
        name
        monthly_planned_seconds
        timers_total_seconds(
          period: { begin: $account_timers_total_seconds_begin, end: $account_timers_total_seconds_end }
        )
      }
    }
  }
`;
export const task_feedbacks = gql`
  query TaskFeedbacks($id: ID!, $limit: Int, $page: Int, $status: [FeedbackStatus!]) {
    task(id: $id) {
      id
      ... on DefaultTask {
        feedbacks(pagination: { limit: $limit, page: $page }, filter: { status: $status }) {
          count
          data {
            id
            feedback
            nps
            status
            created_at
            user {
              id
              name
              avatarUrl
            }
          }
        }
      }
    }
  }
`;
export const task_files = gql`
  query TaskFiles($id: ID!) {
    task(id: $id) {
      id
      title
      files(pagination: { limit: -1, page: 1 }) {
        data {
          id
          status
          name
          thumbnailUrl
          info {
            uuid
            mimetype
            size
            url
          }
          opt_info {
            uuid
            mimetype
            size
            url
          }
        }
      }
    }
  }
`;
export const checking_tasks = gql`
  query CheckinTasks($user: ID, $cell: ID, $account: ID, $begin: Date, $end: Date) {
    tasksChecking(period: { begin: $begin, end: $end }, user: $user, cell: $cell, account: $account) {
      execution {
        delayed
        pending
        done
      }
      check {
        delayed
        pending
        done
      }
      approval {
        delayed
        pending
        done
      }
      finish {
        delayed
        pending
        done
      }
      done {
        delayed
        pending
        done
      }
    }
  }
`;

//-------------------- realm -------------------------

export const realm = gql`
  query Templates {
    me {
      id
      realm {
        id
        name
      }
    }
  }
`;

//-------------------- templates -------------------------

export const templates_list = gql`
  query TemplatesList($limit: Int, $page: Int, $hideAccountTemplates: Boolean) {
    me {
      realm {
        id
        templates(pagination: { limit: $limit, page: $page }, filter: { hideAccountTemplates: $hideAccountTemplates }) {
          data {
            id
            title
            account {
              id
              name
            }
            items(pagination: { limit: -1, page: 1 }, filter: { excludeSubitems: true }) {
              data {
                id
              }
            }
          }
        }
      }
    }
  }
`;
export const selected_account_templates_list = gql`
  query AccountTemplatesList($id: ID!, $limit: Int, $page: Int) {
    account(id: $id) {
      id
      name
      templates(pagination: { limit: $limit, page: $page }) {
        total
        data {
          id
          title
          account {
            id
            name
          }
          items(pagination: { limit: -1, page: 1 }, filter: { excludeSubitems: true }) {
            data {
              id
            }
          }
        }
      }
      realm {
        id
        templates(pagination: { limit: $limit, page: $page }, filter: { hideAccountTemplates: true }) {
          total
          data {
            id
            title
            account {
              id
              name
            }
            items(pagination: { limit: -1, page: 1 }, filter: { excludeSubitems: true }) {
              data {
                id
              }
            }
          }
        }
      }
    }
  }
`;
export const templates_items_list = gql`
  query TemplateItemsList($id: ID!, $limit: Int, $page: Int, $excludeSubitems: Boolean) {
    template(id: $id) {
      id
      title
      items(pagination: { limit: $limit, page: $page }, filter: { excludeSubitems: $excludeSubitems }) {
        total
        data {
          id
          title
          body
        }
      }
    }
  }
`;
export const template_item = gql`
  query TemplateItem($id: ID!) {
    templateItem(id: $id) {
      id
      title
      body
      type
    }
  }
`;

//--------------------- timer -------------------------

export const task_timer = gql`
  query TaskTimersList($id: ID!) {
    task(id: $id) {
      id
      timers(pagination: { limit: -1, page: 1 }) {
        data {
          id
          start_date
          finish_date
          total_seconds
          body
          user {
            id
            name
            email
            avatarUrl
          }
        }
      }
    }
  }
`;
export const user_timer = gql`
  query UserTimer {
    me {
      id
      timer {
        id
        start_date
        finish_date
        total_seconds
        task {
          id
          title
          step
          account {
            name
            id
          }
        }
      }
    }
  }
`;

//--------------------- notes -------------------------

export const task_notes = gql`
  query TaskNotes($id: ID!) {
    task(id: $id) {
      id
      notes(pagination: { page: 1, limit: -1 }) {
        count
        data {
          id
          body
          created_at
          user {
            id
            name
            email
            avatarUrl
          }
        }
      }
    }
  }
`;
export const notes_files = gql`
  query TaskNotesFiles($id: ID!) {
    task(id: $id) {
      id
      notes(pagination: { page: 1, limit: -1 }) {
        data {
          id
          files(pagination: { limit: -1, page: 1 }) {
            data {
              id
              status
              name
              thumbnailUrl
              info {
                uuid
                mimetype
                size
                url
              }
              opt_info {
                uuid
                mimetype
                size
                url
              }
            }
          }
        }
      }
    }
  }
`;

//--------------------- CELLS -------------------------

export const cells_list = gql`
  query {
    me {
      id
      realm {
        id
        cells(pagination: { page: 1, limit: -1 }) {
          total
          data {
            id
            name
            logoUrl
            accounts(pagination: { page: 1, limit: -1 }) {
              total
              data {
                id
                name
              }
            }
          }
        }
      }
    }
  }
`;
export const cells_select = gql`
  query {
    me {
      id
      cells(pagination: { page: 1, limit: -1 }) {
        data {
          id
          name
          logoUrl
        }
      }
    }
  }
`;
export const account_cells_select = gql`
  query AccountCells($id: ID!) {
    account(id: $id) {
      id
      cells(pagination: { page: 1, limit: -1 }) {
        data {
          id
          name
          logoUrl
        }
      }
    }
  }
`;
export const cells_info = gql`
  query Cell($id: ID!) {
    cell(id: $id) {
      id
      name
      logoUrl
      accounts(pagination: { page: 1, limit: -1 }) {
        total
        data {
          id
          name
          status
          logoUrl
        }
      }
      members(pagination: { page: 1, limit: -1 }) {
        total
        data {
          id
          name
          email
          roles
          avatarUrl
          status
        }
      }
    }
  }
`;

//----------------------- LINKS -------------------------

export const selected_account_links_list = gql`
  query Links($id: ID!) {
    account(id: $id) {
      id
      name
      links(pagination: { page: 1, limit: -1 }) {
        total
        data {
          id
          type
          url
          ... on WhatsAppLink {
            title
            body
          }
        }
      }
    }
  }
`;

export const link_info = gql`
  query Link($id: ID!) {
    link(id: $id) {
      id
      type
      subdomain
      path
      dest
      url
      ... on WhatsAppLink {
        title
        body
        ml_group_id
      }
      account {
        id
        name
      }
    }
  }
`;

//------------------------ACCOUNTS-----------------------

export const accounts_list = gql`
  query Accounts($limit: Int, $page: Int) {
    me {
      id
      accounts(pagination: { limit: $limit, page: $page }) {
        total
        data {
          id
          name
          status
          logoUrl
        }
      }
    }
  }
`;
export const accounts_select = gql`
  query {
    me {
      id
      accounts(pagination: { limit: -1, page: 1 }) {
        data {
          id
          name
          status
        }
      }
    }
  }
`;
export const accounts_info = gql`
  query Account($id: ID!) {
    account(id: $id) {
      id
      name
      status
      journey
      logoUrl
      monthly_planned_seconds
      mailerlite_apikey
      mailerlite_fields {
        key
        name
      }
      google {
        id
        name
      }
      facebook {
        id
        name
      }
      cells(pagination: { page: 1, limit: -1 }) {
        data {
          id
          name
          logoUrl
          members {
            total
          }
        }
      }
      customers(pagination: { page: 1, limit: -1 }) {
        data {
          id
          name
          email
          roles
          avatarUrl
        }
      }
    }
  }
`;

//------------------------ACCOUNT_INFOS-------------------

export const selected_account_infos_list = gql`
  query Infos($id: ID!) {
    account(id: $id) {
      id
      name
      infos(pagination: { page: 1, limit: -1 }) {
        data {
          id
          title
          body
        }
      }
    }
  }
`;

export const account_info = gql`
  query AccountInfo($id: ID!) {
    account_info(id: $id) {
      id
      title
      body
      account {
        id
        name
      }
    }
  }
`;
export const account_info_files = gql`
  query AccountInfoFiles($id: ID!) {
    account_info(id: $id) {
      id
      title
      body
      files(pagination: { limit: -1, page: 1 }) {
        data {
          id
          status
          name
          thumbnailUrl
          info {
            uuid
            mimetype
            size
            url
          }
          opt_info {
            uuid
            mimetype
            size
            url
          }
        }
      }
    }
  }
`;

//-------------------------USERS--------------------------

export const users_list = gql`
  query Users($limit: Int, $page: Int) {
    me {
      id
      realm {
        id
        users(pagination: { limit: $limit, page: $page }) {
          total
          data {
            id
            name
            email
            roles
            avatarUrl
            status
          }
        }
      }
    }
  }
`;
export const account_customers_select = gql`
  query AccountCustomers($id: ID!) {
    account(id: $id) {
      id
      realm {
        id
        customers(pagination: { limit: -1, page: 1 }) {
          data {
            id
            name
            email
            roles
            avatarUrl
            status
          }
        }
      }
    }
  }
`;
export const cell_members_select_attach = gql`
  query CellMembersSelect($id: ID!) {
    cell(id: $id) {
      id
      members(pagination: { limit: -1, page: 1 }) {
        data {
          id
          name
          email
          roles
          avatarUrl
          status
        }
      }
    }
  }
`;
export const task_users = gql`
  query TaskUsers($id: ID!) {
    task(id: $id) {
      id
      users {
        id
        name
      }
    }
  }
`;
export const cell_members_select = gql`
  query CellMembers($id: ID!) {
    cell(id: $id) {
      id
      realm {
        id
        members(pagination: { limit: -1, page: 1 }) {
          data {
            id
            name
            email
            roles
            status
          }
        }
      }
    }
  }
`;
export const users_info = gql`
  query User($id: ID!) {
    user(id: $id) {
      id
      name
      email
      roles
      avatarUrl
      status
      cells(pagination: { page: 1, limit: -1 }) {
        data {
          id
          name
          logoUrl
          users(pagination: { page: 1, limit: -1 }) {
            total
            data {
              id
              name
            }
          }
          accounts {
            total
          }
        }
      }
      accounts(pagination: { page: 1, limit: -1 }) {
        data {
          id
          name
          status
          logoUrl
        }
      }
    }
  }
`;
//----------------------- NOTIFICATIONS ---------------------

export const notifications_query = gql`
  query Notifications($page: Int, $limit: Int, $viewed: Boolean) {
    notifications(pagination: { page: $page, limit: $limit }, filter: { viewed: $viewed }) {
      total
      data {
        id
        type
        viewed
        notified_at
        viewed_at
        initiator {
          id
          name
          avatarUrl
        }
        recipient {
          id
          name
        }
        ... on TaskStepChangedNotification {
          task {
            id
            title
            account {
              id
              name
            }
          }
          step
        }
        ... on TaskNoteCreatedNotification {
          task {
            id
            title
            account {
              id
              name
            }
          }
        }
        ... on TaskFeedbackCreatedNotification {
          task {
            id
            title
            account {
              id
              name
            }
          }
        }
      }
    }
  }
`;
export const notification_info = gql`
  query Notification($id: ID!) {
    notification(id: $id) {
      id
      type
      viewed
      notified_at
      viewed_at
      initiator {
        id
        name
        avatarUrl
      }
      recipient {
        id
        name
      }
      ... on TaskStepChangedNotification {
        task {
          id
          title
          account {
            id
            name
          }
        }
        step
      }
      ... on TaskNoteCreatedNotification {
        task {
          id
          title
          account {
            id
            name
          }
        }
      }
      ... on TaskFeedbackCreatedNotification {
        task {
          id
          title
          account {
            id
            name
          }
        }
      }
    }
  }
`;
export const total_notifications_query = gql`
  query NotificationsTotal {
    notifications(pagination: { page: 1, limit: 1 }, filter: { viewed: false }) {
      total
    }
  }
`;

export const account_insights_data_query = gql`
  query AccountInsightsData(
    $accountId: ID!
    $type: String!
    $currentPeriod: DatePeriod!
    $previousPeriod: DatePeriod!
  ) {
    current: insights(accountId: $accountId, type: $type, period: $currentPeriod) {
      date
      type
      clicks
      conversions
      cpc
      cpm
      cpp
      ctr
      frequency
      impressions
      reach
      spend
    }

    previous: insights(accountId: $accountId, type: $type, period: $previousPeriod) {
      date
      type
      clicks
      conversions
      cpc
      cpm
      cpp
      ctr
      frequency
      impressions
      reach
      spend
    }
  }
`;

//----------------------- LEADS -------------------------

export const selected_account_leads_list = gql`
  query AccountLeads(
    $account: ID!
    $begin: Date
    $end: Date
    $name: String
    $email: String
    $phone: String
    $sources: [LeadSource!],
    $classifications: [LeadClassification!]
    $limit: Int,
    $page: Int
  ) {
    account(id: $account) {
      leads(
        filter: {
          subscribed_at: { begin: $begin, end: $end }
          name: $name
          email: $email
          phone: $phone
          sources: $sources,
          classifications: $classifications
        }
        pagination: { limit: $limit, page: $page }
      ) {
        total
        data {
          id
          name
          emails
          source
          classification
          subscribed_at
        }
      }
    }
  }
`;

export const lead_info = gql`
  query Lead($accountId: ID!, $leadId: ID!) {
    lead(id: $leadId) {
      id
      source
      name
      emails
      phones
      fields {
        key
        value
      }
      notes
      classification
      subscribed_at
    }

    account(id: $accountId) {
      id
      mailerlite_fields {
        key
        name
      }
    }
  }
`;

export const update_lead_notes = gql`
  mutation UpdateLeadNotes($id: ID!, $notes: String!) {
    updateLead(id: $id, notes: $notes) {
      id
      notes
    }
  }
`;

export const update_lead_classification = gql`
  mutation UpdateLeadClassification($id: ID!, $classification: LeadClassification!) {
    updateLead(id: $id, classification: $classification) {
      id
      classification
    }
  }
`;

export const delete_lead = gql`
  mutation DeleteLead($id: ID!) {
    deleteLead(id: $id)
  }
`;
