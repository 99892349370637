import React, { useState, useEffect, useLayoutEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { useQuery, useMutation } from '@apollo/client';

import { Card, Button, Form, Input, notification, Typography, List, Popover, Tag } from 'antd';

import ErrorAndLoading from '@comp/ErrorAndLoading';
import ListWithAddTo from '@comp/ListWithAddTo';
import UploadAvatar from '@comp/UploadAvatar';
import AvatarPicture from '@comp/AvatarPicture';
import TutorialButton from '@comp/TutorialButton';
import TutorialCell from './TutorialEditCell';

import {
  add_user_to_cell,
  cells_edit,
  add_account_to_cell,
  remove_user_from_cell,
  remove_account_from_cell,
} from '@logic/mutations';
import { cells_info, accounts_select, cell_members_select } from '@logic/queries';
import errorMessage from '@logic/functions/errorHandeling';
import { route } from '@router';

import styles from './styles.module.less';

const EditCell: React.FC = () => {
  const [confirmUser, setConfirmUser] = useState<number | undefined>();
  const [confirmAccount, setConfirmAccount] = useState<number | undefined>();
  const [userIdList, setUserIdList] = useState<string[]>([]);
  const [accountIdList, setAccountIdList] = useState<string[]>([]);
  const [cellName, setCellName] = useState<string>();
  const [addUser, setAddUser] = useState(false);
  const [addAccount, setAddAccount] = useState(false);

  const [form] = Form.useForm();
  const { cell } = useParams<any>();
  const history = useHistory();

  //---------------------- QUERIES & MUTATIONS -----------------------------

  //----------------------- INFO ------------------------
  const { data, loading, error, refetch } = useQuery(cells_info, { variables: { id: cell } });
  const [UpdateCell] = useMutation(cells_edit);

  //----------------------- USERS -----------------------

  const [AttachUserToCell] = useMutation(add_user_to_cell);
  const [DetachUserFromCell] = useMutation(remove_user_from_cell);

  //-------------------- ACCOUNTS ----------------------

  const [AttachAccountToCell] = useMutation(add_account_to_cell);
  const [DetachAccountFromCell] = useMutation(remove_account_from_cell);

  //--------- cria o array para o select de adicionar clientes -------------
  useEffect(() => {
    if (!data) return;

    const newCellName = data.cell?.name;
    setCellName(newCellName);

    const idListAccounts = data.cell?.accounts?.data?.map(({ id }: { id: string }) => id);
    setAccountIdList(idListAccounts);

    const idListUsers = data.cell?.members?.data?.map(({ id }: { id: string }) => id);
    setUserIdList(idListUsers);
  }, [data]);

  useLayoutEffect(() => {
    if (!cellName) return;

    form.setFieldsValue({ name: cellName });
  }, [cellName, form]);

  //----------------- Save, delete, edit functions -----------------------------

  async function doSave(id: string, name: string) {
    try {
      const res = await UpdateCell({ variables: { id, name } });

      notification.open({ type: 'success', message: 'Alterações salvas.', description: res.data?.UpdateCell?.name });
      refetch({ id: cell });
    } catch (err) {
      notification.open({ type: 'error', ...errorMessage('graph_err', err) });
    }
  }
  const onFinish = (values: any) => {
    form
      .validateFields()
      .then((values) => {
        doSave(cell, values.name);

        form.resetFields();
      })
      .catch((info) => {
        notification.open({ type: 'error', ...errorMessage('form_validation', info) });
      });
  };
  const onFinishFailed = (errorInfo: any) => {
    notification.open({ type: 'error', ...errorMessage('', errorInfo) });
  };

  async function Delete(id: string, type: 'user' | 'account') {
    try {
      if (type === 'user') await DetachUserFromCell({ variables: { user: id, cell, type: 'Cell' } });
      if (type === 'account') await DetachAccountFromCell({ variables: { account: id, cell } });
      notification.open({ type: 'success', message: 'Removido' });
      refetch({ id: cell });
    } catch (err) {
      notification.open({ type: 'error', ...errorMessage('graph_err', err) });
    }
  }

  async function AttachTo(id: string, type: 'user' | 'account') {
    try {
      if (type === 'user') await AttachUserToCell({ variables: { type: 'Cell', user: id, cell } });
      if (type === 'account') await AttachAccountToCell({ variables: { account: id, cell } });
      refetch({ id: cell });
    } catch (err) {
      notification.open({ type: 'error', ...errorMessage('graph_err', err) });
    }
  }

  //---------------------------------------------------------------------

  if (loading) return <ErrorAndLoading loading={loading} />;
  if (error) return <ErrorAndLoading error={error} />;

  const accounts = data?.cell.accounts.data;
  const members = data?.cell.members.data;

  return (
    <>
      <Card style={{ marginBottom: 16 }}>
        <div className={styles.div_avatar} style={{ marginBottom: 16 }}>
          <div id="avatar_cell" className={styles.avatar}>
            <UploadAvatar
              id={cell}
              query={cells_info}
              params={{ variables: { id: cell }, fetchPolicy: 'cache-only' }}
              extractData={(logoUrl) => data?.cell?.logoUrl}
              radius="10%"
              target="Cell"
            />
          </div>

          <div className={styles.form}>
            <Typography.Title style={{ fontSize: '20px' }}>Informações da célula</Typography.Title>

            <Form
              form={form}
              labelCol={{
                span: 2,
                style: { display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', minWidth: 80 },
              }}
              wrapperCol={{ style: { display: 'flex', flex: 1 } }}
              layout="horizontal"
              name="cell_edit"
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
              initialValues={{ name: cellName || '' }}>
              <div id="name_cell">
                <Form.Item name="name" label="Nome">
                  <Input type="text" />
                </Form.Item>
              </div>

              <Form.Item noStyle>
                <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                  <Button type="primary" htmlType="reset" onClick={() => history.goBack()}>
                    Voltar
                  </Button>

                  <Button
                    id="save_cell_name"
                    type="primary"
                    htmlType="submit"
                    loading={loading}
                    style={{ marginLeft: 6 }}>
                    Salvar
                  </Button>
                </div>
              </Form.Item>
            </Form>
          </div>
        </div>
      </Card>

      <ListWithAddTo
        title="Usuários nesta célula"
        button="Adicionar usuário"
        buttonId="add_user_cell"
        helpDescription="Os usuários da células são membros da célula, que é composta por criação, media tester e sucesso de cliente. Eles têm acesso as atividades e clientes da célula. "
        helpTitle="Membros da célula"
        idList={userIdList}
        query={cell_members_select}
        params={{ variables: { id: cell } }}
        extractData={(data) => {
          setAddUser(data?.cell?.realm?.members?.data.length > 0);

          const filtered = (data?.cell?.realm?.members?.data || []).filter((user: any) => user?.status !== 'inactive');

          return filtered;
        }}
        AttachAtoB={(a, b) => {
          AttachTo(a || '', 'user');
        }}
        items={members}
        renderItem={(item, idx) => (
          <List.Item
            id="open_user_cell"
            key={item.id}
            onClick={() => history.push(route('admin.users.edit', { user: item.id }))}
            className={styles.item}
            actions={[
              <Popover
                destroyTooltipOnHide={{ keepParent: false }}
                content={
                  <>
                    <Button
                      onClick={(e) => {
                        e.stopPropagation();
                        setConfirmUser(undefined);
                      }}>
                      Não
                    </Button>
                    <Button
                      style={{ marginLeft: 6 }}
                      onClick={(e) => {
                        e.stopPropagation();
                        setConfirmUser(undefined);
                        Delete(item.id, 'user');
                      }}>
                      Sim
                    </Button>
                  </>
                }
                title="Remover este usuário?"
                trigger="click"
                visible={confirmUser === idx}
                onVisibleChange={() => setConfirmUser(idx)}>
                <Button id="delete_user_cell" onClick={(e) => e.stopPropagation()}>
                  Remover
                </Button>
              </Popover>,
              <Button
                id="open_user_cell_button"
                onClick={() => history.push(route('admin.users.edit', { user: item.id }))}>
                Visualizar
              </Button>,
            ]}>
            <List.Item.Meta
              avatar={<AvatarPicture pictureLink={item.avatarUrl} target="User" size={40} name={item.name} />}
              title={
                <>
                  {item.name}

                  {item.roles.map((role: string) => (
                    <Tag
                      key={role}
                      color="blue"
                      style={{
                        position: 'relative',
                        top: -2,
                        margin: '0 0 0 8px',
                        borderRadius: 4,
                        fontSize: 7,
                        fontWeight: 700,
                        padding: '4px 6px',
                        lineHeight: '7px',
                        cursor: 'pointer',
                      }}>
                      {role === 'admin' && 'ADMINISTRADOR'}
                      {role === 'customer_success' && 'SUCESSO DE CLIENTE'}
                      {role === 'media_tester' && 'MEDIA TESTER'}
                      {role === 'creator' && 'CRIAÇÃO'}
                      {role === 'customer' && 'CLIENTE'}
                    </Tag>
                  ))}

                  {
                    <Tag
                      key={item.status}
                      color="red"
                      visible={item.status === 'inactive' ? true : false}
                      style={{
                        position: 'relative',
                        top: -2,
                        margin: '0 0 0 8px',
                        borderRadius: 4,
                        fontSize: 7,
                        fontWeight: 700,
                        padding: '4px 6px',
                        lineHeight: '7px',
                        cursor: 'pointer',
                      }}>
                      {item.status === 'inactive' && 'INATIVO'}
                    </Tag>
                  }
                </>
              }
              description={item.email}
            />
          </List.Item>
        )}
      />

      <ListWithAddTo
        title="Clientes nesta célula"
        button="Adicionar cliente"
        buttonId="add_account_cell"
        helpTitle="Clientes da célula"
        helpDescription="São clientes que a célula é responsável, desde atividades a comunicação com eles para alinhamento estratégico."
        idList={accountIdList}
        query={accounts_select}
        extractData={(data) => {
          setAddAccount(data.me?.accounts.data.length > 0);
          return data.me?.accounts.data;
        }}
        AttachAtoB={(a, b) => {
          AttachTo(a || '', 'account');
        }}
        items={accounts}
        renderItem={(item, idx) => (
          <List.Item
            id="open_account_cell"
            key={item.id}
            className={styles.item}
            onClick={() => history.push(route('admin.accounts.edit', { account: item.id }))}
            actions={[
              <Popover
                destroyTooltipOnHide={{ keepParent: false }}
                content={
                  <>
                    <Button
                      onClick={(e) => {
                        e.stopPropagation();
                        setConfirmAccount(undefined);
                      }}>
                      Não
                    </Button>
                    <Button
                      style={{ marginLeft: 6 }}
                      onClick={(e) => {
                        e.stopPropagation();
                        setConfirmAccount(undefined);
                        Delete(item.id, 'account');
                      }}>
                      Sim
                    </Button>
                  </>
                }
                title="Remover este cliente?"
                trigger="click"
                visible={confirmAccount === idx}
                onVisibleChange={() => setConfirmAccount(idx)}>
                <Button id="delete_account_cell" onClick={(e) => e.stopPropagation()}>
                  Remover
                </Button>
              </Popover>,
              <Button
                id="open_account_cell_button"
                onClick={() => history.push(route('admin.accounts.edit', { account: item.id }))}>
                Visualizar
              </Button>,
            ]}>
            <List.Item.Meta
              avatar={<AvatarPicture pictureLink={item.logoUrl} target="Account" size={40} name={item.name} />}
              title={item.name}
              description={
                item.status === 'inactive'
                  ? 'Cliente inativo'
                  : item.status === 'active'
                  ? 'Cliente ativo'
                  : item.status === 'suspended'
                  ? 'Cliente suspenso'
                  : ''
              }
            />
          </List.Item>
        )}
      />

      <TutorialButton tourId="018_EditCellManagement" top={80} right={20} placement="left" title="Tutorial da célula" />

      <TutorialCell
        hasAccount={accounts.length > 0}
        hasUser={members.length > 0}
        addAccountButton={addAccount}
        addUserButton={addUser}
      />
    </>
  );
};

export default EditCell;
