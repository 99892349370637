import React, { useContext } from 'react';

import Store from './store';
import DefaultValues from './stores/defaultValues';

export const store: Store = new Store();
export const storeContext: React.Context<Store> = React.createContext(store);

export const useStore = () => {
  return useContext(storeContext);
};

export const defaultValues: DefaultValues = new DefaultValues();
export const defaultValuesContext: React.Context<DefaultValues> = React.createContext(defaultValues);

export const useDefaultValues = () => {
  return useContext(defaultValuesContext);
};

export const useUploadManager = () => {
  const { uploadManager } = useStore();
  return uploadManager;
};
