import { Input, List, notification } from 'antd';
import moment from 'moment';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useMutation } from '@apollo/client';
import Clickable from '@comp/Clickable';
import errorMessage from '@logic/functions/errorHandeling';
import { update_task } from '@logic/mutations';

import ActivityAccountLogo from './ActivityAccountLogo';
import ActivityActions from './ActivityActions';
import ActivityCheckbox from './ActivityCheckbox';
import ActivityDates from './ActivityDates';
import ActivityResponsibles from './ActivityResponsibles';
import ActivityStep from './ActivityStep';

import styles from './styles.module.less';

interface Props {
  index?: number;
  accountId: string;
  showAccountLogo: boolean;
  cellId: string;
  problem: boolean;
  taskId: string;
  taskTitle: string;
  taskStep: string;
  taskExecutionDate: string;
  taskFinishDate: string;
  taskUsers: any[];
  taskType: 'default' | 'assignment';
  showSelectEditMultiple: boolean;
  openTask: () => void;
  refresh: () => void;
}

const ActivitytListItem: React.FC<Props> = ({
  index,
  accountId,
  showAccountLogo,
  cellId,
  problem,
  taskId,
  taskTitle,
  taskStep,
  taskExecutionDate,
  taskFinishDate,
  taskUsers,
  taskType,
  showSelectEditMultiple,
  openTask,
  refresh,
}) => {
  const [{ title, execution, finish, step }, setTask] = useState(() => ({
    title: taskTitle,
    execution: strToMoment(taskExecutionDate),
    finish: strToMoment(taskFinishDate),
    step: taskStep,
  }));
  const [focused, setFocused] = useState(() => false);

  const { t } = useTranslation('activityListItem');

  //-----------------------------Mutations-----------------------------------

  const [UpdateTask] = useMutation(update_task);

  //-------------------------------------------------------------------------

  const save = useCallback(
    async (title: string) => {
      try {
        await UpdateTask({
          variables: { id: taskId, title },
        });

        //refresh();
      } catch (err) {
        notification.open({
          type: 'error',
          ...errorMessage('graph_err', err),
        });
      }
    },
    [UpdateTask, taskId],
  );

  useEffect(() => {
    if (!focused && title !== taskTitle) {
      setTask((task) => ({ ...task, title: taskTitle }));
    }

    if (!!execution && !execution.isSame(taskExecutionDate)) {
      setTask((task: any) => ({ ...task, execution: strToMoment(taskExecutionDate) }));
    }

    if (!!finish && !finish.isSame(taskFinishDate)) {
      setTask((task: any) => ({ ...task, finish: strToMoment(taskFinishDate) }));
    }
  }, [taskTitle, title, taskExecutionDate, execution, taskFinishDate, finish, focused]);

  const handleChange = useCallback(({ target }: any) => {
    setTask((task) => ({ ...task, [target.name]: target.value }));
  }, []);

  const handleBlur = useCallback(() => {
    if (title !== taskTitle) save(title);
  }, [title, taskTitle, save]);

  //-------------------------- callbacks -------------------------------
  const onclickInput = (e: any) => {
    if (focused) e.stopPropagation();
  };

  const onDbClick = () => {
    setFocused(true);
  };

  const onBlurClickable = () => {
    setFocused(false);
  };

  return (
    <List.Item
      id="activity_item_open"
      onClick={openTask}
      className={styles.item}
      style={listStyle}
      onBlur={handleBlur}
      extra={<ActivityActions taskId={taskId} type={taskType} refresh={refresh} index={index} />}>
      {showSelectEditMultiple && <ActivityCheckbox taskId={taskId} />}
      {showAccountLogo && accountId && <ActivityAccountLogo accountId={accountId} />}

      <ActivityStep taskStep={step} taskType={taskType} taskId={taskId} index={index} />

      <Clickable onClick={openTask} onDbClick={onDbClick} onBlur={onBlurClickable} style={{ display: 'flex', flex: 1 }}>
        <Input
          id={index === 0 ? `change_item_task_title_${index}` : undefined}
          name="title"
          placeholder={t('placeholder')}
          value={title}
          onChange={handleChange}
          onClick={onclickInput}
          readOnly={!focused}
          bordered={focused}
          style={{
            flex: 1,
            textOverflow: 'ellipsis',
            overflow: 'hidden',
            cursor: focused ? 'text' : 'pointer',
            ...(problem ? { fontWeight: 500, color: '#ff5432', fontSize: 15 } : {}),
          }}
        />
      </Clickable>

      <ActivityResponsibles cellId={cellId} taskId={taskId} refresh={refresh} taskUsers={taskUsers} index={index} />

      <ActivityDates
        taskId={taskId}
        taskType={taskType}
        execution={execution}
        finish={finish}
        step={step}
        index={index}
      />
    </List.Item>
  );
};

export default ActivitytListItem;

const listStyle: React.CSSProperties = {
  padding: 0,
  display: 'flex',
  flexDirection: 'row',
  cursor: 'pointer',
};

function strToMoment(date?: string) {
  if (!date) return undefined;
  return moment(date, 'YYYY-MM-DD HH:mm:ss').local();
}
