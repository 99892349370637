import React from 'react';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react-lite';
import { Badge, Tooltip, Typography } from 'antd';
import useConfig from '@logic/config';
import { TooltipPlacement } from 'antd/lib/tooltip';

import { IoRocketSharp } from 'react-icons/io5';

import styles from './styles.module.less';

interface Props {
  offset?: number[];
  title: string;
  description: string;
  configId: string;
  ribbon?: boolean;
  placement: TooltipPlacement;
  primaryColor: boolean;
  fixedTooltip?: boolean;
}

// Tooltip de nova funconalidade

const NewFunctionalities: React.FC<Props> = ({
  offset,
  title,
  description,
  children,
  configId,
  ribbon,
  placement,
  primaryColor,
  fixedTooltip,
}) => {
  const config = useConfig();

  const { t } = useTranslation('newFunctionalities');

  const tutorials = [...((config.tutorial as any) || [])];
  const firtsTimeTutorial = !tutorials.includes(configId);

  if (tutorials.includes(configId)) return <>{children}</>;

  return (
    <>
      {!fixedTooltip && ribbon && (
        <Badge.Ribbon
          text={
            <Tooltip
              destroyTooltipOnHide={{ keepParent: false }}
              title={
                <div style={{ flexDirection: 'column', padding: 10 }}>
                  <Typography.Text className={styles.title}>{title}</Typography.Text>

                  <Typography.Text className={styles.description}>{description}</Typography.Text>

                  <p
                    onClick={() => {
                      config.setConfig('tutorial', [...tutorials, configId]);
                    }}
                    className={styles.white_button}>
                    {t('button_ok')}
                  </p>
                </div>
              }
              color={primaryColor ? '#ff5333' : '#3385ff'}
              placement={placement}>
              <Typography.Text className={styles.novidade}>
                {<IoRocketSharp className={styles.rocket} />}Novidade!
              </Typography.Text>
            </Tooltip>
          }
          color={primaryColor ? '#ff5333' : '#3385ff'}>
          {children}
        </Badge.Ribbon>
      )}

      {!fixedTooltip && !ribbon && (
        <Tooltip
          destroyTooltipOnHide={{ keepParent: false }}
          title={
            <div className={styles.root}>
              <Typography.Text className={styles.title}>{title}</Typography.Text>

              <Typography.Text className={styles.description}>{description}</Typography.Text>

              <p
                onClick={() => {
                  config.setConfig('tutorial', [...tutorials, configId]);
                }}
                className={styles.white_button}>
                {t('button_ok')}
              </p>
            </div>
          }
          color={primaryColor ? '#ff5333' : '#3385ff'}
          placement={placement}>
          <IoRocketSharp size={16} style={{ color: primaryColor ? '#ff5333' : '#3385ff' }} />
        </Tooltip>
      )}

      {fixedTooltip && !ribbon && (
        <Tooltip
          destroyTooltipOnHide={{ keepParent: false }}
          align={{ offset }}
          visible={firtsTimeTutorial ? true : false}
          title={
            <div className={styles.root}>
              <Typography.Text className={styles.title}>{title}</Typography.Text>

              <Typography.Text className={styles.description}>{description}</Typography.Text>

              <p
                onClick={() => {
                  config.setConfig('tutorial', [...tutorials, configId]);
                }}
                className={styles.white_button}>
                {t('button_ok')}
              </p>
            </div>
          }
          color={primaryColor ? '#ff5333' : '#3385ff'}
          placement={placement}>
          {children}
        </Tooltip>
      )}
    </>
  );
};

export default observer(NewFunctionalities);
