import React from 'react';
import { gql, useQuery } from '@apollo/client';
import { Card } from 'antd';

import ErrorAndLoading from '@comp/ErrorAndLoading';
import TemplateRender from './TemplateReport';
import ReportsPeriodFilter from './ReportsPeriodFilter';

const TemplateReports: React.FC = () => {
  const { data, loading, error } = useQuery(templates_list, {
    fetchPolicy: 'no-cache',
    variables: { limit: -1, page: 1, hideAccountTemplates: false },
  });

  const templates = data?.me?.realm?.templates?.data
    .map(({ account, id, title, items: { data } }: any) => ({ account, id, title, children: data }))
    .sort((a: any, b: any) => {
      if (a.title > b.title) return 1;
      if (a.title < b.title) return -1;
      return 0;
    })
    .sort((a: any, b: any) => {
      if (a.account && b.account) {
        if (a.account > b.account) return 1;
        if (a.account < b.account) return -1;
        return 0;
      }
      if (a.account) return 1;
      if (b.account) return -1;
      return 0;
    });

  if (error) return <ErrorAndLoading error={error} />;

  return (
    <Card loading={loading}>
      <ReportsPeriodFilter />

      <div style={{ height: 16 }} />

      {templates &&
        templates.map(({ id, title, account, children }: any) => (
          <TemplateRender
            key={`template_${id}`}
            id={id}
            title={title}
            account={account}
            items={children.map(({ id }: any) => id)}
          />
        ))}
    </Card>
  );
};

export default TemplateReports;

const templates_list = gql`
  query TemplatesList($limit: Int, $page: Int, $hideAccountTemplates: Boolean) {
    me {
      realm {
        id
        templates(pagination: { limit: $limit, page: $page }, filter: { hideAccountTemplates: $hideAccountTemplates }) {
          data {
            id
            title
            account {
              id
              name
              logoUrl
            }
            items(pagination: { limit: -1, page: 1 }, filter: { excludeSubitems: true }) {
              data {
                id
              }
            }
          }
        }
      }
    }
  }
`;
