import { notification, Popover, Radio, Tooltip, Typography } from 'antd';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import {
  CheckCircleOutlined,
  FileSearchOutlined,
  ReloadOutlined,
  SoundOutlined,
  UserOutlined,
} from '@ant-design/icons';
import { gql, useMutation } from '@apollo/client';
import errorMessage from '@logic/functions/errorHandeling';

import styles from './styles.module.less';

const update_task_step = gql`
  mutation UpdateTaskStep($id: ID!, $step: TaskStep) {
    updateTask(id: $id, step: $step) {
      id
      step
    }
  }
`;

interface Props {
  taskStep: string;
  taskId: string;
  taskType: 'default' | 'assignment';
  whiteText?: boolean;
  index?: number;
}

// Editar a etapa de uma atividade pela lista

const ActivityStep: React.FC<Props> = ({ taskStep, taskType, taskId, whiteText, index }) => {
  const [stepState, setStepState] = useState(taskStep);
  const { t } = useTranslation('activityStep');

  //---------------------------Mutations-----------------------------------------------------

  const [UpdateTask] = useMutation(update_task_step);

  //-----------------------------------------------------------------------------------------

  useEffect(() => {
    setStepState(taskStep);
  }, [taskStep]);

  const save = useCallback(
    async (step: string) => {
      if (step === taskStep) return;
      try {
        await UpdateTask({ variables: { id: taskId, step } });
      } catch (err) {
        notification.open({ type: 'error', ...errorMessage('graph_err', err) });
      }
    },
    [UpdateTask, taskStep, taskId],
  );

  return (
    <div id={`change_step_icon_${index}`} onClick={(e) => e.stopPropagation()} style={{ cursor: 'pointer' }}>
      <Popover
        destroyTooltipOnHide={{ keepParent: false }}
        trigger="click"
        style={{ marginLeft: 8, marginRight: 4 }}
        content={
          <div style={{ display: 'flex', flexDirection: 'column', margin: -4 }}>
            <Typography.Text style={{ marginBottom: 4 }}>{t('change_step')}</Typography.Text>
            <Radio.Group
              buttonStyle="solid"
              value={stepState}
              onChange={({ target }) => {
                setStepState(target.value);

                save(target.value);
              }}>
              <Tooltip destroyTooltipOnHide={{ keepParent: false }} title={t('step_execution')} mouseEnterDelay={0.6}>
                <Radio.Button value="execution">
                  <ReloadOutlined />
                </Radio.Button>
              </Tooltip>

              {taskType === 'default' && (
                <>
                  <Tooltip destroyTooltipOnHide={{ keepParent: false }} title={t('step_check')} mouseEnterDelay={0.6}>
                    <Radio.Button value="check">
                      <FileSearchOutlined />
                    </Radio.Button>
                  </Tooltip>

                  <Tooltip
                    destroyTooltipOnHide={{ keepParent: false }}
                    title={t('step_approval')}
                    mouseEnterDelay={0.6}>
                    <Radio.Button value="approval">
                      <UserOutlined />
                    </Radio.Button>
                  </Tooltip>

                  <Tooltip destroyTooltipOnHide={{ keepParent: false }} title={t('step_finish')} mouseEnterDelay={0.6}>
                    <Radio.Button value="finish">
                      <SoundOutlined />
                    </Radio.Button>
                  </Tooltip>
                </>
              )}

              <Tooltip destroyTooltipOnHide={{ keepParent: false }} title={t('step_done')} mouseEnterDelay={0.6}>
                <Radio.Button value="done">
                  <CheckCircleOutlined />
                </Radio.Button>
              </Tooltip>
            </Radio.Group>
          </div>
        }>
        <Typography.Text style={{ marginLeft: 8, marginRight: 4 }}>{StepsIcons(stepState, whiteText)}</Typography.Text>
      </Popover>
    </div>
  );
};

export default ActivityStep;

function StepsIcons(step: string, white?: boolean) {
  //if (hasProblem) return <CloseCircleOutlined className={styles.icon} style={{ color: '#FF2362' }} />;
  switch (step) {
    case 'execution':
      return <ReloadOutlined className={styles.icon} style={{ color: white ? 'rgba(255,255,255,0.87)' : '#3385FF' }} />;
    case 'check':
      return (
        <FileSearchOutlined className={styles.icon} style={{ color: white ? 'rgba(255,255,255,0.87)' : '#FF5333' }} />
      );
    case 'approval':
      return <UserOutlined className={styles.icon} style={{ color: white ? 'rgba(255,255,255,0.87)' : '#B03591' }} />;
    case 'finish':
      return <SoundOutlined className={styles.icon} style={{ color: white ? 'rgba(255,255,255,0.87)' : '#55A630' }} />;
    case 'done':
      return (
        <CheckCircleOutlined className={styles.icon} style={{ color: white ? 'rgba(255,255,255,0.87)' : '#8f8f8f' }} />
      );

    default:
      return undefined;
  }
}
