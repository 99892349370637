import { Avatar, notification, Popover, Select, Tag, Tooltip, Typography } from 'antd';
import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { gql, useMutation, useQuery } from '@apollo/client';

import AvatarPicture from '@comp/AvatarPicture';

import errorMessage from '@logic/functions/errorHandeling';
import { attach_user, detach_user } from '@logic/mutations';

import styles from './styles.module.less';

// Usuários responsáveis pela atividade
// Só é possível adicionar usuários se a atividade estiver em uma célula
// Ao criar uma atividade ela deve selecionar automaticamente a primeira célula

interface Props {
  cellId: string;
  taskId: string;
  taskUsers: any[];
  refresh: () => void;
  index?: number;
}
const fetch_cell_members = gql`
  query CellMembers($id: ID!) {
    cell(id: $id) {
      id
      members(pagination: { page: 1, limit: -1 }) {
        total
        data {
          id
          name
          avatarUrl
          status
        }
      }
    }
  }
`;

const ActivityResponsibles: React.FC<Props> = ({ taskId, cellId, taskUsers, refresh, index }) => {
  const [AttachUser] = useMutation(attach_user);
  const [DetachUser] = useMutation(detach_user);

  const { t } = useTranslation('activityResponsibles');

  // Fetch cell members
  const { data } = useQuery(fetch_cell_members, { variables: { id: cellId } /*fetchPolicy:'cache-and-network'*/ });

  const cellMembers = (data?.cell?.members?.data || []).filter((user: any) => user?.status !== 'inactive');

  const handleAttach = useCallback(
    async (value: any) => {
      try {
        await AttachUser({ variables: { target: taskId, type: 'Task', user: value } });
        refresh();
      } catch (err) {
        notification.open({ type: 'error', ...errorMessage('graph_err', err) });
      }
    },
    [AttachUser, refresh, taskId],
  );

  const handleDetach = useCallback(
    async (value: any) => {
      try {
        await DetachUser({ variables: { target: taskId, type: 'Task', user: value } });

        refresh();
      } catch (err) {
        notification.open({ type: 'error', ...errorMessage('graph_err', err) });
      }
    },
    [DetachUser, refresh, taskId],
  );

  //---------------------- Select memos -----------------------------
  const selectedUsersIds = useMemo(() => taskUsers.map((user: any) => user.id), [taskUsers]);

  const cellMembersList = useMemo(
    () =>
      (cellMembers || []).map(({ id, name, avatarUrl }: { id: string; name: string; avatarUrl: string }) => (
        <Select.Option key={id} value={id}>
          <div className={styles.users_list_item}>
            <div style={{ width: 25 }}>
              <AvatarPicture pictureLink={avatarUrl} target="User" size={20} name={name} />
            </div>
            <Typography.Text className={styles.user_name} ellipsis>
              {name}
            </Typography.Text>
          </div>
        </Select.Option>
      )),
    [cellMembers],
  );

  const cellInactive = useMemo(
    () => (data?.cell?.members?.data || []).filter(({ status }: any) => status === 'inactive'),
    [data],
  );

  return (
    <div
      id={index === 0 ? `change_item_responsibles_${index}` : undefined}
      onClick={(e) => e.stopPropagation()}
      style={{ cursor: 'pointer', width: 80, minWidth: 80 }}>
      <Popover
        destroyTooltipOnHide={{ keepParent: false }}
        trigger="click"
        style={{ marginLeft: 8, marginRight: 4 }}
        color="red"
        content={
          <div style={{ display: 'flex', flexDirection: 'column', margin: -4 }}>
            <Typography.Text style={{ marginBottom: 4 }}>{t('responsibles')}</Typography.Text>
            <Select
              mode="multiple"
              style={{ maxWidth: '80vw' }}
              value={selectedUsersIds}
              onSelect={handleAttach}
              onDeselect={handleDetach}
              filterOption={false}
              tagRender={({ label, value, closable, onClose }: any) => {
                const index = cellInactive.findIndex((user: any) => user.id === value);

                if (typeof label === 'string' && index !== -1)
                  return (
                    <Tag
                      closable={closable}
                      onClose={onClose}
                      title="Usuário inativo"
                      style={{ ...tagStyle, backgroundColor: '#ff8e8e77' }}>
                      <div className={styles.users_list_item} style={{ maxWidth: 100 }}>
                        <div style={{ width: 25 }}>
                          <AvatarPicture
                            pictureLink={cellInactive[index]?.avatarUrl}
                            target="User"
                            size={20}
                            name={cellInactive[index]?.name}
                          />
                        </div>
                        <Typography.Text className={styles.user_name_tag} ellipsis>
                          {cellInactive[index]?.name}
                        </Typography.Text>
                      </div>
                    </Tag>
                  );

                return (
                  <Tag closable={closable} onClose={onClose} style={{ ...tagStyle, backgroundColor: '#f1f1f1' }}>
                    {label}
                  </Tag>
                );
              }}>
              {cellMembersList}
            </Select>
          </div>
        }>
        <div>
          {taskUsers.length > 0 ? (
            <Avatar.Group
              maxPopoverPlacement="bottom"
              maxCount={taskUsers.length > 3 ? 2 : 3}
              size="small"
              maxStyle={{ color: '#ff5333', backgroundColor: '#fde3cf' }}>
              {taskUsers.map((user) => (
                <Tooltip
                  destroyTooltipOnHide={{ keepParent: false }}
                  key={`task_list_select_${user.id}`}
                  title={user.name}>
                  <AvatarPicture pictureLink={user.avatarUrl} target="User" name={user.name} size={26} />
                </Tooltip>
              ))}
            </Avatar.Group>
          ) : (
            <Tooltip destroyTooltipOnHide={{ keepParent: false }} mouseEnterDelay={0.7} title={t('add_title')}>
              <Typography.Text style={{ color: '#c1c1c1', fontSize: 13 }}>{t('add_button')}</Typography.Text>
            </Tooltip>
          )}
        </div>
      </Popover>
    </div>
  );
};

export default ActivityResponsibles;

const tagStyle = {
  display: 'flex',
  alignItems: 'center',
  marginRight: 3,
  padding: '2px 6px',
  border: 'none',
  borderRadius: 3,
  cursor: 'default',
};
