import React from 'react';
import { DocumentNode } from '@apollo/client';

import ActivitytList from '../ActivitiesList';
//import InfinitedPaginatedList from '@comp/InfinitePaginatedList';

//const ActivitytListItem = React.lazy(() => import('../ActivitiesList/ActivityListItem'));

interface Props {
  accountId: string;
  page: 'my_activities' | 'account_activities' | 'realm_activities' | 'home_dashboard';
  setSelected: (taskId: string) => void;
  query: DocumentNode;
  params: any;
  getData: (data: any) => any;
}

// Lista de atividades da visão global

const ActivitiesListAll: React.FC<Props> = ({ accountId, page, setSelected, params, getData, query }) => {
  /*return (
    <div style={{ height: '73vh' }}>
      <InfinitedPaginatedList
        getData={getData}
        query={query}
        limit={10}
        height="73vh"
        variables={params}
        renderItem={({ data }) => {
          return (
            <Suspense fallback={<p>carregando componente</p>}>
              <ActivitytListItem
                problem={data.has_problem}
                accountId={data.account_id}
                cellId={data.cell_id}
                taskId={data.id}
                taskTitle={data.title}
                taskStep={data.step}
                taskType={data.type}
                taskUsers={data.users}
                taskExecutionDate={data.execution_date}
                taskFinishDate={data.finish_date}
                refresh={() => {}}
                openTask={() => setSelected(data.id)}
                showAccountLogo={page !== 'account_activities'}
              />
            </Suspense>
          );
        }}
      />
    </div>
  );*/
  return (
    <ActivitytList
      accountId={accountId}
      setSelected={setSelected}
      getData={getData}
      query={query}
      variables={{ ...params, types: ['default', 'assignment'] }}
      canReorderList={!params.excludeSubtasks}
      canCreateNewTask={page === 'account_activities'}
      showAccountLogo={page !== 'account_activities'}
      showSelectEditMultiple={page !== 'home_dashboard'}
    />
  );
};

export default ActivitiesListAll;
