import React, { useEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { Breadcrumb, Button, Drawer, notification, Popconfirm, Tabs, Typography } from 'antd';
import { gql, useLazyQuery, useMutation } from '@apollo/client';

import { RightOutlined, DeleteOutlined, CloseCircleOutlined, SyncOutlined } from '@ant-design/icons';

import TemplateItemTab from './TemplateItemTab';
import Navigate from '@comp/Navigate';

import { route } from '@router';
import errorMessage from '@logic/functions/errorHandeling';
import { delete_template_item } from '@logic/mutations';

const template_info = gql`
  query TemplateItemInfo($id: ID!) {
    templateItem(id: $id) {
      id
      title
      planned_seconds
      template {
        id
        account {
          id
          name
        }
      }
      parent {
        id
        title
        parent {
          id
          title
        }
      }
    }
  }
`;

interface Props {
  displayOnly?: boolean; // Unused
  templateId: string;
}

const TemplateItem: React.FC<Props> = ({ displayOnly, templateId }) => {
  const { id: paramAccountId } = useParams<any>();
  const history = useHistory();

  const accountId = paramAccountId;
  const itemId = templateId;

  const [fetchTaemplate, { data, loading }] = useLazyQuery(template_info, {
    variables: { id: itemId },
    fetchPolicy: 'network-only',
  });

  const [DeleteTemplate] = useMutation(delete_template_item, { variables: { id: itemId } });

  const closeDrawer = () => history.push(history.location.pathname);

  async function Delete() {
    try {
      await DeleteTemplate();

      notification.open({
        placement: 'bottomRight',
        duration: 2,
        type: 'success',
        message: 'Template excluído',
      });

      data?.templateItem?.parent?.id ? history.push(templatesRouter(data?.templateItem?.parent?.id)) : closeDrawer();
    } catch (err) {
      notification.open({ type: 'error', ...errorMessage('graph_err', err) });
    }
  }

  useEffect(() => {
    fetchTaemplate();
  }, [itemId, fetchTaemplate]);

  function templatesRouter(id: string) {
    return (!accountId ? route('templates') : route('id.home.templates', { id: accountId })) + '#template/' + id;
  }

  const onlyDisplayView = !!accountId && !data?.templateItem?.template?.account?.name;

  //------------------------------------------------------------------------------

  return (
    <Drawer
      forceRender
      destroyOnClose
      visible
      width={'800px'}
      title={
        <div>
          {data && !!data.templateItem && (
            <Typography.Text ellipsis style={{ width: '100%', marginBottom: 6 }}>
              {data.templateItem.template?.account?.name}
            </Typography.Text>
          )}
          {data && !!data.templateItem && !!data.templateItem.parent && (
            <div style={{ display: 'flex', flexDirection: 'row' }}>
              <Breadcrumb>
                {!!data.templateItem.parent.parent && (
                  <Navigate to={templatesRouter(data.templateItem.parent.parent.id)}>
                    <Breadcrumb.Item separator={<RightOutlined />}>
                      <Typography.Link ellipsis style={{ maxWidth: '30%' }}>
                        {data.templateItem.parent.parent.title}
                      </Typography.Link>
                    </Breadcrumb.Item>
                  </Navigate>
                )}

                <Navigate to={templatesRouter(data.templateItem.parent.id)}>
                  <Breadcrumb.Item separator={<RightOutlined />}>
                    <Typography.Link ellipsis style={{ maxWidth: !!data.templateItem.parent.parent ? '30%' : '45%' }}>
                      {data.templateItem.parent.title}
                    </Typography.Link>
                  </Breadcrumb.Item>
                </Navigate>

                <Breadcrumb.Item>
                  <Typography.Link ellipsis style={{ maxWidth: !!data.templateItem.parent.parent ? '30%' : '45%' }}>
                    {data.templateItem.title}
                  </Typography.Link>
                </Breadcrumb.Item>
              </Breadcrumb>
            </div>
          )}
        </div>
      }
      closeIcon={loading ? <SyncOutlined spin style={{ color: '#ff5333' }} /> : <CloseCircleOutlined />}
      bodyStyle={{ height: '100vh', paddingBottom: 0, paddingTop: 0 }}
      headerStyle={{ paddingBottom: 8, paddingTop: 8, borderBottom: 'none' }}
      onClose={closeDrawer}>
      <Tabs
        destroyInactiveTabPane={true}
        tabPosition="top"
        tabBarStyle={{ height: '30px', margin: 0 }}
        style={{ flexDirection: 'column', width: '100%', height: '100%' }}>
        <Tabs.TabPane tab="Template" key="templates_tab">
          <TemplateItemTab
            accountId={accountId}
            templateId={itemId}
            setSelected={(id) => history.push(templatesRouter(id))}
            displayOnly={onlyDisplayView}
          />
        </Tabs.TabPane>

        {!onlyDisplayView && (
          <Tabs.TabPane
            active={false}
            disabled
            tab={
              <Popconfirm
                overlayStyle={{ width: 300 }}
                title="Tem certeza que deseja excluír este template?"
                onConfirm={() => Delete()}
                okText="Sim"
                cancelText="Não">
                <Button style={{ marginLeft: 8, padding: 0 }} type="text">
                  <DeleteOutlined />
                </Button>
              </Popconfirm>
            }
          />
        )}
      </Tabs>
    </Drawer>
  );
};

export default TemplateItem;
