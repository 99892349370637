import React, { useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { Input, Typography, notification, Row, Col, Button } from 'antd';
import { useMutation } from '@apollo/client';

import Help from '@comp/Help';

import { route } from '@router';
import { create_link } from '@logic/mutations';
import errorMessage from '@logic/functions/errorHandeling';

import styles from './styles.module.less';

const NewWhatsappLink: React.FC = () => {
  const { id: paramId } = useParams<any>();
  const history = useHistory();

  const defaultValues: any = {
    account: paramId,
    subdomain: '',
    path: '',
    dest: '',
    title: '',
    body: '',
    ml_group_id: ''
  };

  const [link, setLink] = useState<any>(defaultValues);

  //----- Mutation ------------------------------------------------

  const [CreateLink] = useMutation(create_link);

  //--------------Save function ------------------------------------------

  async function doSave(
    subdomain: string,
    path: string,
    dest: string,
    ml_group_id: string,
    title?: string,
    body?: string,
  ) {
    try {
      const { data } = await CreateLink({
        variables: { type: 'whatsapp', account: paramId, subdomain, path, dest, title, body, ml_group_id },
      });
      history.push(route('id.home.links', { id: paramId }) + '#url_link/' + data.createLink?.id);
    } catch (err) {
      notification.open({ type: 'error', ...errorMessage('graph_err', err) });
    }
  }
  //------------- Form functions ------------------------------------------------

  const handleChange = ({ target: { name, value } }: any) => {
    setLink((t: any) => ({ ...t, [name]: value }));
  };

  //------------------------------------------------------------------------------

  return (
    <div className={styles.root} style={{ height: '100%' }}>
      <Row id="link_subdomain" className={styles.title}>
        <Col span={6} className={styles.column}>
          <Typography.Text className={styles.label}>Subdomínio: </Typography.Text>
          <Help
            title="Subdomínio"
            description="Subdomínio do link. Este é um campo obrigatório."
            text={
              <Typography.Text style={{ color: '#FFF' }}>
                https://<b className={styles.bold}>subdominio</b>.idaction.link/caminho
              </Typography.Text>
            }
            overlayStyle={{ minWidth: 320 }}
            placement="left"
            iconStyle={{ color: '#5c5c5c', fontSize: 12 }}
          />
        </Col>
        <Col span={18}>
          <Input
            name="subdomain"
            placeholder="Subdomínio do link"
            value={link.subdomain}
            onChange={handleChange}
            style={{
              border: undefined,
              width: '100%',
              borderRadius: 4,
              padding: '2px 4px',
              whiteSpace: 'break-spaces',
            }}
          />
        </Col>
      </Row>

      <Row id="link_path" className={styles.title}>
        <Col span={6} className={styles.column}>
          <Typography.Text className={styles.label}>Caminho: </Typography.Text>
          <Help
            title="Caminho"
            description="É um identificador que precisa ser único para o mesmo subdomínio. Este é um campo obrigatório."
            text={
              <Typography.Text style={{ color: '#FFF' }}>
                https://empresa.idaction.link/<b className={styles.bold}>caminho</b>
              </Typography.Text>
            }
            overlayStyle={{ minWidth: 320 }}
            placement="left"
            iconStyle={{ color: '#5c5c5c', fontSize: 12 }}
          />
        </Col>
        <Col span={18}>
          <Input
            name="path"
            placeholder="Caminho do link"
            value={link.path}
            onChange={handleChange}
            style={{
              border: undefined,
              width: '100%',
              borderRadius: 4,
              padding: '2px 4px',
              whiteSpace: 'break-spaces',
            }}
          />
        </Col>
      </Row>

      <Row id="link_dest" className={styles.title}>
        <Col span={6} className={styles.column}>
          <Typography.Text className={styles.label}>Destino: </Typography.Text>
          <Help
            title="Destino"
            description="É o site ou link que será redirecionado. Este é um campo obrigatório."
            text={
              <Typography.Text style={{ color: '#FFF' }}>
                <b className={styles.bold}>https://www.link-para-abrir.com.br</b>
              </Typography.Text>
            }
            overlayStyle={{ minWidth: 320 }}
            placement="left"
            iconStyle={{ color: '#5c5c5c', fontSize: 12 }}
          />
        </Col>
        <Col span={18}>
          <Input
            name="dest"
            placeholder="Destino do link"
            value={link.dest}
            onChange={handleChange}
            style={{
              border: undefined,
              width: '100%',
              borderRadius: 4,
              padding: '2px 4px',
              whiteSpace: 'break-spaces',
            }}
          />
        </Col>
      </Row>

      <Row id="link_title" className={styles.title}>
        <Col span={6} className={styles.column}>
          <Typography.Text className={styles.label}>Título: </Typography.Text>
          <Help
            title="Título"
            description="É o título da conversa de whatsapp."
            placement="left"
            iconStyle={{ color: '#5c5c5c', fontSize: 12 }}
          />
        </Col>
        <Col span={18}>
          <Input
            name="title"
            placeholder="Título do link"
            value={link.title}
            onChange={handleChange}
            style={{
              border: undefined,
              width: '100%',
              borderRadius: 4,
              padding: '2px 4px',
              whiteSpace: 'break-spaces',
            }}
          />
        </Col>
      </Row>

      <Row id="link_body" className={styles.title}>
        <Col span={6} className={styles.column}>
          <Typography.Text className={styles.label}>Descrição: </Typography.Text>
          <Help
            title="Descrição"
            description="É o texto da conversa de whatsapp."
            placement="left"
            iconStyle={{ color: '#5c5c5c', fontSize: 12 }}
          />
        </Col>
        <Col span={18}>
          <Input
            name="body"
            placeholder="Descrição do link"
            value={link.body}
            onChange={handleChange}
            style={{
              border: undefined,
              width: '100%',
              borderRadius: 4,
              padding: '2px 4px',
              whiteSpace: 'break-spaces',
            }}
          />
        </Col>
      </Row>

      <Row id="ml_group_id" className={styles.title}>
        <Col span={6} className={styles.column}>
          <Typography.Text className={styles.label}>Grupo MailerLite: </Typography.Text>
          <Help
            title="MailerLite"
            description="ID do grupo que o lead será criado dentro do MailerLite."
            placement="left"
            iconStyle={{ color: '#5c5c5c', fontSize: 12 }}
          />
        </Col>
        <Col span={18}>
          <Input
            name="ml_group_id"
            placeholder="ID do grupo do MailerLite"
            value={link.ml_group_id}
            onChange={handleChange}
            style={{
              border: undefined,
              width: '100%',
              borderRadius: 4,
              padding: '2px 4px',
              whiteSpace: 'break-spaces',
            }}
          />
        </Col>
      </Row>

      <Row gutter={24}>
        <Col span={6}>
          <Button
            id="link_save_button"
            type="primary"
            htmlType="submit"
            block
            disabled={!link.subdomain || !link.path || !link.dest || !link.title || !link.body || !link.ml_group_id}
            onClick={() => doSave(link.subdomain, link.path, link.dest, link.ml_group_id, link.title, link.body)}>
            Salvar
          </Button>
        </Col>
      </Row>
    </div>
  );
};

export default NewWhatsappLink;
