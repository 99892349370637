import React from 'react';
import { Card } from 'antd';
import { useHistory, useParams } from 'react-router-dom';

import LeadsList from './LeadsList';

import { route } from '@router';

import styles from './styles.module.less';

const Leads: React.FC = () => {
  const history = useHistory();
  const { id: accountId } = useParams<any>();

  const leadId = history.location.hash.split('/')[1];

  return (
    <>
      <Card className={styles.root}>
        <LeadsList
          leadId={leadId}
          accountId={accountId}
          setSelected={(id) => history.push(route('id.home.leads', { id: accountId }) + '#url_lead/' + id)}
        />
      </Card>
    </>
  );
};

export default Leads;
