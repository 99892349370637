import { AiFillQuestionCircle } from "react-icons/ai";
import { FaEnvelope, FaFacebook, FaGlobe, FaLinkedinIn, FaMoneyBill, FaPhone, FaUserCircle, FaWhatsapp } from "react-icons/fa";
import { FiUser, FiUserCheck, FiUserX } from "react-icons/fi";

const classifications: any = {
  none: {
    icon: <AiFillQuestionCircle size={16} />,
    name: 'Não contatado',
    color: '#EC4C72',
  },
  no_contact: {
    icon: <FiUserX size={16} />,
    name: 'Sem contato',
    color: '#d64848',
  },
  has_profile: {
    icon: <FiUserCheck size={16} />,
    name: 'Com perfil',
    color: '#0f81a3',
  },
  no_profile: {
    icon: <FiUser size={16} />,
    name: 'Sem perfil',
    color: '#ff6f1b',
  },
  closed: {
    icon: <FaMoneyBill size={16} />,
    name: 'Fechado',
    color: '#09b27f',
  },
};

const sources: any = {
  none: {
    icon: <FaUserCircle />,
    name: '',
    label: 'Qualquer'
  },
  facebook: {
    icon: <FaFacebook />,
    name: 'facebook',
    label: 'Facebook',
  },
  whatsapp: {
    icon: <FaWhatsapp />,
    name: 'whatsapp',
    label: 'Whatsapp'
  },
  website: {
    icon: <FaGlobe />,
    name: 'website',
    label: 'Website',
  },
  linkedin: {
    icon: <FaLinkedinIn />,
    name: 'linkedin',
    label: 'Linkedin',
  },
};

const fields = {
  whatsapp: {
    icon: <FaWhatsapp size={18} />,
    name: 'whatsapp',
    label: 'Whatsapp'
  },
  phone: {
    icon: <FaPhone size={16} />,
    name: 'phone',
    label: 'Telefone'
  },
  mail: {
    icon: <FaEnvelope size={16} />,
    name: 'mail',
    label: 'E-mail'
  },
};

export { classifications, sources, fields };