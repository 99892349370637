import React, { useCallback, useEffect, useState } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import { Input, notification, Typography } from 'antd';

import ErrorAndLoading from '@comp/ErrorAndLoading';

import errorMessage from '@logic/functions/errorHandeling';
import { lead_info, update_lead_notes, update_lead_classification } from '@logic/queries';

import LeadField from '../LeadField';

import styles from './styles.module.less';
import LeadClassification from '../LeadClassification';

interface Props {
  leadId: string;
  accountId: string;
}

const LeadInfo: React.FC<Props> = ({ leadId, accountId }) => {
  const [info, setInfo] = useState<any>();
  const [notes, setNotes] = useState<string>('');
  const [classification, setClassification] = useState<any>('');

  //---------------------- QUERIES -----------------------------

  const { data, loading, error } = useQuery(lead_info, { 
    fetchPolicy: 'network-only', 
    variables: { leadId, accountId } 
  });

  useEffect(() => {
    if (!data) return;
    setInfo(data);
  }, [data]);

  useEffect(() => {
    if (!data) return;
    setNotes(data.lead.notes ?? '');
  }, [data?.lead.notes]);

  useEffect(() => {
    if (!data) return;
    setClassification(data.lead.classification ?? 'none');
  }, [data?.lead.classification]);

  //---------------------- mutations ------------------------------
  const [updateNotes] = useMutation(update_lead_notes);
  const [updateClassifications] = useMutation(update_lead_classification);

  //----------------- Save, delete, edit functions -----------------------------

  const update = useCallback(
    async (key: string, value: any) => {
      try {
        if (key === 'notes') {
          return await updateNotes({ variables: { id: leadId, [key]: value } });
        }

        if (key === 'classification') {
          return await updateClassifications({ variables: { id: leadId, [key]: value || 'none' } });
        }
      } catch (err) {
        notification.open({ type: 'error', ...errorMessage('graph_err', err) });
      }
    },
    [updateNotes, updateClassifications, leadId],
  );

  //------------- Form functions ------------------------------------------------

  const handleBlur = () => {
    update('notes', notes);
  }; 

  const handleSelectClassification = useCallback((c: string) => {
    setClassification(c);
    update('classification', c);
  }, [update]);

  //------------------------------------------------------------------------------

  if (loading || error) return <ErrorAndLoading loading={loading} error={error} />;

  return (
    <>
      {!!info && (
        <div className={styles.root} style={{ height: '100%' }}>
          <div className={styles.title}>
            <div id="lead_body">
              {info.lead.emails.length > 0 && (
                <>
                  <Typography.Paragraph className={styles.leadtitle}>E-mail</Typography.Paragraph>
                  {info.lead.emails.map((email: string, i: number) => (
                    <LeadField type="mail" info={email} key={String(i)} />      
                  ))}
                </>
              )}

              {info.lead.phones.length > 0 && (
                <>
                  <Typography.Paragraph className={styles.leadtitle}>Telefone</Typography.Paragraph>
                  {info.lead.phones.map((phone: string, i: number) => (
                    <LeadField type="phone" info={phone} key={String(i)} />      
                  ))}
                </>
              )}

              {(info.account.mailerlite_fields || []).map((field: any) => {
                const found = (info.lead.fields || []).find((leadField: any) => leadField.key === field.key);
                if (!found) return null;

                return (
                  <>
                    <Typography.Paragraph className={styles.leadtitle}>{field.name}</Typography.Paragraph>
                    <LeadField type="custom" info={found.value} />
                  </>
                );
              })}
            </div>

            <div id="lead_note">
              <Typography.Paragraph className={styles.leadtitle}>Observações</Typography.Paragraph>
              <Input 
                type="text"
                value={notes}
                defaultValue={notes}
                onChange={(e) => setNotes(e.target.value)}
                onBlur={handleBlur}
                placeholder="Escreva aqui as informações desse Lead"
              />
            </div>
            
            <div id="lead_classification">
              <Typography.Paragraph className={styles.leadtitle}>Classificação do lead</Typography.Paragraph>
              <LeadClassification selected={classification} onSelect={handleSelectClassification} />
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default LeadInfo;
