import { observer } from 'mobx-react-lite';
import React from 'react';
import Tour from 'reactour';

import { useStore } from '@logic/context';
import { changeStep, disableScroll, enableScroll } from '@logic/functions/enableDisableScroll';

import styles from './styles.module.less';

interface Props {
  isNew: boolean;
}

const TutorialInfodrawer: React.FC<Props> = ({ isNew }) => {
  const store = useStore();
  const isTourOpen = !!store.tour && store.tour === '013_InfoDrawer' ? true : false;

  const steps: any[] = [
    {
      selector: '[id="lead_title"]',
      content: 'Aqui você consegue ver o nome da lead, via e quando ela foi cadastrada',
      style: { fontSize: 16, color: 'rgba(0, 0, 0, 0.85)' },
    },
    {
      selector: '[id="delete_info"]',
      content: 'Clique aqui para excluir a informação',
      style: { fontSize: 16, color: 'rgba(0, 0, 0, 0.85)' },
    },
    {
      selector: '[id="lead_body"]',
      content: 'Aqui você vê as informações da lead',
      style: { fontSize: 16, color: 'rgba(0, 0, 0, 0.85)' },
    },
    {
      selector: '[id="lead_note"]',
      content: 'Você pode editar as observações da lead nesse campo de texto',
      style: { fontSize: 16, color: 'rgba(0, 0, 0, 0.85)' },
    },
    {
      selector: '[id="lead_classification"]',
      content: 'Você pode editar a classificação da lead selecionando a opção em que ela se enquadra',
      style: { fontSize: 16, color: 'rgba(0, 0, 0, 0.85)' },
    }
  ].filter((step) => !!step);

  const ids: any[] = [
    'lead_title',
    'delete_info',
    'lead_body',
    'lead_note',
    'lead_classification'
  ].filter((id) => !!id);

  return (
    <Tour
      startAt={0}
      steps={steps}
      isOpen={isTourOpen}
      onRequestClose={() => store.setTour(undefined)}
      accentColor="#ff5333"
      maskSpace={4}
      maskClassName={styles.mask}
      rounded={4}
      getCurrentStep={(curr) => changeStep(curr, ids)}
      onAfterOpen={() => disableScroll('ant-tabs-content-holder', 'class_name')}
      onBeforeClose={() => enableScroll('ant-tabs-content-holder', 'class_name')}
    />
  );
};

export default observer(TutorialInfodrawer);
