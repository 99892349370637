import React from 'react';
import { Card } from 'antd';

import AvatarPicture from '@comp/AvatarPicture';
import AccountIndicators from './AccountIndicators';

import { EyeOutlined } from '@ant-design/icons';
import { RiExchangeDollarLine } from 'react-icons/ri';
import Tap from '../../../../img/Tap/index';

import styles from './styles.module.less';

interface Props {
  indicators: any;
  accountId: string;
  logoUrl: string;
  accountName: string;
  dataTut?: string;
}

const Account: React.FC<Props> = ({ indicators, accountId, logoUrl, accountName, dataTut }) => {
  return (
    <Card style={cardStyle} bodyStyle={cardBodyStyle} id={dataTut}>
      <div className={styles.avatar}>
        <AvatarPicture pictureLink={logoUrl} target="Account" size={36} name={accountName} />
      </div>

      <AccountIndicators
        end={false}
        value={indicators.impressions}
        key={accountId + 0}
        icon={<EyeOutlined />}
        fontSize={18}
        color="#55A630"
        title={`${indicators.impressions} impressões`}
      />

      <AccountIndicators
        end={false}
        value={indicators.clicks}
        key={accountId + 1}
        icon={<Tap style={{ fontSize: 22, color: '#ff8c00' }} />}
        color="#ff8c00"
        title={`${indicators.clicks} cliques`}
      />

      <AccountIndicators
        end={true}
        value={indicators.conversions}
        key={accountId + 3}
        icon={<RiExchangeDollarLine style={{ marginBottom: -2 }} />}
        color="#ff3d00"
        fontSize={24}
        title={`${indicators.conversions} conversões`}
      />
    </Card>
  );
};

export default Account;

const cardStyle: React.CSSProperties = {
  backgroundColor: '#ffffff',
  padding: 0,
  borderRadius: 4,
  boxShadow: '0 1px 4px #00000040',
  margin: 8,
  maxWidth: 650,
};
const cardBodyStyle: React.CSSProperties = {
  display: 'flex',
  padding: 10,
  maxWidth: 650,
  justifySelf: 'center',
};
