import React, { useEffect, useCallback, useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { Drawer, Radio } from 'antd';
import { useLazyQuery } from '@apollo/client';

import { FaWhatsapp } from 'react-icons/fa';
import { CloseCircleOutlined, LinkOutlined, SyncOutlined } from '@ant-design/icons';

import { route } from '@router';
import { link_info } from '@logic/queries';
import useCloseFn from '@logic/functions/closeDrawer';

import DefaultLink from './DefaultLink/index';
import WhatsappLink from './WhatsappLink/index';
import TutorialLink from './TutorialLink';

import ErrorAndLoading from '@comp/ErrorAndLoading';
import TutorialButton from '@comp/TutorialButton';

import styles from './styles.module.less';

interface Props {
  linkId: string;
}

const UrlRedirect: React.FC<Props> = ({ linkId }) => {
  const [type, setType] = useState<any>('default');
  const { id: paramAccountId } = useParams<any>();
  const history = useHistory();

  const accountId = paramAccountId;

  const newUrl = linkId === 'new';

  const [fetchLink, { data, loading, error, refetch }] = useLazyQuery(link_info, {
    fetchPolicy: 'cache-and-network',
  });

  const doCloseDrawer = useCallback(() => history.push(route('id.home.links', { id: accountId })), [
    accountId,
    history,
  ]);

  useEffect(() => {
    if (newUrl) return;

    fetchLink({ variables: { id: linkId } });
  }, [linkId, newUrl, fetchLink]);

  useEffect(() => {
    if (newUrl) return;
    if (!data) return;

    setType(data.link?.type);
  }, [data, newUrl]);

  const refetchLink = useCallback(() => {
    if (refetch) refetch();
  }, [refetch]);

  useCloseFn(doCloseDrawer, 'linkListItem');

  //------------------------------------------------------------------------------

  return (
    <Drawer
      visible
      forceRender
      destroyOnClose
      mask={false}
      width={'800px'}
      onClose={doCloseDrawer}
      closeIcon={loading ? <SyncOutlined spin style={{ color: '#ff5333' }} /> : <CloseCircleOutlined />}
      title={
        <>
          {error ? (
            <ErrorAndLoading borderless error={error} />
          ) : (
            <div style={{ display: 'flex', flexDirection: 'row' }}>
              {newUrl && (
                <div id="link_whatsapp_default" style={{ marginTop: 6 }}>
                  <Radio.Group value={type} onChange={({ target }) => setType(target.value)}>
                    <Radio.Button value="default">
                      <LinkOutlined style={{ marginRight: 4 }} />
                      Link Padrão
                    </Radio.Button>

                    <Radio.Button value="whatsapp">
                      <FaWhatsapp style={{ marginRight: 4 }} />
                      Whatsapp
                    </Radio.Button>
                  </Radio.Group>
                </div>
              )}

              {!newUrl && data && !!data.link && <p className={styles.account}>{data.link.account?.name}</p>}
            </div>
          )}
        </>
      }
      headerStyle={{ paddingBottom: 8, paddingTop: 8, borderBottom: 'none' }}
      bodyStyle={{ height: '100vh', paddingBottom: 0, paddingTop: 0 }}>
      {type === 'default' && (
        <DefaultLink account={data?.link?.account_id} link={data?.link} id={linkId} refetch={refetchLink} />
      )}

      {type === 'whatsapp' && (
        <WhatsappLink account={data?.link?.account_id} link={data?.link} id={linkId} refetch={refetchLink} />
      )}

      <TutorialButton
        tourId="015_Link"
        circleSize="small"
        iconSize={14}
        bottom={20}
        right={40}
        placement="left"
        title={newUrl ? 'Tutorial para criar links' : 'Tutorial para editar links'}
      />

      <TutorialLink isNew={newUrl} isWhatsApp={type === 'whatsapp' ? true : false} />
    </Drawer>
  );
};

export default UrlRedirect;
