import React, { useState, useRef, useCallback, useLayoutEffect, useMemo } from 'react';
import { Input, Typography } from 'antd';
import Linkify from 'react-linkify';
import { SmileOutlined } from '@ant-design/icons';

import { Picker } from 'emoji-mart';
import 'emoji-mart/css/emoji-mart.css';

interface Props {
  emojiPicker: 'on' | 'off' | 'focus'; // tells if the emoji button will be showing, not showing or showed when the input is on focus
  value?: string;
  id?: string;
  name?: string;
  className?: string;
  multiline?: boolean;
  border?: boolean; // bordered from antd input
  placeholder?: string;
  onChange?: (value: any) => void; // Needs {target: {name: [input_name], value: [input_value], id?: [input_id]}}
  onBlur?: (e: any) => void; // Needs {target: {name: [input_name], value: [input_value]}}
  extraProps?: any; // to pass extra input properties
  disabled?: boolean;
  viewOnly?: boolean;
}

const EmojiLinkInput: React.FC<Props> = ({
  multiline,
  emojiPicker,
  value,
  id,
  name,
  className,
  placeholder,
  onChange,
  onBlur,
  border,
  extraProps,
  disabled,
  viewOnly,
}) => {
  const ref = useRef<any>();

  const [focus, setFocus] = useState(false);
  const [picker, setPicker] = useState(false);
  const [sel, setSel] = useState(0);

  const handleChange = useCallback(
    ({ target }) => (onChange ? onChange({ target: { value: target.value, name, id } }) : {}),
    [onChange, name, id],
  );

  const handleEmojiSelect = useCallback(
    ({ native }) => {
      const currRef = ref.current;
      if (!currRef) return;

      const el = multiline ? currRef.resizableTextArea.textArea : currRef.input;
      const selection: number = el.selectionStart;
      setSel(selection + native.length);

      const newValue = el.value.substring(0, selection) + native + el.value.substring(selection, el.value.length);

      handleChange({ target: { value: newValue } });
    },
    [multiline, handleChange],
  );

  useLayoutEffect(() => {
    if (!focus || viewOnly) return;
    const currRef = ref.current;
    if (!currRef) return;

    const el = multiline ? currRef.resizableTextArea.textArea : currRef.input;
    el.setSelectionRange(sel, sel);
    el.focus();
  }, [sel, multiline, focus, viewOnly]);

  const on = emojiPicker === 'on';
  const off = emojiPicker === 'off';

  const comp = useMemo(
    () =>
      !focus ? null : multiline ? (
        <Input.TextArea
          {...extraProps}
          ref={ref}
          name={name}
          disabled={disabled}
          placeholder={placeholder}
          className={className}
          onFocus={() => setFocus(true)}
          style={{
            border: border || on || focus || picker ? undefined : 0,
            width: '100%',
            borderRadius: 4,
            padding: '2px 4px',
            whiteSpace: 'break-spaces',
            cursor: 'text',
          }}
          onChange={handleChange}
          // onBlur={onBlur}
          //onCopy={(event) => console.log(event)}
          value={!value ? undefined : value || ''}
          autoSize={{ minRows: 1, maxRows: 100 }}
        />
      ) : (
        <Input
          {...extraProps}
          ref={ref}
          name={name}
          disabled={disabled}
          placeholder={placeholder}
          className={className}
          onFocus={() => setFocus(true)}
          style={{
            border: on || focus || picker ? undefined : 0,
            width: '100%',
            borderRadius: 4,
            cursor: 'text',
          }}
          onChange={handleChange}
          // onBlur={onBlur}
          value={!value ? undefined : value || ''}
        />
      ),
    [extraProps, disabled, border, className, focus, name, handleChange, multiline, on, picker, placeholder, value],
  );

  const textComp = useMemo(
    () => (
      <div
        className={className}
        style={{
          minHeight: '30px',
          width: '100%',
          border: border ? '1px solid #c7c7c7' : 0,
          borderRadius: 4,
          padding: '0 6px',
          alignItems: 'center',
          whiteSpace: 'break-spaces',
          display: 'flex',
        }}
        onClick={() => setFocus(true)}>
        <Typography.Text
          disabled={!!placeholder && !value}
          style={{ margin: 0, padding: 0, width: '100%', cursor: 'text' }}>
          <Linkify
            ref={ref}
            componentDecorator={(decoratedHref: string, decoratedText: string, key: number) => (
              <a
                key={key}
                href={decoratedHref}
                style={{ textDecoration: 'underline' }}
                onClick={(e) => e.stopPropagation()}
                target="_blank"
                rel="noopener noreferrer">
                {decoratedText}
              </a>
            )}>
            {!value ? placeholder : value || ''}
          </Linkify>
        </Typography.Text>
      </div>
    ),
    [value, className, border, placeholder],
  );

  return (
    <div
      onBlur={(e) => {
        if (!picker && onBlur) onBlur({ target: { name, value } });
      }}>
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        {focus && !viewOnly ? comp : textComp}

        {!off && (focus || on) && (
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              marginTop: '6px',
              alignItems: 'center',
              justifyContent: 'flex-start',
            }}>
            <SmileOutlined
              style={{
                cursor: 'pointer',
                fontSize: '18px',
                marginRight: '8px',
              }}
              onClick={() => setPicker(!picker)}
            />

            {/*<LinkOutlined
              style={{
                cursor: 'pointer',
                fontSize: '18px',
                marginRight: '8px',
              }}
            />*/}
          </div>
        )}
      </div>

      {picker && (
        <Picker
          perLine={9}
          defaultSkin={undefined}
          showPreview={false}
          showSkinTones={false}
          onSelect={handleEmojiSelect}
        />
      )}
    </div>
  );
};

export default EmojiLinkInput;
