import React, { useState, useEffect, ReactElement } from 'react';
import { useQuery } from '@apollo/client';
import { DocumentNode } from 'graphql';
import { useTranslation } from 'react-i18next';

import { Card, Button, Row, Col, notification, Typography, List, Select } from 'antd';

import Help from '@comp/Help';

import styles from './styles.module.less';

interface Props {
  title: string; // Título do card
  button: string; // Texto do botão: adicionar usuários/células/clientes
  buttonId?: string;
  helpTitle: string; // (?) texto de ajuda
  helpDescription: string; // (?) texto de ajuda
  query: DocumentNode; // query dos itens do select
  idList: string[]; // lista de ids dos items da lista, que já estão adicionados e não apareceram no select
  items: any[]; // itens da lista
  params?: any; // parâmetros da query do select
  renderItem: (item: any, index: number) => ReactElement | null; // Renderizar os itens da lista
  AttachAtoB: (a?: string, b?: string) => void;
  extractData: (data: any) => any[]; // Extrair os dados do select
}

// Utilizado nas listas de células, usuários e clientes na área de administrador
// Recebe uma lista de usuários, células ou clientes
// Recebe uma query com um extractData para os usuários que serão exibidos no select
// AttachAtoB => A: usuário/célula/cliente a ser adicionado, B: usuário/célula/cliente a receber o "A"

const ListWithAddTo: React.FC<Props> = ({
  title,
  button,
  buttonId,
  helpDescription,
  helpTitle,
  query,
  idList,
  items,
  params,
  renderItem,
  AttachAtoB,
  extractData,
}) => {
  const [addAToB, setAddAToB] = useState(false);
  const [selectList, setSelectList] = useState<any[]>();

  const { t } = useTranslation('listWithAddTo');

  //---------------------- QUERIES & MUTATIONS -----------------------------

  const { data: selectData, loading: selectLoading } = useQuery(query, params);

  //--------- cria o array para o select de adicionar usuários -------------
  useEffect(() => {
    if (!selectData) return;

    const selectListInitial = extractData(selectData).map(({ id, name }: { id: string; name: string }) => {
      if (idList.indexOf(id) === -1) return { value: id, label: name };
      return null;
    });

    setSelectList(selectListInitial.filter((e) => e !== null));
  }, [selectData, idList, extractData]);

  return (
    <>
      <Card className={styles.container} style={{ marginBottom: '16px' }}>
        <div className={styles.header}>
          <div style={{ display: 'inline-flex', alignItems: 'baseline' }}>
            <Typography.Title style={{ fontSize: '20px', marginRight: 4 }}>{title}</Typography.Title>
            <Help title={helpTitle} description={helpDescription} iconStyle={{ color: '#5c5c5c', fontSize: 12 }} />
          </div>
          {selectList && selectList.length > 0 && (
            <Button id={buttonId} disabled={addAToB} onClick={() => setAddAToB(true)}>
              {button}
            </Button>
          )}
        </div>

        {addAToB && (
          <div>
            <Row gutter={24}>
              <Col span={12}>
                <Select
                  value=""
                  style={{ width: '100%' }}
                  loading={selectLoading}
                  onSelect={(value) => {
                    AttachAtoB(value.toString(), undefined);
                    notification.success({ message: t('finish_message') });
                  }}
                  options={selectList}
                />
              </Col>
            </Row>
          </div>
        )}

        {items && items.length > 0 && (
          <List size="small" bordered style={{ marginTop: '16px' }} split>
            {items.map((items: any, idx: number) => {
              return renderItem(items, idx);
            })}
          </List>
        )}
      </Card>
    </>
  );
};

export default ListWithAddTo;
