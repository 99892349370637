import React from 'react';
import { useHistory } from 'react-router-dom';

interface Props {
  to: string;
  params?: any;
  onClick?: () => void;
}

const Navigate: React.FC<Props> = ({ to, params, onClick, children }) => {
  const history = useHistory();

  function handleClick() {
    history.push(to, params);
    if (onClick) onClick();
  }

  return React.cloneElement(children as any, { onClick: handleClick });
};

export default Navigate;
