import { useStore } from '@logic/context';

interface Config {
  [key: string]: unknown;
  setConfig: (key: string, value: unknown) => void;
}

export default function useConfig(): Config {
  const store = useStore();

  // State for holding config, with starting ones as undefined
  const { config: cfg } = store;

  return {
    ...cfg,
    setConfig: (key: string, value: unknown) => store.updateConfig({ [key]: value }),
  };
}
