import { Button, Modal, notification, Popover } from 'antd';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { DeleteOutlined, MoreOutlined, SwapOutlined } from '@ant-design/icons';
import { useMutation } from '@apollo/client';
import errorMessage from '@logic/functions/errorHandeling';
import { convert_task, delete_task } from '@logic/mutations';

interface Props {
  taskId: string;
  type: 'default' | 'assignment';
  refresh: () => void;
  index?: number;
}

// Mudar o tipo da atividade (pontual/padrão) e excluir a atividade

const ActivityActions: React.FC<Props> = ({ taskId, refresh, type, index }) => {
  const { t } = useTranslation('activityActions');

  //--------------------------------Mutations----------------------------------------

  const [DeleteTask] = useMutation(delete_task, { variables: { id: taskId } });

  const [ConvertTask] = useMutation(convert_task, {
    variables: { id: taskId, type: type === 'default' ? 'assignment' : 'default' },
  });

  //---------------------------------------------------------------------------------

  const handleDelete = useCallback(() => {
    Modal.confirm({
      title: t('delete_title'),
      icon: <DeleteOutlined />,
      content: (
        <div>
          <p>{t('delete_question')}</p>
          <p>{t('delete_explain')}</p>
        </div>
      ),
      onOk: async () => {
        try {
          await DeleteTask();

          notification.open({
            placement: 'bottomRight',
            duration: 2,
            type: 'success',
            message: t('message_delete'),
          });

          refresh();
        } catch (err) {
          notification.open({ type: 'error', ...errorMessage('graph_err', err) });
        }
      },
      okType: 'danger',
    });
  }, [DeleteTask, refresh, t]);

  const handleConvert = useCallback(() => {
    Modal.confirm({
      title: t('convert_title'),
      icon: <SwapOutlined />,
      content: (
        <div>
          <p>{t('convert_question')}</p>
          {type === 'assignment' ? <p>{t('assignment_explain')}</p> : <p>{t('default_explain')}</p>}
        </div>
      ),
      onOk: async () => {
        try {
          await ConvertTask();

          notification.open({
            placement: 'topRight',
            duration: 2,
            type: 'success',
            message: t('message_convert'),
          });

          refresh();
        } catch (err) {
          notification.open({
            type: 'error',
            ...errorMessage('graph_err', err),
          });
        }
      },
      okType: 'danger',
    });
  }, [ConvertTask, refresh, type, t]);

  const convertMenu = (
    <div style={{ display: 'flex', flexDirection: 'row', margin: '-6px -10px' }}>
      <Button
        type="default"
        size="small"
        title={t('button_convert') + ` ${type === 'default' ? t('type_assignment') : t('type_default')}`}
        icon={<SwapOutlined />}
        onClick={handleConvert}
      />
      <Button
        type="primary"
        size="small"
        title={t('button_delete')}
        style={{ marginLeft: 4 }}
        icon={<DeleteOutlined />}
        onClick={handleDelete}
      />
    </div>
  );

  const stopPropagation = (e: any) => e.stopPropagation();

  return (
    <div
      id={index === 0 ? `change_item_options_${index}` : undefined}
      style={{ marginRight: 8, cursor: 'pointer' }}
      onClick={stopPropagation}>
      <Popover destroyTooltipOnHide={{ keepParent: false }} placement="right" trigger={['click']} content={convertMenu}>
        <MoreOutlined />
      </Popover>
    </div>
  );
};

export default ActivityActions;
