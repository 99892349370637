import React, { useMemo, useState } from 'react';
import { gql, useMutation } from '@apollo/client';
import { observer } from 'mobx-react-lite';
import { Card, Button, Rate, notification, Divider, Typography } from 'antd';

import { GoThumbsdown, GoThumbsup } from 'react-icons/go';

import EmojiLinkInput from '@comp/EmojiLinkInput';
import Upload from '@comp/Upload';
import Help from '@comp/Help';

import { useStore } from '@logic/context';
import errorMessage from '@logic/functions/errorHandeling';
import OnPasteDiv from '@comp/OnPasteDiv';
import { UploadItem } from '@logic/stores/uploadManager';

import styles from './styles.module.less';

interface Props {
  taskId: string;
  refetch: () => void;
}

const TaskFeedback: React.FC<Props> = ({ taskId, refetch }) => {
  const { profile, uploadManager } = useStore();
  const [{ feedback, nps }, setTaskFeedback] = useState({ feedback: '', nps: 0 });

  const [ApproveTask, { loading: approving }] = useMutation(approve_task);
  const [RejectTask, { loading: rejecting }] = useMutation(reject_task);

  async function handleApprove() {
    try {
      await ApproveTask({
        variables: {
          id: taskId,
          feedback,
          nps,
          files: uploadManager.temporaryFiles
            .filter((file) => file.parentId === taskId)
            .map((file) => ({ key: file.fileKey, name: file.file.name })),
        },
      }).then(() => {
        uploadManager.removeTemporaryFiles(taskId);
        refetch();
        setTaskFeedback({ feedback: '', nps: 0 });
      });
    } catch (err) {
      notification.open({ type: 'error', ...errorMessage('graph_err', err) });
    }
  }

  async function handleReject() {
    if (!feedback) {
      notification.open({
        type: 'error',
        message: 'Feedback',
        description: 'Não é possível rejeitar uma atividade sem um feedback',
      });
      return;
    }
    try {
      await RejectTask({
        variables: {
          id: taskId,
          feedback,
          nps,
          files: uploadManager.temporaryFiles
            .filter((file) => file.parentId === taskId)
            .map((file) => ({ key: file.fileKey, name: file.file.name })),
        },
      }).then(() => {
        uploadManager.removeTemporaryFiles(taskId);
        refetch();
        setTaskFeedback({ feedback: '', nps: 0 });
      });
    } catch (err) {
      notification.open({ type: 'error', ...errorMessage('graph_err', err) });
    }
  }

  const roles = useMemo(() => profile?.roles, [profile]);

  // Apenas Sucesso de cliente e admins podem criar feedbacks
  if (roles && (roles?.includes('customer_success') || roles?.includes('admin'))) {
    return (
      <div id="write_feedback_task">
        <Divider />
        <div className={styles.title}>
          <Typography.Text className={styles.feedbacksDivisor}>Feedback de revisão</Typography.Text>
          <Help
            title="Feedback de revisão"
            description="É uma resposta sua ou do cliente a atividade que já foi executada"
            iconStyle={{ color: '#ff5333', fontSize: 12 }}
            placement="right"
          />
        </div>
        <div style={{ height: 16 }} />

        <OnPasteDiv
          onPaste={(data) => {
            for (const file of data) {
              const uploadItem: UploadItem = { parentId: taskId, targetType: 'Feedback', file };
              uploadManager.uploadFile(uploadItem);
            }
          }}>
          <Card style={{ marginBottom: '16px', borderColor: '#ff5333' }} bodyStyle={cardBodyStyle}>
            <div style={{ width: '100%' }}>
              <EmojiLinkInput
                border
                multiline
                emojiPicker="focus"
                placeholder="Escreva seu feedback aqui"
                value={feedback}
                disabled={approving || rejecting}
                onChange={(value) => setTaskFeedback({ feedback: value.target.value, nps })}
              />
            </div>

            <div style={{ width: '100%', marginTop: 12 }}>
              <Upload parentId={taskId} targetType="Feedback" loading={approving || rejecting} />
            </div>

            <div style={{ width: '100%', display: 'flex', justifyContent: 'space-between' }}>
              <div>
                Avalie esta atividade:&nbsp;&nbsp;
                <Rate
                  disabled={approving || rejecting}
                  value={nps}
                  onChange={(value) => setTaskFeedback({ feedback, nps: value })}
                />
              </div>

              <div>
                <Button loading={approving || rejecting} onClick={handleApprove} style={{ marginLeft: 6 }}>
                  Aprovar
                  <GoThumbsup style={{ marginLeft: 6, fontSize: 14 }} />
                </Button>

                <Button
                  loading={approving || rejecting}
                  onClick={handleReject}
                  disabled={!feedback}
                  style={{ marginLeft: 6 }}>
                  Rejeitar
                  <GoThumbsdown style={{ marginLeft: 6, fontSize: 14 }} />
                </Button>
              </div>
            </div>
          </Card>
        </OnPasteDiv>
      </div>
    );
  }

  return null;
};

export default observer(TaskFeedback);

const cardBodyStyle: React.CSSProperties = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  padding: '12px',
};

const approve_task = gql`
  mutation ApproveTask($id: ID!, $feedback: String, $nps: Int, $files: [AttachFile!]) {
    approveTask(id: $id, feedback: $feedback, nps: $nps, files: $files) {
      id
      title
      finish_date
      ... on DefaultTask {
        execution_date
        feedbacks(pagination: { limit: -1, page: 1 }) {
          count
          data {
            id
            feedback
            nps
            status
            created_at
            user {
              id
              name
              avatarUrl
            }
          }
        }
      }
    }
  }
`;

const reject_task = gql`
  mutation RejectTask($id: ID!, $feedback: String!, $nps: Int, $files: [AttachFile!]) {
    rejectTask(id: $id, feedback: $feedback, nps: $nps, files: $files) {
      id
      title
      finish_date
      ... on DefaultTask {
        execution_date
        feedbacks(pagination: { limit: -1, page: 1 }) {
          count
          data {
            id
            feedback
            nps
            status
            created_at
            user {
              id
              name
              avatarUrl
            }
          }
        }
      }
    }
  }
`;
