import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Card, Empty, List, Popconfirm, Tag, Typography } from 'antd';
import { gql, useMutation, useQuery } from '@apollo/client';

import { GoogleOutlined, SyncOutlined, DeleteOutlined } from '@ant-design/icons';

import ErrorAndLoading from '@comp/ErrorAndLoading';
import AvatarPicture from '@comp/AvatarPicture';

import { useStore } from '@logic/context';

import styles from './styles.module.less';

const GoogleAnalyticsAccountsList: React.FC = () => {
  const store = useStore();
  const { t } = useTranslation('googleAccounts');

  const { data, loading, error } = useQuery(realm_google_accounts);

  const [LogoutGoogle] = useMutation(logout_google);
  const [LoginGoogle] = useMutation(login_google);
  const handleLoginGoogle = () => {
    try {
      LoginGoogle().then((res) => window.location.replace(res?.data?.addGoogleAccount));
    } catch (err) {
      console.error(err);
    }
  };

  const accounts = data?.me?.realm?.google_accounts?.data;

  useEffect(() => {
    if (!accounts || accounts.length === 0) store.setHasGoogleAccounts(false);
    if (!!accounts && accounts.length > 0) store.setHasGoogleAccounts(true);
  }, [accounts, store]);

  return (
    <Card className={styles.container} style={{ marginTop: 16 }}>
      <div className={styles.header}>
        <div style={{ display: 'inline-flex', alignItems: 'baseline' }}>
          <Typography.Title style={{ fontSize: '20px', marginRight: 4 }}>
            <GoogleOutlined size={20} style={{ marginRight: 6 }} />
            {t('title')}
          </Typography.Title>
        </div>

        <Button
          id="add_google_account"
          title={t('add_button_tooltip')}
          icon={<GoogleOutlined />}
          className={styles.button_google}
          onClick={handleLoginGoogle}>
          {t('add_button')}
        </Button>
      </div>

      {error && <ErrorAndLoading error={error} />}

      <List size="small" loading={loading} bordered style={{ marginTop: '16px' }} split>
        {(!accounts || accounts.length === 0) && (
          <Empty description={t('empty')} image={Empty.PRESENTED_IMAGE_SIMPLE} />
        )}

        {!!accounts &&
          accounts.length > 0 &&
          accounts.map(({ name, email, avatar, has_problem, id, accounts: clients }: any, idx: number) => {
            return (
              <List.Item
                id={`reconnect_google_account_${idx}`}
                key={`accounts_list_${id}_${idx}`}
                extra={
                  <>
                    {has_problem && (
                      <Button
                        type="primary"
                        icon={<SyncOutlined />}
                        style={{ marginRight: 6 }}
                        onClick={handleLoginGoogle}>
                        {t('reconect_button')}
                      </Button>
                    )}
                    <Popconfirm
                      title={
                        clients.total === 1
                          ? t('remove_initial_phrase') + ` ${clients.total} ` + t('remove_end_singular')
                          : clients.total > 1
                          ? t('remove_initial_phrase') + ` ${clients.total} ` + t('remove_end_plural')
                          : t('remove')
                      }
                      cancelText={t('cancel_text')}
                      okText={t('ok_text')}
                      placement="leftTop"
                      onConfirm={() => LogoutGoogle({ variables: { id } })}>
                      <Button id={`remove_google_account_${idx}`} type="primary" danger icon={<DeleteOutlined />}>
                        {t('remove_button')}
                      </Button>
                    </Popconfirm>
                  </>
                }>
                <List.Item.Meta
                  avatar={<AvatarPicture pictureLink={avatar} size={44} target="User" name={name} />}
                  title={
                    <>
                      {name}
                      {clients.total > 0 && (
                        <Tag
                          title={`${clients.total} ${
                            clients.total > 1 ? t('accounts_connected') : t('account_connected')
                          } a ${name}`}
                          color="blue"
                          style={{
                            margin: '0 0 0 8px',
                            borderRadius: 4,
                            fontSize: 9,
                            fontWeight: 700,
                            padding: '4px 6px',
                            lineHeight: '7px',
                            cursor: 'pointer',
                          }}>
                          {clients.total}
                          {clients.total > 1 ? t('accounts') : t('account')}
                        </Tag>
                      )}
                      {has_problem && (
                        <Tag
                          color="red"
                          style={{
                            margin: '0 0 0 8px',
                            borderRadius: 4,
                            fontSize: 9,
                            fontWeight: 700,
                            padding: '4px 6px',
                            lineHeight: '7px',
                            cursor: 'pointer',
                          }}>
                          {t('problem_tag')}
                        </Tag>
                      )}
                    </>
                  }
                  description={email}
                />
              </List.Item>
            );
          })}
      </List>
    </Card>
  );
};

export default GoogleAnalyticsAccountsList;

export const realm_google_accounts = gql`
  query RealmGoogleAccounts {
    me {
      realm {
        google_accounts(pagination: { limit: -1 }) {
          data {
            id
            name
            email
            avatar
            has_problem
            accounts {
              total
            }
          }
        }
      }
    }
  }
`;

const login_google = gql`
  mutation LoginGoogle {
    addGoogleAccount
  }
`;

const logout_google = gql`
  mutation LogoutGoogle($id: ID!) {
    removeGoogleAccount(id: $id)
  }
`;
