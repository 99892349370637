import React from 'react';
import { Form, Input, Card, Button, Typography, notification } from 'antd';

import idLogo from '../../img/id_lab_logo_vertical.png';
import Navigate from '@comp/Navigate';
import errorMessage from '@logic/functions/errorHandeling';

import styles from './styles.module.less';

const RecoverPassword: React.FC = () => {
  const onFinish = (values: any) => {
    console.log('Success:', values);
  };

  const onFinishFailed = (errorInfo: any) => {
    notification.open({ type: 'error', ...errorMessage('form_validation', errorInfo) });
  };

  return (
    <div className={styles.root}>
      <Card hoverable={false} className={styles.card} bodyStyle={{ flex: 1, width: '100%', padding: '8px 32px' }}>
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <img src={idLogo} alt="id escrito em forma de uma seta" />
        </div>
        <div style={{ display: 'flex', justifyContent: 'center', textAlign: 'center' }}>
          <Typography.Text>Insira seu endereço de email para enviarmos o link de recuperação de senha</Typography.Text>
        </div>
        <Form
          name="password_recover"
          layout="vertical"
          initialValues={{ remember: true }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          wrapperCol={{ span: 24 }}>
          <Form.Item
            label="Email"
            name="email"
            rules={[
              { required: true, message: 'Você precisa inserir seu email' },
              { type: 'email', message: 'O email não é valido' },
            ]}>
            <Input />
          </Form.Item>

          <Form.Item>
            <Button type="primary" htmlType="submit" block loading={false}>
              Enviar
            </Button>
          </Form.Item>
        </Form>

        <div style={{ display: 'flex', justifyContent: 'flex-start' }}>
          <Navigate to="/">
            <Button type="link" style={{ padding: 0 }}>
              Voltar para o login
            </Button>
          </Navigate>
        </div>
      </Card>
    </div>
  );
};

export default RecoverPassword;
