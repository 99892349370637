import { Carousel, Typography } from 'antd';
import { CarouselProps } from 'antd/lib/carousel';
import React, { useEffect, useMemo, useState } from 'react';
import { gql, useQuery } from '@apollo/client';

import Help from '@comp/Help';
import ErrorAndLoading from '@comp/ErrorAndLoading';
import useWindowSize from '@logic/functions/windowSize';
import useConfig from '@logic/config';

import Account from './Account';

import styles from './styles.module.less';

const Accounts: React.FC = () => {
  const { width } = useWindowSize();
  const config = useConfig();

  const base = config.collapsed_menu ? 3 : 2;

  const [accountsData, setAccountsData] = useState<any[]>();

  //----------------------------- QUERY -------------------------------

  const { data, loading, error } = useQuery(accounts_indicators, { fetchPolicy: 'cache-and-network' });

  //----------------------------------------------------------------------

  useEffect(() => {
    if (!data) return;

    setAccountsData(data.me?.accounts?.data);
  }, [data]);

  // Base de configurações do carroussel
  const configSettingsBase = {
    rows: 3,
    slidesToShow: base + 1,
    slidesToScroll: 1,
    speed: 500,
    dots: true,
    className: styles.carousel,
    infinite: false,
  };

  const settings: CarouselProps = {
    ...configSettingsBase,
    responsive: [
      {
        breakpoint: 1680,
        settings: {
          ...configSettingsBase,
          slidesToShow: base,
        },
      },
      {
        breakpoint: 1600,
        settings: {
          ...configSettingsBase,
          slidesToShow: base + 1,
        },
      },
      {
        breakpoint: 1200,
        settings: {
          ...configSettingsBase,
          slidesToShow: base,
        },
      },
      {
        breakpoint: 920,
        settings: {
          ...configSettingsBase,
          slidesToShow: base - 1,
        },
      },
    ],
  };

  const accountsIndicators = useMemo(() => {
    if (!accountsData || accountsData === undefined) return <></>;
    return accountsData?.map((item: any, index: number) => {
      return (
        <Account
          key={'account'}
          accountId={item.id}
          indicators={item.summary}
          logoUrl={item.logoUrl}
          accountName={item.name}
          dataTut={(width <= 1100 && index === 3) || (width > 1100 && index === 0) ? 'account_indicators' : undefined}
        />
      );
    });
  }, [accountsData, width]);

  if (loading) return <ErrorAndLoading loading={loading} borderless cardStyle={errorCardStyle} />;
  if (error) return <ErrorAndLoading error={error} />;

  return (
    <div id="accounts">
      <Typography.Text className={styles.accounts}>Clientes</Typography.Text>

      <Help
        title="Clientes"
        description="Veja todos os seus clientes e seus indicadores"
        iconStyle={{ color: '#5c5c5c' }}
      />

      {!!accountsData && <Carousel {...settings}>{accountsIndicators}</Carousel>}
    </div>
  );
};

export default Accounts;

const errorCardStyle = { minHeight: 210 };

const accounts_indicators = gql`
  query AccountsInfo {
    me {
      id
      accounts(status: active) {
        data {
          id
          name
          logoUrl
          summary {
            impressions
            clicks
            conversions
          }
        }
      }
    }
  }
`;
