import React, { useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { Typography, notification, Row, Col, Button, Input } from 'antd';
import { useMutation } from '@apollo/client';

import Help from '@comp/Help';

import { route } from '@router';
import { create_link } from '@logic/mutations';
import errorMessage from '@logic/functions/errorHandeling';

import styles from './styles.module.less';

const NewDefaultLink: React.FC = () => {
  const { id: paramId } = useParams<any>();
  const history = useHistory();

  const defaultValues: any = { account: paramId, subdomain: '', path: '', dest: '' };

  const [link, setLink] = useState<any>(defaultValues);

  //----- Mutation ------------------------------------------------

  const [CreateLink] = useMutation(create_link);

  //--------------Save function ------------------------------------------

  async function doSave(subdomain: string, path: string, dest: string) {
    try {
      const { data } = await CreateLink({ variables: { type: 'default', account: paramId, subdomain, path, dest } });

      history.push(route('id.home.links', { id: paramId }) + '#url_link/' + data.createLink?.id);
    } catch (err) {
      notification.open({ type: 'error', ...errorMessage('graph_err', err) });
    }
  }
  //------------- Form functions ------------------------------------------------

  const handleChange = ({ target: { name, value } }: any) => {
    setLink((t: any) => ({ ...t, [name]: value }));
  };

  //------------------------------------------------------------------------------

  return (
    <div className={styles.root} style={{ height: '100%' }}>
      <Row id="link_subdomain" className={styles.title}>
        <Col span={4} className={styles.column}>
          <Typography.Text className={styles.label}>Subdomínio: </Typography.Text>
          <Help
            title="Subdomínio"
            description="Subdomínio do link. Este é um campo obrigatório."
            text={
              <Typography.Text style={{ color: '#FFF' }}>
                https://<b className={styles.bold}>subdominio</b>.idaction.link/caminho
              </Typography.Text>
            }
            overlayStyle={{ minWidth: 320 }}
            placement="left"
            iconStyle={{ color: '#5c5c5c', fontSize: 12 }}
          />
        </Col>
        <Col span={20}>
          <Input
            name="subdomain"
            placeholder="Subdomínio do link"
            value={link.subdomain}
            onChange={handleChange}
            style={{
              border: undefined,
              width: '100%',
              borderRadius: 4,
              padding: '2px 4px',
              whiteSpace: 'break-spaces',
            }}
          />
        </Col>
      </Row>

      <Row id="link_path" className={styles.title}>
        <Col span={4} className={styles.column}>
          <Typography.Text className={styles.label}>Caminho: </Typography.Text>
          <Help
            title="Caminho"
            description="É um identificador que precisa ser único para o mesmo subdomínio. Este é um campo obrigatório."
            text={
              <Typography.Text style={{ color: '#FFF' }}>
                https://empresa.idaction.link/<b className={styles.bold}>caminho</b>
              </Typography.Text>
            }
            overlayStyle={{ minWidth: 320 }}
            placement="left"
            iconStyle={{ color: '#5c5c5c', fontSize: 12 }}
          />
        </Col>
        <Col span={20}>
          <Input
            name="path"
            placeholder="Caminho do link"
            value={link.path}
            onChange={handleChange}
            style={{
              border: undefined,
              width: '100%',
              borderRadius: 4,
              padding: '2px 4px',
              whiteSpace: 'break-spaces',
            }}
          />
        </Col>
      </Row>

      <Row id="link_dest" className={styles.title}>
        <Col span={4} className={styles.column}>
          <Typography.Text className={styles.label}>Destino: </Typography.Text>
          <Help
            title="Destino"
            description="É o site ou link que será redirecionado. Este é um campo obrigatório."
            text={
              <Typography.Text style={{ color: '#FFF' }}>
                <b className={styles.bold}>https://www.link-para-abrir.com.br</b>
              </Typography.Text>
            }
            overlayStyle={{ minWidth: 320 }}
            placement="left"
            iconStyle={{ color: '#5c5c5c', fontSize: 12 }}
          />
        </Col>
        <Col span={20}>
          <Input
            name="dest"
            placeholder="Destino do link"
            style={{
              border: undefined,
              width: '100%',
              borderRadius: 4,
              padding: '2px 4px',
              whiteSpace: 'break-spaces',
            }}
            value={link.dest}
            onChange={handleChange}
          />
        </Col>
      </Row>

      <Row gutter={24}>
        <Col span={6}>
          <Button
            id="link_save_button"
            type="primary"
            disabled={!link.subdomain || !link.path || !link.dest}
            htmlType="submit"
            block
            onClick={() => doSave(link.subdomain, link.path, link.dest)}>
            Salvar
          </Button>
        </Col>
      </Row>
    </div>
  );
};

export default NewDefaultLink;
