import React, { useState, useEffect } from 'react';
import { DragDropContext, Draggable, Droppable, DropResult } from 'react-beautiful-dnd';
import { useParams } from 'react-router-dom';
import { useQuery, useMutation } from '@apollo/client';
import { Typography, List, Input, Button, notification } from 'antd';

import ErrorAndLoading from '@comp/ErrorAndLoading';
import TemplateItemListItem from './TemplateItemListItem';

import { templates_items_list } from '@logic/queries';
import { create_template_item, move_before, move_after } from '@logic/mutations';
import errorMessage from '@logic/functions/errorHandeling';

import styles from './styles.module.less';

interface Props {
  templateId: string;
  accountId: string;
  openItem: (id: string) => void;
  setSelection: (id: string, value: boolean) => void;
  checkForSelected: (id: string) => boolean;
  refresh: () => void;
  reload: boolean;
  displayOnly?: boolean;
}

const TemplateItemsList: React.FC<Props> = ({
  accountId,
  openItem,
  setSelection,
  templateId,
  checkForSelected,
  refresh,
  reload,
  displayOnly,
}) => {
  const [templates, setTemplates] = useState<any[]>();
  const [total, setTotal] = useState(0);
  const [page, setPage] = useState(1);
  const [newTemplate, setNewTemplate] = useState('');

  const { item } = useParams<any>();

  const { data, loading, error, refetch, fetchMore } = useQuery(templates_items_list, {
    fetchPolicy: 'no-cache',
    variables: { id: templateId, limit: -1, page: 1, excludeSubitems: true },
  });

  const [MoveAfter] = useMutation(move_after);
  const [MoveBefore] = useMutation(move_before);

  const [CreateTemplate] = useMutation(create_template_item);

  useEffect(() => {
    refetch();
  }, [reload, refetch, refresh, item]);

  useEffect(() => {
    if (!data) return;

    const templates = data.template?.items?.data;

    setTemplates(templates);
    setTotal(data.template?.items?.total);
  }, [data, templateId, refetch]);

  /*useEffect(() => {
    setPage(1);
    setTotal(0);

    refetch();
    refresh();
  }, [refetch, refresh]);*/

  const onDragEnd = (result: DropResult) => {
    if (!result.destination || !templates) return;

    const from = result.source.index;
    const to = result.destination.index;

    if (from === to) return;

    const reference = from < to ? 'after' : 'before';
    const templateOrdId = templates[from].id;
    const templateRefId = templates[to].id;

    Order(reference, templateRefId, templateOrdId);

    const newTemplates = [...templates];

    const originItem = newTemplates.find((t: any) => t.id === templateOrdId);

    newTemplates.splice(from, 1);

    newTemplates.splice(to, 0, originItem);

    setTemplates(newTemplates);
  };

  async function Order(reference: 'before' | 'after', templateRefId: string, templateOrdId: string) {
    try {
      if (reference === 'after')
        await MoveAfter({ variables: { type: 'TemplateItem', id: templateOrdId, target: templateRefId } });
      if (reference === 'before')
        await MoveBefore({ variables: { type: 'TemplateItem', id: templateOrdId, target: templateRefId } });
    } catch (err) {
      console.error(err);
    } finally {
      refetch({ id: templateId, excludeSubitems: true, limit: -1, page: 1 });
    }
  }

  //-------------- create -----------------------

  async function doCreate() {
    try {
      await CreateTemplate({ variables: { template: templateId, title: newTemplate, type: 'default' } });

      setNewTemplate('');

      refetch();
      refresh();
    } catch (err) {
      notification.open({ type: 'error', ...errorMessage('graph_err', err) });
    }
  }

  function handleChange(event: React.ChangeEvent<HTMLInputElement>) {
    setNewTemplate(event?.target?.value);
  }

  if (error) return <ErrorAndLoading error={error} />;

  return (
    <List id="show_item-template" size="small" style={{ margin: 0 }} loading={loading && !data}>
      {data && !loading && (!templates || templates.length === 0) && (
        <List.Item key="empty" style={{ display: 'flex', justifyContent: 'center' }}>
          <Typography.Text style={{ fontSize: '14px' }}>nehum item nesse template 🧐</Typography.Text>
        </List.Item>
      )}

      {templates && (
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId={templateId}>
            {(provided) => (
              <div ref={provided.innerRef} {...provided.droppableProps}>
                {templates.map(({ id, title }: any, idx: number) => (
                  <Draggable draggableId={id} key={`${id + idx}`} index={idx}>
                    {(provided) => (
                      <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                        <TemplateItemListItem
                          key={id}
                          templateItemId={id}
                          accountId={accountId}
                          templateTitle={title}
                          selected={checkForSelected(id)}
                          setSelection={(value) => setSelection(id, value)}
                          openItem={(id) => openItem(id)}
                          displayOnly={displayOnly}
                        />
                      </div>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      )}

      {!displayOnly && (
        <List.Item
          className={styles.item}
          style={{ padding: 0, display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
          <Input
            style={{ border: 'none', backgroundColor: 'transparent' }}
            placeholder="Criar novo item"
            value={newTemplate}
            onChange={handleChange}
            onKeyPress={({ key }) => {
              if (key === 'Enter') {
                doCreate();
              }
            }}
          />
          <Button onClick={() => doCreate()} type="primary">
            Salvar
          </Button>
        </List.Item>
      )}

      {templates && templates?.length < total && (
        <List.Item
          key="more"
          onClick={() => {
            fetchMore({
              variables: { page: page + 1 },
              updateQuery: (prev, { fetchMoreResult }) => {
                setTemplates([...templates, ...fetchMoreResult.template?.items?.data]);
              },
            });
            setPage(page + 1);
          }}
          style={{ cursor: 'pointer', display: 'flex', justifyContent: 'center' }}>
          Exibindo {templates?.length} de {total}. Clique para ver mais
        </List.Item>
      )}
    </List>
  );
};

export default TemplateItemsList;
