import React, { useCallback } from 'react';

interface Props {
  onPaste: (data: File[]) => void;
  style?: React.CSSProperties;
}

const OnPasteDiv: React.FC<Props> = ({ style, onPaste, children }) => {
  const handleClick = useCallback((event: any) => {
    event.target.focus();
  }, []);

  return (
    <div
      tabIndex={-1}
      style={style}
      onClick={handleClick}
      onPaste={(event) => {
        event.stopPropagation();

        // Evita que seja colado um texto como uma imagem
        const text = event.clipboardData.getData('text/plain');
        if (!!text) return;

        const items = event.clipboardData.items;
        let data = [];

        for (let idx = 0; idx < items.length; idx++) {
          const file = items[idx].getAsFile();

          if (file) {
            data.push(file);
          }
        }

        onPaste(data);
      }}>
      {children}
    </div>
  );
};

export default OnPasteDiv;
