import React from 'react';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react-lite';
import { Progress, Tooltip, Menu, Card, Typography } from 'antd';

import { CloseCircleOutlined } from '@ant-design/icons';

import { useUploadManager } from '@logic/context';

import styles from './styles.module.less';

interface Props {
  id?: string;
}

const UploadList: React.FC<Props> = ({ id }) => {
  const uploadManager = useUploadManager();

  const uploading = uploadManager.isUploading;

  //------------------------------------------------------------------------------

  if (!uploading) return null;

  let filesList = uploadManager.uploadList;

  if (!!id) {
    filesList = uploadManager.uploadList.filter(({ targetId }) => targetId === id);
  }

  const currId = uploadManager.uploadList[0]?.fileId;
  const progress = uploadManager.progress * 100;

  //------------------------------------------------------------------------------

  if (id) {
    return (
      <div className={styles.list}>
        {filesList.map(({ file, fileId, targetId }, idx: number) => (
          <Card
            bordered={false}
            key={filesList[idx].file.name}
            className={styles.card}
            bodyStyle={{ display: 'flex', flexDirection: 'column', padding: '2px 4px' }}>
            <Typography.Text>{`${file.name} - ${(file.size / (1024 * 1024)).toFixed(2)}MB`}</Typography.Text>

            <div style={{ flex: 1, display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
              <Tooltip destroyTooltipOnHide={{ keepParent: false }} title={file.name}>
                <Progress
                  percent={fileId === currId ? progress : 0}
                  showInfo={false}
                  trailColor="#ccc"
                  strokeColor="#ff5333"
                />
              </Tooltip>

              <CloseCircleOutlined className={styles.close_icon} onClick={() => uploadManager.cancelUpload(fileId)} />
            </div>
          </Card>
        ))}
      </div>
    );
  }

  return (
    <Menu mode="horizontal">
      <Menu.SubMenu title={<ProgressObserver />}>
        {filesList.map(({ file, fileId, targetId }, idx: number) => (
          <Menu.SubMenu
            key={`${fileId}_${idx}`}
            icon={
              <CloseCircleOutlined className={styles.close_icon} onClick={() => uploadManager.cancelUpload(fileId)} />
            }
            title={
              <Progress
                percent={fileId === currId ? progress : 0}
                showInfo={false}
                width={80}
                trailColor="#ccc"
                strokeColor="#ff5333"
              />
            }>
            <Menu.Item>{`${file.name} - ${(file.size / (1024 * 1024)).toFixed(2)}MB`}</Menu.Item>
          </Menu.SubMenu>
        ))}
      </Menu.SubMenu>
    </Menu>
  );
};

const ProgressObserver = observer(() => {
  const uploadManager = useUploadManager();

  const { t } = useTranslation('upload');

  const percentage = uploadManager.totalProgress * 100;
  return (
    <div>
      <Progress
        status="active"
        trailColor="#ccc"
        strokeColor="#ff5333"
        showInfo={false}
        percent={percentage}
        style={{ width: 100, marginRight: 16 }}>
        {t('upload_files')}
      </Progress>
    </div>
  );
});

export default observer(UploadList);
