import React, { ReactElement, useState, useEffect } from 'react';
import { Pagination, List } from 'antd';
import styles from './styles.module.less';
import { DocumentNode } from 'graphql';
import { useQuery } from '@apollo/client';
import ErrorAndLoading from '../ErrorAndLoading';

interface Props {
  query: DocumentNode;
  limit: number;
  renderItem: (item: any, index: number) => ReactElement | null;
  showPath: (data: any) => { total: number; data: any[] };
  params?: any;
  reloadParam: boolean;
}

// Lista usada na área de admin
// Lista de [limit] items com (total/[limit]) páginas

const PaginatedList: React.FC<Props> = ({ query, limit, renderItem, showPath, params, reloadParam }) => {
  const [page, setPage] = useState(1);
  const [data, setData] = useState<{ total: number; data: any[] }>();

  const { data: res, loading, error, fetchMore, refetch } = useQuery(query, {
    ...params,
    variables: { limit, page },
  });

  useEffect(() => {
    refetch();
  }, [reloadParam, showPath, refetch]);

  useEffect(() => {
    if (!res) return;

    const dataArray = showPath(res);

    setData(dataArray);
  }, [res, showPath]);

  if (error) return <ErrorAndLoading error={error} />;

  if (!data) return <div>{JSON.stringify(res)}</div>;

  return (
    <>
      <List loading={loading && !data} size="small" bordered style={{ marginTop: '16px' }} split>
        {data.data.map((item, idx) => {
          return renderItem(item, idx);
        })}

        {data.total / limit > 1 && (
          <div className={styles.pageIndicator}>
            <Pagination
              defaultCurrent={1}
              pageSize={limit}
              total={data.total}
              current={page}
              onChange={(p) => {
                setPage(p);

                fetchMore({
                  variables: { page: p },
                  updateQuery: ({ fetchMoreResult }) => fetchMoreResult,
                });
              }}
              hideOnSinglePage
            />
          </div>
        )}
      </List>
    </>
  );
};

export default PaginatedList;
