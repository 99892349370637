import React from 'react';
import { useParams } from 'react-router-dom';

import NewAccount from './NewAccount';
import EditAccount from './EditAccount';

const Account: React.FC = () => {
  const { account }= useParams<any>();

  const newAccount = account === 'new';

  if (newAccount) return <NewAccount />;
  else return <EditAccount />;
};

export default Account;
