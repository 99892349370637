import { ConfigProvider } from 'antd';
import pt_BR from 'antd/es/locale/pt_BR';
import i18n from 'i18next';
import moment from 'moment';
import React from 'react';
import ReactDOM from 'react-dom';
import { setConfiguration } from 'react-grid-system';
import { initReactI18next } from 'react-i18next';

import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';

import en from './lang/en';
import pt from './lang/pt';
import LoginSwitch from './LoginSwitch';
import * as serviceWorker from './serviceWorker';

import 'moment/locale/pt-br';
import './index.less';

setConfiguration({
  containerWidths: [540, 800, 1200, 800],
  maxScreenClass: 'xl',
});

// TODO  change language according to location
moment.locale('pt-br'); // => Para datas e calendários

if (process.env.NODE_ENV === 'production') {
  Sentry.init({
    dsn: 'https://47a50bc708b84d0f911598504045fe8c@o150551.ingest.sentry.io/5432491',
    integrations: [new Integrations.BrowserTracing()],
    tracesSampleRate: 1.0,
  });
}

//  FIXME  WARN  render site before or show loading
ReactDOM.render(
  <ConfigProvider locale={pt_BR}>
    <LoginSwitch />
  </ConfigProvider>,
  document.getElementById('root'),
);

serviceWorker.unregister();

i18n.use(initReactI18next).init({
  resources: { en, pt },
  lng: navigator.language, //'en',
  fallbackLng: 'en',
  interpolation: { escapeValue: false },
});
