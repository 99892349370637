import React, { useCallback, useState } from 'react';
import { DocumentNode } from '@apollo/client';
import { Collapse, Typography } from 'antd';
import { useTranslation } from 'react-i18next';

import {
  CheckCircleOutlined,
  ReloadOutlined,
  UserOutlined,
  SoundOutlined,
  FileSearchOutlined,
  WarningOutlined,
} from '@ant-design/icons';

import ActivitytList from '../ActivitiesList';
import TutorialButton from '@comp/TutorialButton';

import styles from './styles.module.less';

interface Props {
  accountId: string;
  page: 'my_activities' | 'account_activities' | 'realm_activities' | 'home_dashboard';
  setSelected: (taskId: string) => void;
  query: DocumentNode;
  params: any;
  getData: (data: any) => any;
}

// Lista de atividades por etapa

const ActivitiesListBySteps: React.FC<Props> = ({ accountId, page, setSelected, params, getData, query }) => {
  const [openPannel, setOpenPannel] = useState(true);

  const { t } = useTranslation('activitiesListBySteps');

  const stepsSeparation = [
    {
      key: 'execution',
      step: ['execution'],
      title: t('execution_step'),
      icon: <ReloadOutlined className={styles.icon} />,
    },
    {
      key: 'check',
      step: ['check'],
      title: t('check_step'),
      icon: <FileSearchOutlined className={styles.icon} />,
    },
    {
      key: 'approval',
      step: ['approval'],
      title: t('approval_step'),
      icon: <UserOutlined className={styles.icon} />,
    },
    {
      key: 'finish',
      step: ['finish'],
      title: t('finish_step'),
      icon: <SoundOutlined className={styles.icon} />,
    },
    {
      key: 'done',
      step: ['done'],
      title: t('done_step'),
      icon: <CheckCircleOutlined className={styles.icon} />,
    },
  ];

  const checkPannel = useCallback((key: any | any[]) => {
    if (!key) {
      setOpenPannel(false);
    }
    if (!!key) {
      setOpenPannel(true);
    }
  }, []);

  return (
    <Collapse
      accordion
      bordered={false}
      destroyInactivePanel={true}
      expandIconPosition="right"
      defaultActiveKey={'execution'}
      onChange={checkPannel}>
      <Collapse.Panel
        key="pendingFeedbacks"
        style={pannel}
        header={
          <Typography.Title style={{ ...titleStyle, color: '#ff5333' }}>
            {<WarningOutlined className={styles.icon} />}
            {t('problem')}
          </Typography.Title>
        }>
        <ActivitytList
          hideProblemStyle
          accountId={accountId}
          setSelected={setSelected}
          getData={getData}
          query={query}
          variables={{
            ...params,
            steps: ['execution', 'check', 'approval', 'finish', 'done'],
            excludeAllExceptTasksWithPendingFeedbacks: true,
          }}
          canReorderList={false}
          canCreateNewTask={page === 'account_activities'}
          showAccountLogo={page !== 'account_activities'}
          showSelectEditMultiple={page !== 'home_dashboard'}
        />
      </Collapse.Panel>

      {stepsSeparation.map(({ step, title, icon, key }) => {
        return (
          <Collapse.Panel
            key={key}
            style={pannel}
            header={
              <Typography.Title style={titleStyle}>
                {icon}&nbsp;
                {title}
              </Typography.Title>
            }>
            <ActivitytList
              accountId={accountId}
              setSelected={setSelected}
              getData={getData}
              query={query}
              variables={{ ...params, steps: step, types: ['assignment', 'default'] }}
              canReorderList={!params.excludeSubtasks}
              canCreateNewTask={page === 'account_activities'}
              showAccountLogo={page !== 'account_activities'}
              showSelectEditMultiple={page !== 'home_dashboard'}
            />
          </Collapse.Panel>
        );
      })}

      {!openPannel && (
        <TutorialButton
          title={[t('activities_filter')]}
          placement="left"
          tourId={['003_ActivitiesFilters']}
          top={80}
          right={20}
        />
      )}
    </Collapse>
  );
};

export default ActivitiesListBySteps;

const pannel: React.CSSProperties = {
  padding: 0,
  marginBottom: '8px',
};

const titleStyle: React.CSSProperties = {
  fontSize: '16px',
  margin: 0,
};
