import React, { useCallback, useMemo } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import { Badge, Dropdown, notification } from 'antd';
import { useHistory } from 'react-router-dom';
import { observer } from 'mobx-react-lite';

import { BellFilled } from '@ant-design/icons';

import NotificationsList from './NotificationsList';
import Notification from '@comp/Notifications/Notification';

import { mark_as_viewed } from '@logic/mutations';
import { notification_info, total_notifications_query } from '@logic/queries';
import { useSocketEventQuery } from '@logic/functions/socket';
import { useStore } from '@logic/context';

const Notifications: React.FC = () => {
  const history = useHistory();
  const store = useStore();

  const { data: totalNotifications, refetch } = useQuery(total_notifications_query, { fetchPolicy: 'network-only' });
  const [MarAsViewed] = useMutation(mark_as_viewed);

  const handleMarkAsSeen = useMemo(
    () => (id: string) => {
      MarAsViewed({ variables: { id } });
      if (refetch) refetch();
    },
    [MarAsViewed, refetch],
  );

  const handleSocket = useCallback(
    (data: any) => {
      const {
        id,
        type,
        initiator: { name, avatarUrl },
        step,
        viewed,
        notified_at,
        task: { title, id: taskId },
      }: any = data.notification;

      notification.open({
        message: (
          <Notification
            id={id}
            initiatorAvatar={avatarUrl}
            initiatorName={name}
            step={step}
            taskId={taskId}
            taskTitle={title}
            type={type}
            notified_at={notified_at}
            viewed={viewed}
            displayOnly
          />
        ),
        onClick: () => {
          handleMarkAsSeen(id);
          if (taskId) {
            history.push(
              history?.location?.pathname +
                '#task/' +
                taskId +
                (type === 'task_note_created' ? '/notes_tab' : '/task_tab'),
            );
          }
          if (refetch) refetch();
        },
        style: { paddingLeft: 8, paddingBottom: 0 },
      });

      if (refetch) refetch();
    },
    [refetch, history, handleMarkAsSeen],
  );

  useSocketEventQuery(store.profile?.id, 'notification', notification_info, {}, handleSocket);

  return <Bell total={totalNotifications?.notifications?.total || 0} />;
};

export default observer(Notifications);

interface BellProps {
  total: number;
}

const Bell: React.FC<BellProps> = ({ total }) => {
  return (
    <div style={{ marginLeft: 16, paddingTop: 10 }} id="notifications">
      <Dropdown
        overlay={<NotificationsList unreadNotifications={total} />}
        trigger={['click']}
        align={{ offset: [0, 22] }} // offset [horizontal, vertical]
        overlayStyle={{ borderRadius: 4, overflow: 'hidden', boxShadow: '2px 2px 4px #00000099' }}>
        <Badge count={total}>
          <BellFilled
            id="Dropdown"
            style={{
              fontSize: 26,
              cursor: 'pointer',
            }}
          />
        </Badge>
      </Dropdown>
    </div>
  );
};
