import React from 'react';
import { Typography, List } from 'antd';

import { BiNote } from 'react-icons/bi';

import InfoAction from './InfoAction';

import styles from './styles.module.less';

interface Props {
  infoId: string;
  title: string;
  setSelected: (id: string) => void;
  refresh: () => void;
}

const InfoItem: React.FC<Props> = ({ infoId, setSelected, title, refresh }) => {
  return (
    <List.Item
      id="open_info"
      extra={<InfoAction infoId={infoId} refresh={refresh} />}
      onClick={() => setSelected(infoId)}
      className={styles.item}
      style={{
        padding: 0,
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        cursor: 'pointer',
      }}>
      <Typography.Text style={{ marginLeft: 8, marginRight: 8, fontSize: 20 }}>
        <BiNote style={{ margin: 0, color: '#E1C306' }} />
      </Typography.Text>

      <Typography.Text ellipsis style={{ cursor: 'pointer', width: '100%' }}>
        {title}
      </Typography.Text>
    </List.Item>
  );
};

export default InfoItem;
