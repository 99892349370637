import { Badge, Dropdown, Menu, Modal, notification, Tabs } from 'antd';
import React, { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { DeleteOutlined, EllipsisOutlined, SwapOutlined } from '@ant-design/icons';
import { useMutation } from '@apollo/client';
import errorMessage from '@logic/functions/errorHandeling';
import { convert_task, delete_task } from '@logic/mutations';

import ClockTab from '../ClockTab';
import HelpTab from '../HelpTab';
import NotesTab from '../NotesTab';
import TaskTimer from '../TaskTimer';
import AssignmentTab from './AssignmentTab';
import NewAssignment from './NewAssignment';

import styles from './styles.module.less';

interface Props {
  tab?: string;
  task?: any;
  taskId: string;
  accountId: string;
  setSelected: (id: string) => void;
  parent?: string;
  refetch: () => void;
}

const AssignmentTask: React.FC<Props> = ({ task, tab, accountId, taskId, setSelected, parent, refetch }) => {
  const history = useHistory();

  const { t } = useTranslation('assignmentTask');

  const newAssignment = taskId === 'new';

  const [DeleteTask] = useMutation(delete_task, {
    variables: { id: taskId },
  });

  const [ConvertTask] = useMutation(convert_task, {
    variables: { id: taskId, type: 'default' },
  });

  const handleDelete = useCallback(() => {
    Modal.confirm({
      title: t('delete_title'),
      icon: <DeleteOutlined />,
      content: (
        <div>
          <p>{t('delete_question')}</p>
          <p>{t('delete_warning')}</p>
        </div>
      ),
      onOk: async () => {
        try {
          await DeleteTask();

          notification.open({
            placement: 'bottomRight',
            duration: 2,
            type: 'success',
            message: t('delete_success'),
          });

          !!parent ? setSelected(parent) : history.push(history.location.pathname);
        } catch (err) {
          notification.open({
            type: 'error',
            ...errorMessage('graph_err', err),
          });
        }
      },
      okType: 'danger',
    });
  }, [DeleteTask, parent, setSelected, history, t]);

  const handleConvert = useCallback(() => {
    Modal.confirm({
      title: t('convert_title'),
      icon: <SwapOutlined />,
      content: (
        <div>
          <p>{t('convert_question')}</p>
          <p>{t('convert_warning')}</p>
        </div>
      ),
      onOk: async () => {
        try {
          await ConvertTask();

          notification.open({
            placement: 'topRight',
            duration: 2,
            type: 'success',
            message: t('convert_success'),
          });

          refetch();
        } catch (err) {
          notification.open({
            type: 'error',
            ...errorMessage('graph_err', err),
          });
        }
      },
      okType: 'danger',
    });
  }, [ConvertTask, refetch, t]);

  const convertMenu = (
    <Menu style={{ width: 260 }} mode="vertical" theme="light">
      <Menu.Item key="convert_assignment" icon={<SwapOutlined style={{ marginRight: 8 }} />} onClick={handleConvert}>
        {t('convert_menu')}
      </Menu.Item>

      <Menu.Item key="delete" icon={<DeleteOutlined style={{ marginRight: 8 }} />} onClick={handleDelete}>
        {t('delete_menu')}
      </Menu.Item>
    </Menu>
  );

  return (
    <div className={styles.root}>
      <Tabs
        activeKey={history.location.hash.split('/')[2]}
        destroyInactiveTabPane={true}
        tabPosition="top"
        tabBarStyle={{ height: '30px', margin: 0 }}
        style={{ height: '100%' }}
        onTabClick={(activeKey) => history.push('#task/' + taskId + '/' + activeKey)}
        tabBarExtraContent={!newAssignment && <TaskTimer taskId={taskId} />}>
        <Tabs.TabPane tab={t('task_tab')} key="task_tab" forceRender>
          <>
            {newAssignment && <NewAssignment />}

            {!!task && !newAssignment && (
              <AssignmentTab refetch={refetch} assignmentData={task} accountId={accountId} setSelected={setSelected} />
            )}
          </>
        </Tabs.TabPane>

        {!newAssignment && (
          <Tabs.TabPane
            tab={
              <Badge count={task?.notes?.total} size="small" offset={[10, 8]}>
                {t('note_tab')}
              </Badge>
            }
            key="notes_tab">
            <NotesTab taskId={taskId} />
          </Tabs.TabPane>
        )}

        {!newAssignment && (
          <Tabs.TabPane tab={t('clock_tab')} key="clock_tab">
            <ClockTab taskId={taskId} />
          </Tabs.TabPane>
        )}

        {!!task?.from_template_item && !newAssignment && (
          <Tabs.TabPane tab={t('help_tab')} key="help_tab">
            <HelpTab taskId={taskId} />
          </Tabs.TabPane>
        )}

        {!newAssignment && (
          <Tabs.TabPane
            active={false}
            disabled
            tab={
              <Dropdown trigger={['click']} overlay={convertMenu}>
                <EllipsisOutlined style={{ color: 'black', fontSize: 20 }} />
              </Dropdown>
            }
            key="options_tab"
          />
        )}
      </Tabs>
    </div>
  );
};

export default AssignmentTask;
