import React from 'react';
import { useTranslation } from 'react-i18next';

import { Card, Spin, Typography } from 'antd';

import styles from './styles.module.less';

interface Props {
  error?: any;
  loading?: boolean;
  borderless?: boolean;
  loadingSize?: string;
  cardClassName?: string;
  cardStyle?: React.CSSProperties;
}

export default function ErrorAndLoading({ error, loading, borderless, loadingSize, cardStyle, cardClassName }: Props) {
  const { t } = useTranslation('errorLoading');

  if (error && error.response?.status === 422) return null;

  if (loading) {
    return (
      <Card style={cardStyle} className={`${styles.paper} ${cardClassName || ''}`} bordered={!borderless}>
        <Spin spinning={loading} style={{ fontSize: loadingSize || '40px' }} />
        {/*<Loading3QuartersOutlined spin style={{ fontSize: loadingSize || '40px' }} />*/}
      </Card>
    );
  }

  if (error && error.response?.status === 404) {
    return (
      <Card style={cardStyle} className={`${styles.paper} ${cardClassName || ''}`} bordered={!borderless}>
        <Typography.Title className={styles.notFound}>{t('not_found_sc')}</Typography.Title>
      </Card>
    );
  }

  if (error && error.response?.status === 500) {
    return (
      <Card style={cardStyle} className={`${styles.paper} ${cardClassName || ''}`} bordered={!borderless}>
        <Typography.Title className={styles.server}>{t('server_error_sc')}</Typography.Title>
      </Card>
    );
  }

  if (error) {
    const message = error.response?.data?.message || error.message;
    let translated = message;

    if (message.includes('not found')) {
      translated = t('not_found');
    }

    if (message.includes('Failed to fetch')) {
      translated = t('failed_fetch');
    }

    return (
      <Card style={cardStyle} className={`${styles.paper} ${cardClassName || ''}`} bordered={!borderless}>
        <Typography.Text className={styles.server}>
          {error.response?.status}&nbsp;
          {translated}
        </Typography.Text>
      </Card>
    );
  }

  return null;
}
