import React, { useCallback, useEffect, useMemo } from 'react';
import { useLazyQuery } from '@apollo/client';

import { account_cells_select } from '@logic/queries';
import { Select, Typography } from 'antd';

import styles from './styles.module.less';
import AvatarPicture from '@comp/AvatarPicture';
import ErrorAndLoading from '@comp/ErrorAndLoading';
import { useTranslation } from 'react-i18next';

interface Props {
  // id da celula selecionada ou "selectFirst" que seleciona a primeira celula automaticamente
  cellId: string;
  // conta de onde as celulas serão puxadas
  accountId: string;
  // salvar alteração da celula
  cellChange: (id: string) => void;
}

const CellSelector: React.FC<Props> = ({ accountId, cellId, cellChange }) => {
  const { t } = useTranslation('cellSelector');
  //----------------------Queries-----------------------
  const [getAccountCells, { data: cellsSelectList, loading, error }] = useLazyQuery(account_cells_select);

  useEffect(() => {
    getAccountCells({ variables: { id: accountId } });
  }, [accountId, getAccountCells]);

  //---------------------Select Cell----------------------

  const handleSelect = useCallback(
    (value: any) => {
      if (cellChange) cellChange(value);
    },
    [cellChange],
  );

  //-------------------------------------------------------

  const cellsList = useMemo(() => {
    const cells = cellsSelectList?.account?.cells?.data || [];

    if (cellId === 'selectFirst') handleSelect(cells[0]?.id);

    return cells.map(({ id, name, logoUrl }: { id: string; name: string; logoUrl: string }) => (
      <Select.Option key={id} value={id}>
        <div className={styles.cells_list_item}>
          <AvatarPicture pictureLink={logoUrl} target="Cell" size={20} name={name} />
          <Typography.Text className={styles.cell_name}>{name}</Typography.Text>
        </div>
      </Select.Option>
    ));
  }, [cellsSelectList, handleSelect, cellId]);

  const select = useMemo(() => {
    return (
      <Select
        value={cellId === 'selectFirst' ? undefined : cellId}
        placeholder={t('cell_selector')}
        onSelect={handleSelect}
        style={{ width: '100%' }}
        dropdownMatchSelectWidth={false}>
        {cellsList}
      </Select>
    );
  }, [cellId, handleSelect, cellsList, t]);

  if (loading || error) {
    return (
      <ErrorAndLoading
        error={error}
        loading={loading}
        borderless
        loadingSize="20px"
        cardStyle={{ display: 'block', margin: -24 }}
      />
    );
  }

  return select;
};

export default CellSelector;
