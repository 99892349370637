import React from 'react';
import { useTranslation } from 'react-i18next';

import SearchBar from '../../SearchBar';
import ProfileMenu from '../../ProfileMenu';
import Timer from '../../UserTimer';
import UploadList from '@comp/UploadProgress/UploadList';
import Notifications from '@comp/Notifications';
import NewFunctionalities from '@comp/NewFunctionalities';

import useWindowSize from '@logic/functions/windowSize';
import useHashInfo from '@logic/functions/currentTask';

import styles from './styles.module.less';

// Parte do menu à direita
// Barra de pesquisa
// Central de notificações
// Menu do usuário
// StartEmptyTimer

const RightMenu: React.FC = () => {
  const { width } = useWindowSize();

  const { t } = useTranslation('rightMenu');

  const drawer = useHashInfo();
  const drawerIsOpen = ['#task', '#acc_info', '#template', '#url_link'].includes(drawer[0]);

  return (
    <div className={styles.searchBarView}>
      <div className={styles.timer}>
        <UploadList />
      </div>

      {width > 1100 && (
        <div className={styles.timer}>
          <Timer button={true} />
        </div>
      )}

      {width > 1250 && <SearchBar />}

      {!drawerIsOpen && (
        <NewFunctionalities
          title={t('new_functionalities_title')}
          description={t('new_functionalities_description')}
          configId="2021-02-04-teste.011"
          fixedTooltip={true}
          ribbon={false}
          placement="bottomRight"
          offset={[6, -20]}
          primaryColor={true}>
          <Notifications />
        </NewFunctionalities>
      )}

      <ProfileMenu />
    </div>
  );
};

export default RightMenu;
