import React from 'react';
import { useHistory } from 'react-router-dom';
import { useMutation } from '@apollo/client';

import { Card, Button, Form, Input, Row, Col, notification, Select } from 'antd';

import TutorialNewUser from './TutorialNewUser';
import TutorialButton from '@comp/TutorialButton';

import { users_create } from '@logic/mutations';
import errorMessage from '@logic/functions/errorHandeling';
import { route } from '@router';

import styles from './styles.module.less';

type UserRole = 'admin' | 'customer_success' | 'media_tester' | 'creator' | 'customer';

const NewUser: React.FC = () => {
  const [form] = Form.useForm();
  const history = useHistory();

  const [CreateUser] = useMutation(users_create);

  async function doSave(name: string, email: string, password: string, roles: UserRole[], status: string) {
    try {
      const res = await CreateUser({ variables: { name, email, password, roles, status } });

      notification.open({
        type: 'success',
        message: 'Usuário criado.',
        description: res.data?.createUser?.name,
      });
      history.push(route('admin.users.edit', { user: res.data?.createUser?.id }));
    } catch (err) {
      notification.open({ type: 'error', ...errorMessage('graph_err', err) });
    }
  }

  const onFinish = (values: any) => {
    form
      .validateFields()
      .then((values) => {
        doSave(values.name, values.email, values.password, values.roles, values.status);
        form.resetFields();
      })
      .catch((info) => {
        notification.open({ type: 'error', ...errorMessage('form_validation', info) });
      });
  };

  const onFinishFailed = (errorInfo: any) => {
    notification.open({ type: 'error', ...errorMessage('', errorInfo) });
  };

  return (
    <Card className={styles.root}>
      <Form
        form={form}
        labelCol={{
          span: 3,
          style: { display: 'flex', flexDirection: 'row', justifyContent: 'flex-start' },
        }}
        wrapperCol={{ style: { display: 'flex', flex: 1 } }}
        layout="horizontal"
        name="user_cadaster"
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}>
        <div id="role_new_user">
          <Form.Item name="roles" label="Tipo de usuário">
            <Select mode="multiple">
              <Select.Option value="admin">Administrador</Select.Option>

              <Select.OptGroup label="Equipe">
                <Select.Option value="customer_success">Sucesso de cliente</Select.Option>
                <Select.Option value="media_tester">Media tester</Select.Option>
                <Select.Option value="creator">Criação</Select.Option>
              </Select.OptGroup>

              <Select.OptGroup label="Externo">
                <Select.Option value="customer">Cliente</Select.Option>
              </Select.OptGroup>
            </Select>
          </Form.Item>
        </div>

        <div id="status_new_user">
          <Form.Item name="status" label="Status">
            <Select
              value={form.getFieldValue('status')}
              options={[
                { value: 'active', label: 'Usuário ativo' },
                { value: 'inactive', label: 'Usuário inativo' },
              ]}
            />
          </Form.Item>
        </div>

        <div id="name_new_user">
          <Form.Item name="name" label="Nome">
            <Input type="text" />
          </Form.Item>
        </div>

        <div id="email_new_user">
          <Form.Item name="email" label="E-mail">
            <Input type="email" />
          </Form.Item>
        </div>

        <div id="password_new_user">
          <Form.Item
            label="Senha"
            name="password"
            rules={[
              { min: 8, message: 'A senha precisa conter ao menos 8 caracteres' },
              { required: true, message: 'Este campo é obrigatório' },
            ]}>
            <Input.Password />
          </Form.Item>
        </div>

        <div id="confirm_password_new_user">
          <Form.Item
            label="Confirmar senha"
            name="password_confirm"
            dependencies={['password']}
            rules={[
              { required: true, message: 'Este campo é obrigatório' },
              ({ getFieldValue }) => ({
                validator(rule, value) {
                  if (!value || getFieldValue('password') === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject('As senhas não coincidem');
                },
              }),
            ]}>
            <Input.Password />
          </Form.Item>
        </div>

        <Form.Item>
          <Row gutter={24}>
            <Col span={6}>
              <Button type="primary" htmlType="reset" block onClick={() => history.goBack()}>
                Voltar
              </Button>
            </Col>

            <Col span={6}>
              <Button id="save_new_user" type="primary" htmlType="submit" block>
                Salvar
              </Button>
            </Col>
          </Row>
        </Form.Item>
      </Form>
      <TutorialButton
        tourId="022_NewUserManagement"
        top={80}
        right={20}
        placement="left"
        title="Tutorial para criar um novo usuário"
      />

      <TutorialNewUser />
    </Card>
  );
};

export default NewUser;
