import React, { useCallback, useEffect, useState } from 'react';
import { Breadcrumb, Drawer, Radio, Tooltip, Typography } from 'antd';
import { useHistory, useParams } from 'react-router-dom';
import { useLazyQuery } from '@apollo/client';
import { DateTime, Duration } from 'luxon';

import { CloseCircleOutlined, RightOutlined, SyncOutlined, ClockCircleOutlined } from '@ant-design/icons';

import ErrorAndLoading from '@comp/ErrorAndLoading';
import Timer from '@comp/UserTimer';
import AssignmentTask from './AssignmentTask';
import DefaultTask from './DefaultTask';
import TutorialNewTask from './TutorialNewTask';
import TutorialTaskTab from './TutorialTaskTab';

import useCloseFn from '@logic/functions/closeDrawer';
import { task_info } from '@logic/queries';
import { route } from '@router';

import styles from './styles.module.less';

interface TaskProps {
  taskId: string;
  tab: string;
}

const Task: React.FC<TaskProps> = ({ taskId, tab }) => {
  const history = useHistory();
  const { id: paramId } = useParams<any>();

  const [type, setType] = useState<any>('default');

  const isNew = taskId === 'new';
  const accountId = paramId || undefined;

  const [fetchTask, { data, loading, error, refetch }] = useLazyQuery(task_info, {
    variables: {
      id: taskId,
      account_timers_total_seconds_begin: DateTime.local().startOf('month').toFormat('yyyy-LL-dd'),
      account_timers_total_seconds_end: DateTime.local().endOf('month').toFormat('yyyy-LL-dd'),
    },
    fetchPolicy: 'cache-and-network',
  });

  useEffect(() => {
    if (!taskId || isNew) return;

    fetchTask();
  }, [taskId, isNew, fetchTask]);

  useEffect(() => {
    if (isNew) return;
    if (!data) return;

    if (setType) setType(data.task?.type);
  }, [data, isNew, setType]); // para verificar se o setType existe ele precisa estar nas dependências

  const refetchTask = useCallback(() => {
    if (refetch) refetch();
  }, [refetch]);

  function openTask(id: string) {
    history.push('#task/' + id + '/task_tab');
  }

  const taskAccount = {
    id: data?.task?.account?.id,
    name: data?.task?.account?.name,
    monthly_planned_seconds: data?.task?.account?.monthly_planned_seconds,
    timers_total_seconds: data?.task?.account?.timers_total_seconds,
    time_last: data?.task?.account?.monthly_planned_seconds - data?.task?.account?.timers_total_seconds,
  };

  function closeDrawer() {
    history.push(history.location.pathname);
  }

  useCloseFn(closeDrawer, 'drawerException');

  //------------------------------------------------------------------------------

  if (!taskId) return null;

  return (
    <Drawer
      visible
      forceRender
      destroyOnClose
      mask={false}
      className={styles.root}
      width={'800px'}
      closeIcon={loading ? <SyncOutlined spin style={{ color: '#ff5333' }} /> : <CloseCircleOutlined />}
      title={
        <>
          {error && !isNew ? (
            <ErrorAndLoading borderless error={error} />
          ) : (
            <div style={{ display: 'flex', flexDirection: 'row' }}>
              {isNew && (
                <div id="type_task" style={{ marginTop: 6 }}>
                  <Radio.Group value={type} onChange={({ target }) => setType(target.value)}>
                    <Radio.Button value="default">Atividade</Radio.Button>
                    <Radio.Button value="assignment">Atividade pontual</Radio.Button>
                  </Radio.Group>
                </div>
              )}
              <div style={{ flex: 1 }}>
                {data && !!taskAccount && (
                  <div style={{ display: 'flex', flexDirection: 'row' }}>
                    {/* Cliente */}
                    <p
                      className={styles.account_link}
                      onClick={() => {
                        history.push(route('id.home.tasks', { id: taskAccount.id }));
                      }}>
                      {taskAccount.name}
                    </p>
                    {/* Tempo gasto pelo cliente */}
                    <Tooltip
                      destroyTooltipOnHide={{ keepParent: false }}
                      color="#ff5333"
                      placement="rightTop"
                      title={`horas usadas de ${Duration.fromMillis(
                        taskAccount.monthly_planned_seconds * 1000,
                      ).toFormat('hh', { floor: true })} horas`}>
                      <Typography.Text style={{ color: taskAccount.time_last >= 0 ? 'green' : 'red', marginLeft: 6 }}>
                        {/* {taskAccount.time_last > 0 ? '' : '- '} */}
                        {/* (taskAccount.time_last > 0 ? taskAccount.time_last : -taskAccount.time_last) */}
                        {Duration.fromMillis(taskAccount.timers_total_seconds * 1000).toFormat('hh:mm')}
                        <ClockCircleOutlined style={{ fontSize: 13, marginLeft: 4 }} />
                      </Typography.Text>
                    </Tooltip>
                  </div>
                )}
                {/* Atividades "parent" e "grandparent" */}
                {data && !!data.task && !!data.task.parent && (
                  <Breadcrumb style={{ maxWidth: 600, flexDirection: 'row' }}>
                    {!!data.task.parent.parent && (
                      <Breadcrumb.Item
                        separator={<RightOutlined />}
                        onClick={() => openTask(data.task.parent.parent.id)}>
                        <Typography.Text ellipsis className={styles.activity_link} style={{ maxWidth: '25%' }}>
                          {data.task.parent.parent.title}
                        </Typography.Text>
                      </Breadcrumb.Item>
                    )}

                    <Breadcrumb.Item separator={<RightOutlined />} onClick={() => openTask(data.task.parent.id)}>
                      <Typography.Text
                        ellipsis
                        className={styles.activity_link}
                        style={{ maxWidth: !!data.task.parent.parent ? '25%' : '40%' }}>
                        {data.task.parent.title}
                      </Typography.Text>
                    </Breadcrumb.Item>

                    <Breadcrumb.Item>
                      <Typography.Text
                        ellipsis
                        className={styles.activity_link}
                        style={{ maxWidth: !!data.task.parent.parent ? '25%' : '40%', color: '#ff5333' }}>
                        {data.task.title}
                      </Typography.Text>
                    </Breadcrumb.Item>
                  </Breadcrumb>
                )}
              </div>

              <div style={{ marginRight: 50, display: 'flex', alignItems: 'center' }}>
                <Timer taskId={taskId} button={true} />
              </div>
            </div>
          )}
        </>
      }
      headerStyle={{ paddingBottom: 8, paddingTop: 8, borderBottom: 'none' }}
      bodyStyle={{ height: '100vh', paddingBottom: 0, paddingTop: 0 }}
      onClose={closeDrawer}>
      {type === 'default' && (
        <DefaultTask
          task={data?.task}
          tab={tab}
          taskId={taskId}
          accountId={taskAccount.id}
          refetch={refetchTask}
          setSelected={openTask}
          parent={data?.task?.parent?.id}
        />
      )}

      {type === 'assignment' && (
        <AssignmentTask
          task={data?.task}
          tab={tab}
          taskId={taskId}
          accountId={taskAccount.id}
          refetch={refetchTask}
          setSelected={openTask}
          parent={data?.task?.parent?.id}
        />
      )}

      <TutorialTaskTab taskType={type} step={data?.task.step} />
      <TutorialNewTask hasTask={!!accountId ? true : false} />
    </Drawer>
  );
};

export default Task;
