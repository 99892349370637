import React from 'react';
import ProgressBar from './ProgressBar';

import styles from './styles.module.less';
interface Props {
  summaryData: any;
}

const Indicators: React.FC<Props> = ({
  summaryData: { tasks_done, tasks_delayed, tasks_total, planned_seconds_used, planned_seconds, total_seconds_used },
}) => {
  let tasksDoneRatio = 0;
  if (tasks_total > 0) tasksDoneRatio = (tasks_done / tasks_total) * 100;

  let tasksDelayedRatio = 0;
  if (tasks_total > 0) tasksDelayedRatio = (tasks_delayed / tasks_total) * 100;

  let timeUsedRatio = 0;
  if (planned_seconds > 0) timeUsedRatio = (planned_seconds_used / planned_seconds) * 100;

  let timeUsedAverage = 0;
  if (tasks_done > 0 && total_seconds_used > 0) timeUsedAverage = total_seconds_used / tasks_done / 60;

  return (
    <div className={styles.root}>
      <div className={styles.item}>
        <ProgressBar
          type="percent"
          value={parseFloat(tasksDoneRatio.toFixed(1))}
          text="Atividades concluídas"
          color="#33ff6c"
          tooltipText={`Concluídas: ${tasks_done} - Total: ${tasks_total}`}
        />
      </div>

      <div className={styles.item}>
        <ProgressBar
          type="percent"
          value={parseFloat(tasksDelayedRatio.toFixed(1))}
          text="Atividades em atraso"
          color="#ff8c00"
          tooltipText={`Atrasadas: ${tasks_delayed} - Total: ${tasks_total}`}
        />
      </div>

      <div className={styles.item}>
        <ProgressBar
          type="percent"
          value={parseFloat(timeUsedRatio.toFixed(1))}
          text="Tempo previsto usado"
          color={timeUsedRatio >= 100 ? '#ff8c00' : '#33ff6c'}
          tooltipText={`Tempo previsto: ${(planned_seconds / 3600).toFixed(1)}h e tempo consumido: ${(
            planned_seconds_used / 3600
          ).toFixed(1)}h`}
        />
      </div>

      <div className={styles.last_item}>
        <ProgressBar type="meanTime" value={Math.round(timeUsedAverage)} text="Média de tempo usado por atividade:" />
      </div>
    </div>
  );
};

export default Indicators;
