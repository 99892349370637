import React, { useCallback, useMemo } from 'react';
import { Typography, Card, Row } from 'antd';
import { useQuery } from '@apollo/client';

import Help from '@comp/Help';
import MapUpdatedTasks from './MapUpdatedTasks';

import { useStore } from '@logic/context';
import { useSocketEvent } from '@logic/functions/socket';
import { notifications_query, total_notifications_query } from '@logic/queries';

import styles from './styles.module.less';

const RecentUpdates: React.FC = () => {
  const store = useStore();

  const { data, refetch } = useQuery(notifications_query, {
    variables: { page: 1, limit: 8 },
    fetchPolicy: 'network-only',
  });

  useQuery(total_notifications_query, { fetchPolicy: 'network-only' });

  const handleSocket = useCallback(
    (data: any) => {
      if (refetch) refetch();
    },
    [refetch],
  );

  useSocketEvent(store.profile?.id, 'notification', handleSocket);

  const formattedData = useMemo(
    () =>
      data?.notifications?.data?.map(
        ({
          id,
          type,
          initiator: { name, avatarUrl },
          step,
          viewed,
          notified_at,
          task: { title, id: taskId },
        }: any) => ({
          id,
          type,
          initiatorName: name,
          initiatorAvatar: avatarUrl,
          step,
          viewed,
          notified_at,
          taskId,
          taskTitle: title,
        }),
      ),
    [data],
  );

  return (
    <Card style={cardStyle} bodyStyle={cardBodyStyle} className={styles.root} id="recent_updates">
      <Row className={styles.title}>
        <Typography.Text className={styles.updates}>Atualizações Recentes</Typography.Text>

        <Help
          title="Atualizações recentes"
          description="São todas as alterações recentes que ocorreram nas suas atividades. Veja também o usuário que realizou a alteração"
          iconStyle={{ color: '#5c5c5c' }}
          placement="right"
        />
      </Row>

      {formattedData && <MapUpdatedTasks recentTaskUpdate={formattedData} />}
    </Card>
  );
};

const cardStyle = { borderRadius: 4, overflow: 'hidden', boxShadow: '0 1px 4px #00000040', marginBottom: 32 };
const cardBodyStyle = { padding: 0 };

export default RecentUpdates;
