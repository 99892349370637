import { action, observable, makeObservable } from 'mobx';
import localForage from 'localforage';
import { UploadManager } from './stores/uploadManager';

export default class Store {
  client: any;

  token?: string | null = undefined;

  config: { [key: string]: unknown } = {};

  calendarDate?: moment.Moment;

  tour?: string;

  editMultiple: boolean = false;
  editMultipleList: string[] = [];
  showItems?: boolean;

  hasInfo?: boolean;

  hasLink?: boolean;

  hasGoogleAccounts?: boolean;

  hasFacebookAccounts?: boolean;

  hasFeedback?: boolean;

  hasPendingFeedback?: boolean;

  profile?: {
    name: string;
    email: string;
    roles: string[];
    id: string;
    avatarUrl: string;
    accounts: any[];
    realmId: string;
  } | null = undefined; //  FIXME  any

  uploadManager = new UploadManager(this);

  constructor() {
    makeObservable(this, {
      client: observable,
      token: observable,
      tour: observable,
      showItems: observable,
      hasInfo: observable,
      hasLink: observable,
      hasGoogleAccounts: observable,
      hasFacebookAccounts: observable,
      hasFeedback: observable,
      hasPendingFeedback: observable,
      config: observable,
      calendarDate: observable,
      editMultiple: observable,
      editMultipleList: observable,
      profile: observable,
      setToken: action,
      setTour: action,
      setShowItems: action,
      setHasInfo: action,
      setHasLink: action,
      setHasGoogleAccounts: action,
      setHasFacebookAccounts: action,
      setHasFeedback: action,
      setHasPendingFeedback: action,
      setConfig: action,
      setCalendarDate: action,
      setEditMultiple: action,
      addTaskToMultipleEdit: action,
      removeTaskFromMultipleEdit: action,
      cleanEditMultipleList: action,
      setProfile: action,
      updateConfig: action,
    });
    localForage.getItem('config').then((cfg: any) => this.setConfig(JSON.parse(cfg || '{}')));
  }

  async storeToken(token?: string | null) {
    if (!!token && token === this.token) return;
    if (!!token) {
      await localForage.setItem('token', token);
    } else {
      await localForage.removeItem('token');
    }

    this.setToken(token);
    //this.token = token;
  }

  setClient(client: any) {
    this.client = client;
  }

  setToken(token?: string | null) {
    this.token = token;
  }

  setTour(tour?: string) {
    this.tour = tour;
  }

  setShowItems(showItems?: boolean) {
    this.showItems = showItems;
  }

  setHasInfo(hasInfo?: boolean) {
    this.hasInfo = hasInfo;
  }

  setHasLink(hasLink?: boolean) {
    this.hasLink = hasLink;
  }

  setHasGoogleAccounts(hasGoogleAccounts: boolean) {
    this.hasGoogleAccounts = hasGoogleAccounts;
  }

  setHasFacebookAccounts(hasFacebookAccounts: boolean) {
    this.hasFacebookAccounts = hasFacebookAccounts;
  }

  setHasFeedback(hasFeedback?: boolean) {
    this.hasFeedback = hasFeedback;
  }

  setHasPendingFeedback(hasPendingFeedback?: boolean) {
    this.hasPendingFeedback = hasPendingFeedback;
  }

  setConfig(config: { [key: string]: unknown }) {
    this.config = config || {};
  }

  setCalendarDate(date?: moment.Moment) {
    this.calendarDate = date;
  }

  updateConfig(config: { [key: string]: unknown }) {
    this.config = { ...this.config, ...config };
    localForage.setItem('config', JSON.stringify(this.config));
  }

  setEditMultiple(editMultiple: boolean) {
    this.editMultiple = editMultiple;
  }

  addTaskToMultipleEdit(task: string) {
    this.editMultipleList.push(task);
  }

  removeTaskFromMultipleEdit(task: string) {
    this.editMultipleList = this.editMultipleList.filter((t) => t !== task);
  }

  cleanEditMultipleList() {
    this.editMultipleList = [];
  }

  setProfile(
    profile?: {
      name: string;
      email: string;
      roles: string[];
      id: string;
      avatarUrl: string;
      accounts: any[];
      realmId: string;
    } | null,
  ) {
    //  FIXME  any
    this.profile = profile;
  }
}
