import React, { useCallback, useLayoutEffect, useMemo, useState } from 'react';
import { gql, useLazyQuery } from '@apollo/client';
import { observer } from 'mobx-react-lite';
import { Select, Typography } from 'antd';

import AvatarPicture from '@comp/AvatarPicture';

import useConfig from '@logic/config';
import { useStore } from '@logic/context';

import styles from './styles.module.less';

interface FilterUserAccountCellProps {
  params: any;
}

const FilterUserAccountCell: React.FC<FilterUserAccountCellProps> = observer(({ params }) => {
  const config = useConfig();
  const { profile } = useStore();

  const admin = profile?.roles.includes('admin');

  const [fetchData, { data, loading }] = useLazyQuery(
    !admin ? user_accounts_and_cells : realm_accounts_cells_and_users,
    { fetchPolicy: 'cache-and-network' },
  );

  const { users, cells, accounts } = params;

  const usersSelect: any[] = data?.me?.realm?.users?.data;
  const cellsSelect: any[] = admin ? data?.me?.realm?.cells?.data : data?.me?.cells?.data;
  const accountsSelect: any[] = admin ? data?.me?.realm?.accounts?.data : data?.me?.accounts?.data;

  useLayoutEffect(() => {
    fetchData();
  }, [fetchData]);

  const onDeSelect = useCallback(
    (value) => {
      if (users && users.findIndex((user: any) => user === value) !== -1) {
        const newUsers = [...(users || [])];
        const valueIndex = newUsers.indexOf(value.toString());
        newUsers.splice(valueIndex, 1);
        config.setConfig('timers_filter_users', newUsers);
      } else if (cells && cells.findIndex((cell: any) => cell === value) !== -1) {
        const newCells = [...(cells || [])];
        const valueIndex = newCells.indexOf(value.toString());
        newCells.splice(valueIndex, 1);
        config.setConfig('timers_filter_cells', newCells);
      } else if (accounts && accounts.findIndex((acc: any) => acc === value) !== -1) {
        const newAccs = [...(accounts || [])];
        const valueIndex = newAccs.indexOf(value.toString());
        newAccs.splice(valueIndex, 1);
        config.setConfig('timers_filter_accounts', newAccs);
      }
    },
    [config, cells, users, accounts],
  );

  const onSelect = useCallback(
    (value) => {
      if (usersSelect && usersSelect.findIndex((user) => user.id === value) !== -1) {
        const newUsers = [...(users || [])];
        newUsers.push(value.toString());
        config.setConfig('timers_filter_users', newUsers);
      } else if (cellsSelect && cellsSelect.findIndex((cell) => cell.id === value) !== -1) {
        const newCells = [...(cells || [])];
        newCells.push(value.toString());
        config.setConfig('timers_filter_cells', newCells);
      } else if (accountsSelect && accountsSelect.findIndex((acc) => acc.id === value) !== -1) {
        const newAccs = [...(accounts || [])];
        newAccs.push(value.toString());
        config.setConfig('timers_filter_accounts', newAccs);
      }
    },
    [config, cells, users, accounts, usersSelect, cellsSelect, accountsSelect],
  );

  return (
    <SelectComponent
      value={[...(users || []), ...(cells || []), ...(accounts || [])]}
      admin={!!admin}
      loading={loading}
      usersSelect={usersSelect}
      cellsSelect={cellsSelect}
      accountsSelect={accountsSelect}
      onClear={() => {
        config.setConfig('timers_filter_users', undefined);
        config.setConfig('timers_filter_cells', undefined);
        config.setConfig('timers_filter_accounts', undefined);
      }}
      onSelect={onSelect}
      onDeSelect={onDeSelect}
    />
  );
});

export default FilterUserAccountCell;

const realm_accounts_cells_and_users = gql`
  query Realm {
    me {
      id
      realm {
        id
        accounts(pagination: { page: 1, limit: -1 }) {
          data {
            id
            name
            logoUrl
          }
        }
        cells(pagination: { page: 1, limit: -1 }) {
          data {
            id
            name
            logoUrl
          }
        }
        users(pagination: { page: 1, limit: -1 }) {
          data {
            id
            name
            avatarUrl
            status
          }
        }
      }
    }
  }
`;

const user_accounts_and_cells = gql`
  query Realm {
    me {
      id
      name
      avatarUrl
      accounts(pagination: { page: 1, limit: -1 }) {
        data {
          id
          name
          logoUrl
        }
      }
      cells(pagination: { page: 1, limit: -1 }) {
        data {
          id
          name
          logoUrl
        }
      }
    }
  }
`;

interface SelectComponentProps {
  loading: boolean;
  admin: boolean;
  onDeSelect: (value: any) => void;
  onSelect: (value: any) => void;
  onClear: () => void;
  usersSelect: any[];
  cellsSelect: any[];
  accountsSelect: any[];
  value: any[];
}
const SelectComponent: React.FC<SelectComponentProps> = ({
  loading,
  admin,
  onDeSelect,
  onSelect,
  onClear,
  usersSelect,
  cellsSelect,
  accountsSelect,
  value,
}) => {
  const [searchText, setSearchText] = useState<string>();

  const handleSearch = useCallback((value?: any) => setSearchText(value), []);

  const usersSelectItems = useMemo(() => {
    let users = usersSelect;

    if (searchText) {
      users = (users || []).filter((user) => user.name.toLowerCase().indexOf(searchText.toLowerCase()) !== -1);
    }

    users = (users || []).filter((user: any) => user?.status !== 'inactive');

    if (users && users.length > 0) {
      return (
        <Select.OptGroup label="Usuários">
          {(users || []).map((user: any) => (
            <Select.Option key={user?.id} value={user?.id}>
              <div className={styles.users_list_item}>
                <AvatarPicture pictureLink={user?.avatarUrl} target="User" size={20} name={user?.name} />
                <Typography.Text className={styles.user_name}>{user?.name}</Typography.Text>
              </div>
            </Select.Option>
          ))}
        </Select.OptGroup>
      );
    }

    return null;
  }, [usersSelect, searchText]);

  const cellsSelectItems = useMemo(() => {
    let cells = cellsSelect;

    if (searchText) {
      cells = cells.filter((cell) => cell.name.toLowerCase().indexOf(searchText.toLowerCase()) !== -1);
    }

    if (cells && cells.length > 0) {
      return (
        <Select.OptGroup label="Células">
          {cells.map((cell: any) => (
            <Select.Option key={cell?.id} value={cell?.id}>
              <div className={styles.users_list_item}>
                <AvatarPicture pictureLink={cell?.logoUrl} target="Cell" size={20} name={cell?.name} />
                <Typography.Text className={styles.user_name}>{cell?.name}</Typography.Text>
              </div>
            </Select.Option>
          ))}
        </Select.OptGroup>
      );
    }

    return null;
  }, [cellsSelect, searchText]);

  const accountsSelectItems = useMemo(() => {
    let accounts = accountsSelect;

    if (searchText) {
      accounts = accounts.filter((account) => account.name.toLowerCase().indexOf(searchText.toLowerCase()) !== -1);
    }

    if (accounts && accounts.length > 0) {
      return (
        <Select.OptGroup label="Clientes">
          {accounts.map((account: any) => (
            <Select.Option key={account?.id} value={account?.id}>
              <div className={styles.users_list_item}>
                <AvatarPicture pictureLink={account?.logoUrl} target="Cell" size={20} name={account?.name} />
                <Typography.Text className={styles.user_name}>{account?.name}</Typography.Text>
              </div>
            </Select.Option>
          ))}
        </Select.OptGroup>
      );
    }

    return null;
  }, [accountsSelect, searchText]);

  return (
    <Select
      mode="multiple"
      style={{ minWidth: 300, marginLeft: 6 }}
      allowClear
      loading={loading}
      placeholder={admin ? 'Filtrar por usuário, célula ou cliente' : 'Filtrar por cliente ou célula'}
      onClear={onClear}
      value={value}
      onSearch={handleSearch}
      showSearch
      filterOption={false}
      dropdownMatchSelectWidth={false}
      onDeselect={onDeSelect}
      onSelect={onSelect}>
      {cellsSelectItems}

      {accountsSelectItems}

      {admin && usersSelectItems}
    </Select>
  );
};
