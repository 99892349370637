import React, { useEffect, useState } from 'react';
import { useMutation } from '@apollo/client';
import { observer } from 'mobx-react-lite';
import { Card, Button, Form, notification, Typography, Tooltip } from 'antd';
import { DateTime } from 'luxon';

import EmojiLinkInput from '@comp/EmojiLinkInput';
import Upload from '@comp/Upload';
import OnPasteDiv from '@comp/OnPasteDiv';
import AvatarPicture from '@comp/AvatarPicture';

import { update_note, delete_note } from '@logic/mutations';
import errorMessage from '@logic/functions/errorHandeling';
import { UploadItem } from '@logic/stores/uploadManager';
import { useUploadManager, useStore } from '@logic/context';

import styles from './styles.module.less';

interface Props {
  index: number;
  taskId: string;
  noteId: string;
  createdAt: string;
  user: any;
  note: string;
  refresh: () => void;
}

const EditNote: React.FC<Props> = ({ index, taskId, noteId, note, createdAt, user, refresh }) => {
  const [focus, setFocus] = useState(false);
  const [form] = Form.useForm();

  const uploadManager = useUploadManager();
  const store = useStore();

  const profileId = store.profile?.id;
  const isAdmin = store.profile?.roles.includes('admin');

  const canEdit = user.id === profileId || isAdmin;

  //--------------------- Mutation -------------------------------

  const [UpdateNote] = useMutation(update_note);
  const [DeleteNote] = useMutation(delete_note);

  // Atualiza os campos quando existem novos valores

  useEffect(() => {
    form.setFieldsValue({ ...form.getFieldsValue(), body: note });
  }, [note, form]);

  //---------------- SAVE & DELETE FUNCTION ----------------------
  async function doSave(body: string) {
    if (!focus) {
      notification.open({ type: 'error', message: 'Clique em editar para editar o texto da nota.' });
      return;
    }
    try {
      await UpdateNote({ variables: { id: noteId, body: body } }).then(() => {
        notification.open({
          placement: 'bottomRight',
          duration: 2,
          type: 'success',
          message: 'Nota atualizada.',
        });
        setFocus(false);
        refresh();
      });
    } catch (err) {
      notification.open({ type: 'error', ...errorMessage('graph_err', err) });
    }
  }
  async function Delete(id: string) {
    try {
      await DeleteNote({ variables: { id } });

      notification.open({
        placement: 'bottomRight',
        duration: 2,
        type: 'success',
        message: 'Nota excluída',
      });
      setFocus(false);
      refresh();
    } catch (err) {
      notification.open({ type: 'error', ...errorMessage('graph_err', err) });
    }
  }

  //------------------ Form functions ------------------------------

  const onFinish = () => {
    form
      .validateFields()
      .then((values) => {
        doSave(values.body);
        setFocus(false);
        form.resetFields();
      })
      .catch((info) => {
        notification.open({ type: 'error', ...errorMessage('form_validation', info) });
      });
  };

  const onFinishFailed = (errorInfo: any) => {
    notification.open({ type: 'error', ...errorMessage('', errorInfo) });
  };

  //------------------------------------------------------------------------------

  return (
    <OnPasteDiv
      onPaste={(data) => {
        for (const file of data) {
          const uploadItem: UploadItem = { targetId: noteId, targetType: 'Note', file };

          uploadManager.uploadFile(uploadItem);
        }
      }}>
      <Card
        id="edit_note"
        bordered
        style={{ marginBottom: '16px', borderColor: '#ff5333' }}
        headStyle={{ paddingLeft: 12, paddingRight: 12 }}
        bodyStyle={{ paddingBottom: 12, paddingLeft: 12, paddingRight: 12 }}
        hoverable={true}
        title={
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <div
              style={{ display: 'flex', flexDirection: 'row', cursor: 'pointer' }}
              //onClick={() => history.push(route(''))} ir para o perfil de quem criou a nota
            >
              <div className={styles.profilePhoto}>
                <AvatarPicture pictureLink={user.avatarUrl} target="User" size={40} name={user.name} />
              </div>

              <div>
                <Tooltip
                  destroyTooltipOnHide={{ keepParent: false }}
                  placement="topLeft"
                  mouseEnterDelay={0.3}
                  title={user.email}>
                  <Typography className={styles.name}>{user.name}</Typography>
                </Tooltip>

                <Typography className={styles.date}>
                  {DateTime.fromFormat(createdAt, 'yyyy-LL-dd HH:mm:ss', { zone: 'utc' })
                    .toLocal()
                    .toFormat('HH:mm:ss - dd/LL/yyyy')}
                </Typography>
              </div>
            </div>

            <div>
              {canEdit && !focus && (
                <Button title={'Edite suas notas'} type="default" onClick={() => setFocus(true)}>
                  Editar
                </Button>
              )}

              {focus && (
                <>
                  <Button onClick={() => form.submit()} type="primary">
                    Salvar
                  </Button>
                  <Button onClick={() => setFocus(false)} style={{ marginLeft: 6 }}>
                    Cancelar
                  </Button>
                </>
              )}

              {canEdit && (
                <Button
                  title={'Exclua suas notas'}
                  type="dashed"
                  onClick={() => Delete(noteId)}
                  style={{ marginLeft: '6px' }}>
                  Excluir
                </Button>
              )}
            </div>
          </div>
        }>
        <Form
          form={form}
          layout="vertical"
          name={`edit_note_${noteId}`}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          initialValues={{ body: note }}>
          <Form.Item name="body">
            <EmojiLinkInput
              multiline
              border={focus}
              disabled={!focus}
              emojiPicker={focus ? 'on' : 'off'}
              viewOnly={!focus}
              className={styles.body}
            />
          </Form.Item>
        </Form>

        <Upload targetId={noteId} viewOnly={!focus} parentId={taskId} targetType="Note" />
      </Card>
    </OnPasteDiv>
  );
};

export default observer(EditNote);
