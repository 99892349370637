import React, { useState, useEffect } from 'react';
import { Form, Input, Card, Button, Typography, notification } from 'antd';

import { useParams } from 'react-router-dom';

import { useLazyQuery } from '@apollo/client';
import { gql } from '@apollo/client';

import Navigate from '@comp/Navigate';
import errorMessage from '@logic/functions/errorHandeling';

import idLogo from '../../img/id_lab_logo_vertical.png';
import styles from './styles.module.less';

const userFromResetPasswordtoken = gql`
  query UserFromResetPasswordtoken($token: String!) {
    userFromResetPasswordtoken(token: $token) {
      name
    }
  }
`;

const ResetPassword: React.FC = () => {
  const [success, setSuccess] = useState(false);
  const [expired, setExpired] = useState(false);

  const { token } = useParams<any>();

  const [checkToken, { data, error }] = useLazyQuery(userFromResetPasswordtoken, { variables: { token } });

  useEffect(() => {
    if (!token) return;
    checkToken();
  }, [token, checkToken]);

  useEffect(() => {
    if (error) setExpired(true);
    else if (data) setExpired(false);
  }, [data, error, token]);

  const onFinish = (values: any) => {
    console.log('Success:', values);
    setSuccess(true);
  };

  const onFinishFailed = (errorInfo: any) => {
    notification.open({ type: 'error', ...errorMessage('form_validation', errorInfo) });
  };

  return (
    <div className={styles.root}>
      {expired && (
        <Card hoverable={false} className={styles.card} bodyStyle={{ flex: 1, width: '100%', padding: '16px 32px' }}>
          <div style={{ display: 'flex', justifyContent: 'center', marginBottom: '16px' }}>
            <Typography.Text>Este link não é mais válido, por favor gere um novo link.</Typography.Text>
          </div>
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <Navigate to="/recover_password">
              <Button type="primary">Gerar um novo link</Button>
            </Navigate>
          </div>
        </Card>
      )}

      {success && (
        <Card hoverable={false} className={styles.card} bodyStyle={{ flex: 1, width: '100%', padding: '8px 32px' }}>
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <img src={idLogo} alt="id escrito em forma de uma seta" />
          </div>
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <Typography.Text>Senha alterada</Typography.Text>
          </div>
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <Navigate to="/">
              <Button type="primary">Ir para o login</Button>
            </Navigate>
          </div>
        </Card>
      )}

      {!expired && !success && (
        <Card hoverable={false} className={styles.card} bodyStyle={{ flex: 1, width: '100%', padding: '8px 32px' }}>
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <img src={idLogo} alt="id escrito em forma de uma seta" />
          </div>
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <Typography.Text>Recuperação de senha</Typography.Text>
          </div>
          <Form
            name="password_reset"
            layout="vertical"
            initialValues={{ remember: true }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            wrapperCol={{ span: 24 }}>
            <Form.Item
              label="Nova senha"
              name="password"
              rules={[
                { min: 8, message: 'A senha precisa conter ao menos 8 caracteres' },
                { required: true, message: 'Este campo é obrigatório' },
              ]}>
              <Input.Password />
            </Form.Item>

            <Form.Item
              label="Confirmar nova senha"
              name="password_confirm"
              dependencies={['password']}
              rules={[
                { required: true, message: 'Este campo é obrigatório' },
                ({ getFieldValue }) => ({
                  validator(rule, value) {
                    if (!value || getFieldValue('password') === value) {
                      return Promise.resolve();
                    }
                    return Promise.reject('As senhas não coincidem');
                  },
                }),
              ]}>
              <Input.Password />
            </Form.Item>

            <Form.Item>
              <Button type="primary" htmlType="submit" block loading={false}>
                Salvar
              </Button>
            </Form.Item>
          </Form>
        </Card>
      )}
    </div>
  );
};

export default ResetPassword;
