import React, { useCallback, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { DateTime } from 'luxon';
import { Card, Button, Rate } from 'antd';

import EmojiLinkInput from '@comp/EmojiLinkInput';
import AvatarPicture from '@comp/AvatarPicture';
import Upload from '@comp/Upload';

import styles from './styles.module.less';

interface Props {
  taskId: string;
  accountId: string; // Unused
  id: string;
  nps: number;
  status: string; // Unused
  feedback: string;
  user: string;
  createdAt: string;
  userID: string; // Unused
  avatarUrl: string;
  refetch: () => void; // Unused
}

const Feedback: React.FC<Props> = ({
  taskId,
  accountId,
  id,
  feedback: paramFeedback,
  status,
  nps: paramNps,
  createdAt,
  user,
  avatarUrl,
  userID,
  refetch,
}) => {
  const history = useHistory();

  const [{ feedback, nps }] = useState({ feedback: paramFeedback, nps: paramNps });

  //------------------------------------------------------------------------------

  const tasksRouter = useCallback(
    (taskId: string) => {
      let path = '/#task/' + taskId;

      history.push(path);
    },
    [history],
  );

  //------------------------------------------------------------------------------

  return (
    <Card
      style={cardStyle}
      headStyle={cardHeadStyle}
      bodyStyle={cardBodyStyle}
      className={styles.card}
      extra={
        <div id="pending_feedbacks_task">
          <Button id="drawerException" size="small" onClick={() => tasksRouter(taskId)}>
            Ver Atividade
          </Button>
        </div>
      }
      hoverable
      title={
        <Card.Meta
          className="feedback"
          avatar={<AvatarPicture pictureLink={avatarUrl} target="User" size={40} name={user} />}
          title={user}
          description={DateTime.fromFormat(createdAt, 'yyyy-LL-dd HH:mm:ss', { zone: 'utc' })
            .toLocal()
            .toFormat('HH:mm:ss - dd/LL/yyyy')}
        />
      }>
      {/*title={<div style={{ display: 'flex', flexDirection: 'row' }}>
          <div style={{ display: 'flex', flex: 1 }}>
            <div className={styles.profilePhoto}>
              <AvatarPicture pictureLink={avatarUrl} target="User" size={40} />
            </div>

            <div>
              <Typography.Text ellipsis className={styles.name}>
                {user}
              </Typography.Text>

              <Typography className={styles.date}>
                {DateTime.fromFormat(createdAt, 'yyyy-LL-dd HH:mm:ss', { zone: 'utc' })
                  .toLocal()
                  .toFormat('HH:mm:ss - dd/LL/yyyy')}
              </Typography>
            </div>
          </div>
      </div>}*/}

      {!feedback && nps > 0 && (
        <span className={styles.nps}>
          <Rate disabled value={nps} style={{ fontSize: 24 }} />
        </span>
      )}

      {!!feedback && (
        <EmojiLinkInput
          border={false}
          multiline
          emojiPicker={'off'}
          className={styles.body}
          viewOnly
          value={feedback}
        />
      )}

      <div style={{ width: '100%' }}>
        <Upload targetId={id} viewOnly parentId={taskId} targetType="Feedback" previewSize={60} />
      </div>

      {!!feedback && nps > 0 && (
        <span className={styles.nps}>
          <Rate disabled value={nps} style={{ fontSize: 16 }} />
        </span>
      )}
    </Card>
  );
};

export default observer(Feedback);

const cardStyle = { marginBottom: '8px', borderColor: '#ff5333' };
const cardHeadStyle = { padding: '0 12px' };
const cardBodyStyle = { padding: 12 };
