import React, { useState, useCallback, useMemo, useEffect } from 'react';
import { useQuery, useMutation } from '@apollo/client';
import { Typography, Select, Tag } from 'antd';

import ErrorAndLoading from '@comp/ErrorAndLoading';
import AvatarPicture from '@comp/AvatarPicture';

import { cell_members_select_attach, task_users } from '@logic/queries';
import { attach_user, detach_user } from '@logic/mutations';

import styles from './styles.module.less';

interface Props {
  target: string; // Atividade a que o usuário será adicionado
  attachType: string; // Cell, Account ou Task
  selectMode: 'multiple' | 'tags' | undefined;
  cellId: string; // célula de onde serão buscados os usuários
  avatarsView?: boolean; // unused
}

const AttatchUser: React.FC<Props> = React.forwardRef(
  ({ attachType, target, selectMode, cellId, avatarsView }, ref: any) => {
    const [searchText, setSearchText] = useState<string | undefined>();

    //---------------------- QUERIES & MUTATIONS -----------------------------

    // Gets the members from the task's cell
    const { data: cellMembers, loading: cellMembersLoading, error: cellMembersError } = useQuery(
      cell_members_select_attach,
      {
        fetchPolicy: 'network-only',
        variables: { id: cellId },
      },
    );

    // Gets the members responsible for the task
    const { data: taskUsers, loading: taskUsersLoading, error: taskUsersError, refetch: refetchTaskUsers } = useQuery(
      task_users,
      {
        fetchPolicy: 'network-only',
        variables: { id: target },
      },
    );

    const [AttachUser] = useMutation(attach_user);
    const [DetachUser] = useMutation(detach_user);

    // Search members
    const handleSearch = useCallback((value?: any) => setSearchText(value), []);

    // Attach user and update the selected members list
    const handleAttach = useCallback(
      (value: any) =>
        AttachUser({ variables: { target, type: attachType, user: value } }).then(() => {
          handleSearch();
          refetchTaskUsers();
        }),
      [handleSearch, AttachUser, refetchTaskUsers, attachType, target],
    );

    // Detach user and update the selected members list
    const handleDetach = useCallback(
      (value: any) =>
        DetachUser({ variables: { target, type: attachType, user: value } }).then(() => {
          handleSearch();
          refetchTaskUsers();
        }),
      [handleSearch, DetachUser, refetchTaskUsers, attachType, target],
    );

    // Clear search and stop propagation into form
    const handleBlur = useCallback(
      (e: any) => {
        e.stopPropagation();
        handleSearch();
      },
      [handleSearch],
    );

    //---------------------- Select memos -----------------------------

    useEffect(() => {
      new Promise(async () => {
        const cellMembersList = (cellMembers?.cell?.members?.data || []).map(({ id }: any) => id);
        const taskUsersList = (taskUsers?.task?.users || []).map(({ id }: any) => id);

        if (cellMembersList.length === 0) return;

        // Checks if there are any members that don't belong to the cell responsible for the task and removes them
        for (const id of taskUsersList) {
          const index = cellMembersList.indexOf(id);

          if (index === -1) await DetachUser({ variables: { target, type: attachType, user: id } });
        }

        await refetchTaskUsers();
      }).catch((err) => console.error(err));
    }, [cellMembers, taskUsers, handleDetach, DetachUser, attachType, target, refetchTaskUsers]);

    //---------------------- Select memos -----------------------------
    const selectedUsersIds = useMemo(() => (taskUsers?.task?.users || []).map((user: any) => user.id), [taskUsers]);

    const cellMembersList = useMemo(() => {
      //  TODO  remover acentos dos nomes
      let members = cellMembers?.cell?.members?.data || [];

      if (searchText) {
        members = members.filter((member: any) => member.name.toLowerCase().indexOf(searchText.toLowerCase()) !== -1);
      }

      return members.map(
        ({ id, name, avatarUrl, status }: { id: string; name: string; avatarUrl: string; status: any }) => {
          if (status === 'inactive') return null;
          return (
            <Select.Option key={id} value={id} disabled={status === 'inactive'}>
              <div className={styles.users_list_item}>
                <AvatarPicture pictureLink={avatarUrl} target="User" size={20} name={name} />
                <Typography.Text className={styles.user_name}>{name}</Typography.Text>
              </div>
            </Select.Option>
          );
        },
      );
    }, [cellMembers, searchText]);

    const inactiveUsers = useMemo(() => {
      let members = cellMembers?.cell?.members?.data || [];

      return members.filter(({ status }: { status: any }) => status === 'inactive');
    }, [cellMembers]);

    const select = useMemo(() => {
      return (
        <Select
          ref={ref}
          mode={selectMode}
          style={{ width: '100%' }}
          value={selectedUsersIds}
          onBlur={handleBlur}
          onSelect={handleAttach}
          onDeselect={handleDetach}
          onSearch={handleSearch}
          showSearch
          filterOption={false}
          tagRender={({ label, value, closable, onClose }: any) => {
            const index = inactiveUsers.findIndex((user: any) => user.id === value);

            if (typeof label === 'string' && index !== -1)
              return (
                <Tag
                  closable={closable}
                  onClose={onClose}
                  title="Usuário inativo"
                  style={{ ...tagStyle, backgroundColor: '#ff8e8e77' }}>
                  <div className={styles.users_list_item}>
                    <AvatarPicture
                      pictureLink={inactiveUsers[index]?.avatarUrl}
                      target="User"
                      size={20}
                      name={inactiveUsers[index]?.name}
                    />
                    <Typography.Text className={styles.user_name}>{inactiveUsers[index]?.name}</Typography.Text>
                  </div>
                </Tag>
              );

            return (
              <Tag closable={closable} onClose={onClose} style={{ ...tagStyle, backgroundColor: '#f1f1f1' }}>
                {label}
              </Tag>
            );
          }}>
          {cellMembersList}
        </Select>
      );
    }, [
      ref,
      selectMode,
      cellMembersList,
      selectedUsersIds,
      handleAttach,
      handleDetach,
      handleSearch,
      handleBlur,
      inactiveUsers,
    ]);

    if (cellMembersLoading || taskUsersLoading || cellMembersError || taskUsersError) {
      return (
        <ErrorAndLoading
          borderless
          loadingSize="20px"
          cardStyle={{ display: 'block', margin: -24 }}
          loading={cellMembersLoading || taskUsersLoading}
          error={cellMembersError || taskUsersError}
        />
      );
    }

    return select;
  },
);

export default AttatchUser;

const tagStyle = {
  display: 'flex',
  alignItems: 'center',
  marginRight: 3,
  padding: '2px 6px',
  border: 'none',
  borderRadius: 3,
  cursor: 'default',
};
