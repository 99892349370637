import React from 'react';

import Help from '@comp/Help';

import styles from './styles.module.less';

interface Props {
  total: number;
  done: number;
  weekdaysUntilEndOfMonth: number;
}

const Goal: React.FC<Props> = ({ total, done, weekdaysUntilEndOfMonth }) => {
  const idealTasksPerDay = (total - done) / (weekdaysUntilEndOfMonth || 1);

  return (
    <div className={styles.root}>
      <div className={styles.text}>Você precisa realizar {idealTasksPerDay.toFixed(1)} atividades por dia</div>

      <div className={styles.help}>
        <Help
          title="Média de atividades"
          description="Este valor considera tudo que tem aberto para realizar nos próximos 30 dias"
          iconStyle={{ color: '#ededed' }}
          placement="bottom"
        />
      </div>
    </div>
  );
};

export default Goal;
