import React, { useCallback, useEffect, useState } from 'react';
import { useMutation } from '@apollo/client';
import { Typography, Input, notification, Row, Col } from 'antd';

import Help from '@comp/Help';

import { update_link } from '@logic/mutations';
import errorMessage from '@logic/functions/errorHandeling';

import styles from './styles.module.less';

interface Props {
  link: any;
  accountId: string; // Unused
}

const EditWhatsappLink: React.FC<Props> = ({ link, accountId }) => {
  const [links, setLinks] = useState<any>({
    id: link.id,
    subdomain: '',
    path: '',
    dest: '',
    title: '',
    body: '',
    ml_group_id: '',
  });

  useEffect(() => {
    setLinks(link);
  }, [link]);

  //---------------------- mutations ------------------------------

  //  TODO  converter whatsapp para link

  const [UpdateWhatsapp] = useMutation(update_link);

  //--------------Save and Delete functions -----------------------

  const update = useCallback(
    async (key: string, value: any) => {
      try {
        await UpdateWhatsapp({ variables: { id: link.id, [key]: value } });
      } catch (err) {
        notification.open({ type: 'error', ...errorMessage('graph_err', err) });
      }
    },
    [UpdateWhatsapp, link.id],
  );

  //------------- Form functions ------------------------------------------------

  const handleChange = ({ target: { name, value } }: any) => {
    setLinks((t: any) => ({ ...t, [name]: value }));
  };

  const handleBlur = ({ target }: any) => {
    if (link[target.name] !== target.value) {
      update(target.name, target.value);
    }
  };

  //------------------------------------------------------------------------------

  return (
    <div style={{ paddingBottom: '16px' }}>
      {!!link && (
        <div className={styles.root} style={{ height: '100%' }}>
          <Row id="link_subdomain" className={styles.title}>
            <Col span={6} className={styles.column}>
              <Typography.Text className={styles.label}>Subdomínio: </Typography.Text>
              <Help
                title="Subdomínio"
                description="Subdomínio do link. Este é um campo obrigatório."
                text={
                  <Typography.Text className={styles.example}>
                    https://<b className={styles.bold}>subdominio</b>.idaction.link/caminho
                  </Typography.Text>
                }
                overlayStyle={{ minWidth: 320 }}
                placement="left"
                iconStyle={{ color: '#5c5c5c', fontSize: 12 }}
              />
            </Col>
            <Col span={18}>
              <Input
                name="subdomain"
                placeholder="Subdomínio do link"
                style={{
                  border: undefined,
                  width: '100%',
                  borderRadius: 4,
                  padding: '2px 4px',
                  whiteSpace: 'break-spaces',
                }}
                value={links.subdomain}
                onChange={handleChange}
                onBlur={handleBlur}
              />
            </Col>
          </Row>

          <Row id="link_path" className={styles.title}>
            <Col span={6} className={styles.column}>
              <Typography.Text className={styles.label}>Caminho: </Typography.Text>
              <Help
                title="Caminho"
                description="É um identificador que precisa ser único para o mesmo subdomínio. Este é um campo obrigatório."
                text={
                  <Typography.Text className={styles.example}>
                    https://empresa.idaction.link/<b className={styles.bold}>caminho</b>
                  </Typography.Text>
                }
                overlayStyle={{ minWidth: 320 }}
                placement="left"
                iconStyle={{ color: '#5c5c5c', fontSize: 12 }}
              />
            </Col>
            <Col span={18}>
              <Input
                name="path"
                placeholder="Caminho do link"
                style={{
                  border: undefined,
                  width: '100%',
                  borderRadius: 4,
                  padding: '2px 4px',
                  whiteSpace: 'break-spaces',
                }}
                value={links.path}
                onChange={handleChange}
                onBlur={handleBlur}
              />
            </Col>
          </Row>

          <Row id="link_dest" className={styles.title}>
            <Col span={6} className={styles.column}>
              <Typography.Text className={styles.label}>Destino: </Typography.Text>
              <Help
                title="Destino"
                description="É o site ou link que será redirecionado. Este é um campo obrigatório."
                text={
                  <Typography.Text className={styles.example}>
                    <b className={styles.bold}>https://www.link-para-abrir.com.br</b>
                  </Typography.Text>
                }
                overlayStyle={{ minWidth: 320 }}
                placement="left"
                iconStyle={{ color: '#5c5c5c', fontSize: 12 }}
              />
            </Col>
            <Col span={18}>
              <Input
                name="dest"
                placeholder="Destino do link"
                style={{
                  border: undefined,
                  width: '100%',
                  borderRadius: 4,
                  padding: '2px 4px',
                  whiteSpace: 'break-spaces',
                }}
                value={links.dest}
                onChange={handleChange}
                onBlur={handleBlur}
              />
            </Col>
          </Row>

          <Row id="link_title" className={styles.title}>
            <Col span={6} className={styles.column}>
              <Typography.Text className={styles.label}>Título: </Typography.Text>
              <Help
                title="Título"
                description="É o título da conversa de whatsapp."
                placement="left"
                iconStyle={{ color: '#5c5c5c', fontSize: 12 }}
              />
            </Col>
            <Col span={18}>
              <Input
                name="title"
                placeholder="Título do link"
                value={links.title}
                style={{
                  border: undefined,
                  width: '100%',
                  borderRadius: 4,
                  padding: '2px 4px',
                  whiteSpace: 'break-spaces',
                }}
                onChange={handleChange}
                onBlur={handleBlur}
              />
            </Col>
          </Row>

          <Row id="link_body" className={styles.title}>
            <Col span={6} className={styles.column}>
              <Typography.Text className={styles.label}>Descrição: </Typography.Text>
              <Help
                title="Descrição"
                description="É o texto da conversa de whatsapp."
                placement="left"
                iconStyle={{ color: '#5c5c5c', fontSize: 12 }}
              />
            </Col>
            <Col span={18}>
              <Input
                name="body"
                placeholder="Descrição do link"
                value={links.body}
                style={{
                  border: undefined,
                  width: '100%',
                  borderRadius: 4,
                  padding: '2px 4px',
                  whiteSpace: 'break-spaces',
                }}
                onChange={handleChange}
                onBlur={handleBlur}
              />
            </Col>
          </Row>

          <Row id="ml_group_id" className={styles.title}>
            <Col span={6} className={styles.column}>
              <Typography.Text className={styles.label}>Grupo MailerLite: </Typography.Text>
              <Help
                title="MailerLite"
                description="ID do grupo que o lead será criado dentro do MailerLite."
                placement="left"
                iconStyle={{ color: '#5c5c5c', fontSize: 12 }}
              />
            </Col>
            <Col span={18}>
              <Input
                name="ml_group_id"
                placeholder="ID do grupo do MailerLite"
                value={links.ml_group_id}
                style={{
                  border: undefined,
                  width: '100%',
                  borderRadius: 4,
                  padding: '2px 4px',
                  whiteSpace: 'break-spaces',
                }}
                onChange={handleChange}
                onBlur={handleBlur}
              />
            </Col>
          </Row>

          <Row>
            <Col span={6} className={styles.column}>
              <Typography.Text className={styles.label}>URL ID: </Typography.Text>
            </Col>
            <Col span={18}>
              <a href={link.url} target="_blank" rel="noreferrer">
                {link.url}
              </a>
            </Col>
          </Row>
        </div>
      )}
    </div>
  );
};

export default EditWhatsappLink;
