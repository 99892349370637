import React, { useState, useEffect } from 'react';
import { useQuery } from '@apollo/client';
import { useTranslation } from 'react-i18next';

import ErrorAndLoading from '@comp/ErrorAndLoading';
import Timer from '@comp/UserTimer';
import TutorialButton from '@comp/TutorialButton';
import EditTimer from './EditTimer';
import TutorialClockTab from './TutorialClockTab';

import { task_timer } from '@logic/queries';
import { useStore } from '@logic/context';

interface Props {
  taskId: string;
}

const ClockTab: React.FC<Props> = ({ taskId }) => {
  const [timers, setTimers] = useState<any[]>();
  const { profile } = useStore();

  const { t } = useTranslation('clockTab');

  // Apenas o usuário que criou o timer e o admin podem editar o timer
  const admin = profile?.roles.includes('admin');
  const userId = profile?.id;

  //----------------------------- QUERIES -------------------------------

  const { data, loading, error, refetch } = useQuery(task_timer, {
    variables: { id: taskId },
    fetchPolicy: 'cache-and-network',
  });

  //------------------------- data handeling -----------------------------

  useEffect(() => {
    if (!data) return;

    setTimers(data.task?.timers?.data);
  }, [data]);

  useEffect(() => {
    refetch({ id: taskId });
  }, [taskId, refetch]);

  //------------------------------------------------------------------------------

  return (
    <div>
      <Timer taskId={taskId} button={false} from="task" refresh={() => refetch({ id: taskId })} />

      {loading && <ErrorAndLoading loading={loading} />}

      {error && <ErrorAndLoading error={error} />}

      {timers &&
        timers.map(({ body, start_date, finish_date, id, user }: any) => {
          return (
            <EditTimer
              key={id}
              id={id}
              taskId={taskId}
              user={user}
              start_date={start_date}
              finish_date={finish_date}
              body={body}
              canEdit={!!admin || user.id === userId}
              refresh={() => refetch({ id: taskId })}
            />
          );
        })}

      {timers && timers.length === 0 && (
        <div style={{ display: 'flex', justifyContent: 'center' }}>{t('no_timers')}</div>
      )}

      <TutorialButton
        tourId="008_ClockTab"
        circleSize="small"
        iconSize={14}
        bottom={20}
        right={40}
        placement="left"
        title={t('clock_tab')}
      />

      <TutorialClockTab hasTimers={!timers || timers.length === 0 ? false : true} />
    </div>
  );
};

export default ClockTab;
