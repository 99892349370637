import axios, { CancelToken } from 'axios';
import { DocumentNode } from 'graphql';
import { gql } from '@apollo/client';

const uploadMutation = gql`
  mutation CreateUploadUrl($mimeType: String!) {
    createUploadUrl(mimetype: $mimeType) {
      key
      url
    }
  }
`;

interface UploadParams {
  file: File;
  applyMutation?: DocumentNode;
  applyVariables?: any;
  onUploadProgress?: (progressEvent: any) => void;
  cancelToken?: CancelToken;
}

function getApolloClient() {
  // @ts-ignore  FIXME  stop using window
  return window.apolloClient;
}

export async function uploadFile(params: UploadParams) {
  const client = getApolloClient();

  // Create upload url
  const { data } = await client.mutate({ mutation: uploadMutation, variables: { mimeType: params.file.type } });
  const url = data?.createUploadUrl?.url;

  // Upload file to digitaocean
  await axios.put(url, params.file, {
    headers: {
      'Content-Type': params.file.type,
      'x-amz-acl': 'public-read',
    },
    onUploadProgress: params.onUploadProgress,
    cancelToken: params.cancelToken,
  });

  // Return file's key
  return { key: data?.createUploadUrl?.key, url: url.split('?')[0] };
}

export async function uploadFileAndAttach(params: UploadParams) {
  const client = getApolloClient();

  // Upload file to digital ocean and get its key
  const { key } = await uploadFile(params);

  // Attach the file to element
  return await client.mutate({ mutation: params.applyMutation, variables: { key, ...params.applyVariables } });
}
