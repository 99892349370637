import React from 'react';
import { List } from 'antd';

import { EyeInvisibleOutlined, DeleteOutlined } from '@ant-design/icons';

import AvatarPicture from '@comp/AvatarPicture';

import { notificationAction, NotificationTime } from './Translations';

interface NotificationProps {
  id: string;
  type: string;
  initiatorName: string;
  initiatorAvatar: string;
  step: string;
  viewed: boolean;
  notified_at: string;
  taskTitle: string;
  taskId: string;
  displayOnly?: boolean;
  handleMarkAsSeen?: (id: string) => void;
  handleDelete?: (id: string) => void;
  handleOpenTask?: (id: string, tab: string) => void;
}

// Componente que apenas renderiza a notificação, sem mutations ou queries
// pois o notification.open() está fora do contexto do graph

const Notification: React.FC<NotificationProps> = ({
  id,
  type,
  initiatorName,
  initiatorAvatar,
  step,
  viewed,
  notified_at,
  taskId,
  taskTitle,
  displayOnly,
  handleMarkAsSeen,
  handleDelete,
  handleOpenTask,
}) => {
  const handleOnClick = () => {
    if (!viewed && !!handleMarkAsSeen) handleMarkAsSeen(id);
    if (taskId && handleOpenTask) handleOpenTask(taskId, type === 'task_note_created' ? '/notes_tab' : '/task_tab');
  };

  const initiator = <b style={{ color: 'rgba(0, 0, 0, 0.71)' }}>{initiatorName}</b>;

  return (
    <div
      id="drawerException"
      style={{
        padding: '4px 6px',
        cursor: !taskId ? 'default' : 'pointer',
        display: 'flex',
        width: '100%',
        ...(!displayOnly && !viewed ? { backgroundColor: '#ffecdd' } : undefined),
      }}
      key={id}
      onClick={handleOnClick}>
      <List.Item.Meta
        avatar={<AvatarPicture pictureLink={initiatorAvatar} target="User" size={40} name={initiatorName} />}
        title={
          <div style={{ marginBottom: -6 }}>
            {initiator} {notificationAction(type, { taskTitle, step })}
          </div>
        }
        description={<NotificationTime time={notified_at} />}
      />
      {!displayOnly && !!handleMarkAsSeen && (
        <div onClick={(e) => e.stopPropagation()}>
          {!viewed && (
            <EyeInvisibleOutlined
              title="Marcar como vista"
              onClick={() => handleMarkAsSeen(id)}
              style={{ marginRight: 6, marginLeft: 6 }}
            />
          )}
          {!!handleDelete && (
            <DeleteOutlined
              title="Excluir"
              onClick={() => handleDelete(id)}
              style={{ marginRight: 6, marginLeft: 6 }}
            />
          )}
        </div>
      )}
    </div>
  );
};

export default Notification;
