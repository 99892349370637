import React, { useState } from 'react';
import { useMutation } from '@apollo/client';
import { useParams, useHistory } from 'react-router-dom';
import { notification, Typography, Row, Col, DatePicker } from 'antd';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

import EmojiLinkInput from '@comp/EmojiLinkInput';
import StepSelector from '@comp/StepSelector';
import CellSelector from '@comp/CellSelector';
import AccountSelector from '@comp/AccountSelector';
import TutorialButton from '@comp/TutorialButton';
import Help from '@comp/Help';

import { stepsKeys, stepsIconsArray } from '../../..';
import { create_task } from '@logic/mutations';
import errorMessage from '@logic/functions/errorHandeling';

import styles from './styles.module.less';

const NewAssignment: React.FC = () => {
  const { id: paramId } = useParams<any>();
  const history = useHistory();

  const { t } = useTranslation('newAssignment');

  const date = history.location.hash.replace('#', '').split('/')[3];

  const defaultValues: any = {
    account: paramId,
    title: '',
    step: 'execution',
    body: '',
    cell: 'selectFirst',
    finish_date: date || undefined,
  };

  const [assignment, setAssignment] = useState<any>(defaultValues);

  //------------------------ Mutation -----------------------------

  const [CreateTask] = useMutation(create_task);

  //----------------------- Save function -------------------------

  async function create(key: string, value: any) {
    try {
      const { data } = await CreateTask({ variables: { type: 'assignment', ...{ ...assignment, [key]: value } } });

      history.push(history.location.pathname + '#task/' + data?.createTask?.id + '/task_tab');
    } catch (err) {
      notification.open({ type: 'error', ...errorMessage('graph_err', err) });
    }
  }

  const handleChange = ({ target: { name, value } }: any) => {
    if (
      ((name === 'cell' && !!assignment.account) || (name === 'account' && assignment.cell !== 'selectFirst')) &&
      !paramId
    ) {
      create(name, value);
    }
    setAssignment((t: any) => ({ ...t, [name]: value }));
  };

  const handleBlur = ({ target: { name, value } }: any) => {
    if (!assignment.account || assignment.cell === 'selectFirst') return;
    if (defaultValues[name] !== value) create(name, value);
  };

  //------------------------------------------------------------------------------

  return (
    <div id="any_field_task" className={styles.root} style={{ height: '100%' }}>
      <Row className={styles.form_item}>
        <Col span={6} className={styles.column}>
          <Typography.Text className={styles.label}>{t('title_label')}</Typography.Text>
          <Help
            title={t('help_title')}
            description={t('help_title_description')}
            placement="right"
            iconStyle={{ color: '#5c5c5c', fontSize: 12 }}
          />
        </Col>
        <Col span={18}>
          <EmojiLinkInput
            name="title"
            placeholder={t('title_placeholder')}
            border
            value={assignment.title}
            onChange={handleChange}
            onBlur={handleBlur}
            emojiPicker="focus"
          />
        </Col>
      </Row>

      <Row id="account_task" className={styles.form_item}>
        <Col span={6} className={styles.column}>
          <Typography.Text className={styles.label}>{t('customer_label')}</Typography.Text>
          <Help
            title={t('help_customer_title')}
            description={t('help_customer_description')}
            placement="right"
            iconStyle={{ color: '#5c5c5c', fontSize: 12 }}
          />
        </Col>
        <Col span={18}>
          <AccountSelector
            accountId={assignment.account}
            accountChange={(id) => handleChange({ target: { name: 'account', value: id } })}
          />
        </Col>
      </Row>

      {!!assignment.account && (
        <Row className={styles.form_item}>
          <Col span={6} className={styles.column}>
            <Typography.Text className={styles.label}>{t('cell_label')}</Typography.Text>
            <Help
              title={t('help_cell_title')}
              description={t('help_cell_description')}
              placement="right"
              iconStyle={{ color: '#5c5c5c', fontSize: 12 }}
            />
          </Col>
          <Col span={18}>
            <CellSelector
              cellId={assignment.cell}
              accountId={assignment.account}
              cellChange={(id) => handleChange({ target: { name: 'cell', value: id } })}
            />
          </Col>
        </Row>
      )}

      <Row className={styles.form_item}>
        <Col span={6} className={styles.column}>
          <Typography.Text className={styles.label}>{t('finish_date_label')}</Typography.Text>
          <Help
            title={t('help_finish_date_title')}
            description={t('help_finish_date_description')}
            placement="right"
            iconStyle={{ color: '#5c5c5c', fontSize: 12 }}
          />
        </Col>
        <Col span={18}>
          <DatePicker
            name="finish_date"
            format="DD/MM/YYYY"
            value={assignment?.finish_date ? moment(assignment?.finish_date) : null}
            onChange={(value) => handleChange({ target: { name: 'finish_date', value: momentToStr(value) } })}
            onBlur={({ target }) => handleBlur({ target: { name: 'finish_date', value: strToStr(target.value) } })}
          />
        </Col>
      </Row>

      <div className={styles.form_item}>
        <StepSelector
          type="assignment"
          onChange={({ target }) => handleChange({ target: { name: 'step', value: target.value } })}
          stepKeys={['execution', 'done']}
          stepKeysTitle={stepsKeys.filter((e, idx) => idx === 0 || idx === 4)}
          stepsIcons={stepsIconsArray().filter((e, idx) => idx === 0 || idx === 4)}
        />
      </div>

      <div className={styles.column}>
        <Typography.Text className={styles.label}>{t('descrption_label')}</Typography.Text>
        <Help
          title={t('help_description_title')}
          description={t('help_description')}
          iconStyle={{ color: '#5c5c5c', fontSize: 12 }}
          placement="right"
        />
      </div>
      <div className={styles.form_item}>
        <EmojiLinkInput
          name="body"
          emojiPicker="focus"
          placeholder={t('description_placeholder')}
          border
          multiline
          className={styles.body}
          value={assignment.body}
          onChange={handleChange}
          onBlur={handleBlur}
        />
      </div>

      <TutorialButton
        tourId="009_NewTask"
        circleSize="small"
        iconSize={14}
        top={18}
        right={490}
        placement="right"
        title={t('create_assignment')}
      />
    </div>
  );
};

export default NewAssignment;

function momentToStr(time?: moment.Moment | null) {
  if (!time) return null;
  return moment(time).format('YYYY-MM-DD');
}
function strToStr(date?: string | null) {
  if (!date) return undefined;
  return moment(date, 'DD/MM/YYYY').format('YYYY-MM-DD');
}
