import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useMutation, useQuery } from '@apollo/client';
import { Button, Card, notification, Popconfirm, Typography } from 'antd';

import { GlobalOutlined, UserOutlined, PlusOutlined, DeleteOutlined } from '@ant-design/icons';

import TemplatesList from './TemplatesList';
import TutorialTemplates from './TutorialTemplates';
import ErrorAndLoading from '@comp/ErrorAndLoading';
import TutorialButton from '@comp/TutorialButton';
import Help from '@comp/Help';

import { route } from '@router';
import errorMessage from '@logic/functions/errorHandeling';
import { create_tasks_from_template_items, delete_template, delete_template_items } from '@logic/mutations';
import { selected_account_templates_list, templates_list } from '@logic/queries';
import useConfig from '@logic/config';

import styles from './styles.module.less';

interface Props {
  accountTemplates: boolean;
}

const Templates: React.FC<Props> = ({ accountTemplates }) => {
  const history = useHistory();
  const config = useConfig();

  const { id: paramAccountId } = useParams<any>();

  const { accountId } = useMemo(() => ({ accountId: paramAccountId || '' }), [paramAccountId]);

  const [reloadItemsList, setReloadItemsList] = useState(false);
  const [templates, setTemplates] = useState<any[]>();
  const [selectedItems, setSelectedItems] = useState<string[]>([]);
  const [selectedTemplates, setSelectedTemplates] = useState<string[]>([]);

  // -------------------------- Query ---------------------------

  const { data, error, refetch } = useQuery(accountId ? selected_account_templates_list : templates_list, {
    fetchPolicy: 'no-cache',
    variables: !!accountId ? { id: accountId, limit: -1, page: 1 } : { limit: -1, page: 1 },
  });

  //---------------------- mutations ---------------------------------

  const [CreateTasksFromSelectedItems] = useMutation(create_tasks_from_template_items);

  const [DeleteTemplateItems] = useMutation(delete_template_items);

  const [DeleteTemplate] = useMutation(delete_template);

  // -------------------------------------------------------------------

  // reloads the lists
  useEffect(() => {
    setReloadItemsList(false);
  }, [reloadItemsList]);

  //refeshes the templates state
  useEffect(() => {
    if (!data) return;

    const templatesArray = !accountId
      ? data.me?.realm?.templates?.data
      : [...data.account?.templates?.data, ...data.account?.realm?.templates?.data];

    setTemplates(templatesArray);
  }, [data, accountId, refetch]);

  // refetches the data whenever necessary
  useEffect(() => {
    refetch(!!accountId ? { id: accountId, limit: -1, page: 1 } : { limit: -1, page: 1 });

    setReloadItemsList(true);
  }, [accountId, refetch]);

  // adds and removes templates and templateItems to the respective selected states
  const onSelect = useCallback(
    (type: 'templates' | 'items', id: string, value: boolean) => {
      if (!templates) return;

      const newSelectedItems = [...selectedItems];
      const newSelectedTemplates = [...selectedTemplates];

      const isTemplate = type === 'templates';
      const index = (isTemplate ? newSelectedTemplates : newSelectedItems).indexOf(id);
      const isSelected = index !== -1;

      let templateIdx;

      if (isTemplate) {
        templateIdx = templates.findIndex((t) => t.id === id);
      } else {
        for (const template of templates) {
          const templateItems = template.items.data.map((item: any) => item.id);

          if (templateItems.indexOf(id) !== -1) {
            templateIdx = templates.findIndex((t) => t.id === template.id);
          }
        }
      }

      if (templateIdx === undefined) return;

      const templateItems = templates[templateIdx].items.data;

      if (isTemplate) {
        if (value && !isSelected) {
          newSelectedTemplates.push(id);

          for (const item of templateItems) {
            const idx = newSelectedItems.indexOf(item.id);
            const itemIsSelected = idx !== -1;

            if (value && !itemIsSelected) {
              newSelectedItems.push(item.id);
            }
          }
        } else if (!value && isSelected) {
          newSelectedTemplates.splice(index, 1);

          for (const item of templateItems) {
            const idx = newSelectedItems.indexOf(item.id);
            const itemIsSelected = idx !== -1;

            if (!value && itemIsSelected) {
              newSelectedItems.splice(idx, 1);
            }
          }
        }
      }

      if (!isTemplate) {
        const templateIsSelected = templateIdx !== -1;

        if (value && !isSelected) {
          newSelectedItems.push(id);

          let hasOneFalse = false;

          for (const item of templateItems) {
            if (!hasOneFalse && newSelectedItems.indexOf(item.id) === -1) {
              hasOneFalse = true;
            }
          }

          if (!hasOneFalse && !templateIsSelected) {
            newSelectedTemplates.push(id);
          } else if (hasOneFalse && templateIsSelected) {
            newSelectedTemplates.splice(templateIdx, 1);
          }
        } else if (!value && isSelected) {
          newSelectedItems.splice(index, 1);

          if (templateIsSelected) {
            newSelectedTemplates.splice(templateIdx, 1);
          }
        }
      }

      setSelectedTemplates(newSelectedTemplates);
      setSelectedItems(newSelectedItems);
    },
    [selectedItems, selectedTemplates, templates],
  );
  function cleanTasksPeriodFilter() {
    config.setConfig('tasks_filter_begin', undefined);
    config.setConfig('tasks_filter_end', undefined);
  }

  // Creates tasks from the selected templateItems
  async function doSave() {
    try {
      await CreateTasksFromSelectedItems({ variables: { account: accountId, ids: selectedItems } });

      notification.open({
        placement: 'bottomRight',
        duration: 2,
        type: 'success',
        message: 'Atividades criadas',
      });

      cleanTasksPeriodFilter();

      history.push(route('id.home.tasks', { id: accountId }));

      /*refetch({
        id: accountId,
      });
      setReloadItemsList(true);*/
    } catch (err) {
      notification.open({ type: 'error', ...errorMessage('graph_err', err) });
    }
  }

  // Deletes the selected templateItems/templates
  async function doDelete() {
    if (selectedItems.length > 0) {
      try {
        await DeleteTemplateItems({ variables: { ids: selectedItems } });

        notification.open({
          placement: 'bottomRight',
          duration: 2,
          type: 'success',
          message: 'Excluído',
        });
        setSelectedItems([]);

        refetch();
        setReloadItemsList(true);
      } catch (err) {
        notification.open({ type: 'error', ...errorMessage('graph_err', err) });
      }
    }

    if (selectedTemplates.length > 0) {
      for (const id of selectedTemplates) {
        try {
          await DeleteTemplate({ variables: { id } });

          notification.open({
            placement: 'bottomRight',
            duration: 2,
            type: 'success',
            message: 'Excluído',
          });
          setSelectedTemplates([]);

          refetch();
          setReloadItemsList(true);
        } catch (err) {
          notification.open({ type: 'error', ...errorMessage('graph_err', err) });
        }
      }
    }
  }

  // Opens the drawer for the selected templateItem
  function openItem(id: string) {
    history.push(
      (!!accountId ? route('id.home.templates', { id: accountId }) : route('templates')) + '#template/' + id,
    );
  }

  const globalTemplatesList = (templates || []).filter((t) => !t.account?.name);
  const accountTemplatesList = (templates || []).filter((t) => !!t.account?.name);

  if (error) return <ErrorAndLoading error={error} />;

  return (
    <div>
      <Card className={styles.root} style={cardStyle} bodyStyle={cardBodyStyle}>
        {(selectedTemplates.length > 0 || selectedItems.length > 0) && (
          <div className={styles.buttons_view}>
            <div>
              <Popconfirm title="Deseja excluir os templates e items selecionados?" onConfirm={() => doDelete()}>
                <Button id="delete_item" type="primary" className={styles.buttons_delete}>
                  Excluir
                </Button>
              </Popconfirm>

              {!!accountId && selectedItems.length > 0 && (
                <Popconfirm
                  title="Deseja criar atividades com base nos templates selecionados?"
                  onConfirm={() => doSave()}>
                  <Button id="create_task_template" type="primary" className={styles.buttons_create}>
                    Criar atividades no cliente
                  </Button>
                </Popconfirm>
              )}
            </div>
          </div>
        )}

        {accountTemplates && accountTemplatesList && (
          <div id="account_templates">
            <div className={styles.div_title_account}>
              <Typography.Title
                style={{
                  fontSize: 20,
                  marginBottom: 16,
                  flex: 1,
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'baseline',
                }}>
                <UserOutlined style={{ marginRight: 4 }} /> Templates de {data?.account?.name}
                <Help
                  title="Templates do cliente"
                  description={
                    'Templates são modelos de atividades, que podem ser utilizados para criar novas atividades. Templates do cliente são utilizados apenas no cliente.'
                  }
                  placement="right"
                  iconStyle={{ color: '#5c5c5c', fontSize: 12, marginLeft: 4 }}
                />
              </Typography.Title>

              <Button
                type="primary"
                className={styles.button_back}
                onClick={() => {
                  history.push(route('id.home.tasks', { id: paramAccountId }));
                }}>
                Voltar
              </Button>
            </div>

            <TemplatesList
              refresh={() => refetch()}
              reload={reloadItemsList}
              templatesArray={accountTemplatesList}
              openItem={openItem}
              accountId={accountId}
              accountName={data?.account?.name}
              onSelect={onSelect}
              selectedTemplates={selectedTemplates}
              selectedItems={selectedItems}
            />

            <div style={{ height: 32 }} />
          </div>
        )}

        {globalTemplatesList && (
          <div id="global_templates">
            <div className={styles.div_title_global}>
              <Typography.Title style={{ fontSize: 20 }} className={styles.title}>
                <GlobalOutlined /> Templates globais
              </Typography.Title>
              <Help
                title="Templates globais"
                description={
                  globalTemplatesList && accountTemplates && accountTemplatesList
                    ? 'Templates globais podem ser utilizados em todos os cliente'
                    : 'Templates são modelos de atividades, que podem ser utilizados para criar novas atividades. Templates globais podem ser utilizados em qualquer cliente.'
                }
                placement="right"
                iconStyle={{ color: '#5c5c5c', fontSize: 12 }}
              />
            </div>

            <TemplatesList
              refresh={() => refetch()}
              reload={reloadItemsList}
              templatesArray={globalTemplatesList}
              openItem={openItem}
              accountId={accountId}
              onSelect={onSelect}
              selectedTemplates={selectedTemplates}
              selectedItems={selectedItems}
              realm={accountTemplates}
            />
          </div>
        )}
      </Card>

      <div style={{ position: 'fixed', bottom: 10, right: 60 }}>
        {(selectedTemplates.length > 0 || selectedItems.length > 0) && (
          <div className={styles.buttons_fixed} onClick={(e) => e.stopPropagation()}>
            <Popconfirm
              title="Deseja excluir os templates e items selecionados?"
              placement="topRight"
              onConfirm={() => doDelete()}>
              <Button
                id="delete_item_bottom"
                icon={<DeleteOutlined style={{ fontSize: 20 }} />}
                type="primary"
                shape="circle"
                size="large"
                className={styles.buttons_delete}
              />
            </Popconfirm>

            {!!accountId && selectedItems.length > 0 && (
              <Popconfirm
                title="Deseja criar atividades com base nos templates selecionados?"
                placement="topRight"
                onConfirm={() => doSave()}>
                <Button
                  id="create_task_template_bottom"
                  icon={<PlusOutlined style={{ fontSize: 20 }} />}
                  type="primary"
                  shape="circle"
                  size="large"
                  className={styles.buttons_create}
                />
              </Popconfirm>
            )}
          </div>
        )}
      </div>

      <TutorialButton tourId="011_TemplatePage" top={80} right={20} placement="left" title="Tutorial de Templates" />

      <TutorialTemplates
        accountId={!!accountId}
        accountTemplates={accountTemplates}
        selectedItems={!!selectedItems && selectedItems.length > 0 ? true : false}
        selectedTemplates={!!selectedTemplates && selectedTemplates.length > 0 ? true : false}
        accountTemplateList={!!accountTemplatesList && accountTemplatesList.length > 0 ? true : false}
        globalTemplateList={!!globalTemplatesList && globalTemplatesList.length > 0 ? true : false}
      />
    </div>
  );
};

export default Templates;

// ---------------- Styles ---------------------------

const cardStyle = { display: 'flex', width: '100%' };
const cardBodyStyle = { width: '100%' };
