import { gql } from '@apollo/client';

//------------------- TASKS ----------------------------------

export const create_task = gql`
  mutation CreateTask(
    $account: ID!
    $title: String!
    $step: TaskStep!
    $body: String
    $execution_date: Date
    $finish_date: Date
    $cell: ID
    $type: TaskType!
    $parent: ID
  ) {
    createTask(
      account: $account
      title: $title
      step: $step
      body: $body
      execution_date: $execution_date
      finish_date: $finish_date
      cell: $cell
      type: $type
      parent: $parent
    ) {
      id
      title
      body
      step
      type
      parent {
        id
        title
        parent {
          id
          title
        }
      }
      finish_date
      ... on DefaultTask {
        execution_date
      }
      cell {
        id
        name
      }
      users {
        id
        name
      }
      account {
        id
        name
      }
    }
  }
`;
export const update_task = gql`
  mutation UpdateTask(
    $id: ID!
    $title: String
    $body: String
    $execution_date: Date
    $finish_date: Date
    $step: TaskStep
    $cell: ID
  ) {
    updateTask(
      id: $id
      title: $title
      body: $body
      execution_date: $execution_date
      finish_date: $finish_date
      step: $step
      cell: $cell
    ) {
      id
      title
      body
      step
      type
      parent {
        id
        title
        parent {
          id
          title
        }
      }
      finish_date
      ... on DefaultTask {
        execution_date
      }
      cell {
        id
        name
      }
      users {
        id
        name
      }
      account {
        id
        name
      }
    }
  }
`;
export const delete_task = gql`
  mutation DeleteTask($id: ID!) {
    deleteTask(id: $id) {
      id
      title
      body
      step
      type
      parent {
        id
        title
        parent {
          id
          title
        }
      }
      from_template_item {
        id
      }
      notes {
        total
      }
      finish_date
      ... on DefaultTask {
        execution_date
      }
      cell {
        id
        name
      }
      users {
        id
        name
      }
      account {
        id
        name
      }
    }
  }
`;

export const convert_task = gql`
  mutation ConvertTask($id: ID!, $type: TaskType!) {
    convertTask(id: $id, type: $type) {
      id
      title
      body
      step
      type
      parent {
        id
        title
        parent {
          id
          title
        }
      }
      from_template_item {
        id
      }
      notes {
        total
      }
      finish_date
      ... on DefaultTask {
        execution_date
      }
      cell {
        id
        name
      }
      users {
        id
        name
      }
      account {
        id
        name
      }
    }
  }
`;
//------------------- Move stuff -----------------------------

export const move_before = gql`
  mutation MoveBefore($type: SortableType!, $id: ID!, $target: ID!) {
    moveBefore(type: $type, id: $id, target: $target)
  }
`;
export const move_after = gql`
  mutation MoveAfter($type: SortableType!, $id: ID!, $target: ID!) {
    moveAfter(type: $type, id: $id, target: $target)
  }
`;

//------------------- TEMPLATE ITEMS ----------------------------------

export const create_template_item = gql`
  mutation CreateTemplateItem($template: ID!, $parent: ID, $title: String!, $body: String, $type: TaskType!) {
    createTemplateItem(template: $template, parent: $parent, title: $title, body: $body, type: $type) {
      id
    }
  }
`;
export const update_template_item = gql`
  mutation UpdateTemplateItem($id: ID!, $title: String, $body: String, $type: TaskType) {
    updateTemplateItem(id: $id, title: $title, body: $body, type: $type) {
      id
    }
  }
`;
export const delete_template_item = gql`
  mutation DeleteTemplateItem($id: ID!) {
    deleteTemplateItem(id: $id) {
      title
    }
  }
`;

export const delete_template_items = gql`
  mutation DeleteTemplateItems($ids: [ID!]!) {
    deleteTemplateItems(ids: $ids)
  }
`;

export const create_tasks_from_template_items = gql`
  mutation DeleteTemplateItems($account: ID!, $ids: [ID!]!) {
    createTasksOfTemplateItems(account: $account, ids: $ids) {
      id
    }
  }
`;

//------------------- TEMPLATES ----------------------------------

export const create_template = gql`
  mutation CreateTemplate($title: String!, $account: ID) {
    createTemplate(title: $title, account: $account) {
      id
    }
  }
`;
export const update_template = gql`
  mutation UpdateTemplate($id: ID!, $title: String!) {
    updateTemplate(id: $id, title: $title) {
      id
    }
  }
`;
export const delete_template = gql`
  mutation DeleteTemplate($id: ID!) {
    deleteTemplate(id: $id) {
      title
    }
  }
`;

//----------------------- LINK ------------------------------

export const create_link = gql`
  mutation CreateLink(
    $type: LinkType!
    $account: ID!
    $subdomain: String!
    $path: String!
    $dest: String!
    $ml_group_id: String!
    $title: String
    $body: String
  ) {
    createLink(
      type: $type
      account: $account
      subdomain: $subdomain
      path: $path
      dest: $dest
      ml_group_id: $ml_group_id
      title: $title
      body: $body
    ) {
      id
      url
    }
  }
`;
export const update_link = gql`
  mutation UpdateLink(
    $id: ID!
    $type: LinkType
    $title: String
    $body: String
    $subdomain: String
    $path: String
    $dest: String
    $ml_group_id: String
  ) {
    updateLink(
      id: $id
      type: $type
      title: $title
      body: $body
      subdomain: $subdomain
      path: $path
      dest: $dest
      ml_group_id: $ml_group_id
    ) {
      id
      url
      subdomain
      path
      dest
      ... on WhatsAppLink {
        title
        body
        ml_group_id
      }
    }
  }
`;
export const delete_link = gql`
  mutation DeleteLink($id: ID!) {
    deleteLink(id: $id)
  }
`;

export const convert_link = gql`
  mutation ConvertLink($id: ID!, $type: LinkType!) {
    convertLink(id: $id, type: $type) {
      id
      dest
      subdomain
      path
      url
      ... on WhatsAppLink {
        title
        body
      }
    }
  }
`;

//----------------------- REALM ------------------------------

export const update_realm = gql`
  mutation UpdateRealm($name: String!) {
    updateRealm(name: $name) {
      id
    }
  }
`;

//---------------------- FILES --------------------------

export const delete_file = gql`
  mutation Delete($id: ID!) {
    deleteFile(id: $id)
  }
`;

//--------------------- TIMER -------------------------

export const create_timer = gql`
  mutation CreateTimer($task: ID!, $start_date: DateTime!, $finish_date: DateTime!, $body: String) {
    createTimer(task: $task, start_date: $start_date, finish_date: $finish_date, body: $body) {
      id
    }
  }
`;
export const start_timer = gql`
  mutation StartTimer($body: String, $task: ID) {
    startTimer(body: $body, task: $task) {
      id
    }
  }
`;
export const end_timer = gql`
  mutation StopTimer($task: ID) {
    stopTimer(task: $task) {
      id
    }
  }
`;
export const update_timer = gql`
  mutation UpdateTimer($id: ID!, $start_date: DateTime, $finish_date: DateTime, $body: String) {
    updateTimer(id: $id, start_date: $start_date, finish_date: $finish_date, body: $body) {
      id
    }
  }
`;
export const delete_timer = gql`
  mutation DeleteTimer($id: ID!) {
    deleteTimer(id: $id) {
      id
    }
  }
`;

//----------------- CRUD NOTES ----------------------------------

export const create_note = gql`
  mutation CreateNote($task: ID!, $body: String!, $files: [AttachFile!]) {
    createNote(task: $task, body: $body, files: $files) {
      id
    }
  }
`;
export const update_note = gql`
  mutation UpdateNote($id: ID!, $body: String!) {
    updateNote(id: $id, body: $body) {
      id
      body
      files(pagination: { limit: -1, page: 1 }) {
        data {
          id
          status
          name
          thumbnailUrl
          info {
            uuid
            mimetype
            size
            url
          }
          opt_info {
            uuid
            mimetype
            size
            url
          }
        }
      }
    }
  }
`;
export const delete_note = gql`
  mutation DeleteNote($id: ID!) {
    deleteNote(id: $id) {
      id
    }
  }
`;

//---------------------- FEEDBACK -----------------------------

export const set_feedback_status = gql`
  mutation SetFeedbackStatus($id: ID!, $status: FeedbackStatus!) {
    setFeedbackStatus(id: $id, status: $status) {
      id
      status
      feedback
      nps
    }
  }
`;

//------------------ LOGIN & LOGOUT ----------------------------

export const user_logout = gql`
  mutation UserLogout {
    logout
  }
`;
export const user_login = gql`
  mutation UserLogin($email: String!, $password: String!) {
    login(email: $email, password: $password)
  }
`;

//----------------- CRUD USER ----------------------------------

export const users_create = gql`
  mutation CreateUser($name: String!, $email: String!, $password: String!, $roles: [UserRole!]!, $status: UserStatus!) {
    createUser(name: $name, email: $email, password: $password, roles: $roles, status: $status) {
      id
      name
      email
      roles
      status
    }
  }
`;
export const users_edit = gql`
  mutation UpdateUser(
    $id: ID!
    $name: String
    $email: String
    $password: String
    $roles: [UserRole!]!
    $status: UserStatus
  ) {
    updateUser(id: $id, name: $name, email: $email, password: $password, roles: $roles, status: $status) {
      id
      name
      email
      roles
      status
    }
  }
`;
export const delete_user = gql`
  mutation DeleteUser($id: ID!) {
    deleteUser(id: $id) {
      name
    }
  }
`;
export const attach_user = gql`
  mutation AttachUser($type: AttachType!, $user: ID!, $target: ID!) {
    attachUser(type: $type, user: $user, target: $target)
  }
`;
export const detach_user = gql`
  mutation DetachUser($type: AttachType!, $user: ID!, $target: ID!) {
    detachUser(type: $type, user: $user, target: $target)
  }
`;

export const update_profile = gql`
  mutation UpdateProfile($name: String, $password: String, $email: String) {
    updateProfile(name: $name, password: $password, email: $email) {
      id
      name
      email
    }
  }
`;

//----------------- CRUD CELL ----------------------------------

export const cells_create = gql`
  mutation CreateCell($name: String!) {
    createCell(name: $name) {
      name
      id
    }
  }
`;
export const cells_edit = gql`
  mutation UpdateCell($id: ID!, $name: String!) {
    updateCell(id: $id, name: $name) {
      name
    }
  }
`;
export const delete_cell = gql`
  mutation DeleteCell($id: ID!) {
    deleteCell(id: $id) {
      name
    }
  }
`;

//----------------- CRUD Account ----------------------------------

export const accounts_create = gql`
  mutation CreateAccount($name: String!, $status: AccountStatus!, $journey: AccountJourney!) {
    createAccount(name: $name, status: $status, journey: $journey) {
      id
      name
      status
      journey
    }
  }
`;
export const account_edit = gql`
  mutation UpdateAccount(
    $id: ID!
    $name: String
    $status: AccountStatus
    $journey: AccountJourney
    $mailerlite_apikey: String
    $monthly_planned_seconds: Int
  ) {
    updateAccount(
      id: $id
      name: $name
      status: $status
      journey: $journey
      mailerlite_apikey: $mailerlite_apikey
      monthly_planned_seconds: $monthly_planned_seconds
    ) {
      id
      name
      status
      journey
      mailerlite_apikey
      monthly_planned_seconds
    }
  }
`;
export const delete_account = gql`
  mutation DeleteAccount($id: ID!) {
    deleteAccount(id: $id) {
      name
    }
  }
`;

//----------------- CRUD AccountInfo ---------------------------

export const account_info_create = gql`
  mutation CreateAccountInfo($account: ID!, $title: String!, $body: String) {
    createAccountInfo(account: $account, title: $title, body: $body) {
      title
      body
      id
    }
  }
`;
export const account_info_update = gql`
  mutation updateAccountInfo($id: ID!, $title: String, $body: String) {
    updateAccountInfo(id: $id, title: $title, body: $body) {
      title
      body
      id
    }
  }
`;
export const account_info_delete = gql`
  mutation deleteAccountInfo($id: ID!) {
    deleteAccountInfo(id: $id) {
      title
      body
      id
    }
  }
`;

//----------------- ATTACH TO ----------------------------------

export const add_user_to_cell = gql`
  mutation AttachUserToCell($cell: ID!, $user: ID!, $type: AttachType!) {
    attachUser(type: $type, user: $user, target: $cell)
  }
`;
export const add_account_to_cell = gql`
  mutation AttachAccountToCell($cell: ID!, $account: ID!) {
    attachCell(cell: $cell, account: $account)
  }
`;
export const add_user_to_account = gql`
  mutation AttachUserToAccount($user: ID!, $account: ID!, $type: AttachType!) {
    attachUser(type: $type, user: $user, target: $account)
  }
`;

//----------------- Detach FROM ----------------------------------

export const remove_user_from_cell = gql`
  mutation DetachUserFromCell($cell: ID!, $user: ID!, $type: AttachType!) {
    detachUser(type: $type, user: $user, target: $cell)
  }
`;
export const remove_user_from_account = gql`
  mutation DetachUserFromAccount($account: ID!, $user: ID!, $type: AttachType!) {
    detachUser(type: $type, user: $user, target: $account)
  }
`;
export const remove_account_from_cell = gql`
  mutation DetachAccountFromCell($account: ID!, $cell: ID!) {
    detachCell(account: $account, cell: $cell)
  }
`;

//----------------------- NOTIFICATIONS ----------------------------------

export const delete_notification = gql`
  mutation DeleteNotification($id: ID!) {
    deleteNotification(id: $id) {
      id
    }
  }
`;
export const mark_all_as_viewed = gql`
  mutation MarkAllAsViewed {
    markAllNotificationsAsViewed {
      id
    }
  }
`;
export const mark_as_viewed = gql`
  mutation MarkAsViewed($id: ID!) {
    markNotificationAsViewed(id: $id) {
      id
      type
      viewed
      notified_at
      viewed_at
      initiator {
        id
        name
        avatarUrl
      }
      recipient {
        id
        name
      }
      ... on TaskStepChangedNotification {
        task {
          id
          title
          account {
            id
            name
          }
        }
        step
      }
      ... on TaskNoteCreatedNotification {
        task {
          id
          title
          account {
            id
            name
          }
        }
      }
      ... on TaskFeedbackCreatedNotification {
        task {
          id
          title
          account {
            id
            name
          }
        }
      }
    }
  }
`;

export const sync_leads = gql`
  mutation SyncLeads($account: ID!) {
    syncLeads(account: $account)
  }
`;

export const update_mailerlite_apikey = gql`
  mutation UpdateMailerliteApiKey($account: ID!, $mailerlite_apikey: String) {
    updateMailerliteApiKey(account: $account, mailerlite_apikey: $mailerlite_apikey) {
      id
      name
      status
      journey
      mailerlite_apikey
      monthly_planned_seconds
    }
  }
`;

export const add_mailerlite_field = gql`
  mutation AddMailerliteField($account: ID!, $key: String!, $name: String!) {
    addMailerliteField(account: $account, key: $key, name: $name) {
      id
      mailerlite_fields {
        key
        name
      }
    }
  }
`;

export const delete_mailerlite_field = gql`
  mutation DeleteMailerliteField($account: ID!, $key: String!) {
    deleteMailerliteField(account: $account, key: $key) {
      id
      mailerlite_fields {
        key
        name
      }
    }
  }
`;
