import React, { useState, useEffect } from 'react';
import { Button } from 'antd';
import { useQuery } from '@apollo/client';

import ErrorAndLoading from '@comp/ErrorAndLoading';
import NewNote from './NewNote';
import EditNote from './EditNote';

import { task_notes } from '@logic/queries';
import TutorialButton from '@comp/TutorialButton';
import TutorialNoteTab from './TutorialNoteTab';

interface Props {
  taskId: string;
}

const NotesTab: React.FC<Props> = ({ taskId }) => {
  const [notes, setNotes] = useState<any[]>();
  const [total, setTotal] = useState(0);
  const [page, setPage] = useState(1);

  //------------------------- Queries and mutations ----------------------------

  const { data, loading, error, refetch } = useQuery(task_notes, {
    variables: { id: taskId, limit: 10, page },
    fetchPolicy: 'cache-and-network',
  });

  useEffect(() => {
    if (!data) return;

    setNotes(data.task?.notes?.data);
    setTotal(data.task?.notes?.total);
  }, [data]);

  useEffect(() => {
    setPage(1);
    setTotal(0);
    setNotes(undefined);
  }, [taskId]);

  function Refresh() {
    setPage(1);
    setTotal(0);

    refetch({ id: taskId, limit: 10, page });
  }

  //------------------------------------------------------------------------------

  return (
    <div>
      <NewNote taskId={taskId} refresh={() => Refresh()} />

      {loading && <ErrorAndLoading loading={loading} />}

      {error && <ErrorAndLoading error={error} />}

      {notes &&
        notes.length > 0 &&
        notes.map(({ body, id, user, created_at }, idx: number) => {
          return (
            <EditNote
              key={id}
              refresh={() => Refresh()}
              index={idx}
              taskId={taskId}
              note={body}
              createdAt={created_at}
              noteId={id}
              user={user}
            />
          );
        })}

      {notes && notes.length < total && (
        <div style={{ display: 'flex' }}>
          <Button
            type="text"
            onClick={() => {
              refetch({ id: taskId, limit: 10, page: page + 1 });

              setPage(page + 1);
            }}>
            Exibindo {notes.length} de {total}. Clique para ver mais
          </Button>
        </div>
      )}

      {!loading && (!notes || notes.length === 0) && (
        <div style={{ display: 'flex', justifyContent: 'center' }}>Nenhuma nota para exibir</div>
      )}

      <TutorialButton
        tourId="007_NotesTab"
        circleSize="small"
        iconSize={14}
        bottom={20}
        right={40}
        placement="left"
        title="Tutorial das notas"
      />

      <TutorialNoteTab hasNotes={!notes || notes.length === 0 ? false : true} />
    </div>
  );
};

export default NotesTab;
