import { useCallback, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { DocumentNode, LazyQueryHookOptions, TypedDocumentNode } from '@apollo/client';
import { io } from 'socket.io-client';

import { store } from '@logic/context';

export let socket = io(process.env.REACT_APP_GRAPH_HTTP_URL || 'http://localhost:5000', { path: '/ws' });

export function authenticate(token: string) {
  socket.emit('token', token);
}

socket.on('connect', () => {
  if (store.token) authenticate(store.token);
});

//socket.onAny((aaa: any) => console.log(aaa));

export function useSocketEvent<T = any>(
  room: string | undefined,
  event: string | string[],
  callback: (data: T) => void,
) {
  const { t } = useTranslation('logic');

  const eventId = useMemo(() => {
    const roomId = room || '?';
    return (typeof event === 'string' ? [roomId, event] : [roomId, ...event]).join('_').toLowerCase();
  }, [room, event]).toLowerCase();

  // Garante que o ID é válido
  if (!eventId) throw new Error(t('socket_error'));

  useEffect(() => {
    if (!room) return;

    //console.log(eventId);

    socket.on(eventId, callback);
    socket.emit('join', room);

    return () => {
      socket.emit('leave', room);
      socket.off(eventId, callback);
    };
  }, [room, eventId, callback]);
}

export function useSocketEventQuery<T = any>(
  room: string | undefined,
  event: string | string[],
  query: DocumentNode | TypedDocumentNode,
  options: LazyQueryHookOptions,
  callback: (data: T) => void,
) {
  const onData = useCallback(
    (id: string) => {
      // @ts-ignore  FIXME  stop using window
      window.apolloClient
        .query({ query, variables: { ...options.variables, id } })
        .then(({ data }: any) => callback(data));
    },
    [callback, query, options],
  );

  useSocketEvent(room, event, onData);
}
