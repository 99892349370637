import React, { useEffect, useState } from 'react';
import { Card, Col, Row } from 'antd';
import { gql, useQuery } from '@apollo/client';

import ErrorAndLoading from '@comp/ErrorAndLoading';

import Goal from './Goal/index';
import Indicators from './Indicators';
import MeanPerformance from './MeanPerformance';
import Welcome from './Welcome';

import styles from './styles.module.less';
import { weekDays } from '@logic/functions/date';

const Performance: React.FC = () => {
  const [user, setUser] = useState<any>();

  const days = weekDays();

  //----------------------------- QUERY -------------------------------

  const { data, loading, error } = useQuery(user_summary, { fetchPolicy: 'cache-and-network' });

  //----------------------------------------------------------------------

  useEffect(() => {
    if (!data) return;
    setUser(data.me);
  }, [data]);

  if (loading) return <ErrorAndLoading loading={loading} borderless cardStyle={errorCardStyle} />;
  if (error) return <ErrorAndLoading error={error} />;

  let performance = 0;

  if (user?.summary && user.summary.tasks_total > 0) {
    const tasksPerDay = user.summary.tasks_total / (days.next.length + days.prev.length || 1);
    const tasksDidPerDay = (user.summary.tasks_done - user.summary.tasks_delayed * 1.5) / (days.prev.length || 1);
    performance = tasksDidPerDay / tasksPerDay || 1;
  }

  if (!!user) {
    return (
      <Card style={cardStyle} bodyStyle={cardBodyStyle} id="performance">
        <Row style={{ margin: 0, display: 'flex' }}>
          <Col xs={24} sm={24} md={24} lg={24} xl={12} xxl={12} className={styles.welcome_user}>
            <Welcome name={user.name} />

            <MeanPerformance value={Math.round(performance * 100)} />

            <Goal
              total={user.summary?.tasks_total}
              done={user.summary?.tasks_done}
              weekdaysUntilEndOfMonth={days.next.length}
            />
          </Col>

          <Col xs={24} sm={24} md={24} lg={24} xl={12} xxl={12} className={styles.indicators}>
            <Indicators summaryData={user.summary} />
          </Col>
        </Row>
      </Card>
    );
  }

  return null;
};

export default Performance;

const cardStyle = {
  backgroundColor: '#3385FF',
  borderRadius: 4,
  boxShadow: '0 1px 4px #00000040',
  border: '1px solid #3385FF',
};

const errorCardStyle = {
  backgroundColor: '#3385FF',
  borderRadius: 4,
  boxShadow: '0 1px 4px #00000040',
  minHeight: 320,
};

const cardBodyStyle = { padding: 0 };

const user_summary = gql`
  query UserSummary {
    me {
      id
      name
      summary {
        tasks_total
        tasks_done
        tasks_delayed
        planned_seconds
        planned_seconds_used
        total_seconds_used
      }
    }
  }
`;
