import React from 'react';
import { Card } from 'antd';

import ReportsPeriodFilter from '@pages/TemplatesReports/ReportsPeriodFilter';
import AccountTimeReports from './AccountTimeReports';

const TimeReports: React.FC = () => {
  return (
    <Card>
      <ReportsPeriodFilter />

      <div style={{ height: 16 }} />

      <AccountTimeReports />
    </Card>
  );
};

export default TimeReports;
