import React from 'react';
import { useTranslation } from 'react-i18next';
import { Col, Popconfirm, Tooltip } from 'antd';

import { Loading3QuartersOutlined, FilePdfOutlined, CloseCircleOutlined, FileOutlined } from '@ant-design/icons';

import unloadedImage from '../../../../img/unloaded_image.png';
import unloadedVideo from '../../../../img/unloaded_video.png';

import styles from './styles.module.less';

interface Props {
  id: string;
  idx: number;
  name: string;
  info: any;
  opt_info: any;
  status: string;
  thumbnailUrl: string;
  previewSize?: number;
  deleteFile: (id: string) => void;
  setOpenMd: (idx: number, id: string) => void;
}

// Item da grade de arquivos, apenas renderiza

const FilesListItem: React.FC<Props> = ({
  id,
  idx,
  name,
  info,
  opt_info,
  status,
  deleteFile,
  thumbnailUrl,
  previewSize,
  setOpenMd,
}) => {
  const { t } = useTranslation('upload');

  const done = status === 'done';
  const failed = status === 'failed';

  const file = opt_info || info;
  const mimetype = file?.mimetype || '';
  const url = file?.url;

  const video = mimetype.startsWith('video');
  const image = mimetype.startsWith('image');
  const pdf = mimetype.indexOf('pdf') !== -1;

  const size = previewSize || 120;

  return (
    <Col style={colStyle}>
      <span style={{ position: 'absolute', top: 0, right: size / 7.5 }}>
        <Popconfirm
          title={t('delete_question')}
          cancelText={t('cancel_text')}
          okText={t('ok_text')}
          onConfirm={() => deleteFile(id)}>
          <CloseCircleOutlined />
        </Popconfirm>
      </span>

      <Tooltip destroyTooltipOnHide={{ keepParent: false }} title={name}>
        <div className={styles.item_div} style={{ width: size, height: size }}>
          {(video || image) && !(done || failed) && <Loading3QuartersOutlined spin style={{ fontSize: size / 6 }} />}

          {(done || failed) && video && (
            <div
              onClick={() => setOpenMd(idx, id)}
              style={{
                width: size,
                height: size,
                cursor: 'pointer',
                background: `#d9d9d9 no-repeat center`,
                backgroundImage: `url(${thumbnailUrl}), url(${unloadedVideo})`,
                backgroundSize: 'cover',
              }}
            />
          )}

          {(done || failed) && image && (
            <div
              onClick={() => setOpenMd(idx, id)}
              style={{
                width: size,
                height: size,
                cursor: 'pointer',
                background: `#d9d9d9 no-repeat center`,
                backgroundImage: `url(${thumbnailUrl}), url(${unloadedImage})`,
                backgroundSize: 'cover',
              }}
            />
          )}

          {pdf && (
            <a href={url} target="_blank" rel="noopener noreferrer">
              <FilePdfOutlined style={{ fontSize: size / 3 }} />
            </a>
          )}

          {!image && !video && !pdf && (
            <a href={url} target="_blank" rel="noopener noreferrer">
              <FileOutlined style={{ fontSize: size / 3 }} />
            </a>
          )}
        </div>
      </Tooltip>
    </Col>
  );
};

export default FilesListItem;

const colStyle = { display: 'flex', alignItems: 'center', justifyContent: 'center', paddingTop: 6 };
